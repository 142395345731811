export const DEVICE_TARIFF_PACKAGE_ID = {
  MONTH: 1,
  ANNUAL: 2,
}

export const DONGLE_SIGNAL_ID = {
  SIGNAL_BAD: 1,
  SIGNAL_MEDIUM: 2,
  SIGNAL_GOOD: 3,
}
