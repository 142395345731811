import {
  DongleIMEIChangeBackendResponse,
  DongleIMEIChangeResponse,
} from '../../../../types/dongle/IMEIChange'

export const mapDongleIMEIChangeBackendToDongleIMEIChange = (
  item: DongleIMEIChangeBackendResponse,
): DongleIMEIChangeResponse => {
  const { result } = item.data

  return {
    result: result,
  }
}
