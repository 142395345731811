// @ts-nocheck

// TODO: add TS to here

import React from 'react'
import { ReactComponent as CaretRightIcon } from '../../../../../../../assets/icons/caret-right.svg'
import { ReactComponent as TrashIcon } from '../../../../../../../assets/icons/trash.svg'
import { DongleListResponse } from '../../../../../../../sdk/datagates/types/dongle/_crud/list'
import { RedirectionListResponse } from '../../../../../../../sdk/datagates/types/redirection/_crud/list'
import { ActionsCell } from '../../../../../../../shared/ui-kit-2/data-display/table/components/Cells/ActionsCell/ActionsCell'
import { LimitedCell } from '../../../../../../../shared/ui-kit-2/data-display/table/components/Cells/LimitedCell/LimitedCell'
import { RowCell } from '../../../../../../../shared/ui-kit-2/data-display/table/components/Cells/RowCell/RowCell'
import { getGroupHeaderDataByDate } from '../../../../../../../shared/ui-kit-2/data-display/table/methods/getGroupHeaderDataByDate'
import { RowGroupType } from '../../../../../../../shared/ui-kit-2/data-display/table/Table'
import IconButton from '../../../../../../../shared/ui-kit-3/components/IconButton/IconButton'
import { Sticker } from '../../../../../../../shared/ui-kit-3/components/Sticker'

export const getTableRowGroups = (
  redirections: RedirectionListResponse['redirections'],
  devices: DongleListResponse['dongles'],
  onEditRedirection: (
    redirection: RedirectionListResponse['redirections'][0],
  ) => void,
  onDeleteRedirection: (redirectionId: string) => void,
) => {
  const smsGroups = getGroupHeaderDataByDate(redirections)

  const ModemsStickers = ({ deviceIds }) => {
    if (deviceIds.length === 0) {
      return <Sticker variant={'darkgrey'} text={'Any'} />
    }

    return deviceIds.map((deviceId) => {
      const deviceById = devices.find(({ dongleId }) => dongleId === deviceId)

      return deviceById ? (
        <Sticker variant={'darkgrey'} text={deviceById.name} />
      ) : (
        <></>
      )
    })
  }

  const NumbersSticker = ({
    fromPhones,
    fromContactIds,
    fromContactGroupIds,
  }) => {
    if (
      fromPhones.length === 0 &&
      fromContactIds.length === 0 &&
      fromContactGroupIds.length === 0
    ) {
      return <Sticker variant={'darkgrey'} text={'Any'} />
    }

    return (
      <Sticker
        variant={'darkgrey'}
        text={
          fromPhones.length ||
          fromContactIds.length ||
          fromContactGroupIds.length
        }
      />
    )
  }

  return smsGroups.map<RowGroupType>(([date, smsRows]) => ({
    groupHeader: (
      <div>
        <div>{date}</div>
      </div>
    ),
    rows: smsRows.map((redirection) => {
      const {
        name,
        deviceIds,
        toEmails,
        toTelegramChatIds,
        fromPhones,
        fromContactIds,
        fromContactGroupIds,
      } = redirection

      return [
        <LimitedCell>{name}</LimitedCell>,
        <RowCell>
          <ModemsStickers deviceIds={deviceIds} />
        </RowCell>,
        <RowCell>
          <NumbersSticker
            fromPhones={fromPhones}
            fromContactIds={fromContactIds}
            fromContactGroupIds={fromContactGroupIds}
          />
        </RowCell>,
        <RowCell>
          {toEmails.map((email) => (
            <Sticker variant={'darkgrey'} text={email} />
          ))}
        </RowCell>,
        <RowCell>
          {toTelegramChatIds.map((telegram) => (
            <Sticker variant={'darkgrey'} text={`@${telegram}`} />
          ))}
        </RowCell>,
        <ActionsCell>
          <IconButton
            onClick={() => onDeleteRedirection(redirection.redirectionId)}
            icon={<TrashIcon />}
          />

          <IconButton
            onClick={() => onEditRedirection(redirection)}
            icon={<CaretRightIcon />}
          />
        </ActionsCell>,
      ]
    }),
  }))
}
