import React, { FC } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  wrapperProps?: React.HTMLProps<HTMLDivElement>
  color?: string
  isSmall?: boolean
}

export const Loader: FC<LoaderProps> = ({
  wrapperProps,
  color = '#39BE46',
  isSmall = false,
}) => {
  return (
    <div
      className={clsx(styles.pageLoader, !isSmall && styles.pageLoader_big)}
      {...wrapperProps}
    >
      <svg
        width={isSmall ? '45' : '180'}
        height={isSmall ? '45' : '180'}
        viewBox={isSmall ? '0 0 45 45' : '0 0 90 90'}
        xmlns="http://www.w3.org/2000/svg"
        stroke={color}
      >
        <g
          fill="none"
          fillRule="evenodd"
          transform="translate(1 1)"
          strokeWidth="2"
        >
          <circle
            cx={isSmall ? '22' : '40'}
            cy={isSmall ? '22' : '40'}
            r="6"
            strokeOpacity="0"
          >
            <animate
              attributeName="r"
              begin="0s"
              dur="3s"
              values="6;22"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="0s"
              dur="3s"
              values="1;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              begin="0s"
              dur="3s"
              values="2;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle
            cx={isSmall ? '22' : '40'}
            cy={isSmall ? '22' : '40'}
            r="6"
            strokeOpacity="0"
          >
            <animate
              attributeName="r"
              begin="1s"
              dur="3s"
              values="6;22"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="1s"
              dur="3s"
              values="1;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              begin="1s"
              dur="3s"
              values="2;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle
            cx={isSmall ? '22' : '40'}
            cy={isSmall ? '22' : '40'}
            r="6"
            strokeOpacity="0"
          >
            <animate
              attributeName="r"
              begin="2s"
              dur="3s"
              values="6;22"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="2s"
              dur="3s"
              values="1;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              begin="2s"
              dur="3s"
              values="2;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
    </div>
  )
}
