import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {TariffPackageListResponse} from "../../../sdk/datagates/types/billing/tariff-package/_crud/list";


type TariffPackagesType = {
  list: TariffPackageListResponse['tariffPackages']
}

const tariffPackagesInitialState: TariffPackagesType = {
  list: []
}

const tariffPackagesSlice = createSlice({
  name: 'tariffPackages',
  initialState: tariffPackagesInitialState,
  reducers: {
    setTariffPackages(state, { payload }: PayloadAction<TariffPackagesType>) {
      state.list = payload.list ? [...payload.list] : []
    },
  },
})

export const { setTariffPackages } = tariffPackagesSlice.actions
export default tariffPackagesSlice.reducer
