export const isValidWavByBase64 = (base64: string): boolean => {
  const byteCharacters = atob(base64)
  const header = byteCharacters.slice(0, 44)

  const riff = header.slice(0, 4) === 'RIFF'
  const wave = header.slice(8, 12) === 'WAVE'
  const fmt = header.slice(12, 16) === 'fmt '

  return riff && wave && fmt
}
