import { CallsAutoResponse } from '../../../../../../../../../pages/lk/subpages/calls/subpages/calls-auto-response/CallsAutoResponse'
import { AuthRoutePath } from '../../../../../../../constants/authRoutePath'
import { CallsRoutePath } from '../../../../../../../constants/callsRoutePath'
import { AuthRoute } from '../../../../../../../enums/authRoute'
import { CallsRoute } from '../../../../../../../enums/callsRoute'
import { RouteDescriptor } from '../../../../../../../types/routeDescriptor'

export const callsAutoResponse: RouteDescriptor<CallsRoute.CallsAutoResponse> =
  {
    route: CallsRoute.CallsAutoResponse,
    type: 'subpage',
    title: 'Teleleo',
    path: CallsRoutePath[CallsRoute.CallsAutoResponse],
    render: CallsAutoResponse,
    accessLevel: 'user',
    noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
  }
