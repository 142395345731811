import React, { ComponentProps } from 'react'
import { Field } from 'react-final-form'
import { DongleListResponse } from '../../../../sdk/datagates/types/dongle/_crud/list'
import { SelectSearchField } from '../../../lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import { SelectSearchProps } from '../../../ui-kit-2/inputs/select-search/SelectSearch'

type DeviceSelectorProps = {
  devices: DongleListResponse['dongles']
} & SelectSearchProps &
  ComponentProps<typeof Field<string>>

// TODO: в будущем нужно перенести сюда всю логику селектора девайса (например, автовыбор девайса если он один)
const DeviceSelector = ({ devices, ...props }: DeviceSelectorProps) => {
  return <SelectSearchField {...props} />
}

export default DeviceSelector
