import React from 'react'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { ActivateDeviceContextProvider } from './contexts/ActivateDeviceContext'
import styles from './styles.module.scss'

export const ManualDeviceActivationPage = () => {
  return (
    <PageWrapper>
      <ActivateDeviceContextProvider />
    </PageWrapper>
  )
}
