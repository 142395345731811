import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { isNumber } from 'lodash'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../app/hooks/useSiteVersion'
import { ReactComponent as ContactsIcon } from '../../../../assets/icons/header-dropdown-contacts.svg'
import { ReactComponent as CallsIcon } from '../../../../assets/icons/sidebar-calls.svg'
import { ReactComponent as DashboardIcon } from '../../../../assets/icons/sidebar-dashboard.svg'
import { ReactComponent as DevicesIcon } from '../../../../assets/icons/sidebar-devices.svg'
import { ReactComponent as SmsIcon } from '../../../../assets/icons/sidebar-sms.svg'
import { ReactComponent as VPNIcon } from '../../../../assets/icons/sidebar-vpn.svg'
import { ReactComponent as WifiIcon } from '../../../../assets/icons/sidebar-wifi.svg'
import {
  CallsRootRoutePath,
  CallsRoutePath,
} from '../../../../config/routes/constants/callsRoutePath'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import {
  SmsRootRoutePath,
  SmsRoutePath,
} from '../../../../config/routes/constants/smsRoutePath'
import { CallsRoute } from '../../../../config/routes/enums/callsRoute'
import { Route } from '../../../../config/routes/enums/route'
import { SmsRoute } from '../../../../config/routes/enums/smsRoute'
import { PushNotificationsType } from '../../../../store/reducers/push-notifications'
import { LC } from '../../../../tests/e2e/locators'
import styles from './styles.module.scss'

export type SidebarLink = {
  key: string
  icon: ReactElement
  path: string
  events?: number
  pathsGroup?: string
  label: string
  testId?: string
}

export const SidebarAndTabBarPagesMessages = defineMessages({
  dashboard: {
    id: 'SidebarAndTabBarPagesMessages.dashboard',
    defaultMessage: 'Dashboard',
  },
  sms: {
    id: 'SidebarAndTabBarPagesMessages.sms',
    defaultMessage: 'SMS',
  },
  calls: {
    id: 'SidebarAndTabBarPagesMessages.calls',
    defaultMessage: 'Calls',
  },
  wifi: {
    id: 'SidebarAndTabBarPagesMessages.wifi',
    defaultMessage: 'Wi-Fi',
  },
  devices: {
    id: 'SidebarAndTabBarPagesMessages.devices',
    defaultMessage: 'Devices',
  },
  contacts: {
    id: 'SidebarAndTabBarPagesMessages.contacts',
    defaultMessage: 'Contacts',
  },
  vpn: {
    id: 'SidebarAndTabBarPagesMessages.vpn',
    defaultMessage: 'VPN',
  },
})

export const SidebarPages = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const { pathname } = useLocation()
  const { siteVersion } = useSiteVersion()

  // @ts-ignore
  const {
    pushNotifications,
  }: {
    // @ts-ignore
    pushNotifications: PushNotificationsType.pushNotifications
  } =
    useSelector((state) => state)

  const pages: SidebarLink[] = [
    {
      key: RoutePath[Route.Dashboard],
      icon: <DashboardIcon />,
      path: RoutePath[Route.Dashboard],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.dashboard),
      events: 0,
    },
    {
      key: SmsRoutePath[SmsRoute.SmsList],
      icon: <SmsIcon />,
      path: SmsRoutePath[SmsRoute.SmsList],
      pathsGroup: SmsRootRoutePath,
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.sms),
      testId: LC.HEADER.SMS_BTN,
      events: pushNotifications?.sms?.totalCount,
    },
    ...(siteVersion !== 'ru'
      ? [
          {
            key: CallsRoutePath[CallsRoute.CallsList],
            icon: <CallsIcon />,
            path: CallsRoutePath[CallsRoute.CallsList],
            pathsGroup: CallsRootRoutePath,
            label: intl.formatMessage(SidebarAndTabBarPagesMessages.calls),
            testId: LC.HEADER.CALLS_BTN,
            events: pushNotifications?.calls?.totalCount,
          },
        ]
      : []),
    {
      key: RoutePath[Route.WifiList],
      icon: <WifiIcon />,
      path: RoutePath[Route.WifiList],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.wifi),
      testId: LC.HEADER.WIFI_BTN,
      events: pushNotifications?.wifi?.totalCount,
    },
    {
      key: RoutePath[Route.VPN],
      icon: <VPNIcon />,
      path: RoutePath[Route.VPN],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.vpn),
      testId: LC.HEADER.DEVICES_BTN,
      // events: pushNotifications?.vpn?.totalCount,
    },
    {
      key: RoutePath[Route.DeviceList],
      icon: <DevicesIcon />,
      path: RoutePath[Route.DeviceList],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.devices),
      testId: LC.HEADER.DEVICES_BTN,
      // events: pushNotifications?.modems?.totalCount,
    },
    {
      key: RoutePath[Route.Contacts],
      icon: <ContactsIcon />,
      path: RoutePath[Route.Contacts],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.contacts),
      testId: LC.HEADER.CONTACTS_BTN,
      // events: pushNotifications?.contacts?.totalCount,
    },
  ]

  return (
    <div className={styles.Container}>
      {pages.map((p) => (
        <div
          key={p.key}
          className={clsx(
            styles.PageButton,
            (pathname === p.path ||
              (p.pathsGroup && pathname.includes(p.pathsGroup))) &&
              styles.PageButton_active,
          )}
          onClick={() => navigate(p.path)}
          data-test-id={p.testId}
        >
          <div className={styles.PageButtonIcon}>{p.icon}</div>

          <div className={styles.PageButtonTitle}>{p.label}</div>

          {isNumber(p?.events) && p.events > 0 && (
            <div className={styles.PageButtonEventCounter}>
              {p.events > 50 ? '50+' : p.events}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
