import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DialogProvider } from '../layouts/dialog/DialogProvider'
import { Header } from '../layouts/header/Header'
import { MobileHeader } from '../layouts/mobileHeader/MobileHeader'
import { ModalsProvider } from '../layouts/modals/ModalsProvider'
import { Sidebar } from '../layouts/sidebar/Sidebar'
import { SnackbarProvider } from '../layouts/snackbar/SnackbarProvider'
import { TabBar } from '../layouts/tabBar/TabBar'
import { RootRoutes } from '../routing/RootRoutes'
import { usePushNotifications } from '../sdk/hooks/use-push-notifications/usePushNotifications'
import SoundPlayer from '../shared/global-components/SoundPlayer'
import { WEB_SOCKETS_EVENTS_VALUES } from '../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../shared/lib/hooks/useLessThanDeviceScreen'
import { useWebSockets } from '../shared/lib/hooks/useWebSockets'
import { useAppSelector } from '../store'
import { setPushNotifications } from '../store/reducers/push-notifications'
import { useAppFirstRender } from './hooks/useAppFirstRender'
import { NotificationsProvider } from './providers/notifications/notifications'
import { withProviders } from './providers/withProviders'
import styles from './styles.module.scss'
import {useTariffPackages} from "../sdk/hooks/use-tariff-packages/useTariffPackages";

const App = () => {
  // TODO: перенести в App
  useAppFirstRender()
  useTariffPackages({ initialFetch: true })

  const {
    user: { isAuthChecked },
  } = useAppSelector((state) => state)

  const dispatch = useDispatch()

  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)
  // const jivoChat = useJivoChat()

  const {
    pushNotifications,
    handleFetchNotifications: handleFetchAllNotifications,
  } = usePushNotifications({
    events: Object.values(WEB_SOCKETS_EVENTS_VALUES),
  })

  const { webSocketsEventData } = useWebSockets({
    events: Object.values(WEB_SOCKETS_EVENTS_VALUES),
  })

  useEffect(() => {
    if (webSocketsEventData && webSocketsEventData.hasOwnProperty('event')) {
      handleFetchAllNotifications()
    }
  }, [webSocketsEventData])

  const getPushNotificationsCountByEvents = (
    events: Array<WEB_SOCKETS_EVENTS_VALUES>,
  ) =>
    pushNotifications
      ? pushNotifications
          .filter(({ event }) => events.includes(event))
          .reduce((totalCount, { count }) => totalCount + count, 0)
      : 0

  useEffect(() => {
    dispatch(
      setPushNotifications({
        sms: {
          smsList: getPushNotificationsCountByEvents([
            WEB_SOCKETS_EVENTS_VALUES.sms_status_changed,
            WEB_SOCKETS_EVENTS_VALUES.sms_incoming_created,
            WEB_SOCKETS_EVENTS_VALUES.sms_outgoing_delayed_created,
          ]),
          smsAutoReplies: 0,
          bulkSms: 0,
          totalCount: getPushNotificationsCountByEvents([
            WEB_SOCKETS_EVENTS_VALUES.sms_status_changed,
            WEB_SOCKETS_EVENTS_VALUES.sms_incoming_created,
            WEB_SOCKETS_EVENTS_VALUES.sms_outgoing_delayed_created,
          ]),
        },
        calls: {
          callsList: getPushNotificationsCountByEvents([
            WEB_SOCKETS_EVENTS_VALUES.call_created,
          ]),
          callsNotifications: 0,
          autoReply: 0,
          autoReplyRules: 0,
          autoDialer: 0,
          totalCount: getPushNotificationsCountByEvents([
            WEB_SOCKETS_EVENTS_VALUES.call_created,
          ]),
        },
        wifi: {
          wifiList: getPushNotificationsCountByEvents([
            WEB_SOCKETS_EVENTS_VALUES.dongle_network_status_changed,
            WEB_SOCKETS_EVENTS_VALUES.dongle_subscription_status_changed,
          ]),
          totalCount: getPushNotificationsCountByEvents([
            WEB_SOCKETS_EVENTS_VALUES.dongle_network_status_changed,
            WEB_SOCKETS_EVENTS_VALUES.dongle_subscription_status_changed,
          ]),
        },
        vpn: {
          vpnList: 0,
          totalCount: 0,
        },
        modems: {
          modemsList: 0,
          totalCount: 0,
        },
        contacts: {
          contactsList: 0,
          totalCount: 0,
        },
      }),
    )
  }, [pushNotifications, webSocketsEventData])

  if (!isAuthChecked) {
    return <div className={styles.AppLoader} />
  }

  return (
    <NotificationsProvider
      handleFetchAllNotifications={handleFetchAllNotifications}
    >
      <div className={styles.AppWrapper}>
        {isMobile ? <MobileHeader /> : <Header />}

        {isMobile ? <TabBar /> : <Sidebar />}

        <RootRoutes />
        <ModalsProvider />
        <DialogProvider />
        <SnackbarProvider />

        {/*<Footer />*/}
        {/*jivoChat*/}
        <SoundPlayer />
      </div>
    </NotificationsProvider>
  )
}

export default withProviders(App)
