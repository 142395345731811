import { defineMessages, useIntl } from 'react-intl'
import { DialogComponent } from '../../../../../shared/ui-kit-2/data-display/dialog/Dialog'

type RebootDeviceDialogType = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  loading: boolean
}

const RebootDeviceDialogMessages = defineMessages({
  title: {
    id: 'RebootDeviceDialogMessages.title',
    defaultMessage: 'Reboot device',
  },
  content: {
    id: 'RebootDeviceDialogMessages.content',
    defaultMessage: 'Are you sure you want to reboot your device?',
  },
  actionNo: {
    id: 'RebootDeviceDialogMessages.actionNo',
    defaultMessage: 'No',
  },
  actionYes: {
    id: 'RebootDeviceDialogMessages.actionYes',
    defaultMessage: "Yes, let's reboot",
  },
})

export const RebootDeviceDialog = ({
  open,
  onClose,
  onSuccess,
  loading,
}: RebootDeviceDialogType) => {
  const intl = useIntl()

  return (
    <DialogComponent
      onClose={onClose}
      onSuccess={onSuccess}
      open={open}
      title={intl.formatMessage(RebootDeviceDialogMessages.title)}
      content={intl.formatMessage(RebootDeviceDialogMessages.content)}
      actions={{
        cancel: intl.formatMessage(
          RebootDeviceDialogMessages.actionNo,
        ) as string,
        submit: intl.formatMessage(
          RebootDeviceDialogMessages.actionYes,
        ) as string,
      }}
      loading={loading}
    />
  )
}
