import React, { useEffect, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Navigate, Route as ReactRoute, Routes } from 'react-router-dom'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { SmsRoutePath } from '../../../../config/routes/constants/smsRoutePath'
import { Route } from '../../../../config/routes/enums/route'
import { SmsRoute } from '../../../../config/routes/enums/smsRoute'
import { smsRoutes } from '../../../../config/routes/smsRoutes'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { AccessRoute } from '../../../../routing/AccessRoute'
import { useDevices } from '../../../../sdk/hooks/use-devices/useDevices'
import {
  CloudTabs,
  CloudTabType,
} from '../../../../shared/ui-kit-2/navigation/cloud-tabs/CloudTabs'
import styles from './styles.module.scss'
import {DongleListResponse} from "../../../../sdk/datagates/types/dongle/_crud/list";

export interface SmsListPageProps {
  activeDevices: DongleListResponse['dongles']
  activeDevicesLoading: boolean
  userHasDevices: boolean
}

const SMS_PAGE_TABS_IDS = {
  SMS_LIST: SmsRoutePath[SmsRoute.SmsList],
  SMS_REDIRECTION: SmsRoutePath[SmsRoute.SmsRedirection],
  SMS_AUTO_REPLY: SmsRoutePath[SmsRoute.SmsAutoReply],
  SMS_MAILING: SmsRoutePath[SmsRoute.SmsMailing],
}

const SmsPageMessages = defineMessages({
  smsList: {
    id: 'SmsPageMessages.smsList',
    defaultMessage: 'SMS',
  },
  smsRules: {
    id: 'SmsPageMessages.settings',
    defaultMessage: 'SMS Settings',
  },
  smsRedirection: {
    id: 'SmsPageMessages.smsRedirection',
    defaultMessage: 'Redirection Settings',
  },
  smsAutoReply: {
    id: 'SmsPageMessages.settings',
    defaultMessage: 'Autoreply',
  },
  smsMailing: {
    id: 'SmsPageMessages.smsMailing',
    defaultMessage: 'Bulk SMS',
  },
})

export const SmsPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const defaultTabId = useMemo(() => pathname, [])

  const { devices, loading } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    isActive: true,
  })

  const pushNotificationsFromStore = useSelector(
    // @ts-ignore
    (store) => store.pushNotifications,
  )

  const cloudTabOptions: CloudTabType[] = [
    {
      id: SMS_PAGE_TABS_IDS.SMS_LIST,
      label: intl.formatMessage(SmsPageMessages.smsList),
      onClick: () => navigate(SmsRoutePath[SmsRoute.SmsList]),
      eventsCount: pushNotificationsFromStore.sms.smsList,
    },
    {
      id: SMS_PAGE_TABS_IDS.SMS_AUTO_REPLY,
      label: intl.formatMessage(SmsPageMessages.smsAutoReply),
      onClick: () => navigate(SmsRoutePath[SmsRoute.SmsAutoReply]),
    },
    {
      id: SMS_PAGE_TABS_IDS.SMS_REDIRECTION,
      label: intl.formatMessage(SmsPageMessages.smsRedirection),
      onClick: () => navigate(SmsRoutePath[SmsRoute.SmsRedirection]),
    },
    {
      id: SMS_PAGE_TABS_IDS.SMS_MAILING,
      label: intl.formatMessage(SmsPageMessages.smsMailing),
      onClick: () => navigate(SmsRoutePath[SmsRoute.SmsMailing]),
    },
  ]

  useEffect(() => {
    if (pathname === RoutePath[Route.Sms]) {
      navigate(SmsRoutePath[SmsRoute.SmsList])
    }
  }, [pathname])

  const handleTabChange = (path: string) => {
    navigate(path)
  }

  return (
    <PageWrapper>
      <CloudTabs
        items={cloudTabOptions}
        mainRoot={SmsRoutePath[SmsRoute.SmsList]}
        defaultTabId={defaultTabId}
        handleChange={handleTabChange}
        classes={{
          nav: styles.CloudTabsAdditional,
        }}
      />

      <Routes>
        {Object.values(smsRoutes).map((r) => (
          <ReactRoute
            key={r.route}
            path={r.path.split('/').pop()}
            element={
              <AccessRoute
                key={r.route}
                route={r}
                props={{
                  activeDevices: devices,
                  activeDevicesLoading: loading,
                  userHasDevices: devices?.length > 0
                }}
              />
            }
          />
        ))}

        <ReactRoute
          path={'*'}
          element={<Navigate to={RoutePath[Route.NotFound]} replace={true} />}
        />
      </Routes>
    </PageWrapper>
  )
}
