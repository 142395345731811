import BackgroundImage from '../../../assets/background-figures-grey.png'
import { SliderCard, SliderCardThemes } from '../../SliderCard/SliderCard'
import styles from './styles.module.scss'

export const EasyStart = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className={styles.Background}>
          <img src={BackgroundImage} alt="" />
        </div>
        <div className={styles.Header}>
          <div className={styles.Title}>Простой старт для использования</div>
        </div>
        <div className={styles.Cards}>
          <SliderCard
            title="Регистрация на платформе"
            text="Вам не требуется установки никаких дополнительных компонентов! Простая регистрация на платформе посредством ввода email - первый шаг."
            theme={SliderCardThemes.black}
          />
          <SliderCard
            title="Установите сим карту любого оператора в модем"
            text="Подключите модем к компьютеру или блоку питания. Подождите активации устройства - модем автоматически появится в вашем личном кабинете. Платформа готова к использованию!"
            theme={SliderCardThemes.black}
          />
          <SliderCard
            title="Пользуйтесь платформой в полной мере!"
            text="Отправка СМС, звонки, раздача WiFi - вам доступно все! Не забывайте следить за балансом сим карты!"
            theme={SliderCardThemes.black}
          />
        </div>
      </div>
    </div>
  )
}
