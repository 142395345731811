import { CenteredDrawer } from 'shared/ui-kit-2/data-display/centered-drawer/CenteredDrawer'
import styles from './styles.module.scss'

export const RepeatCallModal = () => {
  return (
    <CenteredDrawer isOpen={true} close={() => null}>
      TTD
    </CenteredDrawer>
  )
}
