import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import deleteFailedImageSrc from '../../../assets/images/delete-failed.png'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

const AddCardWarningModalMessages = defineMessages({
  description: {
    id: 'AddCardWarningModalMessages.description',
    defaultMessage: 'Remove a card to add a new one',
  },
})

export const AddCardWarningModal = () => {
  const { handleHideModal } = useModal()
  const intl = useIntl()

  return (
    <Drawer isOpen={true} close={handleHideModal}>
      <div className={styles.Wrapper}>
        <div className={styles.ImageAndDescription}>
          <img
            src={deleteFailedImageSrc}
            alt={'Delete failed'}
            className={styles.Image}
          />
          <div className={styles.Description}>
            {intl.formatMessage(AddCardWarningModalMessages.description)}
          </div>
        </div>

        <Button text={'Okay'} onClick={() => handleHideModal()} />
      </div>
    </Drawer>
  )
}
