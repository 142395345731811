import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDialog } from '../../../../../app/hooks/useDialog'
import { useModal } from '../../../../../app/hooks/useModal'
import { ReactComponent as CaretRight } from '../../../../../assets/icons/caret-right.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg'
import { QueryParameter } from '../../../../../sdk/datagates/helpers/_common/wrap-api-request'
import { ContactListResponse } from '../../../../../sdk/datagates/types/contact/_crud/list'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import { Counter } from '../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { RowGroupType } from '../../../../../shared/ui-kit-2/data-display/table/Table'
import { DialogVersion } from '../../../../../store/reducers/dialog/types'
import { MODAL_TYPES } from '../../../../modals/ModalsProvider'
import styles from './styles.module.scss'

const ContactTableRowsMessages = defineMessages({
  deleteTitle: {
    id: 'ContactTableRowsMessages.deleteTitle',
    defaultMessage: 'Delete the contact?',
  },
})

export const ContactTableRows = (
  contacts: ContactListResponse['contacts'],
  handleFetchContacts: (props?: {
    params?: QueryParameter[] | undefined
    hidden?: boolean | undefined
  }) => Promise<void>,
  handleFetchContactGroups: () => Promise<void>,
  handleDeleteContact: (contactId: number) => Promise<void>,
): RowGroupType[] => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const { handleOpenDialog, handleHideDialog } = useDialog()

  const onDelete = (contactId: number) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: intl.formatMessage(ContactTableRowsMessages.deleteTitle),
        subtitle: 'This action cannot be undone',
        subtitleIsAlert: true,
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog(),
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: async () => {
            handleHideDialog()
            await handleDeleteContact(contactId)
            await handleFetchContacts()
            await handleFetchContactGroups()
          },
        },
      },
    })
  }

  const openContactSettingsModal = (contactId: number) => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_SETTINGS_MODAL,
      props: {
        contactId: contactId,
        handleFetchContacts,
        handleFetchContactGroups,
        handleDeleteContact: () => handleDeleteContact(contactId),
      },
    })
  }

  if (contacts.length === 0) return []

  return [
    {
      groupHeader: null,
      rows: contacts.map((contact) => {
        return [
          <div className={styles.Name}>{contact.name}</div>,
          <div>{formatPhoneNumber(contact.phone)}</div>,
          <Counter count={contact.groupsCount} variant={'outlined'} />,
          <div className={styles.Actions}>
            <TrashIcon onClick={() => onDelete(contact.contactId)} />
            <CaretRight
              onClick={() => openContactSettingsModal(contact.contactId)}
            />
          </div>,
        ]
      }),
    },
  ]
}
