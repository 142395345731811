import React from 'react'
import { useSiteVersion } from '../../../../../app/hooks/useSiteVersion'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import DotsMobileBackground from '../../../assets/dots-mobile.png'
import DotsBackground from '../../../assets/dots.png'
import Home02Img from '../../../assets/home02.png'
import styles from './styles.module.scss'

export const Home02 = () => {
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.LAPTOP)
  const { siteVersion } = useSiteVersion()

  const enInfo = (
    <div className={styles.Info}>
      Experience Limitless Control with{' '}
      <span className={styles.Marked}>Teleleo</span>: Our all-in-one ecosystem
      brings global device management,{' '}
      <span className={styles.Marked}>SMS, and Voice control</span> into a
      single modem and platform. Seamlessly control devices, manage
      communications, and unlock worldwide connectivity — all at your
      fingertips. Empower your business with unparalleled flexibility and
      efficiency.
    </div>
  )

  const ruInfo = (
    <div className={styles.Info}>
      Испытайте безграничный контроль c{' '}
      <span className={styles.Marked}>Telecorn</span>: Наша экосистема «все в
      одном» объединяет глобальное управление устройствами,{' '}
      <span className={styles.Marked}>SMS, и голосовое управление</span> в одном
      модеме и платформе. Беспрепятственно контролируйте устройства, управляйте
      связью и открывайте возможности подключения по всему миру - и все это на
      кончиках ваших пальцев. Обеспечьте своему бизнесу беспрецедентную гибкость
      и эффективность.
    </div>
  )

  const Info = {
    ru: ruInfo,
    en: enInfo,
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Background}>
        <img
          src={isLessThanTablet ? DotsMobileBackground : DotsBackground}
          alt=""
        />
      </div>
      <div className={styles.Col}>
        <div className={styles.Image}>
          <img src={Home02Img} alt="wi-fi" />
        </div>
      </div>
      <div className={styles.Col}>{Info[siteVersion]}</div>
    </div>
  )
}
