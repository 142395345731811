import React from 'react'
import { useNavigate } from 'react-router'
import { ReactComponent as PlugIcon } from '../../../../assets/icons/v2/ic-plug.svg'
import { ReactComponent as WifiIcon } from '../../../../assets/icons/v2/ic-wifi.svg'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import ManualDeviceActivationStepper from '../../../../shared/global-components/ManualDeviceActivationStepper'
import Button from '../../../../shared/ui-kit-3/components/Button/Button'
import styles from './styles.module.scss'

export const ActivateDevicePage = () => {
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <div className={styles.Content}>
        <div className={styles.Card}>
          <div className={styles.Title}>Automatic activation</div>
          <div className={styles.CenteredItem}>
            <div className={styles.Block}>
              <div className={styles.Block__Circle}>
                <PlugIcon />
              </div>
              <div className={styles.Block__Title}>
                Insert the modem into the computer
              </div>
              <div className={styles.Block__Text}>
                You need to be logged into your Teleleo personal account on this
                computer.{`\n`}
                Disable the SIM card's PIN code beforehand
              </div>
            </div>
            <div className={styles.Or}>or</div>
            <div className={styles.Block}>
              <div className={styles.Block__Circle}>
                <WifiIcon />
              </div>
              <div className={styles.Block__Title}>
                Plug the modem into any outlet and connect to its Wi-Fi
              </div>
              <div className={styles.Block__Text}>
                Connect using the login and password on the modem cover
              </div>
            </div>
          </div>
          <Button
            onClick={() => navigate(RoutePath[Route.AutoDeviceActivation])}
            className={styles.Button}
            variant={'greenFilled'}
            text={'Select'}
            size={'xl'}
          />
        </div>
        <div className={styles.Card}>
          <div className={styles.Title}>Manual activation</div>
          <ManualDeviceActivationStepper />
          <Button
            onClick={() => navigate(RoutePath[Route.ManualDeviceActivation])}
            className={styles.Button}
            variant={'greenFilled'}
            text={'Select'}
            size={'xl'}
          />
        </div>
      </div>
    </PageWrapper>
  )
}
