import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSiteVersion } from '../../../../../app/hooks/useSiteVersion'
import BackgroundFigures from '../../../assets/background-figures.png'
import PortableRuImage from '../../../assets/portable-2-ru.png'
import PortableImage from '../../../assets/portable-2.png'
import RemoteImage from '../../../assets/remote.png'
import SmsBackground from '../../../assets/sms.png'
import UserFriendlyImage from '../../../assets/user-friendly.png'
import styles from './styles.module.scss'

type NewCardProps = {
  title: string
  subtitle: string
  image: string
}

export const Home06Messages = defineMessages({
  firstTitle: {
    id: 'Home06Messages.firstTitle',
    defaultMessage: 'Teleleo',
  },
  secondTitle: {
    id: 'Home06Messages.secondTitle',
    defaultMessage: 'Platform for everything',
  },
  remoteTitle: {
    id: 'Home06Messages.remoteTitle',
    defaultMessage: 'Remote Device Control',
  },
  remoteSubtitle: {
    id: 'Home06Messages.remoteSubtitle',
    defaultMessage:
      'Teleleo stands out with its advanced remote device control feature, allowing users to manage devices from any location. This capability enhances efficiency and flexibility, especially for teams working remotely or managing multiple sites.',
  },
  userFriendlyTitle: {
    id: 'Home06Messages.userFriendlyTitle',
    defaultMessage: 'User-Friendly Interface',
  },
  userFriendlySubtitle: {
    id: 'Home06Messages.userFriendlySubtitle',
    defaultMessage:
      "The platform's user-friendliness is a key differentiator. Teleleo enables easy uploading of contact databases, seamless system connections via an open API, and straightforward firmware updates. This approach ensures a smooth, hassle-free user experience, catering to both tech-savvy and non-technical users alike.",
  },
  portableTitle: {
    id: 'Home06Messages.portableTitle',
    defaultMessage: 'Portable Profile of the Modem',
  },
  portableSubtitle: {
    id: 'Home06Messages.portableSubtitle',
    defaultMessage:
      "Teleleo's modem is uniquely portable yet powerful, equipped with a sophisticated SOC. It's designed for fault tolerance and supports Over-The-Air (OTA) updates. This modem isn't just a connectivity device; it's a robust tool ensuring continuous, reliable service in various environments.",
  },
})

export const Home06 = () => {
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()

  const Card = ({ title, subtitle, image }: NewCardProps) => (
    <div className={styles.Card}>
      <div className={styles.Card__Background}>
        <img src={image} alt="" />
      </div>
      <div className={styles.Card__Content}>
        <div className={styles.Card__Title}>{title}</div>
        <div className={styles.Card__Subtitle}>{subtitle}</div>
      </div>
    </div>
  )

  return (
    <div className={styles.Container}>
      <div className={styles.BackgroundFigures}>
        <img src={BackgroundFigures} alt="" />
      </div>
      <div className={styles.Wrapper}>
        <div className={styles.Title}>
          <span className={styles.Marked}>
            {intl.formatMessage(Home06Messages.firstTitle)}
          </span>{' '}
          {intl.formatMessage(Home06Messages.secondTitle)}
        </div>
        <div className={styles.BackgroundLaptop}>
          <img src={SmsBackground} alt="" />
        </div>
        <div className={styles.Grid}>
          <Card
            title={intl.formatMessage(Home06Messages.remoteTitle)}
            subtitle={intl.formatMessage(Home06Messages.remoteSubtitle)}
            image={RemoteImage}
          />
          <Card
            title={intl.formatMessage(Home06Messages.userFriendlyTitle)}
            subtitle={intl.formatMessage(Home06Messages.userFriendlySubtitle)}
            image={UserFriendlyImage}
          />
          <Card
            title={intl.formatMessage(Home06Messages.portableTitle)}
            subtitle={intl.formatMessage(Home06Messages.portableSubtitle)}
            image={siteVersion === 'ru' ? PortableRuImage : PortableImage}
          />
        </div>
      </div>
    </div>
  )
}
