import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'

interface BlobCreateRequest {
  file_base64: string
  file_name?: string
  scenario: string
}

interface BlobCreateBackendResponse {
  data: {
    tmp_file_id: string
  }
}

interface BlobCreateResponse {
  tmpFileId: string
}

export const blobCreateDatagate = wrapApiRequest<
  BlobCreateRequest,
  BlobCreateBackendResponse,
  BlobCreateResponse
>(
  'file/upload-tmp',
  (item: BlobCreateBackendResponse): BlobCreateResponse => ({
    tmpFileId: item.data.tmp_file_id,
  }),
  'POST',
)
