import { formatDate } from '../../../../lib/utils/date-utils/formatDate'
import { parseStringToDate } from '../../../../lib/utils/parseStringToDate/parseStringToDate'

type GroupHeaderDataItem = {
  createdAt: string
  [key: string]: unknown
}

type GroupedData = Record<string, GroupHeaderDataItem[]>

export const getGroupHeaderDataByDate = (
  data: GroupHeaderDataItem[],
): [string, GroupHeaderDataItem[]][] => {
  const sortEntriesByDate = (
    entries: [string, GroupHeaderDataItem[]][],
  ): [string, GroupHeaderDataItem[]][] =>
    entries.sort((a, b) => {
      const dateA = parseStringToDate(a[0]).getTime()
      const dateB = parseStringToDate(b[0]).getTime()
      return dateB - dateA
    })

  const grouped: GroupedData = data.reduce((acc, item) => {
    const date = formatDate(item.createdAt, 0, 'date')
    acc[date] = acc[date] ?? []
    acc[date].push(item)
    return acc
  }, {} as GroupedData)

  return sortEntriesByDate(Object.entries(grouped))
}
