import React from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { DongleGetResponse } from '../../../../../sdk/datagates/types/dongle/_crud/get'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import { LoadingContent } from '../../../../loading-content/LoadingContent'
import { DeviceDetailsModalMessages } from '../../messages/DeviceDetailsModalMessages'
import styles from './styles.module.scss'

interface NetworkInformationProps {
  networkInformation: Record<string, string>
  networkInformationLoading: boolean
  device: DongleGetResponse | undefined
  isTariffPackageEnabled: boolean
}
export const NetworkInformation = (props: NetworkInformationProps) => {
  const {
    networkInformation,
    networkInformationLoading,
    device,
    isTariffPackageEnabled,
  } = props
  const intl = useIntl()

  return (
    <LoadingContent
      loading={networkInformationLoading}
      content={
        <div
          className={clsx(
            styles.List,
            isTariffPackageEnabled && styles.ErrorSubscription,
          )}
        >
          <div className={styles.Col}>
            <div className={styles.Row}>
              <div className={styles.Label}>
                {intl.formatMessage(DeviceDetailsModalMessages.networkName)}
              </div>
              <div className={styles.Value}>
                {networkInformation?.current_network_name ?? '-'}
              </div>
            </div>
            <div className={styles.Row}>
              <div className={styles.Label}>
                SIM Network Name
              </div>
              <div className={styles.Value}>
                {networkInformation?.sim_network_name ?? '-'}
              </div>
            </div>
            <div className={styles.Row}>
              <div className={styles.Label}>MCC/MNC</div>
              <div className={styles.Value}>
                {networkInformation?.mcc ?? '-'}/{networkInformation?.mnc ?? '-'}
              </div>
            </div>
            <div className={styles.Row}>
              <div className={styles.Label}>
                Network signal
              </div>
              <div className={styles.Value}>
                {'-'}
              </div>
            </div>
          </div>
          <div className={styles.Col}>
            <div className={styles.Row}>
              <div className={styles.Label}>
                {intl.formatMessage(DeviceDetailsModalMessages.number)}
              </div>
              <div className={styles.Value}>
                {formatPhoneNumber(device?.phoneNumber) ?? '-'}
              </div>
            </div>
            <div className={styles.Row}>
              <div className={styles.Label}>IMSI</div>
              <div className={styles.Value}>{networkInformation.imsi ?? '-'}</div>
            </div>
            <div className={styles.Row}>
              <div className={styles.Label}>ICCID</div>
              <div className={styles.Value}>{networkInformation.iccid ?? '-'}</div>
            </div>
            <div className={styles.Row}>
              <div className={styles.Label}>Roaming</div>
              <div className={styles.Value}>{networkInformation.roaming ? 'On' : 'Off'}</div>
            </div>
          </div>
        </div>
      }
    />
  )
}
