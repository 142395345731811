import React, { useEffect } from 'react'
import { DialogActions, DialogContent } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { LoadingContent } from '../../../../layouts/loading-content/LoadingContent'
import { setDialog } from '../../../../store/reducers/dialog'
import { Button } from '../../inputs/button/Button'
import styles from './styles.module.scss'

type DialogType = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  title: string
  loading: boolean
  content?: string
  actions: {
    cancel: string
    submit: string
  }
}

export const DialogComponent = ({
  open,
  onClose,
  onSuccess,
  loading,
  title,
  content,
  actions,
}: DialogType) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setDialog({
        isOpen: open,
      }),
    )
  }, [open])

  const MainBlock = () => (
    <>
      {content && (
        <DialogContent dividers className={styles.DialogContent}>
          <div className={clsx(loading && styles.Hidden)}>{content}</div>
          <LoadingContent loading={loading} content={<></>} />
        </DialogContent>
      )}
      {!loading && (
        <DialogActions>
          <div className={styles.Actions}>
            <Button
              text={actions.cancel}
              variant="blackTextBlackBorderOutlined"
              onClick={onClose}
              disabled={loading}
            />
            <Button
              text={actions.submit}
              onClick={onSuccess}
              disabled={loading}
            />
          </div>
        </DialogActions>
      )}
    </>
  )

  return (
    <Dialog
      onClose={loading ? () => null : onClose}
      open={open}
      classes={{
        paper: styles.Paper,
      }}
    >
      <DialogTitle>
        <div className={styles.Title}>{title}</div>
      </DialogTitle>
      {loading ? <MainBlock /> : <MainBlock />}
    </Dialog>
  )
}
