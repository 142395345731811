import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { usePayDevice } from '../../../_hooks/use-pay-device/usePayDevice'
import { UsePayOrChangeSubscription } from '../../../_hooks/use-pay-or-change-subscription/usePayOrChangeSubscription'
import { useSiteVersion } from '../../../../../app/hooks/useSiteVersion'
import { DongleGetResponse } from '../../../../../sdk/datagates/types/dongle/_crud/get'
import { useNetworkInformation } from '../../../../../sdk/hooks/use-network-information/useNetworkInformation'
import { useTariffPackages } from '../../../../../sdk/hooks/use-tariff-packages/useTariffPackages'
import { TextButton } from '../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { DeviceDetailsModalMessages } from '../../messages/DeviceDetailsModalMessages'
import { ModemInfo } from '../modemInfo/ModemInfo'
import { NetworkInformation } from '../networkInformation/NetworkInformation'
import { RedirectionsDetails } from '../redirectionsDetails/RedirectionsDetails'
import { Subscription } from '../subscription/Subscription'
import styles from './styles.module.scss'
import {DetailsCard} from "../detailsCard/DetailsCard";
import {MODAL_TYPES} from "../../../ModalsProvider";
import {UpdateWifiFormType, useWifi} from "../../../../../sdk/hooks/use-wifi/useWifi";
import {useModal} from "../../../../../app/hooks/useModal";
import {SNACKBAR_TYPES} from "../../../../snackbar/SnackbarProvider";
import {getErrorMessage} from "../../../../../shared/lib/utils/get-error-message/getErrorMessage";
import {useSnackbar} from "../../../../../app/hooks/useSnackbar";
import {useBackendErrorCodes} from "../../../../../sdk/datagates/helpers/_common/use-backend-error-codes";
import {dongleVPNDisableDatagate, dongleVPNEnableDatagate} from "../../../../../sdk/datagates/api/dongle";

interface IDetailsProps {
  device: DongleGetResponse
  handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
  handleSwitchVPN: (dongleId: number, enabled: boolean) => Promise<void>
  handleFetchDevices: () => Promise<void>
  handleFetchDevice: (
    deviceId: number,
  ) => Promise<DongleGetResponse | undefined>
  handleDeleteDevice: (dongleId: number) => Promise<void>
  openSubscriptionsByDefault?: boolean
}

export const Details = ({
  device,
  handleSwitchHotspot,
  handleSwitchVPN: handleSwitchDeviceVPN,
  handleFetchDevices,
  handleFetchDevice,
  handleDeleteDevice,
  openSubscriptionsByDefault,
}: IDetailsProps) => {
  const { handleOpenModal } = useModal()
  const intl = useIntl()
  const { getTariffPackageTranslation, getTariffPackagePrice } =
    useTariffPackages()
  const { siteVersion } = useSiteVersion()
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()

  const [isPayOrChangeSubscription, setIsPayOrChangeSubscription] =
    useState<boolean>(false)
  const { networkInformation, networkInformationLoading } =
    useNetworkInformation(device)

  const [switchWifiLoading, setSwitchWifiLoading] = useState<boolean>(false)
  const [wifiEnabled, setWifiEnabled] = useState<boolean>(
    device?.isHotspotEnable ?? false,
  )

  const [switchVPNLoading, setSwitchVPNLoading] = useState<boolean>(false)
  const [vpnEnabled, setVPNEnabled] = useState<boolean>(
    device?.isHotspotEnable ?? false,
  )

  const { handleUpdateWifi } = useWifi({ autoFetchNeeded: false })

  const isTariffPackageEnabled = device?.isTariffPackageActive;

  const {
    component: componentPayDevice,
    setIsPayDeviceOpen,
    tariffPackageId,
    isOpen: isOpenPayDevice,
  } = usePayDevice({
    getTariffPackagePrice,
    getTariffPackageTranslation,
    device,
    setIsPayOrChangeSubscription,
  })

  const { component: componentSubscription } = UsePayOrChangeSubscription({
    isPayOrChangeSubscription,
    setIsPayOrChangeSubscription,
    tariffPackageId,
    setIsPayDeviceOpen,
    dongleId: device.dongleId,
  })

  useEffect(() => {
    if (openSubscriptionsByDefault) {
      setIsPayDeviceOpen(true)
    }
  }, [openSubscriptionsByDefault])

  const handleOpenWIFISettings = () => {
    if (device)
      handleOpenModal({
        type: MODAL_TYPES.WIFI_SETTINGS,
        props: {
          handleSubmit: (values: UpdateWifiFormType) =>
            handleUpdateWifi(values, () => {
              void handleFetchDevices()
            }),
          deviceId: device.dongleId,
          deviceName: device.name,
          hotspotName: device.hotspotName,
          hotspotPassword: device.hotspotPassword,
        },
      })
  }

  const handleSwitchWifi = async () => {
    setSwitchWifiLoading(true)

    if (device)
      try {
        // @ts-ignore
        const { data } = await handleSwitchHotspot(device.dongleId, !wifiEnabled)
        setWifiEnabled((prev) => !prev)
      } catch (e) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: resolveBackendError(getErrorMessage(e)),
        })
      } finally {
        setSwitchWifiLoading(false)
      }
  }

  const handleSwitchVPN = async () => {
    setSwitchVPNLoading(true)

    if (device)
      try {
        const urlParams = [{ name: 'dongle_id', value: device.dongleId.toString() }];

        if (device.isVPNConnected) {
          await dongleVPNEnableDatagate(null, urlParams);
        } else {
          await dongleVPNDisableDatagate(null, urlParams);
        }

        await handleSwitchDeviceVPN(device.dongleId, device.isVPNConnected)
        setVPNEnabled((prev) => !prev)
      } catch (e) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: resolveBackendError(getErrorMessage(e)),
        })
      } finally {
        setSwitchVPNLoading(false)
      }
  }

  return (
    <>
      <div className={styles.DeviceDetails}>
        <div className={styles.Info}>
          <ModemInfo
            device={device}
            handleFetchDevices={handleFetchDevices}
            handleFetchDevice={handleFetchDevice}
            handleDeleteDevice={handleDeleteDevice}
            isTariffPackageEnabled={isTariffPackageEnabled}
          />
          <DetailsCard
            title={'Wi-Fi'}
            onSettings={handleOpenWIFISettings}
            switcher={{
              status: wifiEnabled,
              onChange: handleSwitchWifi,
              disabled: switchWifiLoading || isTariffPackageEnabled
            }}
            items={[
              {
                label: 'Wi-Fi Name:',
                value: device.hotspotName
              },
              {
                label: 'Wi-Fi Password:',
                value: device.hotspotPassword
              }
            ]}
            warning={!device.isOnline}
          />
          <DetailsCard
            title={'VPN'}
            switcher={{
              status: vpnEnabled,
              onChange: handleSwitchVPN,
              disabled: switchVPNLoading || isTariffPackageEnabled
            }}
            items={[
              {
                label: 'User name:',
                value: device.publicVPNLogin
              },
              {
                label: 'Password:',
                value: device.publicVPNPassword
              }
            ]}
            warning={!device.isOnline}
          />
        </div>

        <Subscription
          device={device}
          getTariffPackagePrice={getTariffPackagePrice}
          getTariffPackageTranslation={getTariffPackageTranslation}
          tariffPackageEnable={isTariffPackageEnabled}
          setIsPayDeviceOpen={setIsPayDeviceOpen}
        />

        <div className={clsx(styles.Card, styles.Card_networkInformation)}>
          <div className={styles.Header}>
            <div
              className={clsx(
                styles.Title,
                isTariffPackageEnabled && styles.ErrorSubscriptionTitle,
              )}
            >
              {intl.formatMessage(
                DeviceDetailsModalMessages.networkInformation,
              )}
            </div>
          </div>
          <div className={styles.Divider} />
          <NetworkInformation
            networkInformation={networkInformation}
            networkInformationLoading={networkInformationLoading}
            device={device}
            isTariffPackageEnabled={isTariffPackageEnabled}
          />
        </div>

        <div
          className={clsx(
            styles.Card,
            styles.Card_redirects,
            isTariffPackageEnabled && styles.ErrorSubscription,
          )}
        >
          <div className={styles.Header}>
            <div className={styles.Title}>
              {intl.formatMessage(DeviceDetailsModalMessages.redirects)}
            </div>
          </div>
          <div className={styles.Divider} />
          <RedirectionsDetails
            deviceId={device.dongleId}
            handleFetchDevices={handleFetchDevices}
            isTariffPackageEnabled={isTariffPackageEnabled}
          />
        </div>
      </div>
      {componentPayDevice}
      {isPayOrChangeSubscription && componentSubscription}
    </>
  )
}
