import React, { ReactNode, useEffect } from 'react'
import { DialogContent } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { ReactComponent as AlertIcon } from '../../../../assets/icons/alert.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import {
  Button,
  BUTTON_ICON_LIST_TYPE,
  VariantType,
} from '../../inputs/button/Button'
import styles from './styles.module.scss'

interface IDialogProps {
  isOpen: boolean
  cookedTemplate?: ReactNode | null
  props: {
    title: string
    subtitle?: string
    subtitleIsAlert?: boolean
    cancel: {
      variant?: VariantType
      icon?: BUTTON_ICON_LIST_TYPE | undefined
      text: string
      onClick: () => void
    }
    submit: {
      variant?: VariantType
      icon?: BUTTON_ICON_LIST_TYPE | undefined
      text: string
      onClick: () => void
    }
  }
}

export const Dialog_v2 = ({ isOpen, props, cookedTemplate }: IDialogProps) => {
  const { title, subtitle, subtitleIsAlert, cancel, submit } = props

  return (
    <Dialog
      onClose={cancel.onClick}
      open={isOpen}
      classes={{
        paper: styles.Paper,
      }}
    >
      <DialogTitle>
        <div className={styles.Title}>{title}</div>
        <button className={styles.CloseButton} onClick={() => cancel.onClick()}>
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent>
        {subtitle && (
          <div className={styles.Subtitle}>
            {subtitleIsAlert && (
              <div className={styles.Subtitle__Alert}>
                <AlertIcon />
              </div>
            )}
            <div className={styles.Subtitle__Text}>{subtitle}</div>
          </div>
        )}
        {cookedTemplate || (
          <div className={styles.Actions}>
            {cancel?.text && (
              <Button
                variant={cancel?.variant || 'blackTextBlackBorderOutlined'}
                icon={cancel?.icon}
                text={cancel.text}
                onClick={cancel.onClick}
              />
            )}
            <Button
              variant={submit?.variant || 'danger'}
              icon={submit?.icon}
              text={submit.text}
              onClick={submit.onClick}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
