import React, { FC } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import {Loader as UIKitLoader} from "../../../ui-kit/data-display/loader/Loader";

type CounterSize = 'small' | 'default'
type CounterVariant = 'outlined' | 'filled'

interface CounterProps {
  count?: number
  text?: string
  customText?: string
  variant?: CounterVariant
  color?: 'red' | 'green' | 'grey'
  size?: CounterSize
  additionalClassNames?: string[]
  loading?: boolean
}

export const Counter: FC<CounterProps> = (props) => {
  const {
    count = null,
    text,
    customText,
    variant = 'outlined',
    size = 'default',
    color = 'green',
    additionalClassNames = [],
    loading,
  } = props

  if (loading) {
    return <></>
  }

  return (
    <div
      className={clsx(
        styles.Counter,
        color === 'red' && styles.Counter_red,
        color === 'green' && styles.Counter_green,
        color === 'grey' && styles.Counter_grey,
        size === 'small' && styles.Counter_small,
        variant === 'filled' && styles.Counter_filled,
        ...additionalClassNames,
      )}
    >
      {customText ? customText : text ? `${count} ${text}` : count}
    </div>
  )
}
