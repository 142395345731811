import React, { useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import deleteFailedImageSrc from '../../../../assets/images/delete-failed.png'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

const UseAddContactsMessages = defineMessages({
  description: {
    id: 'UseAddContactsMessages.description',
    defaultMessage: 'Are you sure you want to change your profile type?',
  },
  cancel: {
    id: 'UseAddContactsMessages.cancel',
    defaultMessage: 'Cancel',
  },
  change: {
    id: 'UseAddContactsMessages.change',
    defaultMessage: 'Change',
  },
})

interface UseChangeProfileTypeProps {
  setUserType: React.Dispatch<React.SetStateAction<string>>
}

export const useChangeProfileType = ({
  setUserType,
}: UseChangeProfileTypeProps) => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [pendingUserType, setPendingUserType] = useState<string | null>(null)
  const ref = useRef<HTMLDivElement>(null)

  const onClose = () => {
    setIsOpen(false)
    setPendingUserType(null)
  }

  const handleChangeType = (userType: string) => {
    setPendingUserType(userType)
    setIsOpen(true)
  }

  const confirmChangeType = () => {
    if (pendingUserType) {
      setUserType(pendingUserType)
      setIsOpen(false)
    }
  }

  useClickOutside(ref, onClose)

  const component = isOpen && (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>
        <img
          src={deleteFailedImageSrc}
          alt={'Delete failed'}
          className={styles.Image}
        />
        <div className={styles.Description}>
          {intl.formatMessage(UseAddContactsMessages.description)}
        </div>
        <div className={styles.Actions}>
          <Button
            text={intl.formatMessage(UseAddContactsMessages.cancel)}
            variant={'white'}
            onClick={onClose}
          />
          <Button
            text={intl.formatMessage(UseAddContactsMessages.change)}
            onClick={confirmChangeType}
          />
        </div>
      </Card>
    </div>
  )

  return {
    setIsOpen,
    handleChangeType,
    component,
  }
}
