import { defineMessages } from 'react-intl'

export const MainMessages = defineMessages({
  of: {
    id: 'MainMessages.of',
    defaultMessage: 'of',
  },
  On: {
    id: 'MainMessages.On',
    defaultMessage: 'On',
  },
  Off: {
    id: 'MainMessages.Off',
    defaultMessage: 'Off',
  },
  Password: {
    id: 'MainMessages.Password',
    defaultMessage: 'Password',
  },
  Online: {
    id: 'MainMessages.Online',
    defaultMessage: 'Online',
  },
  Offline: {
    id: 'MainMessages.Offline',
    defaultMessage: 'Offline',
  },
})
