import { useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  mailingCreateDatagate,
  mailingDeleteDatagate,
  mailingListDatagate,
  mailingUpdateDatagate,
} from '../../datagates/api/mailing'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import {
  QueryParameter,
  UrlParameter,
} from '../../datagates/helpers/_common/wrap-api-request'
import { MailingCreateRequest } from '../../datagates/types/mailing/_crud/create'
import { MailingListResponse } from '../../datagates/types/mailing/_crud/list'
import { MailingUpdateRequest } from '../../datagates/types/mailing/_crud/update'
import { MAILING_STATUSES } from '../use-mailing-statuses/constants/MailingStatuses'

interface UseMailingsProps {
  filters?: QueryParameter[]
}

export const MAILING_TYPE_IDS = {
  SIMPLE: 1,
  DELAYED: 2,
}

const UseMailingsMessages = defineMessages({
  positiveDeleted: {
    id: 'UseMailingsMessages.positiveDeleted',
    defaultMessage: 'Mailing is successfully deleted',
  },
  negativeDeleted: {
    id: 'UseMailingsMessages.negativeDeleted',
    defaultMessage: 'An error occurred while deleting mailing',
  },
})

export const useMailings = (props: UseMailingsProps) => {
  const { filters = [] } = props

  const stableFilters = useMemo(() => filters, [JSON.stringify(filters)])

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [mailings, setMailings] = useState<MailingListResponse['mailings']>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [initialFetchCompleted, setInitialFetchCompleted] =
    useState<boolean>(false)

  const handleDeleteMailing = async (mailingId: number) => {
    try {
      const urlParams = [{ name: 'mailing_id', value: mailingId.toString() }]

      await mailingDeleteDatagate(null, urlParams)
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingsMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseMailingsMessages.negativeDeleted),
      })
    }
  }

  const handleUpdateMailing = async (
    mailingId: number,
    params: MailingUpdateRequest,
  ) => {
    try {
      await mailingUpdateDatagate(params || null, [
        { name: 'mailing_id', value: mailingId.toString() },
      ])

      await handleFetch()

      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: 'Success update mailing rule',
      })
    } catch (e) {
      console.log('e', e)
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    }
  }

  const handleCancelMailing = async (mailingId: number) => {
    try {
      const urlParams = [{ name: 'mailing_id', value: mailingId.toString() }]

      await mailingUpdateDatagate(
        { mailing_status_id: MAILING_STATUSES.CANCELLED },
        urlParams,
      )
      await handleFetch()

      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingsMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseMailingsMessages.negativeDeleted),
      })
    }
  }

  const handleFetch = async ({
    params = [],
    hidden,
  }: { params?: QueryParameter[]; hidden?: boolean } = {}) => {
    try {
      setLoading(!hidden)

      const queryParams: QueryParameter[] = [
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
        { name: 'is_deleted', value: '0' },
        ...stableFilters,
        ...params,
      ]

      const { data } = await mailingListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setMailings(data.mailings)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCreateMailing = async (params: MailingCreateRequest) => {
    try {
      setLoading(true)
      await mailingCreateDatagate(params)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [stableFilters])

  return {
    mailings,
    totalCount,
    loading,
    handleFetch,
    handleCancelMailing,
    handleCreateMailing,
    handleUpdateMailing,
    handleDeleteMailing,
    initialFetchCompleted,
    setInitialFetchCompleted,
  }
}
