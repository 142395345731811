import { CallsSettingsPage } from '../../../../../../../../../pages/lk/subpages/calls/subpages/calls-settings/CallsSettingsPage'
import { AuthRoutePath } from '../../../../../../../constants/authRoutePath'
import { CallsRoutePath } from '../../../../../../../constants/callsRoutePath'
import { AuthRoute } from '../../../../../../../enums/authRoute'
import { CallsRoute } from '../../../../../../../enums/callsRoute'
import { RouteDescriptor } from '../../../../../../../types/routeDescriptor'

export const callsSettings: RouteDescriptor<CallsRoute.CallsSettings> = {
  route: CallsRoute.CallsSettings,
  type: 'subpage',
  title: 'Teleleo',
  path: CallsRoutePath[CallsRoute.CallsSettings],
  render: CallsSettingsPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
