import React from 'react'
import { ConfigProvider, DatePicker } from 'antd'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/arrow-right.svg'
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/v2/ic-check.svg'
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/v2/ic-close-small.svg'
import { RangeSelector } from '../../../range-selector/RangeSelector'
import {
  TABLE_FILTERS_VARIANTS,
  TableHeaderProps,
} from '../TableHeader/TableHeader'
import styles from './styles.module.scss'

type FilterContentProps = {
  col: Record<string, any>
  filterValue: any
  handleChangeFilters: TableHeaderProps['handleChangeFilters']
  blockCloseFilterRef: React.RefObject<boolean>
}

dayjs.extend(utc)

export const TableHeaderFilterContent: React.FC<FilterContentProps> = ({
  col,
  filterValue,
  handleChangeFilters,
  blockCloseFilterRef,
}) => {
  const handleChangeTextField = (colId: string, value: unknown) => {
    if (!filterValue && value === ' ') {
      return
    }

    if (value === '') {
      handleChangeFilters(colId, null)
      return
    }

    handleChangeFilters(colId, value)
  }

  switch (col.filterType) {
    case TABLE_FILTERS_VARIANTS.CHECKBOXES:
      return (
        <div className={styles.Checkboxes}>
          {filterValue &&
            Array.isArray(filterValue) &&
            filterValue.length > 0 && (
              <div
                className={styles.Checkbox__Container}
                onClick={() => handleChangeFilters(col.id, null)}
              >
                <div className={styles.Checkbox__Text}>Clear all</div>
                <div className={styles.Checkbox__Icon}>
                  <CloseIcon />
                </div>
              </div>
            )}
          {col.checkboxes?.map((checkbox: { text: string; value: string }) => {
            const isChecked =
              Array.isArray(filterValue) && filterValue.includes(checkbox.value)

            return (
              <label
                key={checkbox.value}
                className={styles.Checkbox__Container}
              >
                <input
                  type="checkbox"
                  className={styles.Checkbox}
                  value={checkbox.value}
                  checked={isChecked}
                  onChange={(e) => {
                    let newValue: string[] | null = []

                    if (checkbox.text === 'All') {
                      if (e.target.checked) {
                        newValue = col.checkboxes.map(
                          (cb: { value: string }) => cb.value,
                        )
                      } else {
                        newValue = null
                      }
                    } else {
                      newValue = Array.isArray(filterValue)
                        ? e.target.checked
                          ? [...filterValue, checkbox.value]
                          : filterValue.filter((v) => v !== checkbox.value)
                        : [checkbox.value]
                    }

                    const isAllChecked = col.checkboxes
                      .filter((cb: { text: string }) => cb.text !== 'All')
                      .every((cb: { value: string }) =>
                        newValue?.includes(cb.value),
                      )

                    if (Array.isArray(newValue)) {
                      if (isAllChecked) {
                        newValue = Array.from(new Set([...newValue, 'All']))
                      } else {
                        newValue = newValue.filter((value) => value !== 'All')
                      }
                    }

                    if (Array.isArray(newValue) && newValue.length === 0) {
                      newValue = null
                    }

                    handleChangeFilters(col.id, newValue)
                  }}
                />
                <div
                  className={clsx(
                    styles.Checkbox__Text,
                    isChecked && styles.Checkbox__Text_checked,
                  )}
                >
                  {checkbox.text}
                </div>
                <div
                  className={clsx(
                    styles.Checkbox__Icon,
                    isChecked && styles.Checkbox__Icon_checked,
                  )}
                >
                  {isChecked && <CheckIcon />}
                </div>
              </label>
            )
          })}
        </div>
      )

    case TABLE_FILTERS_VARIANTS.RANGE_SELECTOR:
      return (
        <div className={styles.Range}>
          <RangeSelector
            min={col.min as number}
            max={col.max as number}
            step={1}
            onChange={({ min, max }) =>
              handleChangeFilters(col.id, { min, max })
            }
          />
        </div>
      )

    case TABLE_FILTERS_VARIANTS.SEARCH:
      return (
        <div className={styles.Search}>
          <input
            className={styles.SearchInput}
            type="text"
            placeholder="Search"
            value={typeof filterValue === 'string' ? filterValue : ''}
            onChange={(e) => handleChangeFilters(col.id, e.target.value)}
          />
          {col.searchItems.map((searchItem: any) => (
            <div className={styles.SearchText}>{searchItem.text}</div>
          ))}
        </div>
      )

    case TABLE_FILTERS_VARIANTS.TEXT_FIELD:
      return (
        <div className={styles.TextField}>
          <input
            type="text"
            placeholder={col.defaultText}
            value={typeof filterValue === 'string' ? filterValue : ''}
            onChange={(e) => handleChangeTextField(col.id, e.target.value)}
            autoFocus
          />
          {filterValue?.length > 0 && (
            <div
              className={styles.TextField__Icon}
              onClick={() => handleChangeFilters(col.id, null)}
            >
              <CloseIcon />
            </div>
          )}
        </div>
      )

    case TABLE_FILTERS_VARIANTS.CALENDAR:
      const swapDatesIfNeeded = (start?: Dayjs | null, end?: Dayjs | null) => {
        if (!start || !end) return { start, end }
        const startMoment = dayjs(start)
        const endMoment = dayjs(end)

        if (startMoment.isAfter(endMoment)) {
          return { start: endMoment, end: startMoment }
        }
        return { start: startMoment, end: endMoment }
      }

      const customDateFormat = (value: dayjs.Dayjs) =>
        value.utc().format('DD.MM.YYYY HH:mm')

      const disabledDate = (current: dayjs.Dayjs | null): boolean => {
        return !!(current && current > dayjs().endOf('day'))
      }

      return (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#39BE46',
            },
          }}
        >
          <div className={styles.DatePickers}>
            <div className={styles.DatePicker}>
              <DatePicker
                showTime
                onOpenChange={(open: boolean) => {
                  // @ts-ignore
                  blockCloseFilterRef.current =
                    open && blockCloseFilterRef?.current === false
                }}
                placeholder="From"
                onChange={(startDate: Dayjs | null) => {
                  if (startDate) {
                    const { start, end } = swapDatesIfNeeded(
                      startDate,
                      filterValue?.end,
                    )
                    const startMoment = start
                      ? start.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                      : undefined
                    const endMoment = end
                      ? end.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                      : undefined
                    handleChangeFilters(col.id, {
                      from: startMoment,
                      to: endMoment,
                    })
                  } else {
                    handleChangeFilters(
                      col.id,
                      filterValue?.end ? { from: undefined } : undefined,
                    )
                  }
                }}
                value={filterValue?.from ? dayjs(filterValue.from).utc() : null}
                format={customDateFormat}
                disabledDate={disabledDate}
              />
            </div>
            <div className={styles.Icon}>
              <ArrowRightIcon />
            </div>
            <div className={styles.DatePicker}>
              <DatePicker
                showTime
                onOpenChange={(open: boolean) => {
                  // @ts-ignore
                  blockCloseFilterRef.current =
                    open && blockCloseFilterRef?.current === false
                }}
                placeholder="To"
                onChange={(endDate: Dayjs | null) => {
                  if (endDate) {
                    const { start, end } = swapDatesIfNeeded(
                      filterValue?.from,
                      endDate,
                    )

                    const startMoment = start
                      ? start.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                      : undefined
                    const endMoment = end
                      ? end.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                      : undefined
                    handleChangeFilters(col.id, {
                      from: startMoment,
                      to: endMoment,
                    })
                  } else {
                    handleChangeFilters(
                      col.id,
                      filterValue?.to ? { to: undefined } : undefined,
                    )
                  }
                }}
                value={filterValue?.to ? dayjs(filterValue.to).utc() : null}
                format={customDateFormat}
                disabledDate={disabledDate}
              />
            </div>
          </div>
        </ConfigProvider>
      )

    default:
      return null
  }
}
