import { useEffect } from 'react'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { usePushNotifications } from '../../../sdk/hooks/use-push-notifications/usePushNotifications'
import { WEB_SOCKETS_EVENTS_VALUES } from '../constants/WEB_SOCKETS_EVENTS_VALUES'

export const useTableFiltersWithPushNotifications = ({
  filters,
  events,
  reduxTotalCountObjPath,
}: {
  filters: Record<string, any>
  events: Array<WEB_SOCKETS_EVENTS_VALUES>
  reduxTotalCountObjPath: string
}) => {
  const pushNotificationsFromStore = useSelector(
    // @ts-ignore
    (store) => store.pushNotifications,
  )

  const {
    readNotificationsLoading,
    handleReadEvents,
    handleFetchNotifications,
  } = usePushNotifications({
    initialFetch: false,
    events,
  })

  useEffect(() => {
    const eventsFlow = async () => {
      await handleReadEvents({
        events,
      })
    }

    if (
      get(pushNotificationsFromStore, reduxTotalCountObjPath) > 0 &&
      filters.page === 1 &&
      !readNotificationsLoading
    ) {
      eventsFlow()
    }
  }, [pushNotificationsFromStore, filters.page])
}
