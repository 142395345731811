export const formatToDateTimeWithoutTZ = ({
  date,
  toUTC = true,
  includeSeconds = true,
  humanReadable = false,
}: {
  date?: Date
  toUTC?: boolean
  includeSeconds?: boolean
  humanReadable?: boolean
}): string => {
  if (!date) {
    return ''
  }

  const pad = (num: number): string => String(num).padStart(2, '0')

  const year = toUTC ? date.getUTCFullYear() : date.getFullYear()
  const month = pad((toUTC ? date.getUTCMonth() : date.getMonth()) + 1)
  const day = pad(toUTC ? date.getUTCDate() : date.getDate())
  const hours = pad(toUTC ? date.getUTCHours() : date.getHours())
  const minutes = pad(toUTC ? date.getUTCMinutes() : date.getMinutes())
  const seconds = pad(toUTC ? date.getUTCSeconds() : date.getSeconds())

  if (humanReadable) {
    return includeSeconds
      ? `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
      : `${day}.${month}.${year} ${hours}:${minutes}`
  }

  return includeSeconds
    ? `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    : `${year}-${month}-${day} ${hours}:${minutes}`
}
