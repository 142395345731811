import { CallsAutoResponseRules } from '../../../../../../../../../pages/lk/subpages/calls/subpages/calls-auto-response-rules/CallsAutoResponseRules'
import { AuthRoutePath } from '../../../../../../../constants/authRoutePath'
import { CallsRoutePath } from '../../../../../../../constants/callsRoutePath'
import { AuthRoute } from '../../../../../../../enums/authRoute'
import { CallsRoute } from '../../../../../../../enums/callsRoute'
import { RouteDescriptor } from '../../../../../../../types/routeDescriptor'

export const callsAutoResponseRules: RouteDescriptor<CallsRoute.CallsAutoResponseRules> =
  {
    route: CallsRoute.CallsAutoResponseRules,
    type: 'subpage',
    title: 'Teleleo',
    path: CallsRoutePath[CallsRoute.CallsAutoResponseRules],
    render: CallsAutoResponseRules,
    accessLevel: 'user',
    noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
  }
