import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { ReactComponent as AddSuccessCardIcon } from '../../../../assets/icons/add-card-success.svg'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

const PaymentSuccessPageMessages = defineMessages({
  title: {
    id: 'PaymentSuccessPageMessages.title',
    defaultMessage:
      'Your order has been successfully paid and will be shipped soon!',
  },
  description: {
    id: 'PaymentSuccessPageMessages.description',
    defaultMessage:
      'We will ship your order by Fedex within 3 days. You will receive a tracking number to track your order, and upon receipt you will be able to add devices to your personal account',
  },
  toOrders: {
    id: 'PaymentSuccessPageMessages.toOrders',
    defaultMessage: 'To order',
  },
})

export const PaymentSuccessPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const orderId = new URLSearchParams(window.location.search).get('order_id')

  const toOrders = () => {
    if (orderId) {
      navigate(`${RoutePath[Route.Orders]}?order_id=${orderId}`)
    } else {
      navigate(RoutePath[Route.Orders])
    }
  }

  return (
    <PageWrapper>
      <Card additionalClassNames={[styles.Card]}>
        <AddSuccessCardIcon />
        <div className={styles.Container}>
          <div className={styles.Info}>
            <div className={styles.Title}>
              {intl.formatMessage(PaymentSuccessPageMessages.title)}
            </div>
            <div className={styles.Subtitle}>
              {intl.formatMessage(PaymentSuccessPageMessages.description)}
            </div>
          </div>

          <Button
            onClick={toOrders}
            text={intl.formatMessage(PaymentSuccessPageMessages.toOrders)}
            additionalClassNames={[styles.card_button]}
          />
        </div>
      </Card>
    </PageWrapper>
  )
}
