import React from 'react'
import { useSiteVersion } from '../../../app/hooks/useSiteVersion'
import { Card } from '../../../shared/ui-kit-2/data-display/card/Card'
import { EngPrivacyPolicy } from './components/EngPrivacyPolicy/EngPrivacyPolicy'
import { RuPrivacyPolicy } from './components/RuPrivacyPolicy/RuPrivacyPolicy'
import styles from './styles.module.scss'

export const PrivacyPolicyPage = () => {
  const { siteVersion } = useSiteVersion()

  const isRu = siteVersion === 'ru'

  return (
    <div className={styles.Page}>
      <Card additionalClassNames={[styles.Container]}>
        {isRu ? <RuPrivacyPolicy /> : <EngPrivacyPolicy />}
      </Card>
    </div>
  )
}
