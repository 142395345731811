import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { useRootPath } from '../../shared/lib/hooks/useRootPath'
import { Logo } from '../../shared/ui-kit-2/navigation/logo/Logo'
import styles from './styles.module.scss'

interface PageWrapperNoGUIProps {
  showLogo?: boolean
  children: React.ReactNode
}

export const PageWrapperNoGUI: FC<PageWrapperNoGUIProps> = ({
  showLogo = true,
  children,
}) => {
  const navigate = useNavigate()
  const rootPath = useRootPath()

  const toHome = () => navigate(rootPath)

  return (
    <div className={styles.Container}>
      {showLogo && <Logo className={styles.Logo} onClick={toHome} />}

      <div className={styles.Content}>{children}</div>
    </div>
  )
}
