import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import SwaggerUI from 'swagger-ui-react'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { apiDocumentationGetDatagate } from '../../../../sdk/datagates/api/api'
import { useBackendErrorCodes } from '../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { getErrorMessage } from '../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { ApiForm } from './api-form/ApiForm'
import 'swagger-ui-react/swagger-ui.css'
import { defineMessages, useIntl } from 'react-intl'
import { LoadingContent } from '../../../../layouts/loading-content/LoadingContent'
import { userApiKeyDatagate } from '../../../../sdk/datagates/api/user'
import styles from './styles.module.scss'

const ApiPageMessage = defineMessages({
  title: {
    id: 'ApiPageMessage.title',
    defaultMessage: 'API',
  },
  secondTitle: {
    id: 'ApiPageMessage.secondTitle',
    defaultMessage: 'Public API reference',
  },
  errorText: {
    id: 'ApiPageMessage.errorText',
    defaultMessage: 'An error occurred while loading documentation',
  },
})

export const ApiPage = () => {
  const int = useIntl()
  const [swaggerText, setSwaggerText] = useState<string>()
  const { resolveBackendError } = useBackendErrorCodes()
  const [loading, setLoading] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [apiKey, setApiKey] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await userApiKeyDatagate()
        setApiKey(data.apiKey)
      } catch (e) {
        setErrorText(resolveBackendError(getErrorMessage(e)))
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const { data } = await apiDocumentationGetDatagate()
        setSwaggerText(data)
      } catch (e) {
        setErrorText(resolveBackendError(getErrorMessage(e), ''))
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const content = (
    <PageWrapper>
      <Card additionalClassNames={[styles.ApiPageCard]}>
        <div className={styles.Title}>
          {int.formatMessage(ApiPageMessage.title)}
        </div>
        <ApiForm swaggerText={swaggerText} apiKey={apiKey} />
      </Card>
      <Card
        additionalClassNames={[
          clsx(styles.ApiPageCard, styles.ApiPageCard__second),
        ]}
      >
        <div className={styles.Title}>
          {int.formatMessage(ApiPageMessage.secondTitle)}
        </div>

        <SwaggerUI spec={swaggerText} />
      </Card>
    </PageWrapper>
  )

  return (
    <LoadingContent loading={loading} content={content} errorText={errorText} />
  )
}
