import React from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { DongleGetResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/get'
import { TARIFF_PACKAGE_ID } from '../../../../../../sdk/datagates/types/order/_common'
import { formatDate } from '../../../../../../shared/lib/utils/date-utils/formatDate'
import { UsePayDeviceMessages } from '../../usePayDevice'
import styles from './styles.module.scss'
import {Sticker} from "../../../../../../shared/ui-kit-3/components/Sticker";

interface TariffPackageCardProps {
  device?: DongleGetResponse
  getTariffPackagePrice: (
    tariffPackageId: number,
    withDevice?: boolean,
    count?: number,
  ) => number | undefined
  tariffPackageId: number
  paymentMethod: {
    title: string
    paymentMethodType: number
    disabled?: boolean
    description: string
  }
  setTariffPackageId: React.Dispatch<React.SetStateAction<number>>
}

export const TariffPackageCard = (props: TariffPackageCardProps) => {
  const {
    device,
    getTariffPackagePrice,
    tariffPackageId,
    paymentMethod,
    setTariffPackageId,
  } = props
  const intl = useIntl()

  const isActiveTab = tariffPackageId === paymentMethod.paymentMethodType

  const currentTariffPackageIsMonth =
    device?.tariffPackageId === TARIFF_PACKAGE_ID.MONTH
  const currentTariffPackageIsAnnual =
    device?.tariffPackageId === TARIFF_PACKAGE_ID.ANNUAL

  const cardIsMonth = paymentMethod.paymentMethodType === TARIFF_PACKAGE_ID.MONTH;
  const cardIsAnnual = paymentMethod.paymentMethodType === TARIFF_PACKAGE_ID.ANNUAL;
  
  const isTariffPackageActive = device?.isTariffPackageActive

  const paymentMethodHandler = (id: number) => {
    if (
      id === TARIFF_PACKAGE_ID.MONTH &&
      currentTariffPackageIsAnnual &&
      isTariffPackageActive
    ) {
      return
    }
    setTariffPackageId(id)
  }

  const SubscriptionInfo = () => (
    <div className={styles.SubscriptionInfo}>
      {device && (
        <>
          {((currentTariffPackageIsAnnual && cardIsMonth) || (currentTariffPackageIsMonth && cardIsAnnual)) ? (
            <div className={styles.SubscriptionDescription}>
              {paymentMethod.description}
            </div>
          ) : (
            <>
              <div className={styles.SubscriptionWrapper}>
                <div className={clsx(styles.SubscriptionTitle)}>
                  {intl.formatMessage(UsePayDeviceMessages.subscriptionStartTitle)}
                </div>
                {(device.activatedAt || device.createdAt) && (
                  <div className={styles.SubscriptionValue}>
                    {formatDate(
                      device.activatedAt ? device.activatedAt : device.createdAt,
                      0,
                      'date',
                    )}
                  </div>
                )}
              </div>
              <div className={styles.SubscriptionWrapper}>
                <div className={styles.SubscriptionTitle}>
                  {intl.formatMessage(UsePayDeviceMessages.subscriptionEndTitle)}
                </div>
                {device.tariffPackageEnd && (
                  <div
                    className={clsx(
                      styles.SubscriptionValue,
                      !isTariffPackageActive && styles.ErrorSubscription,
                    )}
                  >
                    {formatDate(device.tariffPackageEnd, 0, 'date')}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )

  const TariffPrice = ({ type }: { type: number }) => (
    <div
      className={clsx(
        type === TARIFF_PACKAGE_ID.MONTH
          ? styles.TariffMonth
          : device?.tariffPackageId === TARIFF_PACKAGE_ID.ANNUAL
            ? styles.TariffAnnualCurrent
            : styles.TariffAnnual,
      )}
    >
      € {getTariffPackagePrice(type)}
    </div>
  )

  const Description = ({ text }: { text: string }) => (
    <div className={styles.AnnualTariffWrapper}>
      <div className={styles.AnnualDescription}>{text}</div>
      <div
        className={clsx(
          styles.TariffAnnual,
          paymentMethod.disabled && styles.TariffAnnual_disabled,
        )}
      >
        <span>€{getTariffPackagePrice(paymentMethod.paymentMethodType)}</span>
        {currentTariffPackageIsAnnual &&
          paymentMethod.paymentMethodType === device?.tariffPackageId && (
            <span className={styles.Gifts}>
              {intl.formatMessage(UsePayDeviceMessages.annualPaymentGift)}
            </span>
          )}
        {currentTariffPackageIsMonth && (
          <span className={styles.Gifts}>
            +{' '}
            {intl.formatMessage({
              id: 'giftTwoMonths',
              defaultMessage: 'gift 2 months',
            })}
          </span>
        )}
      </div>
    </div>
  )

  const Content = () => {
    if (cardIsMonth) {
      return (
        <>
          <SubscriptionInfo />
          <TariffPrice type={TARIFF_PACKAGE_ID.MONTH} />
        </>
      )
    } else if (cardIsAnnual) {
      return (
        <>
          <SubscriptionInfo />
          <TariffPrice type={TARIFF_PACKAGE_ID.ANNUAL} />
        </>
      )
    }

    return (
      <Description text={paymentMethod.description} />
    )
  }

  const PaymentSticker = () => {
    if (
      (currentTariffPackageIsMonth && cardIsMonth) ||
      (currentTariffPackageIsAnnual && cardIsAnnual)
    ) {
      return (
        <Sticker
          text={'Current'}
          variant={'blue'}
        />
      )
    }

    if (cardIsAnnual) {
      return (
        <Sticker
          text={'Save up to 22%'}
          variant={'green'}
          textVariant={'darkgreen'}
        />
      )
    }

    return <></>
  }

  return (
    <div
      className={clsx(
        styles.PaymentMethodCard,
        isActiveTab && styles.PaymentMethodCard_active,
        paymentMethod.disabled && styles.PaymentMethodCard_disabled,
      )}
      onClick={() =>
        !paymentMethod.disabled &&
        paymentMethodHandler(paymentMethod.paymentMethodType)
      }
    >
      <div className={styles.TitleAndRadio}>
        <div
          className={clsx(
            styles.Title,
            paymentMethod.disabled && styles.Disable,
          )}
        >
          <div className={styles.Title__Text}>
            {paymentMethod.title}
          </div>

          <PaymentSticker />
        </div>
        <div className={clsx(styles.Checkbox, isActiveTab && styles.Active)} />
      </div>
      <div className={styles.ChildrenPaymentCard}>
        <Content />
      </div>
    </div>
  )
}
