import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router'
import { ReactComponent as ConnectionInCirclesIcon } from '../../../../assets/icons/v2/ic-connection-in-circles.svg'
import { ReactComponent as WifiInCirclesIcon } from '../../../../assets/icons/v2/ic-wifi-in-circles.svg'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import Button from '../../../../shared/ui-kit-3/components/Button/Button'
import styles from './styles.module.scss'
import {useOrderDongle} from "../../../../sdk/hooks/use-order-dongle/useOrderDongle";
import {SNACKBAR_TYPES} from "../../../../layouts/snackbar/SnackbarProvider";
import {useSnackbar} from "../../../../app/hooks/useSnackbar";
import {dongleGetHashForAutoActivation} from "../../../../sdk/datagates/api/dongle";


export const AutoActivationDevicePage = () => {
  const navigate = useNavigate();
  const { handleOpenSnackbar } = useSnackbar();
  const { monthlyAvailable, annualAvailable, loading: orderDongleLoading } = useOrderDongle();

  const [loading, setLoading] = useState<boolean>(false);

  const handleConnectToDongle = async () => {
    setLoading(true);

    try {
      const { data } = await dongleGetHashForAutoActivation();

      // @ts-ignore
      const activationDomain = window?.env?.DONGLE_AUTOMATIC_ACTIVATION_DOMAIN;
      const params = new URLSearchParams();

      if (monthlyAvailable?.length > 0) {
        params.append('monthlyAvailableId', monthlyAvailable[0].orderDongleId)
      }

      if (annualAvailable?.length > 0) {
        params.append('annualAvailableId', annualAvailable[0].orderDongleId)
      }

      params.append('monthlyAvailableCount', monthlyAvailable.length)
      params.append('annualAvailableCount', annualAvailable.length)

      params.append('hash', data?.hash)

      if (activationDomain) {
        window.location.href = `http://${activationDomain}${RoutePath[Route.ConnectToDongle]}?${params.toString()}`;
      } else {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: 'Error. Code: ENV LOST.',
        })
      }
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: 'Error. Code: NO HASH.',
      })
    }
  };

  return (
    <PageWrapper loading={loading || orderDongleLoading}>
      <div className={styles.Card}>
        <div className={styles.Content}>
          <div className={styles.Icons}>
            <ConnectionInCirclesIcon className={styles.Icon} />
            <WifiInCirclesIcon className={styles.Icon} />
          </div>
          <div className={styles.Text}>
            Insert the modem into the computer
            {`\n`}
            or
            {`\n`}
            Plug the modem into any outlet and connect to the Wi-Fi of this
            modem
          </div>
          <div className={styles.Actions}>
            <Button
              className={styles.Button}
              onClick={() => navigate(RoutePath[Route.ManualDeviceActivation])}
              variant={'greyOutlined'}
              text={'Go to manual mode'}
              size={'xl'}
            />
            <Button
              className={styles.Button}
              onClick={handleConnectToDongle}
              variant={'greenFilled'}
              text={'Yes, i did it'}
              size={'xl'}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}
