import { ReactNode, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router'
import { useSiteVersion } from '../../../../../app/hooks/useSiteVersion'
import { RoutePath } from '../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../config/routes/enums/route'
import { FAQSectionAnchors } from '../../constants/anchors'
import styles from './styles.module.scss'

const NAVIGATION = [
  {
    title: 'What is Teleleo?',
    subItems: [
      {
        title: 'Do We Offer a SIM Card?',
      },
      {
        title: 'Activating a New Device',
      },
      {
        title: 'Buying a New Device Using Teleleo Website',
      },
    ],
  },
  {
    title: 'Account Details',
    subItems: [
      {
        title: 'Editing Your Information',
      },
      {
        title: 'Changing Your Password',
      },
      {
        title: 'Balance History',
      },
      {
        title: 'Adding a New Card',
      },
      {
        title: 'Deleting a Card',
      },
      {
        title: 'Topping up Balance',
      },
    ],
  },
  {
    title: 'SMS',
    subItems: [
      {
        title: 'Sending an SMS using Teleleo',
      },
      {
        title: 'Redirecting My SMS to Email or Telegram',
      },
      {
        title: 'Creating Auto Replies for Incoming SMS?',
      },
      {
        title: 'Mailings (Campaigns)',
      },
    ],
  },
  {
    title: 'Calls',
    subItems: [
      {
        title: 'Making a Call Using Teleleo',
      },
      {
        title: 'Redirecting Calls to Email or Telegram',
      },
      {
        title: 'Filtering Calls',
      },
    ],
  },
  {
    title: 'WI-FI',
    subItems: [
      {
        title: 'Configuring a WI-FI Hotspot',
      },
    ],
  },
  {
    title: 'API',
    subItems: [
      {
        title: 'Teleleo API',
      },
    ],
  },
  {
    title: 'Managing contacts and groups',
    subItems: [
      {
        title: 'Adding a Contact',
      },
      {
        title: 'Deleting a Contact',
      },
      {
        title: 'Editing Contacts',
      },
      {
        title: 'Adding a New Group',
      },
      {
        title: 'Deleting a Group',
      },
      {
        title: 'Edit a Contact Group',
      },
    ],
  },
  {
    title: 'Devices',
    subItems: [
      {
        title: 'Manage Your Device',
      },
    ],
  },
]

const DESKTOP_DEFAULT_TOP_VALUE = 220

export const FAQNavigation = () => {
  const [currentNavItem, setCurrentNavItem] = useState(0)
  const { siteVersion } = useSiteVersion()
  const { pathname, hash } = useLocation()
  const divRef = useRef<HTMLDivElement>(null)
  const lastScrollTop = useRef(0)
  const topValue = useRef(DESKTOP_DEFAULT_TOP_VALUE)

  useEffect(() => {
    if (pathname === RoutePath[Route.FAQ]) {
      const anchorElement = document.getElementById(hash)
      if (anchorElement) {
        const yOffset = -100
        const y =
          anchorElement.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset

        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    }
  }, [pathname, hash])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset

      if (divRef.current) {
        if (scrollTop <= 100 + DESKTOP_DEFAULT_TOP_VALUE) {
          if (scrollTop > lastScrollTop.current) {
            topValue.current = topValue.current - 1
          } else {
            topValue.current = topValue.current + 1
          }
        } else {
          topValue.current = 100
        }

        if (scrollTop === 0) {
          topValue.current = DESKTOP_DEFAULT_TOP_VALUE
        }

        divRef.current.style.top = `${topValue.current}px`
      }

      lastScrollTop.current = scrollTop
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (siteVersion === 'ru') {
    return <></>
  }

  return (
    <div
      className={styles.FAQNavigation}
      ref={divRef}
      style={{ top: `${topValue.current}px` }}
    >
      {NAVIGATION.map(({ title, subItems }, index) => {
        const NavItem = ({ children }: { children: ReactNode }) => (
          <div
            className={clsx(
              styles.NavItem,
              currentNavItem === index && styles.NavItem_current,
            )}
            onClick={() => setCurrentNavItem(index)}
          >
            {children}
          </div>
        )

        const SubItems = () =>
          subItems.length > 0 ? (
            <div className={styles.SubItems}>
              {subItems.map(({ title }, subItemIndex) => (
                <a
                  key={`subitem/${index}/${subItemIndex}`}
                  href={
                    FAQSectionAnchors[`ARTICLE${index + 1}-${subItemIndex + 1}`]
                  }
                  className={styles.SubTitle}
                >
                  {title}
                </a>
              ))}
            </div>
          ) : (
            <></>
          )

        return (
          <NavItem key={`navitem/${index}`}>
            <a
              href={FAQSectionAnchors[`ARTICLE${index + 1}`]}
              className={styles.Title}
            >
              {title}
            </a>
            <SubItems />
          </NavItem>
        )
      })}
    </div>
  )
}
