import { CallsListPage } from '../../../../../../../../../pages/lk/subpages/calls/subpages/calls-list/CallsListPage'
import { AuthRoutePath } from '../../../../../../../constants/authRoutePath'
import { CallsRoutePath } from '../../../../../../../constants/callsRoutePath'
import { AuthRoute } from '../../../../../../../enums/authRoute'
import { CallsRoute } from '../../../../../../../enums/callsRoute'
import { RouteDescriptor } from '../../../../../../../types/routeDescriptor'

export const callsList: RouteDescriptor<CallsRoute.CallsList> = {
  route: CallsRoute.CallsList,
  type: 'subpage',
  title: 'Teleleo',
  path: CallsRoutePath[CallsRoute.CallsList],
  render: CallsListPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
