import { AutoActivationDevicePage } from '../../../../../../../pages/lk/subpages/auto-activation-device/AutoActivationDevicePage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const autoDeviceActivation: RouteDescriptor<Route.AutoDeviceActivation> =
  {
    route: Route.AutoDeviceActivation,
    type: 'subpage',
    title: 'Teleleo',
    path: RoutePath[Route.AutoDeviceActivation],
    render: AutoActivationDevicePage,
    accessLevel: 'user',
    noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
  }
