export const formatDateToTableHeader = (dateString: string): string => {
  // Проверяем, что строка соответствует формату YYYY-MM-DD
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return dateString // Если формат некорректен, возвращаем исходную строку
  }

  const [year, month, day] = dateString.split('-')

  if (!day || !month || !year || isNaN(Date.parse(dateString))) {
    return dateString // Если дата некорректна, возвращаем исходную строку
  }

  // Преобразуем в формат DD.MM.YYYY
  return `${day}.${month}.${year}`
}
