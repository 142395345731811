import React from 'react'
import styles from '../../../privacy-policy/styles.module.scss'

export const EngTermsOfUse = () => {
  return (
    <div>
      <div className={styles.Title}>Terms and Conditions of Use</div>

      <h3>Introduction</h3>
      <p>
        These terms and conditions apply between you, the user of this website
        (including subdomains, unless expressly excluded by their own terms and
        conditions), and Teleleo Limited, "the COMPANY," being the owner and
        operator of this website www.teleleo.com.
      </p>

      <h3>The COMPANY Details</h3>
      <p>
        The COMPANY, hereinafter "the COMPANY" is a COMPANY incorporated in the
        United Kingdom, with COMPANY Number 13578725, VAT GB388170859, with its
        registered office and tax address at Office 1.01, 411-413 Oxford Street,
        London W1C 2PE, United Kingdom, and Phone Number +44 20 3608 0605. You
        can contact the COMPANY by email at info@teleleo.com. Read these terms
        and conditions carefully, as they affect your legal rights. Your
        agreement to comply with and be bound by these terms and conditions is
        deemed to occur on your first use of the website. If you do not agree to
        be bound by these terms and conditions, you must immediately stop using
        the website. In these terms and conditions, User or Users means any
        third party who accesses the Website and is not (i) employed by the
        COMPANY and acting in the course of their employment or (ii) engaged as
        a consultant or who provides services to the COMPANY and accesses the
        Website in connection with the provision of such services. You must be
        at least 18 years of age to use this website. By using the Website and
        agreeing to these terms and conditions, you represent and warrant that
        you are at least 18 years of age.
      </p>

      <h3>General Terms and Conditions of Service and Teleleo Device</h3>
      <ol>
        <li>
          The General Terms and Conditions of the service are applicable to all
          users for the use of the Teleleo device, hereinafter "the device."
        </li>
        <li>
          The COMPANY is not responsible for the misuse/improper/fraudulent use
          of the device and/or its functionalities.
        </li>
        <li>
          The COMPANY is not responsible for lost or broken device or other
          irresponsible use. If the user wishes to continue enjoying the
          service, he/she will have to buy a new device again.
        </li>
        <li>
          The COMPANY offers a 3-year legal warranty from the time of purchase
          of the device.
        </li>
        <li>
          For the correct use of the device, the connectivity part will be on
          behalf of the user, where the contracting of a SIM card will be
          required, which includes data of a minimum of 200 MB per month.
        </li>
        <li>
          The consumption produced by the SIM card of SMS messaging, voice, data
          and other concepts will be on behalf of the user and will never form
          part of this contractual relationship. All connectivity issues, both
          quality and price, will be determined and discussed with the
          corresponding mobile operator.
        </li>
        <li>
          The monthly subscription for the use of the device is 50 euros per
          month including VAT. The annual subscription is 480 euros per year,
          VAT included.
        </li>
        <li>
          Shipping costs. Shipping costs 10 euros, which will be charged to the
          customer, if the subscription is monthly. The annual subscription
          includes shipping costs.
        </li>
        <li>
          The price of the device is 100 euros. If the subscription is monthly,
          the 100 euros will be charged along with the monthly fee for the
          service. If the subscription is annual, the 100 euros will be included
          in the price of the annual subscription.
        </li>
        <li>
          At the end of the contract, the device will stop working completely.
          If the user wants to activate the device, they will need to reactivate
          the monthly or annual subscription. Annual subscription will not
          include shipment of a new device.
        </li>
        <li>
          Non-payment entails a breach of contract by the user and the complete
          deactivation of the device within 14 days of the due payment date. If
          the customer wishes to activate the device, he will have to pay the
          debt and reactivate the service.
        </li>
        <li>
          The service will be charged by bank card that the customer will enter
          when contracting the service. The customer will have the option to
          change the card and enter a new one. The subscription, both annual and
          monthly, will be charged in the first 3 days of each month. The
          monthly or annual price does not include the commissions that the
          customer has with his bank.
        </li>
        <li>
          The COMPANY reserves the right to modify the contractual conditions
          with 2 (two) months' notice.
        </li>
        <li>
          Both the COMPANY and the user may give 1 (one) month's notice of the
          termination or non-renewal of the contract.
        </li>
        <li>
          Upon termination of the contract, the user will have the possibility
          to request and exercise the right to erasure of his/her personal data,
          contact details, means of payment and other personal data in
          accordance with Article 17 of Regulation (EU) 2016/679 of the European
          Parliament and of the Council of 27 April 2016 on the protection of
          natural persons with regard to the processing of personal data and on
          free movement of this data and repealing Directive 95/46/EC (General
          Data Protection Regulation), and with article 15 of Organic Law 3/2018
          of 5 December, on the protection of personal data and guarantee of
          digital rights, following the conditions it establishes.
        </li>
      </ol>

      <h3>Intellectual Property and Acceptable Use</h3>
      <ol>
        <li>
          All Content included on the Website, unless uploaded by Users, is the
          property of the COMPANY, our affiliates or other relevant third
          parties.
        </li>
        <li>
          You may, for your own personal, non-commercial use only, do the
          following:
          <ul>
            <li>Retrieve, display, and view the Content on a device.</li>
            <li>
              Download and store the Content in electronic form on a disc (but
              not on any server or other storage device connected to a network).
            </li>
            <li>Print a copy of the Content.</li>
          </ul>
        </li>
        <li>
          You must not reproduce, modify, copy, distribute or use for commercial
          purposes any Content without the written permission of the COMPANY.
        </li>
      </ol>

      <h3>Prohibited Use</h3>
      <ol>
        <li>
          You may not use the Website for any of the following purposes:
          <ul>
            <li>
              In any way that causes, or may cause, damage to the Website or
              interferes with any other person's use or enjoyment of the
              Website.
            </li>
            <li>
              In any way that is harmful, illegal, abusive, harassing,
              threatening, or otherwise objectionable or that violates any law,
              applicable government regulation or order.
            </li>
            <li>
              Make, transmit, or store electronic copies of copyrighted Content
              without the permission of the owner.
            </li>
          </ul>
        </li>
      </ol>

      <h3>Privacy Policy and Use of Cookies</h3>
      <p>
        Your use of the Website is also governed by our Privacy Policy, which
        also reflects information about cookies, which is incorporated into
        these terms and conditions via this link. To read our Privacy and Cookie
        Policy, please visit: https://teleleo.com/privacy-policy.
      </p>

      <h3>Website Availability and Disclaimers</h3>
      <ol>
        <li>
          Any online installations, tools, services or information that the
          COMPANY makes available through the website (the Service) are provided
          on an "as is" and "as available" basis.
        </li>
        <li>
          While the COMPANY makes reasonable efforts to ensure that the website
          is secure and free of errors, viruses and other malware, we do not
          give any warranties in this regard and all users assume responsibility
          for their own security.
        </li>
      </ol>

      <h3>Limitation of Liability</h3>
      <ol>
        <li>
          Nothing in these terms and conditions shall limit or exclude our or
          your liability for death or personal injury resulting from negligence.
        </li>
        <li>
          To the maximum extent permitted by law, Teleleo Limited accepts no
          liability for any indirect or consequential loss or damage.
        </li>
        <li>
          To the maximum extent permitted by law, Teleleo Limited accepts no
          liability for any of the following:
          <ul>
            <li>
              Any business loss, such as loss of profits, revenue, anticipated
              savings, business, contracts, goodwill, or business opportunities.
            </li>
            <li>Loss or corruption of any data, database, or software.</li>
            <li>Any special loss or damage, indirect or consequential.</li>
          </ul>
        </li>
      </ol>

      <h3>General</h3>
      <ol>
        <li>
          You may not transfer any of your rights under these terms and
          conditions to any other person. We may transfer our rights under these
          terms and conditions where we reasonably believe that your rights will
          not be affected.
        </li>
        <li>
          These terms and conditions may be amended by us from time to time.
          Such revised terms will apply to the Website as of the date of
          posting. Users should review the terms and conditions regularly to
          ensure familiarity with the current version at that time.
        </li>
        <li>
          These terms and conditions and the Privacy Policy and Cookie Policy
          contain the entire agreement between the parties in relation to their
          subject matter and supersede any prior discussions, understandings or
          agreements that may have occurred with respect to these terms and
          conditions.
        </li>
        <li>
          The Contracts (Rights of Third Parties) Act 1999 shall not apply to
          these terms and conditions and no third party shall be entitled to
          enforce or rely on any provision of these terms and conditions.
        </li>
        <li>
          If any provision of these terms and conditions (or part of any
          provision) is found by any court or competent authority to be invalid,
          illegal or unenforceable, that provision or partial provision, to the
          extent required, shall be deemed to be deleted, and the validity and
          enforceability of the other provisions of these terms and conditions
          shall not be affected. Unless otherwise agreed, no delay, act or
          omission by a party in exercising any right or remedy shall be deemed
          a waiver of that or any other right or remedy.
        </li>
        <li>
          This Agreement shall be governed by and construed in accordance with
          the laws of United Kingdom and all disputes arising in connection with
          the Agreement (including non-contractual disputes or claims) shall be
          subject to the exclusive jurisdiction of the courts of United Kingdom.
        </li>
      </ol>

      <h3>Attribution</h3>
      <p>
        These terms and conditions were created using a document from Rocket
        Lawyer (https://www.rocketlawyer.com).
      </p>
    </div>
  )
}
