import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SipType } from './types'

const sipInitialState: SipType = {
  sipUser: null,
  from: {
    name: null,
    phoneNumber: null,
    imeiManufacture: null,
  },
  to: {
    name: null,
    phoneNumber: null,
  },
  type: null,
  sip: {
    makeCall: () => null,
    answerIncomingCall: () => null,
    endCall: () => null,
    ua: () => null,
    hasOutgoingCall: () => null,
    hasIncomingCall: () => null,
    callHasAcceptedByOtherSide: () => null,
  },
}

const sipSlice = createSlice({
  name: 'sip',
  initialState: sipInitialState,
  reducers: {
    setSip(state, { payload }: PayloadAction<SipType>) {
      state.sipUser = payload.sipUser
      state.from = payload?.from
      state.to = payload?.to
      state.sip = payload?.sip
      state.type = payload?.type
    },
    clearSip(state) {
      state.sipUser = sipInitialState.sipUser
      state.from = sipInitialState.from
      state.to = sipInitialState.to
      state.sip = sipInitialState.sip
      state.type = sipInitialState?.type
    },
  },
})

export const { setSip, clearSip } = sipSlice.actions
export default sipSlice.reducer
