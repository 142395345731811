import React, { FC } from 'react'
import clsx from 'clsx'
import { ReactComponent as FedexIcon } from '../../../../../../assets/icons/fedex.svg'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import styles from './styles.module.scss'

interface PurchaseDetailProps {
  title: string
  value: string
}

export const PurchaseDetailFedex: FC<PurchaseDetailProps> = (props) => {
  const { title, value } = props
  const isMobileXL = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK_XL)

  // TODO: заменить value === 'FREE' на что-то более опрятное

  return (
    <div className={styles.Detail}>
      <div className={styles.Container}>
        <div className={styles.Title}>{title}</div>
        <div
          className={clsx(styles.Value, value === 'FREE' && styles.Value_green)}
        >
          {value}
        </div>
      </div>
      {!isMobileXL && (
        <div className={styles.Logo}>
          <FedexIcon />
        </div>
      )}
    </div>
  )
}
