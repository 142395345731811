export const PAYMENT_TYPE_IDS = {
  ORDER: 1,
  CREDIT: 2,
  AUTHORIZED: 3,
  REFUND: 4,
  WIRE_TRANSFER: 5,
  PAYMENT_METHOD: 6,
  TARIFF_CREDIT: 7,
  DONGLE_CARD: 8,
  DONGLE_BALANCE: 9,
  DONGLE_WIRE: 10,
}

export const TARIFF_PACKAGE_TYPE_IDS = {
  MONTH_EUROPE: 1,
  YEAR_EUROPE: 2,
  MONTH_RUSSIAN: 3,
  YEAR_RUSSIAN: 4,
}
