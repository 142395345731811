import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonFormMessages } from '../../../config/intl/common-messages/common-form-messages/CommonFormMessages'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  dongleListDatagate,
  dongleUpdateDatagate, dongleWifiCredentialsDatagate, dongleWifiDisableDatagate, dongleWifiEnableDatagate,
} from '../../datagates/api/dongle'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { DongleListResponse } from '../../datagates/types/dongle/_crud/list'

export type UpdateWifiFormType = {
  deviceId: number
  hotspotName: string
  hotspotPassword: string
}

const UseWifiMessages = defineMessages({
  positiveUpdated: {
    id: 'UseSmsesMessages.positiveAdded',
    defaultMessage: 'WI-FI is successfully updated',
  },
  negativeUpdated: {
    id: 'UseSmsesMessages.negativeAdded',
    defaultMessage: 'An error occurred while updating WI-FI',
  },
})

export const useWifi = (props?: {
  inputValue?: string
  autoFetchNeeded?: boolean
}) => {
  const inputValue = props?.inputValue ?? ''
  const autoFetchNeeded = props?.autoFetchNeeded ?? true

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [loading, setLoading] = useState<boolean>(true)
  const [devices, setDevices] = useState<DongleListResponse['dongles']>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [filteredDevices, setFilteredDevices] = useState(devices)

  const handleUpdateWifi = async (
    values: UpdateWifiFormType,
    onSuccess?: () => any,
  ) => {
    try {
      const urlParams = [
        { name: 'dongle_id', value: values.deviceId.toString() },
      ]

      await dongleWifiCredentialsDatagate(
        {
          hotspot_name: values.hotspotName,
          hotspot_password: values.hotspotPassword,
        },
        urlParams,
      )

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseWifiMessages.positiveUpdated),
      })

      await handleFetch()

      handleHideModal()
      
      try {
        if (onSuccess) {
          onSuccess?.()
        }
      } catch (e) {}
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(UseWifiMessages.negativeUpdated),
        ),
      })
    }
  }

  const handleSwitchHotspot = async (dongleId: number, enabled: boolean) => {
    try {
      const urlParams = [{ name: 'dongle_id', value: dongleId.toString() }]

      if (enabled) {
        await dongleWifiEnableDatagate(null, urlParams)
      } else {
        await dongleWifiDisableDatagate(null, urlParams)
      }

      setDevices((prev) =>
        prev.map((device) => {
          if (device.dongleId !== dongleId) return device
          return { ...device, isHotspotEnable: enabled }
        }),
      )
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    }
  }

  const handleFetch = async ({ hidden }: { hidden?: boolean } = {}) => {
    try {
      setLoading(!hidden)

      const queryParams = [
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
      ]

      const { data } = await dongleListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setDevices(data.dongles)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(CommonFormMessages.fetchError),
        ),
      })
    } finally {
      setLoading(false)
    }
  }

  const filterDevices = (
    devices: DongleListResponse['dongles'],
    filterValue: string,
  ) => {
    return devices.filter((device) => {
      const nameMatch = device.name
        ?.toLowerCase()
        .includes(filterValue.toLowerCase())
      const phoneMatch = device.phoneNumber?.includes(filterValue)
      return nameMatch || phoneMatch
    })
  }

  useEffect(() => {
    const result = filterDevices(devices, inputValue)
    setFilteredDevices(result)
  }, [inputValue, devices])

  useEffect(() => {
    if (autoFetchNeeded) {
      void handleFetch()
    }
  }, [])

  return {
    filteredDevices,
    totalCount,
    handleFetch,
    handleUpdateWifi,
    handleSwitchHotspot,
    loading,
  }
}
