import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router'
import { Sticker } from '../../../../../../shared/ui-kit-3/components/Sticker'
import styles from '../../styles.module.scss'
import { Card } from '../Card'
import { SeeAllButton } from '../SeeAllButton'

type SmallCardProps = {
  title: string
  countOnline: number
  countOffline: number
  countDevices: number
  seeAllButtonRoute: string
  bgIcon: ReactNode
}

const SmallCard = ({
  title,
  countOnline,
  countOffline,
  countDevices,
  seeAllButtonRoute,
  bgIcon,
}: SmallCardProps) => {
  const navigate = useNavigate()

  return (
    <Card>
      <>
        <div className={styles.Card__Top}>
          <div className={styles.Card__Title}>{title}</div>
          <SeeAllButton onClick={() => navigate(seeAllButtonRoute)} />
        </div>
        <div className={styles.Card__Stickers}>
          {countDevices === 0 ? (
            <Sticker variant={'grey'} text={`No one modem`} />
          ) : (
            <>
              <Sticker variant={'green'} text={`${countOnline} On`} />
              <Sticker variant={'red'} text={`${countOffline} Off`} />
            </>
          )}
        </div>
        <div className={styles.Card__Background}>{bgIcon}</div>
      </>
    </Card>
  )
}

export default SmallCard
