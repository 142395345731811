import { VPNPage } from '../../../../../../../pages/lk/subpages/vpn/VPNPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const vpn: RouteDescriptor<Route.VPN> = {
  route: Route.VPN,
  type: 'subpage',
  title: 'Teleleo',
  path: RoutePath[Route.VPN],
  render: VPNPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
