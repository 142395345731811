import React from 'react'
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/v2/ic-arrow-right.svg'
import { Button } from '../../../../../../shared/ui-kit-3/components/Button'
import styles from './styles.module.scss'

const SeeAllButton = ({ onClick }: { onClick?: () => void }) => (
  <Button
    variant={'greenOutlined'}
    text={'See all'}
    size={'sm'}
    postfix={
      <div className={styles.ButtonIcon}>
        <ArrowRightIcon />
      </div>
    }
    onClick={onClick}
  />
)

export default SeeAllButton
