import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import { useSiteVersion } from '../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { tariffPackageListDatagate } from '../../datagates/api/billing'
import { calculateOrderPriceDatagate } from '../../datagates/api/order'
import { TariffPackageListResponse } from '../../datagates/types/billing/tariff-package/_crud/list'
import {
  TARIFF_PACKAGE_ID,
  TARIFF_PACKAGE_IDS_EN_TO_RU,
} from '../../datagates/types/order/_common'
import { formatPrice } from '../../formatters/format-price'
import {setTariffPackages as setTariffPackagesStateStore} from "../../../store/reducers/tariff-packages";

const UseTariffPackagesMessages = defineMessages({
  annual: {
    id: 'UseTariffPackagesMessages.annual',
    defaultMessage: 'Annual',
  },
  month: {
    id: 'UseTariffPackagesMessages.month',
    defaultMessage: 'Monthly',
  },
  free: {
    id: 'UseTariffPackagesMessages.free',
    defaultMessage: 'FREE',
  },
  calculated: {
    id: 'UseTariffPackagesMessages.calculated',
    defaultMessage: 'Calculated',
  },
  calculationError: {
    id: 'UseTariffPackagesMessages.calculationError',
    defaultMessage: 'Calculation error',
  },
  unknown: {
    id: 'UseTariffPackagesMessages.unknown',
    defaultMessage: 'Unknown',
  },
  errorFetch: {
    id: 'UseTariffPackagesMessages.errorFetch',
    defaultMessage: 'An error occurred while fetching subscription prices',
  },
})

export const useTariffPackages = ({ initialFetch }: { initialFetch?: boolean } = {}) => {
  // @ts-ignore
  const tariffPackagesFromStore = useSelector(store => store.tariffPackages.list)
  const dispatch = useDispatch()
  const intl = useIntl()
  const { siteVersion, siteDomainId } = useSiteVersion()
  const { handleOpenSnackbar } = useSnackbar()

  const [tariffPackages, setTariffPackagesState] = useState<
    TariffPackageListResponse['tariffPackages']
  >(tariffPackagesFromStore || [])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  const handleFetch = async () => {
    try {
      const { data } = await tariffPackageListDatagate(null, [
        {
          name: 'site_domain_id',
          value: `${siteDomainId}`,
        },
      ])

      const filteredTariffPackages = data.tariffPackages;

      dispatch(setTariffPackagesStateStore({ list: filteredTariffPackages }))

      setTariffPackagesState(filteredTariffPackages)
      setTotalCount(filteredTariffPackages?.length ?? 0)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseTariffPackagesMessages.errorFetch),
      })
    } finally {
      setLoading(false)
    }
  }

  const getTariffPackageTranslation = (tariffPackageId?: number): string => {
    switch (tariffPackageId) {
      case TARIFF_PACKAGE_ID.ANNUAL:
        return intl.formatMessage(UseTariffPackagesMessages.annual)
      case TARIFF_PACKAGE_ID.MONTH:
        return intl.formatMessage(UseTariffPackagesMessages.month)
      default:
        return intl.formatMessage(UseTariffPackagesMessages.unknown)
    }
  }

  const getTariffPackagePrice = (
    tariffPackageId?: number,
    withDevice: boolean = false,
    count: number = 1,
  ) => {
    const siteVersionedTariffPackageId =
      siteVersion === 'ru'
        ? // @ts-ignore
          TARIFF_PACKAGE_IDS_EN_TO_RU[tariffPackageId]
        : tariffPackageId

    const price = tariffPackages.find(
      (tp) => tp.tariffPackageId === siteVersionedTariffPackageId,
    )?.price

    if (!price) return undefined

    if (withDevice) {
      const devicePrice = tariffPackages.find(
        (tp) => tp.tariffPackageId === siteVersionedTariffPackageId,
      )?.donglePrice

      if (devicePrice) return price + devicePrice
    }

    return price * count
  }

  const getDevicePrice = (tariffPackageId: number): number | undefined => {
    const price = tariffPackages.find(
      (tp) => tp.tariffPackageId === tariffPackageId,
    )?.donglePrice

    if (!price) return undefined

    return price
  }

  const getTotalPrices = async (
    tariffPackages: { tariffPackageId: number; count: number }[],
    country_id?: number | string,
    postal_code?: string,
    region?: string,
    city?: string,
    street_address1?: string,
    street_address2?: string,
    delivery_provider_id?: number,
  ): Promise<{
    totalPrice: string
    price: string
    tariffPackagePrices: { [key: string]: string }
    deliveryPrice: string
  }> => {
    try {
      const totalCount = Object.values(tariffPackages).reduce((acc, cur) => {
        return acc + cur.count
      }, 0)

      const is_calculate_delivery = Boolean(
        country_id ||
          postal_code ||
          region ||
          city ||
          street_address1 ||
          street_address2 ||
          delivery_provider_id,
      )

      if (totalCount === 0)
        return {
          price: formatPrice(0),
          deliveryPrice: formatPrice(null),
          totalPrice: formatPrice(null),
          tariffPackagePrices: {},
        }

      const { data } = await calculateOrderPriceDatagate({
        order_dongles: tariffPackages.map((tp) => ({
          tariff_package_id: tp.tariffPackageId,
          count: tp.count,
        })),
        is_calculate_delivery,
        ...(is_calculate_delivery
          ? {
              country_id: country_id ? +country_id : 1,
              postal_code,
              region,
              city,
              street_address1,
              street_address2,
              delivery_provider_id,
            }
          : {}),
      })

      const tariffPackagePrices = data.tariffPackagePrices?.reduce(
        (acc, cur) => {
          // @ts-ignore
          acc[cur.tariffPackageId] = formatPrice(cur.price)
          return acc
        },
        {},
      )

      return {
        price: formatPrice(data.price),
        deliveryPrice:
          data?.deliveryPrice === null || data?.deliveryPrice === 0
            ? intl.formatMessage(UseTariffPackagesMessages.free)
            : formatPrice(data?.deliveryPrice),
        totalPrice: formatPrice(data.price + (data?.deliveryPrice || 0)),
        tariffPackagePrices: tariffPackagePrices,
      }
    } catch (e) {
      return {
        price: intl.formatMessage(UseTariffPackagesMessages.calculationError),
        deliveryPrice: formatPrice(null),
        totalPrice: formatPrice(null),
        tariffPackagePrices: {},
      }
    }
  }

  useEffect(() => {
    if (initialFetch) {
      void handleFetch()
    }
  }, [])

  return {
    tariffPackages,
    loading,
    totalCount,
    getTariffPackageTranslation,
    getTariffPackagePrice,
    getDevicePrice,
    getTotalPrices,
  }
}
