import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapCallListBackendToCallList } from '../../mappers/call/_crud/list/map-call-list-backend-to-call-list'
import {
  CallListBackendResponse,
  CallListRequest,
  CallListResponse,
} from '../../types/call/_crud/list'

export const callListDatagate = wrapApiRequest<
  CallListRequest,
  CallListBackendResponse,
  CallListResponse
>('call', mapCallListBackendToCallList, 'GET')

export const callScenarioListDatagate = wrapApiRequest<any, any, any>(
  'call-scenario',
  (item) => item,
  'GET',
)

export const callScenarioAddDatagate = wrapApiRequest<any, any, any>(
  'call-scenario',
  (item) => item,
  'POST',
)

export const callScenarioUpdateDatagate = wrapApiRequest<any, any, any>(
  'call-scenario/{call_scenario_id}',
  (item) => item,
  'PUT',
)

export const callScenarioDeleteDatagate = wrapApiRequest<any, any, any>(
  'call-scenario/{call_scenario_id}',
  (item) => item,
  'DELETE',
)

export const callAutoDialerAddDatagate = wrapApiRequest<any, any, any>(
  'call-scenario/{call_scenario_id}',
  (item) => item,
  'GET',
)
