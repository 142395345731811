import React, { FC } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '../../store'
import styles from './styles.module.scss'
import {useLocation} from "react-router";
import {NEVER_SHOW_HEADER_ROUTES} from "../header/Header";
import { Loader } from '../../shared/ui-kit/data-display/loader/Loader'

type Props = {
  children: React.ReactNode
  loading?: boolean
}

export const PageWrapper = ({ children, loading }: Props) => {
  const { pathname } = useLocation();

  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  return (
    <div
      className={clsx(
        styles.Wrapper,
        isAuthenticated && styles.Wrapper_authenticated,
        loading && styles.Wrapper_loading,
        NEVER_SHOW_HEADER_ROUTES.includes(pathname) && styles.Wrapper_noPadding,
      )}
    >
      {loading ? (
        <div className={styles.Loader}>
          <Loader />
        </div>
      ) : (
        <div className={styles.Content}>
          {children}
        </div>
      )}
    </div>
  )
}
