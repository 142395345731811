import React from 'react'
import { useLocation } from 'react-router'
import { RoutePath } from '../../config/routes/constants/routePath'
import { Route } from '../../config/routes/enums/route'
import { useAppSelector } from '../../store'
import { GuestHeader } from './components/guest-header/GuestHeader'
import { UserHeader } from './components/user-header/UserHeader'

export const GUEST_HEADER_ROUTES = [
  RoutePath[Route.Home],
  RoutePath[Route.FAQ],
  RoutePath[Route.PrivacyPolicy],
  RoutePath[Route.TermsOfUse],
];

export const NEVER_SHOW_HEADER_ROUTES = [
  RoutePath[Route.ConnectToDongle],
]

export const Header = () => {
  const { isAuthenticated } = useAppSelector((state) => state.user)
  const { pathname } = useLocation()

  if (NEVER_SHOW_HEADER_ROUTES.includes(pathname)) {
    return <></>
  }

  return isAuthenticated && !GUEST_HEADER_ROUTES.includes(pathname) ? (
    <UserHeader />
  ) : (
    <GuestHeader />
  )
}
