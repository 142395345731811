import { FC, useEffect } from 'react'
import { Navigate } from 'react-router'
import { RouteDescriptor } from '../config/routes/types/routeDescriptor'
import { useAppSelector } from '../store'

type AccessRouteProps = {
  route: RouteDescriptor<any>
  props?: any
}

export const AccessRoute: FC<AccessRouteProps> = ({ route, props }) => {
  const Page = route.render
  const { isAuthenticated } = useAppSelector((state) => state.user)

  useEffect(() => {
    const hostname = window.location.hostname
    const domainListRu = process.env?.REACT_APP_DOMAIN_LIST_RU
    const isRuDomain =
      domainListRu && domainListRu.split(',').includes(hostname)

    document.title =
      isRuDomain || hostname.endsWith('.ru') || hostname.includes('-ru')
        ? 'Telecorn'
        : 'Teleleo'
  }, [])

  switch (route.accessLevel) {
    case 'guest':
      return isAuthenticated ? (
        <Navigate to={route.noAccessRedirectPath} replace={true} />
      ) : (
        <Page {...props} />
      )
    case 'user':
      return isAuthenticated ? (
        <Page {...props} />
      ) : (
        <Navigate to={route.noAccessRedirectPath} replace={true} />
      )
    case 'all':
      return <Page {...props} />
    default:
      return <Navigate to={'/'} replace={true} />
  }
}
