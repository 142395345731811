import React, {FC, useMemo} from 'react'
import { useDialog } from '../../../../../../app/hooks/useDialog'
import { useModal } from '../../../../../../app/hooks/useModal'
import { ReactComponent as CaretDownIcon } from '../../../../../../assets/icons/v2/ic-caret-down.svg'
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { RedirectionListResponse } from '../../../../../../sdk/datagates/types/redirection/_crud/list'
import { useDevices } from '../../../../../../sdk/hooks/use-devices/useDevices'
import { REDIRECTION_TYPES } from '../../../../../../sdk/hooks/use-redirection-types/constants/RedirectionTypes'
import { useRedirections } from '../../../../../../sdk/hooks/use-redirections/useRedirections'
import { tableFiltersToUrlFilters } from '../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS,
} from '../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader'
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
  IconContainer,
} from '../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols'
import { useTableFilters } from '../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters'
import { Table } from '../../../../../../shared/ui-kit-2/data-display/table/Table'
import ControlHeader from '../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader'
import { DialogVersion } from '../../../../../../store/reducers/dialog/types'
import { getTableRowGroups } from './helpers/getTableRowGroups'
import styles from './styles.module.scss'
import {
  NOT_FOUND_TYPES,
  NotFoundDevicesBanner
} from "../../../../../../shared/global-components/Banners/banners/NotFoundDevicesBanner";
import {SmsListPageProps} from "../../SmsPage";

export const SmsAutoreplyPage: FC<SmsListPageProps> = ({
  activeDevices,
  activeDevicesLoading,
  userHasDevices
}) => {
  const { handleOpenModal } = useModal()
  const { handleOpenDialog, handleHideDialog } = useDialog()

  const COLS = {
    name: 'name',
    modems: 'modems',
    reply: 'reply',
  }

  const headerCols = [
    {
      id: COLS.name,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Name',
      defaultText: 'Enter the name',
    },
    {
      id: COLS.modems,
      filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
      label: 'Modems',
      defaultText: 'Select modems',
      defaultTextIcon: (
        <IconContainer>
          <CaretDownIcon />
        </IconContainer>
      ),
      checkboxes: activeDevices.map((device) => ({
        text: device.name,
        value: device.dongleId,
      })),
    },
    {
      id: COLS.reply,
      // filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Reply',
      // defaultText: 'Enter text message',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[]

  const { filters, handleChangeFilters, handlePageChange, debouncedFilters } =
    useTableFilters({
      queryIds: [...headerCols.map(({ id }) => id), 'page', 'limit'],
      defaultFilterValues: [
        {
          name: 'page',
          value: 1,
        },
        {
          name: 'limit',
          value: 50,
        },
      ],
    })

  const currentFilters = useMemo(
    () =>
      tableFiltersToUrlFilters([
        { name: 'page', value: debouncedFilters?.page - 1 },
        { name: 'limit', value: debouncedFilters?.limit },
        { name: 'name', value: debouncedFilters?.name },
        { name: 'dongle_ids[]', value: debouncedFilters?.modems },
      ]),
    [debouncedFilters],
  )

  const {
    redirections,
    totalCount: redirectionsTotalCount,
    handleFetch: handleFetchRedirections,
    handleDeleteRedirection,
    loading: redirectionsLoading,
  } = useRedirections({
    filters: currentFilters,
    redirectionTypes: [REDIRECTION_TYPES.AUTO_REPLY],
  })

  const handleActionEditRedirection = (
    redirection: RedirectionListResponse['redirections'][0],
  ) => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirection,
        redirectionTypeId: redirection.redirectionTypeId,
        handleFetch: handleFetchRedirections,
        handleDeleteRedirection: handleDeleteRedirection,
      },
    })
  }

  const handleActionDeleteRedirection = (redirectionId: string) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete Autoreply?',
        subtitle: 'This action cannot be undone',
        subtitleIsAlert: true,
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog(),
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: async () => {
            await handleDeleteRedirection(redirectionId)
            handleHideDialog()
          },
        },
      },
    })
  }

  const rowGroups = useMemo(
    () =>
      getTableRowGroups(
        redirections,
        activeDevices,
        handleActionEditRedirection,
        handleActionDeleteRedirection,
      ),
    [redirections, activeDevices],
  )

  const handleOpenAutoReplySettingsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirectionTypeId: REDIRECTION_TYPES.AUTO_REPLY,
        handleFetch: handleFetchRedirections,
      },
    })
  }

  const globalLoading = useMemo(
    () => activeDevicesLoading || redirectionsLoading,
    [activeDevicesLoading, redirectionsLoading],
  )

  return (
    <Card additionalClassNames={[styles.Card]}>
      <ControlHeader
        title={'Autoreply'}
        counter={{
          min: redirections.length,
          max: redirectionsTotalCount,
        }}
        actions={userHasDevices ? [
          {
            variant: 'greenFilled',
            size: 'md',
            text: 'Create',
            prefix: <PlusIcon />,
            onClick: handleOpenAutoReplySettingsModal,
          },
        ] : []}
        loading={globalLoading}
      />

      <Table
        name={'Autoreply'}
        headerCols={headerCols}
        filters={filters}
        rowGroups={rowGroups}
        currentPage={filters.page}
        totalCount={redirectionsTotalCount}
        itemsPerPage={filters.limit}
        onPageChange={handlePageChange}
        handleChangeFilters={handleChangeFilters}
        loading={globalLoading}
        columnWidths={['250px', 4, 4, '100px']}

        banner={!userHasDevices && <NotFoundDevicesBanner type={NOT_FOUND_TYPES.SMSES} />}
      />
    </Card>
  )
}
