import { useEffect, useMemo, useState } from 'react'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { FilterType } from '../../../layouts/filters/hook/useFilters'
import { Source } from '../../../layouts/modals/_hooks/use-add-source/useAddSource'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import {
  callScenarioAddDatagate,
  callScenarioDeleteDatagate,
  callScenarioListDatagate,
  callScenarioUpdateDatagate,
} from '../../datagates/api/call'
import { QueryParameter } from '../../datagates/helpers/_common/wrap-api-request'

interface UseAutoResponseRulesProps {
  filters?: QueryParameter[]
}

export const useAutoResponseRules = (props: UseAutoResponseRulesProps) => {
  const { filters = [] } = props

  const stableFilters = useMemo(() => filters, [JSON.stringify(filters)])

  const [rules, setRules] = useState<any>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [initialFetchCompleted, setInitialFetchCompleted] =
    useState<boolean>(false)

  const { handleOpenSnackbar } = useSnackbar()

  const handleFetch = async ({
    params = [],
  }: { params?: QueryParameter[] } = {}) => {
    try {
      const queryParams: QueryParameter[] = [
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
        ...stableFilters,
        ...params,
      ]

      const { data } = await callScenarioListDatagate(null, null, queryParams)

      setRules(data.data.call_scenarios)
      setTotalCount(data.data.total_count)
      setLoading(false)
    } catch {}
  }

  const handleUpdateRules = async () => {
    setLoading(true)
    setRules([])
    await handleFetch()
  }

  const handleAddRule = async ({
    dongleId,
    name,
    tmpFileId,
    contactIds,
    phones,
    contactGroupIds,
  }: {
    dongleId: string
    name: string
    tmpFileId: string
    audioFileName: string
    contactIds: string[]
    phones: string[]
    contactGroupIds: string[]
  }) => {
    try {
      await callScenarioAddDatagate({
        call_scenario_type_id: 1,
        is_call_record: true,
        dongle_id: dongleId,
        name,
        tmp_file_id: tmpFileId,
        call_scenario_contact_ids: contactIds,
        call_scenario_phones: phones,
        call_scenario_contact_group_ids: contactGroupIds,
      })

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: 'Rule is successfully created',
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: 'An error occurred while creating',
      })
    }
  }

  const handleUpdateRule = async ({
    callScenarioId,
    name,
    tmpFileId,
    contactIds,
    phones,
    contactGroupIds,
  }: {
    callScenarioId: string
    tmpFileId: string
    contactIds: string[]
    phones: string[]
    contactGroupIds: string[]
    name?: string
  }) => {
    try {
      await callScenarioUpdateDatagate(
        {
          name,
          tmp_file_id: tmpFileId,
          call_scenario_contact_ids: contactIds,
          call_scenario_phones: phones,
          call_scenario_contact_group_ids: contactGroupIds,
        },
        [{ name: 'call_scenario_id', value: String(callScenarioId) }],
      )

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: 'Rule is successfully updated',
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: 'An error occurred while updating',
      })
    }
  }

  const handleDeleteRule = async ({
    callScenarioId,
  }: {
    callScenarioId: number
  }) => {
    try {
      await callScenarioDeleteDatagate(null, [
        { name: 'call_scenario_id', value: String(callScenarioId) },
      ])

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: 'Rule is successfully deleted',
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: 'An error occurred while deleting',
      })
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [stableFilters])

  return {
    rules,
    totalCount,
    handleFetchRules: handleFetch,
    handleUpdateRules,
    handleAddRule,
    handleUpdateRule,
    handleDeleteRule,
    loading,
    initialFetchCompleted,
    setInitialFetchCompleted,
  }
}
