import { useEffect, useState } from 'react'
import { userGetDatagate } from '../../../../../sdk/datagates/api/user'
import { PurchaseStepsFormType } from '../components/purchase-steps/PurchaseSteps'

export const useUserProfile = () => {
  const [userProfileData, setUserProfileData] = useState<
    Partial<PurchaseStepsFormType> | undefined
  >(undefined)
  const [userProfileLoading, setUserProfileLoading] = useState<boolean>(true)
  const [userProfileError, setUserProfileError] = useState<unknown>(false)

  useEffect(() => {
    fetchUserProfile()
  }, [])

  const fetchUserProfile = async () => {
    try {
      setUserProfileLoading(true)
      const { data } = await userGetDatagate()

      const phone = data.companyPhone
        ? { companyPhone: data.companyPhone }
        : { userPhone: data.userPhone }

      setUserProfileData({
        ...data,
        accountType: data.userTypeId?.toString(),
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        vatNumber: data.vatNumber,
        billingEmail: data.billingEmail,
        region: data.region,
        postalCode: data.postalCode,
        streetAddress1: data.streetAddress1,
        streetAddress2: data.streetAddress2,
        countryId: data.countryId?.toString(),
        city: data.city,
        ...phone,
      })
    } catch (e) {
      setUserProfileError(e)
    } finally {
      setUserProfileLoading(false)
    }
  }

  return {
    userProfileData,
    userProfileLoading,
    userProfileError,
  }
}
