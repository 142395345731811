import { ConnectToDonglePage } from '../../../../../../../pages/lk/subpages/connect-to-dongle/ConnectToDonglePage'
import { RoutePath } from '../../../../../constants/routePath'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const connectToDongle: RouteDescriptor<Route.ConnectToDongle> = {
  route: Route.ConnectToDongle,
  type: 'subpage',
  title: 'Teleleo',
  path: RoutePath[Route.ConnectToDongle],
  render: ConnectToDonglePage,
  accessLevel: 'all',
  noAccessRedirectPath: '/',
}
