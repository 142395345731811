import { CallsRoute } from './enums/callsRoute'
import { callsAutoDialer } from './route-descriptors/user/lk/subpages/calls/subpages/calls-auto-dialer/calls-auto-dialer'
import { callsAutoResponseRules } from './route-descriptors/user/lk/subpages/calls/subpages/calls-auto-response-rules/calls-auto-response-rules'
import { callsAutoResponse } from './route-descriptors/user/lk/subpages/calls/subpages/calls-auto-response/calls-auto-response'
import { callsList } from './route-descriptors/user/lk/subpages/calls/subpages/calls-list/calls-list'
import { callsRedirects } from './route-descriptors/user/lk/subpages/calls/subpages/calls-redirects/calls-redirects'
import { callsSettings } from './route-descriptors/user/lk/subpages/calls/subpages/calls-settings/calls-settings'
import { RouteDescriptor } from './types/routeDescriptor'

export const callsRoutes: { [key in CallsRoute]: RouteDescriptor<key> } = {
  [CallsRoute.CallsList]: callsList,
  [CallsRoute.CallsRedirects]: callsRedirects,
  [CallsRoute.CallsSettings]: callsSettings,
  [CallsRoute.CallsAutoResponse]: callsAutoResponse,
  [CallsRoute.CallsAutoResponseRules]: callsAutoResponseRules,
  [CallsRoute.CallsAutoDialer]: callsAutoDialer,
}
