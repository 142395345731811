import React, { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { Form } from 'react-final-form'
import { MailingCreatedContent } from '../_components/mailing-created-content/MailingCreatedContent'
import { PhoneMultipleSettings } from '../_components/phone-multiple-settings/PhoneMultipleSettings'
import { Source } from '../_hooks/use-add-source/useAddSource'
import { useDialog } from '../../../app/hooks/useDialog'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { blobCreateDatagate } from '../../../sdk/datagates/api/blob'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { formatPhoneNumber } from '../../../sdk/formatters/format-phone-number'
import { useDevices } from '../../../sdk/hooks/use-devices/useDevices'
import { MAILING_STATUSES } from '../../../sdk/hooks/use-mailing-statuses/constants/MailingStatuses'
import { useMailing } from '../../../sdk/hooks/use-mailing/useMailing'
import { MAILING_TYPE_IDS } from '../../../sdk/hooks/use-mailings/useMailings'
import { WEB_SOCKETS_EVENTS_VALUES } from '../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { RadioField } from '../../../shared/lib/form/form-field-adapters/v2/radio-field/RadioField'
import { SelectSearchField } from '../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { useWebSockets } from '../../../shared/lib/hooks/useWebSockets'
import { blobToBase64 } from '../../../shared/lib/utils/blob-to-base-64/blobToBase64'
import { formatToDateTimeWithoutTZ } from '../../../shared/lib/utils/format-to-date-without-tz/formatToDateTimeWithoutTZ'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { IconButton } from '../../../shared/ui-kit-2/inputs/icon-button/IconButton'
import { RADIO_VARIANT_LIST } from '../../../shared/ui-kit-2/inputs/radio/components/Radio'
import { SelectSearchOption } from '../../../shared/ui-kit-2/inputs/select-search/SelectSearch'
import { AudioUpload } from '../../../shared/ui-kit-3/components/AudioUpload'
import { DatepickerOptional } from '../../../shared/ui-kit-3/components/DatePickerOptional/DatePickerOptional'
import { DeviceSelector } from '../../../shared/ui-kit-3/components/DeviceSelector'
import { DialogVersion } from '../../../store/reducers/dialog/types'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

export const CallsMailingModal = () => {
  const {
    props: { mailingId, handleFetchMailings },
    handleHideModal,
  } = useModal()
  const { ruleRequired } = useFormRules()
  const { handleOpenSnackbar } = useSnackbar()

  const { devices } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    brandId: 2,
    callTypeId: 2,
  })

  const {
    mailing,
    loading: mailingLoading,
    initialLoading: mailingInitialLoading,
    handleCancelMailing,
    handleCreateMailing,
    handleUpdateMailing,
    handleDeleteMailing,
    handleFetchMailing,
  } = useMailing({ mailingId })
  const { handleOpenDialog, handleHideDialog } = useDialog()
  const { resolveBackendError } = useBackendErrorCodes()

  const dongleOptions: SelectSearchOption[] = useMemo(
    () =>
      devices.map((d) => {
        return {
          key: d.dongleId,
          label: d.name,
          inputLabel: d.name,
          value: d.dongleId.toString(),
        }
      }),
    [devices],
  )

  const [mailingDate, setMailingDate] = useState<Date | undefined>()
  const [sources, setSources] = useState<Source[]>([])
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [errors, setErrors] = useState<Record<string, string | boolean> | null>(
    null,
  )
  const [editMode, setEditMode] = useState<boolean>(false) // for "edit" button on planned status

  const { webSocketsEventData } = useWebSockets({
    events: [WEB_SOCKETS_EVENTS_VALUES.mailing_status_changed],
  })

  useEffect(() => {
    setSources(
      mailing?.mailingPhones
        ? mailing?.mailingPhones?.map(({ phone }: { phone: string }) => {
            const formattedPhone = formatPhoneNumber(phone)

            return {
              sourceName: formattedPhone,
              sourceType: 'phone',
              sourceValue: formattedPhone,
            }
          })
        : [],
    )

    if (mailing?.scheduledAt) {
      setMailingDate(mailing.scheduledAt)
    }
  }, [mailing])

  useEffect(() => {
    setErrors({})
  }, [sources])

  const handleSubmit = async (data: any) => {
    try {
      if (sources.length === 0) {
        setErrors({
          sources: true,
        })

        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: 'At least one phone number, contact or contact group must be selected',
        })
        return
      }

      if (!uploadedFile) {
        setErrors({
          uploadedFile: true,
        })

        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: 'Audio file must be selected',
        })
        return
      }

      const contactGroups = sources
        .filter((s) => s.sourceType === 'contact-groups')
        .map((cg) => +cg.sourceValue)
      const contacts = sources
        .filter((s) => s.sourceType === 'contacts')
        .map((c) => +c.sourceValue)
      const phones = sources
        .filter((s) => s.sourceType === 'phone')
        .map((p) => p.sourceValue)

      const audioBase64 =
        window.location.hostname === 'localhost'
          ? 'UklGRigAAABXQVZFZm10IBAAAAABAAEARKwAABCxAgAEABAAZGF0YQAAAAA='
          : await blobToBase64(
              new Blob([uploadedFile], { type: uploadedFile.type }),
            )

      const getMailingStatusId = () => {
        switch (data.creationType) {
          case 'create-draft':
            return MAILING_STATUSES.DRAFT
          case 'create-planned':
            return MAILING_STATUSES.PLANNED
          default: {
            return MAILING_STATUSES.PLANNED
          }
        }
      }

      const tmpFile = await blobCreateDatagate({
        file_base64: audioBase64,
        file_name: uploadedFile?.name,
        scenario: 'SCENARIO_CONVERT_TO_DONGLE_AUDIO_AND_UPLOAD_TMP',
      })

      if (!tmpFile) {
        setErrors({
          uploadedFile: true,
        })

        return
      }

      const params = {
        mailing_status_id: getMailingStatusId(),
        mailing_entity_type_id: 2,
        mailing_type_id: mailingDate
          ? MAILING_TYPE_IDS.DELAYED
          : MAILING_TYPE_IDS.SIMPLE,
        name: data?.name ?? mailing?.name,
        dongle_id: data?.dongleId ?? mailing?.dongle?.dongleId,
        is_call_record:
          Number(data?.isCallRecord) ?? Number(mailing?.isCallRecord),
        mailing_phones: phones,
        mailing_contact_ids: contacts,
        mailing_contact_group_ids: contactGroups,
        tmp_file_id: tmpFile?.data?.tmpFileId,
      }

      if (mailingDate) {
        // @ts-ignore
        params.scheduled_at = formatToDateTimeWithoutTZ({ date: mailingDate })
      }

      if (mailing) {
        await handleUpdateMailing(mailing.mailingId, {
          mailing_id: mailing.mailingId,
          ...params,
        })

        setEditMode(false)
      } else {
        const data = await handleCreateMailing(params)

        if (data?.mailingId) {
          await handleFetchMailing({ mailingId: data.mailingId })
        }
      }

      await handleFetchMailings({ hidden: true })
    } catch (e) {
      console.log('e', e)
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    }
  }

  const onDeleteMailing = async ({ mailingId }: { mailingId: string }) => {
    handleHideModal()
    await handleDeleteMailing(mailingId)
    await handleFetchMailings()
  }

  const onCancelMailing = async ({ mailingId }: { mailingId: string }) => {
    const data = await handleCancelMailing(mailingId)

    if (data?.mailingId) {
      await handleFetchMailing({ mailingId: String(data.mailingId) })
    }

    await handleFetchMailings()
  }

  const hideForm =
    !editMode &&
    mailing &&
    [
      MAILING_STATUSES.PLANNED,
      MAILING_STATUSES.IN_PROCESS,
      MAILING_STATUSES.ENDED,
      MAILING_STATUSES.CANCELLED,
    ].includes(mailing?.mailingStatusId)

  const onHideModal = () => {
    if (hideForm) {
      handleHideModal()
    } else {
      handleOpenDialog({
        version: DialogVersion.v2,
        props: {
          title: 'Are you sure about close?',
          subtitle: mailing
            ? 'Your changes will be discarded'
            : 'The form will be cleared',
          subtitleIsAlert: true,
          cancel: {
            variant: 'green',
            text: 'Cancel',
            onClick: () => {
              handleHideDialog()
            },
          },
          submit: {
            variant: 'blackTextBlackBorderOutlined',
            text: 'Yes',
            onClick: () => {
              handleHideModal()
              handleHideDialog()

              // For unblocking scroll
              setTimeout(() => {
                document.body.style.overflow = ''
              }, 10)
            },
          },
        },
      })
    }
  }

  useEffect(() => {
    if (
      hideForm &&
      String(webSocketsEventData?.entityId) === String(mailingId)
    ) {
      handleFetchMailing({ mailingId })
    }
  }, [webSocketsEventData])

  return (
    <Drawer
      isOpen={true}
      close={onHideModal}
      title={hideForm ? 'Auto Dialer' : 'Create rule'}
      limitedWidth={false}
      customWidth={'514px'}
      loading={mailingInitialLoading}
    >
      {hideForm ? (
        <MailingCreatedContent
          mailing={mailing}
          handleHideModal={onHideModal}
          onCancelMailing={() => onCancelMailing({ mailingId })}
          onDeleteMailing={() => onDeleteMailing({ mailingId })}
          onEditMailing={setEditMode}
          forceAction={null}
          isCallsMailing
        />
      ) : (
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            isCallRecord: '0',
            dongleId: devices.length === 1 ? devices[0].dongleId : undefined,
            ...(mailing
              ? {
                  name: mailing.name,
                  dongleId: mailing.dongle.dongleId,
                  isCallRecord: `${Number(mailing?.isCallRecord)}`,
                }
              : {}),
          }}
          render={({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit} className={styles.Form}>
              <InputField
                name={'name'}
                validate={ruleRequired()}
                markAsRequired={true}
                label={'Name'}
                placeholder={'The first rule of autodialing'}
              />
              <DeviceSelector
                devices={devices}
                name="dongleId"
                validate={!mailing ? ruleRequired() : undefined}
                options={dongleOptions}
                label={'Modem'}
                placeholder={'Modem'}
                markAsRequired={true}
                dropdownElementsInView={12}
                defaultInputValue={
                  mailing?.dongle?.name ??
                  (dongleOptions.length === 1
                    ? dongleOptions[0].label
                    : undefined)
                }
              />
              <PhoneMultipleSettings
                title="Numbers"
                sources={sources}
                setSources={setSources}
                error={errors?.sources as boolean}
                allPhonesFeatureOn={false}
              />
              <AudioUpload
                cdnUrl={mailing?.file?.cdnUrl}
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                handleClear={() => {
                  setUploadedFile(null)
                }}
                errors={errors}
                setErrors={setErrors}
              />
              <DatepickerOptional
                value={mailingDate}
                onChange={(date) => {
                  setMailingDate(date)
                }}
                title={'Delayed call'}
                minDateToday={true}
              />
              <div className={styles.WriteTheAnswer}>
                <div className={styles.WriteTheAnswer__Title}>
                  Write the answer?
                </div>
                <div className={styles.WriteTheAnswer__Divider} />
                <div className={styles.WriteTheAnswer__Radios}>
                  <RadioField
                    name={'isCallRecord'}
                    label={'No'}
                    value={'0'}
                    comparedValue={String(form.getFieldState('isCallRecord'))}
                    additionalClassNames={[styles.RadioButtonBankCardNew]}
                    variant={RADIO_VARIANT_LIST.outlined}
                  />
                  <RadioField
                    name={'isCallRecord'}
                    label={'Yes'}
                    value={'1'}
                    comparedValue={String(form.getFieldState('isCallRecord'))}
                    additionalClassNames={[styles.RadioButtonBankCardNew]}
                    variant={RADIO_VARIANT_LIST.outlined}
                  />
                </div>
              </div>

              <div
                className={clsx(
                  styles.Actions,
                  mailing && !editMode && styles.Actions_withDelete,
                )}
              >
                {mailing && !editMode && (
                  <IconButton
                    icon={'trash'}
                    variant={'alertOutlined'}
                    additionalClassNames={[styles.Actions__Delete]}
                    onClick={() => onDeleteMailing({ mailingId })}
                    disabled={submitting || mailingLoading}
                  />
                )}
                {editMode && (
                  <Button
                    text={'Undo changes'}
                    variant={'danger'}
                    icon={'trash'}
                    disabled={submitting || mailingLoading}
                    loading={mailingLoading}
                    onClick={() => setEditMode(false)}
                  />
                )}
                <Button
                  text={mailing || editMode ? 'Save changes' : 'Save in drafts'}
                  variant={'blackTextBlackBorderOutlined'}
                  onClick={() => {
                    form.change('creationType', 'to-drafts')
                    handleSubmit()
                  }}
                  disabled={submitting || mailingLoading}
                  loading={mailingLoading}
                />
                {!editMode && (
                  <Button
                    text={mailing ? 'Call now' : 'Create'}
                    variant={'green'}
                    onClick={() => {
                      form.change('creationType', 'send-now')
                      handleSubmit()
                    }}
                    disabled={submitting || mailingLoading}
                    loading={mailingLoading}
                  />
                )}
              </div>
            </form>
          )}
        />
      )}
    </Drawer>
  )
}
