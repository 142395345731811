import { getIn } from 'final-form'
import parsePhoneNumber, {
  AsYouType,
  parsePhoneNumberFromString,
  validatePhoneNumberLength,
} from 'libphonenumber-js'
import { defineMessages, IntlShape, useIntl } from 'react-intl'
import { COUNTRY_PHONES } from '../../constants/COUNTRY_PHONES'
import { phonePostProcessing } from '../../utils/libphonenumber/phone-post-processing'

const FormRulesMessages = defineMessages({
  ruleRequired: {
    id: 'FormRulesMessages.required',
    defaultMessage: 'Required',
  },
  ruleMustBeNumber: {
    id: 'FormRulesMessages.mustBeNumber',
    defaultMessage: 'Must be a number',
  },
  ruleNotIncludeNumbers: {
    id: 'FormRulesMessages.notIncludeNumbers',
    defaultMessage: 'Must not contain numbers',
  },
  ruleMinValue: {
    id: 'FormRulesMessages.ruleMinValue',
    defaultMessage: 'Must be greater than or equal to',
  },
  ruleMaxValue: {
    id: 'FormRulesMessages.ruleMaxValue',
    defaultMessage: 'Must be less than or equal to',
  },
  ruleEmail: {
    id: 'FormRulesMessages.incorrectEmail',
    defaultMessage: 'Incorrect email',
  },
  rulePassword: {
    id: 'FormRulesMessages.rulePassword',
    defaultMessage:
      'Must contain at least 1 number, 1 lowercase letter, 1 uppercase letter, and at least 10 characters',
  },
  ruleHotspotName: {
    id: 'FormRulesMessages.ruleHotspotName',
    defaultMessage: 'Must contain from 1 to 32 ASCII x20-x7E characters',
  },
  ruleHotspotPassword: {
    id: 'FormRulesMessages.ruleHotspotPassword',
    defaultMessage: 'Must contain from 8 to 63 ASCII x20-x7E characters',
  },
  rulePasswordsMismatch: {
    id: 'FormRulesMessages.rulePasswordsMismatch',
    defaultMessage: 'Passwords mismatch',
  },
  rulePhone: {
    id: 'FormRulesMessages.rulePhone',
    defaultMessage: 'Incorrect phone',
  },
  rulePhoneIncorrectCode: {
    id: 'FormRulesMessages.rulePhoneIncorrectCode',
    defaultMessage: 'Incorrect country code',
  },
  rulePhoneTooShort: {
    id: 'FormRulesMessages.rulePhoneTooShort',
    defaultMessage: 'Too short',
  },
  rulePhoneTooLong: {
    id: 'FormRulesMessages.rulePhoneTooLong',
    defaultMessage: 'Too long',
  },
})

const ruleRequired = (intl: IntlShape) => () => (value: string) => {
  return value ? undefined : intl.formatMessage(FormRulesMessages.ruleRequired)
}

const ruleMustBeNumber =
  (intl: IntlShape) =>
  (required: boolean = false) =>
  (value: any) => {
    if (required && !value) {
      return intl.formatMessage(FormRulesMessages.ruleRequired)
    }

    return !value
      ? undefined
      : isNaN(value)
        ? intl.formatMessage(FormRulesMessages.ruleMustBeNumber)
        : undefined
  }

const ruleNotIncludeNumbers =
  (intl: IntlShape) =>
  (required: boolean = false) =>
  (value: any) => {
    if (required && !value) {
      return intl.formatMessage(FormRulesMessages.ruleRequired)
    }

    return !value
      ? undefined
      : /[0-9]/.test(value)
        ? intl.formatMessage(FormRulesMessages.ruleNotIncludeNumbers)
        : undefined
  }

const ruleMinValue = (intl: IntlShape) => (min: number) => (value: any) => {
  if (!value) return undefined
  return isNaN(Number(value)) || Number(value) >= min
    ? undefined
    : `${intl.formatMessage(FormRulesMessages.ruleMinValue)} ${min}`
}

const ruleMaxValue = (intl: IntlShape) => (max: number) => (value: any) => {
  if (!value) return undefined
  return isNaN(Number(value)) || Number(value) <= max
    ? undefined
    : `${intl.formatMessage(FormRulesMessages.ruleMaxValue)} ${max}`
}

const ruleEmail = (intl: IntlShape) => () => (value: string) => {
  if (!value) return undefined

  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    ? undefined
    : intl.formatMessage(FormRulesMessages.ruleEmail)
}

const rulePassword = (intl: IntlShape) => () => (value: string) => {
  if (!value) return undefined

  return String(value).match(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{10,}$/,
  )
    ? undefined
    : intl.formatMessage(FormRulesMessages.rulePassword)
}

const ruleHotspotName = (intl: IntlShape) => () => (value: string) => {
  if (!value) return undefined

  return String(value).match(/^[\x20-\x7E]{1,32}$/)
    ? undefined
    : intl.formatMessage(FormRulesMessages.ruleHotspotName)
}

const ruleHotspotPassword = (intl: IntlShape) => () => (value: string) => {
  if (!value) return undefined

  return String(value).match(/^[\x20-\x7E]{8,63}$/)
    ? undefined
    : intl.formatMessage(FormRulesMessages.ruleHotspotPassword)
}

const rulePasswordsMismatch =
  (intl: IntlShape) => (key: string, values: any) => (value: string) => {
    if (!value) return undefined

    if (value !== getIn(values, key)) {
      return intl.formatMessage(FormRulesMessages.rulePasswordsMismatch)
    }
  }

const rulePhoneOld = (intl: IntlShape) => () => (value: string) => {
  if (!value) return undefined

  const str = String(value).toLowerCase().replaceAll('+', '')

  let matchFlag: boolean

  if (str.length <= 5) matchFlag = !!value.match(/^(?!00)\d{2,5}$/)
  else if (str.length > 5 && str.length < 8)
    matchFlag = !!value.match(/^\+?[0-9][1-9]\d{4,5}$/)
  else matchFlag = !!value.match(/^\+?[0-9][0-9]\d{6,14}$/)

  return matchFlag ? undefined : intl.formatMessage(FormRulesMessages.rulePhone)
}

const rulePhone =
  (intl: IntlShape) =>
  (countryCodeKey: string, values: any) =>
  (value: string) => {
    if (!value) return undefined

    let localNumber = String(value)

    const countryCode: string | null = getIn(values, countryCodeKey)

    if (!countryCode || !COUNTRY_PHONES.hasOwnProperty(countryCode))
      return intl.formatMessage(FormRulesMessages.rulePhone)

    const countryCallingCode = COUNTRY_PHONES[countryCode].code
    const fullPhoneNumber = `${countryCallingCode}${value}`
    const parsedPhone = parsePhoneNumber(fullPhoneNumber)

    if (!localNumber.match(/^\d+$/) || !parsedPhone?.isValid())
      return intl.formatMessage(FormRulesMessages.rulePhone)

    const fullPhone = countryCallingCode + localNumber

    const phoneNumber = parsePhoneNumber(fullPhone)

    if (!phoneNumber) return intl.formatMessage(FormRulesMessages.rulePhone)

    const phoneLengthError = validatePhoneNumberLength(
      phonePostProcessing(fullPhone),
    )

    switch (phoneLengthError) {
      case 'TOO_SHORT':
        return intl.formatMessage(FormRulesMessages.rulePhoneTooShort)
      case 'TOO_LONG':
        return intl.formatMessage(FormRulesMessages.rulePhoneTooLong)
    }

    return phoneNumber.isPossible()
      ? undefined
      : intl.formatMessage(FormRulesMessages.rulePhone)
  }

const ruleShortPhone = (intl: IntlShape) => () => (value: any) => {
  if (!value) return undefined

  if (isNaN(value)) return intl.formatMessage(FormRulesMessages.rulePhone)

  if (value.length > 5)
    return intl.formatMessage(FormRulesMessages.rulePhoneTooLong)

  return undefined
}

const ruleAlphaNumber = (intl: IntlShape) => () => (value: any) => {
  if (!value) return undefined

  if (!value.match(/^[a-zA-Zа-яА-Я0-9]+$/))
    return intl.formatMessage(FormRulesMessages.rulePhone)

  return undefined
}

const rulePhoneNew = (intl: IntlShape) => () => (value: any) => {
  const intlError = intl.formatMessage(FormRulesMessages.rulePhone)

  if (value) {
    const asYouType = new AsYouType()
    const formattedNumber = asYouType.input(value)
    const parsedPhoneNumber = parsePhoneNumberFromString(formattedNumber)

    if (parsedPhoneNumber) {
      return parsedPhoneNumber.isValid() ? undefined : intlError
    }

    return intlError
  }

  return undefined
}

const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    )

export const useFormRules = () => {
  const intl = useIntl()

  return {
    ruleRequired: ruleRequired(intl),
    ruleMustBeNumber: ruleMustBeNumber(intl),
    ruleNotIncludeNumbers: ruleNotIncludeNumbers(intl),
    ruleMinValue: ruleMinValue(intl),
    ruleMaxValue: ruleMaxValue(intl),
    ruleEmail: ruleEmail(intl),
    rulePassword: rulePassword(intl),
    ruleHotspotName: ruleHotspotName(intl),
    ruleHotspotPassword: ruleHotspotPassword(intl),
    rulePasswordsMismatch: rulePasswordsMismatch(intl),
    rulePhoneOld: rulePhoneOld(intl),
    rulePhone: rulePhone(intl),
    ruleShortPhone: ruleShortPhone(intl),
    ruleAlphaNumber: ruleAlphaNumber(intl),
    rulePhoneNew: rulePhoneNew(intl),
  }
}

export { composeValidators }
