import { parseStringAsUTCDate } from '../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  OrderListBackendResponse,
  OrderListResponse,
} from '../../../../../types/order/_crud/list'

export const mapOrderListBackendToOrderList = (
  item: OrderListBackendResponse,
): OrderListResponse => {
  const { orders, total_count } = item.data

  return {
    orders: orders.map((el) => {
      let totalPrice: number | null = el.amount / 100

      if (el.delivery_price !== null) totalPrice += el.delivery_price / 100
      else totalPrice = null

      return {
        orderId: el.order_id,
        userId: el.user_id,
        orderStatusId: el.order_status_id,
        orderPaymentTypeId: el.order_payment_type_id,
        firstName: el.first_name,
        lastName: el.last_name,
        countryId: el.country_id,
        phone: el.phone,
        region: el.region,
        city: el.city,
        streetAddress1: el.street_address1,
        streetAddress2: el.street_address2,
        postalCode: el.postal_code,
        createdAt: parseStringAsUTCDate(el.created_at),
        updatedAt: parseStringAsUTCDate(el.updated_at),
        deliveryPrice:
          el.delivery_price !== null ? el.delivery_price / 100 : null,
        deliveryPriceOld:
          el.delivery_price_old !== null ? el.delivery_price_old / 100 : null,
        amount: el.amount / 100,
        totalPrice: totalPrice,
        orderDongles: el.order_dongles.map((od) => ({
          dongleId: od.dongle_id,
          orderDongleId: od.order_dongle_id,
          orderId: od.order_id,
          tariffPackageId: od.tariff_package_id,
        })),
      }
    }),
    totalCount: total_count,
  }
}
