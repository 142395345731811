import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { Source } from '../_hooks/use-add-source/useAddSource'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import {
  contactCreateDatagate,
  contactGetDatagate,
  contactUpdateDatagate,
} from '../../../sdk/datagates/api/contact'
import { contactGroupListDatagate } from '../../../sdk/datagates/api/contact-group'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { ContactGetResponse } from '../../../sdk/datagates/types/contact/_crud/get'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { PhoneInputFieldNumber } from '../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew'
import { clearPhoneMutator } from '../../../shared/lib/form/form-helpers/mutators'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { getClearedPhoneNumber } from '../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import { MODAL_TYPES } from '../ModalsProvider'
import { AddToGroup } from './components/AddToGroup/AddToGroup'
import styles from './styles.module.scss'

interface ContactSettingsFormType {
  name: string
  phone: string
}

const ContactSettingsModalMessages = defineMessages({
  contactNotFound: {
    id: 'ContactSettingsModalMessages.contactNotFound',
    defaultMessage: 'Contact not found',
  },
  title: {
    id: 'ContactSettingsModalMessages.title',
    defaultMessage: 'Add contact',
  },
  titleEdit: {
    id: 'ContactSettingsModalMessages.titleEdit',
    defaultMessage: 'Edit contact',
  },
  contactNameLabel: {
    id: 'ContactSettingsModalMessages.contactNameLabel',
    defaultMessage: 'Contact name',
  },
  contactNamePlaceholder: {
    id: 'ContactSettingsModalMessages.contactNamePlaceholder',
    defaultMessage: 'Enter contact name',
  },
  phoneLabel: {
    id: 'ContactSettingsModalMessages.phoneLabel',
    defaultMessage: 'Phone Number',
  },
  negativeCreated: {
    id: 'ContactSettingsModalMessages.negativeCreated',
    defaultMessage: 'An error occurred while creating contact',
  },
  negativeUpdated: {
    id: 'ContactSettingsModalMessages.negativeUpdated',
    defaultMessage: 'An error occurred while updating contact',
  },
  positiveCreated: {
    id: 'ContactSettingsModalMessages.positiveCreated',
    defaultMessage: 'Contact is successfully created',
  },
  positiveUpdated: {
    id: 'ContactSettingsModalMessages.positiveUpdated',
    defaultMessage: 'Contact is successfully updated',
  },
  deleteTitle: {
    id: 'ContactSettingsModalMessages.deleteTitle',
    defaultMessage: 'Are you sure you want to delete the contact?',
  },
  fetchContactError: {
    id: 'ContactSettingsModalMessages.fetchContactError',
    defaultMessage: 'An error occurred while fetching contact',
  },
})

export const ContactSettingsModal = () => {
  const { handleHideModal, handleOpenModal, props } = useModal()

  const {
    handleFetchContacts,
    handleFetchContactGroups,
    handleDeleteContact,
    selectedContacts,
    contactGroupId,
    backTo,
  } = props
  const contactId: number | undefined = props.contactId

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired } = useFormRules()
  const { resolveBackendError } = useBackendErrorCodes()
  const [initialValues, setInitialValues] = useState<
    Partial<ContactSettingsFormType>
  >({})
  const [contactGroupSources, setContactGroupSources] = useState<Source[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [contact, setContact] = useState<ContactGetResponse | undefined>(
    undefined,
  )

  const fetchInitialValues = async (contactId: number) => {
    try {
      const { data } = await contactGetDatagate(null, [
        { name: 'contact_id', value: contactId.toString() },
      ])

      const contactGroupsResponse = await contactGroupListDatagate(null, null, [
        { name: 'contact_ids', value: [contactId.toString()] },
      ])

      setInitialValues({
        phone: data.phone,
        name: data.name,
      })

      const defaultSources =
        contactGroupsResponse.data.contactGroups.map<Source>((cg) => ({
          sourceType: 'contact-groups',
          sourceName: cg.name,
          sourceValue: cg.contactGroupId.toString(),
          metaInformation: {
            // TODO FIX to real data!
            contactsCount: cg.contactsCount,
          },
        }))

      setContactGroupSources(defaultSources)

      setContact(data)
      setLoading(false)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(
          ContactSettingsModalMessages.fetchContactError,
        ),
      })
    }
  }

  useEffect(() => {
    if (contactId) {
      void fetchInitialValues(contactId)
    } else {
      setLoading(false)
    }
  }, [])

  const onSubmit = async (values: ContactSettingsFormType) => {
    try {
      const clearedPhoneNumber = getClearedPhoneNumber(values.phone) as string;

      const reqBody = {
        name: values.name,
        phone: clearedPhoneNumber.includes('+') ? clearedPhoneNumber : `+${clearedPhoneNumber}`,
        contact_group_ids: contactGroupSources.map((cg) => +cg.sourceValue),
      }

      if (contactId) {
        const urlParams = [{ name: 'contact_id', value: contactId.toString() }]
        await contactUpdateDatagate(reqBody, urlParams)
      } else {
        await contactCreateDatagate(reqBody)
      }

      await handleFetchContacts?.()
      await handleFetchContactGroups?.()

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(
          contactId
            ? ContactSettingsModalMessages.positiveUpdated
            : ContactSettingsModalMessages.positiveCreated,
        ),
      })

      if (backTo) {
        handleOpenModal({
          type: MODAL_TYPES.CONTACT_GROUP_SETTINGS_MODAL,
          props: {
            selectedContact: selectedContacts,
            contactGroupId: contactGroupId,
          },
        })
      } else {
        handleHideModal()
      }
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(
            contactId
              ? ContactSettingsModalMessages.negativeUpdated
              : ContactSettingsModalMessages.negativeCreated,
          ),
        ),
      })
    }
  }

  const onDelete = () => {
    if (contactId) {
      handleOpenModal({
        type: MODAL_TYPES.DELETE_ITEM,
        props: {
          title: intl.formatMessage(ContactSettingsModalMessages.deleteTitle),
          handleSubmit: async () => {
            await handleDeleteContact?.()
            await handleFetchContacts?.()
            await handleFetchContactGroups?.()
          },
        },
      })
    } else {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(ContactSettingsModalMessages.contactNotFound),
      })
    }
  }

  const submitText = contactId
    ? intl.formatMessage(CommonButtonMessages.save)
    : intl.formatMessage(CommonButtonMessages.create)

  const title = contactId
    ? intl.formatMessage(ContactSettingsModalMessages.titleEdit)
    : intl.formatMessage(ContactSettingsModalMessages.title)

  const content = (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        clearPhoneMutator,
      }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.Content}>
            <InputField
              name={'name'}
              validate={ruleRequired()}
              markAsRequired={true}
              label={intl.formatMessage(
                ContactSettingsModalMessages.contactNameLabel,
              )}
              placeholder={intl.formatMessage(
                ContactSettingsModalMessages.contactNamePlaceholder,
              )}
            />

            <PhoneInputFieldNumber
              name="phone"
              markAsRequired={true}
              label={intl.formatMessage(
                ContactSettingsModalMessages.phoneLabel,
              )}
              initialValue={contact?.phone}
            />

            <AddToGroup
              contactGroupSources={contactGroupSources}
              setContactGroupSources={setContactGroupSources}
            />

            <div className={styles.Actions}>
              {contactId && (
                <Button
                  text={intl.formatMessage(CommonButtonMessages.delete)}
                  variant={'danger'}
                  icon={'trash'}
                  onClick={onDelete}
                />
              )}
              <Button
                type={'submit'}
                text={submitText}
                onClick={handleSubmit}
                disabled={submitting}
                loading={submitting}
              />
            </div>
          </form>
        )
      }}
    />
  )

  return (
    <Drawer
      isOpen={true}
      title={title}
      close={handleHideModal}
      additionalClassNames={[styles.Drawer]}
      fullWidth
    >
      <LoadingContent loading={loading} content={content} />
    </Drawer>
  )
}
