import React from 'react'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../app/hooks/useSiteVersion'
import { ReactComponent as JivoChatCustomIcon } from '../../../../assets/icons/jivo-chat-icon.svg'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { useRootPath } from '../../../../shared/lib/hooks/useRootPath'
import { Logo } from '../../../../shared/ui-kit-2/navigation/logo/Logo'
import { UserHeaderMobileMenu } from './components/user-mobile-header-menu/UserHeaderMobileMenu'
import styles from './styles.module.scss'

export const UserMobileHeader = () => {
  const navigate = useNavigate()
  const { siteVersion } = useSiteVersion()
  const rootPath = useRootPath()

  const toHome = () => navigate(rootPath)

  const handleOpenChat = () => {
    //@ts-ignore
    if (window.jivo_api) {
      //@ts-ignore
      window.jivo_api.open()
    }
  }

  return (
    <div className={styles.Container}>
      <Logo className={styles.Logo} onClick={toHome} />
      <div className={styles.Actions}>
        {siteVersion !== 'ru' && (
          <div className={styles.JivoChat} onClick={handleOpenChat}>
            <JivoChatCustomIcon tabIndex={0} />
          </div>
        )}
        <UserHeaderMobileMenu />
      </div>
    </div>
  )
}
