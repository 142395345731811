import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { LoadingContent } from '../../../../layouts/loading-content/LoadingContent'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { usePushNotifications } from '../../../../sdk/hooks/use-push-notifications/usePushNotifications'
import { useWifi } from '../../../../sdk/hooks/use-wifi/useWifi'
import { MainMessages } from '../../../../shared/intl-messages/MainMessages'
import { WEB_SOCKETS_EVENTS_VALUES } from '../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { useWebSockets } from '../../../../shared/lib/hooks/useWebSockets'
import { Counter } from '../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Input } from '../../../../shared/ui-kit-2/inputs/input/Input'
import { SearchInputMobile } from '../../../../shared/ui-kit-2/inputs/searchInputMobile/SearchInputMobile'
import { ReactComponent as SearchIcon } from './components/assets/icons/search.svg'
import { WifiCardList } from './components/wifi-card-list/WifiCardList'
import styles from './styles.module.scss'
import {
  NOT_FOUND_TYPES,
  NotFoundDevicesBanner, VARIANTS
} from "../../../../shared/global-components/Banners/banners/NotFoundDevicesBanner";

const WifiListPageMessages = defineMessages({
  title: {
    id: 'WifiListPageMessages.title',
    defaultMessage: 'WI-FI',
  },
  searchPlaceholder: {
    id: 'WifiListPageMessages.searchPlaceholder',
    defaultMessage: 'Search by name or phone',
  },
  devicesCount: {
    id: 'WifiListPageMessages.devices',
    defaultMessage: '{count} devices',
  },
})

export const WifiListPage = () => {
  const intl = useIntl()
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const [inputValue, setInputValue] = useState<string>('')

  const {
    filteredDevices,
    handleFetch: handleFetchWifi,
    handleUpdateWifi,
    handleSwitchHotspot,
    totalCount,
    loading,
  } = useWifi({ inputValue: inputValue })

  const { pushNotifications, handleReadEvents } = usePushNotifications({
    events: [
      WEB_SOCKETS_EVENTS_VALUES.dongle_network_status_changed,
      WEB_SOCKETS_EVENTS_VALUES.dongle_subscription_status_changed,
    ],
    initialFetch: false,
  })

  const { webSocketsEventData } = useWebSockets({
    events: [
      WEB_SOCKETS_EVENTS_VALUES.dongle_network_status_changed,
      WEB_SOCKETS_EVENTS_VALUES.dongle_subscription_status_changed,
    ],
  })

  useEffect(() => {
    if (!loading) {
      handleFetchWifi({ hidden: true })
    }
  }, [webSocketsEventData])

  useEffect(() => {
    handleReadEvents({
      events: [
        WEB_SOCKETS_EVENTS_VALUES.dongle_network_status_changed,
        WEB_SOCKETS_EVENTS_VALUES.dongle_subscription_status_changed,
      ],
    })
  }, [pushNotifications])

  const emptyDeviceList = totalCount === 0

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const content = (
    <>
      <div className={styles.PageHeader}>
        <div className={styles.TitleAndCounter}>
          <div className={styles.Title}>
            {intl.formatMessage(WifiListPageMessages.title)}
          </div>
          <Counter
            customText={`${filteredDevices.reduce((sum, { isOnline }) => (isOnline ? sum + 1 : sum), 0)} ${intl.formatMessage(MainMessages.of)} ${filteredDevices.length}`}
            variant="filled"
          />
        </div>
        {!emptyDeviceList && (
          <div className={styles.SearchInputContainer}>
            {isMobile ? (
              <SearchInputMobile
                value={inputValue}
                defaultValue={inputValue}
                placeholder={intl.formatMessage(
                  WifiListPageMessages.searchPlaceholder,
                )}
                onChange={handleInputChange}
                theme="outlined"
              />
            ) : (
              <Input
                validationNeeded={false}
                placeholder={intl.formatMessage(
                  WifiListPageMessages.searchPlaceholder,
                )}
                onChange={handleInputChange}
                postfix={<SearchIcon />}
              />
            )}
          </div>
        )}
      </div>
      {emptyDeviceList ? (
        <div className={styles.NotFound}>
          <NotFoundDevicesBanner type={NOT_FOUND_TYPES.WIFI} variant={VARIANTS.GREY} />
        </div>
      ) : (
        <WifiCardList
          devices={filteredDevices}
          onUpdateWifi={handleUpdateWifi}
          onSwitchHotspot={handleSwitchHotspot}
        />
      )}
    </>
  )

  return (
    <PageWrapper loading={loading}>
      <LoadingContent loading={loading} content={content} />
    </PageWrapper>
  )
}
