import { WEB_SOCKETS_EVENTS_VALUES } from '../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES'
import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'

export const mapPushNotificationGet = (
  item: PushNotificationGetBackendResponse,
): PushNotificationGetResponse => {
  return {
    pushNotifications: item.data.push_notifications,
  }
}

export type PushNotificationGetBackendResponse = {
  data: {
    push_notifications: Array<{
      count: number
      event: WEB_SOCKETS_EVENTS_VALUES
    }>
  }
}

export type PushNotificationEvent = {
  count: number
  event: WEB_SOCKETS_EVENTS_VALUES
}

export type PushNotificationGetResponse = {
  pushNotifications: Array<PushNotificationEvent>
}

export const pushNotificationGetDatagate = wrapApiRequest<
  {},
  PushNotificationGetBackendResponse,
  PushNotificationGetResponse
>('push-notification', mapPushNotificationGet, 'GET')

export const pushNotificationReadDatagate = wrapApiRequest<
  {},
  () => { data: {} },
  () => { data: {} }
>(
  'push-notification/read',
  // @ts-ignore
  () => {
    data: {
    }
  },
  'PUT',
)
