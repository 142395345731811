type DateFormat = 'date' | 'time' | 'datetime' | 'year' | 'datetime_short_year'

export const formatDate = (
  date: Date | string,
  timezoneOffset: number | null = 0,
  format: DateFormat = 'datetime',
): string => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date

  if (!(parsedDate instanceof Date) || isNaN(parsedDate.getTime())) {
    return '---'
  }

  const localOffset = new Date().getTimezoneOffset()
  const effectiveOffset =
    timezoneOffset !== null ? timezoneOffset : -localOffset
  const newDate = new Date(parsedDate.getTime() + effectiveOffset * 60000)

  let dd: number | string = newDate.getUTCDate()
  if (dd < 10) dd = '0' + dd

  let mm: number | string = newDate.getUTCMonth() + 1
  if (mm < 10) mm = '0' + mm

  let yyyy: number = newDate.getUTCFullYear()

  let hh: number | string = newDate.getUTCHours()
  if (hh < 10) hh = '0' + hh

  let minutes: number | string = newDate.getUTCMinutes()
  if (minutes < 10) minutes = '0' + minutes

  let seconds: number | string = newDate.getUTCSeconds()
  if (seconds < 10) seconds = '0' + seconds

  switch (format) {
    case 'year':
      return yyyy.toString()
    case 'date':
      return dd + '.' + mm + '.' + yyyy
    case 'time':
      return hh + ':' + minutes
    case 'datetime_short_year':
      return (
        dd +
        '.' +
        mm +
        '.' +
        (yyyy % 100) +
        ' ' +
        hh +
        ':' +
        minutes +
        ':' +
        seconds
      )
    default:
      return (
        dd + '.' + mm + '.' + yyyy + ' ' + hh + ':' + minutes + ':' + seconds
      )
  }
}
