import { ReactNode } from 'react'
import clsx from 'clsx'
import { ReactComponent as TitleBorderLineIcon } from '../../assets/title-border-line.svg'
import styles from './styles.module.scss'

export enum SliderCardThemes {
  black,
  white,
}

type SliderCardProps = {
  title: string
  subtitle?: string
  text: string | ReactNode
  theme: SliderCardThemes
}

export const SliderCard = ({
  title,
  subtitle,
  text,
  theme,
}: SliderCardProps) => (
  <div
    className={clsx(
      styles.SliderCard,
      theme === SliderCardThemes.black && styles.SliderCard_black,
      theme === SliderCardThemes.white && styles.SliderCard_white,
    )}
  >
    <div className={styles.Line}>
      <TitleBorderLineIcon />
    </div>
    <div className={styles.Title}>{title}</div>
    <div className={styles.Subtitle}>{subtitle}</div>
    <div className={styles.Text}>{text}</div>
  </div>
)
