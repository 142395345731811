import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import { FieldInputProps } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useSiteVersion } from '../../../../app/hooks/useSiteVersion'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { COUNTRY_PHONES } from '../../../lib/constants/COUNTRY_PHONES'
import { useClickOutside } from '../../../lib/hooks/useClickOutside'
import { Input } from '../input/Input'
import { Switcher } from '../switcher/Switcher'
import { ReactComponent as ErrorIcon } from './assets/icons/error.svg'
import { ReactComponent as GlobeIcon } from './assets/icons/globe.svg'
import styles from './styles.module.scss'

type SearchInputNumberOption = {
  key: number | string
  label: string | ReactNode
  inputLabel: string
  value?: string
  disabled?: boolean
}

interface InputPhoneNumberProps extends React.HTMLProps<HTMLInputElement> {
  label?: string
  error?: string
  helperText?: string
  postfix?: string | ReactNode
  additionalAction?: {
    title: string
    activated: boolean
    callback: (...args: any[]) => any
  }
  markAsRequired?: boolean
  validationNeeded?: boolean
  inputRef?: React.LegacyRef<HTMLInputElement>
  additionalClassNames?: string[]
  initialValue?: string
  testId?: string
  customError?: string
  input: FieldInputProps<string, HTMLElement>
  meta: any
  canBeShort?: boolean
  shortMode: boolean
  setShortMode: Dispatch<SetStateAction<boolean>>
}

const KEYS_BLACK_LIST = {
  ru: ['UA'],
  en: [],
} as Record<string, Array<string>>

const InputPhoneNumberMessages = defineMessages({
  useShortNumber: {
    id: 'InputPhoneNumberMessages.useShortNumber',
    defaultMessage: 'Use short number',
  },
})

export const InputPhoneNumber: FC<InputPhoneNumberProps> = (props) => {
  const {
    label,
    error,
    helperText,
    postfix,
    additionalAction,
    markAsRequired,
    inputRef,
    testId,
    input,
    name,
    meta,
    canBeShort = false,
    shortMode,
    setShortMode,
    ...otherProps
  } = props

  const initialValue = props.initialValue || ''

  const [formattedPhone, setFormattedPhone] = useState<string>('')
  const [country, setCountry] = useState<string | undefined>(undefined)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [filteredOptions, setFilteredOptions] = useState<
    SearchInputNumberOption[]
  >([])
  const { siteVersion } = useSiteVersion()
  const intl = useIntl()
  const dropdown = useRef<HTMLInputElement>(null)

  useClickOutside(dropdown, () => {
    setIsOpen(false)
    setFilteredOptions(countryCallingCodeOptions)
  })

  const countryCallingCodeOptions = Object.entries(COUNTRY_PHONES)
    .map<SearchInputNumberOption>((o) => {
      const [code, value] = o

      return {
        key: code,
        label: (
          <>
            {value.flag} {value.label}
          </>
        ),
        inputLabel: `${value.code}`,
        value: code,
      }
    })
    .filter(({ key }) => !KEYS_BLACK_LIST[siteVersion].includes(`${key}`))

  useEffect(() => {
    setFilteredOptions(countryCallingCodeOptions)

    let initialValueWithPlus = initialValue
    if (initialValue && initialValue[0] !== '+') {
      initialValueWithPlus = '+' + initialValue
    }

    const asYouType = new AsYouType()
    const formattedInitialValue = asYouType.input(initialValueWithPlus)
    const parsedPhoneNumber = parsePhoneNumberFromString(formattedInitialValue)

    if (parsedPhoneNumber) {
      setFormattedPhone(formattedInitialValue)
      setCountry(parsedPhoneNumber.country)
    } else {
      setFormattedPhone(initialValueWithPlus)
      setCountry(undefined)
    }
  }, [initialValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value

    if (value && value[0] !== '+' && !shortMode) {
      value = '+' + value
    }

    const asYouType = new AsYouType()
    const formattedNumber = asYouType.input(value)

    if (!shortMode) {
      const parsedPhoneNumber = parsePhoneNumberFromString(formattedNumber)

      if (parsedPhoneNumber) {
        setCountry(parsedPhoneNumber.country)
      } else {
        setCountry(undefined)
      }
    }

    setFormattedPhone(formattedNumber)
    input.onChange(formattedNumber)
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen((prevState) => !prevState)
  }

  const handleOptionClick = (optionValue: string) => {
    const countryCode = COUNTRY_PHONES[optionValue].code
    setFormattedPhone(`${countryCode} `)
    setCountry(optionValue)
    setIsOpen(false)
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    const exactMatches = countryCallingCodeOptions.filter(
      (option) =>
        option.inputLabel === `+${value}` || option.inputLabel === value,
    )

    const startsWith = countryCallingCodeOptions.filter(
      (option) =>
        (option.inputLabel.startsWith(`+${value}`) ||
          option.inputLabel.startsWith(value)) &&
        !exactMatches.includes(option),
    )

    const contains = countryCallingCodeOptions.filter(
      (option) =>
        !exactMatches.includes(option) &&
        !startsWith.includes(option) &&
        option.inputLabel.includes(value),
    )

    const filtered = [...exactMatches, ...startsWith, ...contains]
    setFilteredOptions(filtered)
  }

  return (
    <div
      className={clsx(
        styles.InputWrapper,
        canBeShort && styles.InputWrapper_canBeShort,
      )}
    >
      <div className={styles.LabelContainer}>
        {label && (
          <label className={styles.Label}>
            {label}
            {markAsRequired && <span>*</span>}
          </label>
        )}
        {canBeShort && (
          <Switcher
            label={intl.formatMessage(InputPhoneNumberMessages.useShortNumber)}
            checked={shortMode}
            onChange={() => {
              setFormattedPhone('')
              setCountry(undefined)
              setShortMode((prev) => !prev)
            }}
          />
        )}
      </div>

      <div className={styles.InputContainer}>
        <input
          {...otherProps}
          name={name as string}
          type="text"
          value={formattedPhone}
          onChange={(e) => {
            handleChange(e)
            // input.onChange(getRawPhoneNumber());
          }}
          placeholder={
            shortMode
              ? '00000'
              : siteVersion === 'ru'
                ? '7 700-000-00-00'
                : '+34 000-000-00-00'
          }
          className={clsx(
            styles.Input,
            error && styles.Input_error,
            shortMode && styles.Input_shortMode,
          )}
        />
        {postfix && (
          <div className={clsx(styles.Postfix, error && styles.Postfix_error)}>
            {postfix}
          </div>
        )}
      </div>

      {!shortMode && (
        <button
          className={clsx(
            styles.ButtonDropdown,
            !country && styles.GlobeIcon,
            !label && styles.ButtonDropdownWithoutLabel,
          )}
          onClick={(e) => handleClick(e)}
        >
          {country && COUNTRY_PHONES[country] ? (
            <>{COUNTRY_PHONES[country].flag}</>
          ) : (
            <GlobeIcon />
          )}
        </button>
      )}

      {error && (
        <div
          className={clsx(
            styles.ValidationElement,
            !label && styles.ValidationElementWithoutLabel,
          )}
        >
          <ErrorIcon />
        </div>
      )}

      {isOpen && (
        <div className={styles.Dropdown} ref={dropdown}>
          <div className={styles.SearchInputWrapper}>
            <Input
              additionalClassNames={[styles.SearchInput]}
              onChange={handleFilterChange}
            />
            <div className={styles.SearchIcon}>
              <SearchIcon />
            </div>
          </div>
          <div className={styles.CountryOptionWrapper}>
            {filteredOptions.map((countryOption, index) => (
              <button
                className={styles.CountryOption}
                key={index}
                onClick={() => handleOptionClick(countryOption.key.toString())}
              >
                <div>{countryOption.label}</div>
                <div>{countryOption.inputLabel}</div>
              </button>
            ))}
          </div>
        </div>
      )}
      {helperText && <div className={styles.HelperText}>{helperText}</div>}
      {error && <div className={styles.Error}>{error}</div>}
    </div>
  )
}
