import { useCountries } from './useCountries'

export const useCountrySelectOptions = () => {
  const { countries, loading } = useCountries()

  return {
    countryOptions: countries.map((country) => {
      const label = country.flagEmoji
        ? `${country.flagEmoji} ${country.name}`
        : country.name
      return {
        key: country.id,
        value: country.id.toString(),
        label: label,
        inputLabel: label,
        iso: country.iso,
      }
    }),
    loading: loading,
  }
}
