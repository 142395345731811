import { getSiteVersion } from '../../../shared/lib/utils/get-site-version/getSiteVersion'

const SYMBOL_BY_COUNTRIES: { [key: string]: string } = {
  ru: '₽',
  en: '€',
}

export const formatPrice = (price: number | undefined | null) => {
  const { siteVersion } = getSiteVersion()


  return price === undefined || price === null || price === 0
    ? '---'
    : `${siteVersion !== 'ru' ? '' : SYMBOL_BY_COUNTRIES[siteVersion]} ${price} ${siteVersion === 'ru' ? '' : SYMBOL_BY_COUNTRIES[siteVersion]}`
}
