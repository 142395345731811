import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { formatDate } from '../../../shared/lib/utils/date-utils/formatDate'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  mailingCreateDatagate,
  mailingDeleteDatagate,
  mailingGetDatagate,
  mailingUpdateDatagate,
} from '../../datagates/api/mailing'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { MailingCreateRequest } from '../../datagates/types/mailing/_crud/create'
import { MailingGetResponse } from '../../datagates/types/mailing/_crud/get'
import { MailingUpdateRequest } from '../../datagates/types/mailing/_crud/update'
import { MAILING_STATUSES } from '../use-mailing-statuses/constants/MailingStatuses'

interface UseMailingProps {
  mailingId: number
}

const UseMailingMessage = defineMessages({
  mailingIdError: {
    id: 'UseMailingMessage.mailingIdError',
    defaultMessage: 'An error occurred while trying to view mailing',
  },
  cancelSuccess: {
    id: 'UseMailingMessage.cancelSuccess',
    defaultMessage: 'Mailing is successfully canceled',
  },
  cancelError: {
    id: 'UseMailingMessage.cancelError',
    defaultMessage: 'An error occurred while cancelling mailing',
  },
  deleteSuccess: {
    id: 'UseMailingMessage.deleteSuccess',
    defaultMessage: 'Mailing is successfully deleted',
  },
  deleteError: {
    id: 'UseMailingMessage.deleteError',
    defaultMessage: 'An error occurred while deleting mailing',
  },
})

export const useMailing = (props: UseMailingProps) => {
  const { mailingId } = props

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()

  const [loading, setLoading] = useState<boolean>(true)
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [mailing, setMailing] = useState<MailingGetResponse>()

  useEffect(() => {
    void handleFetchMailing()
  }, [])

  const handleFetchMailing = async ({
    mailingId: propsMailingId,
  }: { mailingId?: string } = {}) => {
    try {
      const curMailingId = propsMailingId || mailingId

      if (!curMailingId) return

      setLoading(true)
      setInitialLoading(true)

      const { data } = await mailingGetDatagate(null, [
        {
          name: 'mailing_id',
          value: curMailingId.toString(),
        },
      ])

      setMailing(data)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
      setInitialLoading(false)
    }
  }

  const handleCancelMailing = async (
    mailingId: string,
    successCallback?: () => void,
  ) => {
    let data

    try {
      data = await mailingUpdateDatagate(
        {
          mailing_id: Number(mailingId),
          mailing_status_id: MAILING_STATUSES.CANCELLED,
        },
        [
          {
            name: 'mailing_id',
            value: mailingId,
          },
        ],
      )

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingMessage.cancelSuccess),
      })

      successCallback?.()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseMailingMessage.cancelError),
      })
    }

    return data?.data
  }

  const handleDeleteMailing = async (
    mailingId: string,
    successCallback?: () => void,
  ) => {
    let data

    try {
      if (mailing?.mailingStatusId === MAILING_STATUSES.DRAFT) {
        data = await mailingDeleteDatagate(null, [
          {
            name: 'mailing_id',
            value: mailingId,
          },
        ])
      } else {
        data = await mailingUpdateDatagate({ is_deleted: true }, [
          {
            name: 'mailing_id',
            value: mailingId,
          },
        ])
      }

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingMessage.deleteSuccess),
      })

      successCallback?.()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseMailingMessage.deleteError),
      })
    }

    return data?.data
  }

  const handleUpdateMailing = async (
    mailingId: number,
    params?: MailingUpdateRequest,
  ) => {
    try {
      await mailingUpdateDatagate(params || null, [
        { name: 'mailing_id', value: mailingId.toString() },
      ])

      await handleFetchMailing()

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: 'Success update mailing rule',
      })
    } catch (e) {
      console.log('e', e)
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    }
  }

  const handleCreateMailing = async (params: MailingCreateRequest) => {
    let data

    try {
      setLoading(true)
      data = await mailingCreateDatagate(params)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }

    return data?.data
  }

  return {
    mailing,
    loading,
    initialLoading,
    handleCancelMailing,
    handleDeleteMailing,
    handleFetchMailing,
    handleUpdateMailing,
    handleCreateMailing,
  }
}
