import React, { ComponentProps, FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import {
  Multiselect,
  MultiselectProps,
} from '../../../../../ui-kit-2/inputs/multiselect/Multiselect'
import { getFieldError } from '../../../form-helpers/get-field-error/getFieldError'

type MultiselectFieldProps = MultiselectProps &
  ComponentProps<typeof Field<string>>

export const MultiselectField: FC<MultiselectFieldProps> = (props) => {
  const { options, label, placeholder, testId, error, ...otherProps } = props

  return (
    <Field {...otherProps}>
      {(fieldRenderProps) => {
        const { input, meta, ...otherFieldRenderProps } =
          fieldRenderProps as FieldRenderProps<string>
        return (
          <Multiselect
            options={options}
            label={label}
            placeholder={placeholder}
            error={error || getFieldError(meta)}
            testId={testId}
            {...input}
            {...otherFieldRenderProps}
          />
        )
      }}
    </Field>
  )
}
