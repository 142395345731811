import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  CloudTabs,
  CloudTabType,
} from '../../../../../../shared/ui-kit-2/navigation/cloud-tabs/CloudTabs'
import { OrdersPageContextProvider } from '../../contexts/OrdersPageContext'
import { BillingHistory } from '../ordersDesktopPage/billing-history/BillingHistory'
import { OrdersMobile } from './components/orders/OrdersMobile'

enum ORDERS_PAGE_TABS {
  MY_ORDERS,
  BILLING_HISTORY,
}

const OrdersMobilePageMessage = defineMessages({
  myOrdersTab: {
    id: 'OrdersMobilePageMessage.myOrdersTab',
    defaultMessage: 'My Orders',
  },
  billingHistoryTab: {
    id: 'OrdersMobilePageMessage.billingHistoryTab',
    defaultMessage: 'Billing History',
  },
})

export const OrdersMobilePage = () => {
  const intl = useIntl()

  const cloudTabOptions: CloudTabType[] = [
    {
      id: ORDERS_PAGE_TABS.MY_ORDERS.toString(),
      label: intl.formatMessage(OrdersMobilePageMessage.myOrdersTab),
      render: <OrdersMobile />,
    },
    {
      id: ORDERS_PAGE_TABS.BILLING_HISTORY.toString(),
      label: intl.formatMessage(OrdersMobilePageMessage.billingHistoryTab),
      render: <BillingHistory />,
    },
  ]

  return (
    <OrdersPageContextProvider>
      <CloudTabs items={cloudTabOptions} />
    </OrdersPageContextProvider>
  )
}
