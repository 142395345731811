import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './app/App'
import { LanguageWrapper } from './config/intl/components/language-wrapper/LanguageWrapper'
import { store } from './store'
import './style.css'
import 'swiper/css'

// import 'antd/dist/antd.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <LanguageWrapper>
      <App />
    </LanguageWrapper>
  </Provider>,
  // </React.StrictMode>
)
