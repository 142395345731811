import React, { FC, ReactNode, useContext, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useDialog } from '../../../../../../../../app/hooks/useDialog'
import { useSnackbar } from '../../../../../../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { RoutePath } from '../../../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../../../config/routes/enums/route'
import { SNACKBAR_TYPES } from '../../../../../../../../layouts/snackbar/SnackbarProvider'
import { useVerificationCodeInput } from '../../../../../../../../layouts/verification-code-input/useVerificationCodeInput'
import { dongleCreateDatagate } from '../../../../../../../../sdk/datagates/api/dongle'
import {
  BackendErrorCodes,
  useBackendErrorCodes,
} from '../../../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { getErrorMessage } from '../../../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { DialogVersion } from '../../../../../../../../store/reducers/dialog/types'
import { ActivateDeviceContext } from '../../../../contexts/ActivateDeviceContext'
import { SelectSubscription } from '../select-subscription/SelectSubscription'
import styles from './styles.module.scss'

const ConnectModemFormMessages = defineMessages({
  title: {
    id: 'ConnectModemFormMessages.title',
    defaultMessage: 'Activate your modem',
  },
  description: {
    id: 'ConnectModemFormMessages.description',
    defaultMessage:
      'Fill in the fields, insert the SIM card and turn on the modem',
  },
  imeiLabel: {
    id: 'ConnectModemFormMessages.imeiLabel',
    defaultMessage: 'Enter IMEI (Last 6 digits)',
  },
  macLabel: {
    id: 'ConnectModemFormMessages.macLabel',
    defaultMessage: 'MAC address (MobileRouter)',
  },
  hotspotPasswordLabel: {
    id: 'ConnectModemFormMessages.hotspotPasswordLabel',
    defaultMessage: 'Wi-Fi password',
  },
  validationError: {
    id: 'ConnectModemFormMessages.validationError',
    defaultMessage:
      'Please enter last 6 digits of IMEI and 4 digits of MAC address',
  },
})

interface DonglePayload {
  imei_manufacture: string
  mac: string
  order_dongle_id?: string
}

const ConnectModemFormWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const intl = useIntl()

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Content}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(ConnectModemFormMessages.title)}
          </div>
          <div className={styles.Description}>
            {intl.formatMessage(ConnectModemFormMessages.description)}
          </div>
        </div>

        {children}
      </div>

      <div className={styles.VideoContainer}>
        <video
          autoPlay
          muted
          loop
          controls={false}
          className={styles.Video}
          playsInline
        >
          <source
            src={`${process.env.PUBLIC_URL}/videos/wifi-hotspot.mp4`}
            type="video/mp4"
          />
        </video>
        <div className={styles.Mask}></div>
      </div>
    </Card>
  )
}

export const ConnectModemForm = () => {
  const { setCurrentStep, setImeiLastSixDigits } = useContext(
    ActivateDeviceContext,
  )

  const intl = useIntl()
  const navigate = useNavigate()
  const { handleOpenSnackbar } = useSnackbar()
  const { handleOpenDialog, handleHideDialog } = useDialog()
  const { resolveBackendError } = useBackendErrorCodes()
  const [searchParams] = useSearchParams()

  const hotspotPasswordInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const { component: IMEIComponent, fullCode: IMEIFullCode } =
    useVerificationCodeInput(
      (code: string) => {
        hotspotPasswordInputRef.current?.focus()
      },
      loading,
      false,
      undefined,
      6,
      searchParams.get('IMEIFullCode'),
    )

  const { component: MACComponent, fullCode: MACFullCode } =
    useVerificationCodeInput(
      (code: string) => {
        hotspotPasswordInputRef.current?.focus()
      },
      loading,
      false,
      undefined,
      4,
      searchParams.get('MACFullCode'),
      /^[0-9a-zA-Z]+$/,
    )

  const onSubmit = async ({
    orderDongleId,
  }: { orderDongleId?: string } = {}) => {
    try {
      setLoading(true)
      if (!IMEIFullCode || !MACFullCode) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: intl.formatMessage(ConnectModemFormMessages.validationError),
        })
        setLoading(false)
        return
      }

      const payload: DonglePayload = {
        imei_manufacture: IMEIFullCode,
        mac: MACFullCode,
      }

      if (orderDongleId) {
        payload.order_dongle_id = orderDongleId
      }

      const { data } = await dongleCreateDatagate(payload)

      const connectedDongleId = data?.dongleId

      handleHideDialog()

      if (connectedDongleId) {
        navigate(
          `${RoutePath[Route.DeviceConnectionStatus]}?dongleId=${connectedDongleId}`,
        )
      } else {
        navigate(RoutePath[Route.DeviceList])
      }
    } catch (e) {
      const backendErrCode = getErrorMessage(e)

      if (
        backendErrCode ===
          BackendErrorCodes.DONGLE_ERROR_DONGLE_CREATE_ORDER_FOUND &&
        !orderDongleId
      ) {
        handleOpenDialog({
          version: DialogVersion.v2,
          cookedTemplate: (
            <SelectSubscription
              mac={MACFullCode}
              imei={IMEIFullCode}
              onSubmit={onSubmit}
            />
          ),
          props: {
            title: 'Select subscription',
            cancel: {
              onClick: () => handleHideDialog(),
            },
          },
        })
      } else {
        handleHideDialog()
        const errorText = resolveBackendError(backendErrCode)
        navigate(
          `${RoutePath[Route.DeviceConnectionStatus]}?errorText=${errorText}&IMEIFullCode=${IMEIFullCode}&MACFullCode=${MACFullCode}`,
        )
      }

      /*
      //TODO process case with existing payed orders

      // setImeiLastSixDigits(IMEIFullCode)

      if (
        backendErrCode ===
        BackendErrorCodes.DONGLE_ERROR_DONGLE_CREATE_ORDER_NOT_FOUND
      ) {
        setCurrentStep(ACTIVATE_DEVICE_STEPS.SUBSCRIPTION_PAYMENT)
        return
      }
      */
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConnectModemFormWrapper>
      <div className={styles.Form}>
        <div className={styles.InputContainer}>
          <div className={styles.InputContainer__label}>
            {intl.formatMessage(ConnectModemFormMessages.imeiLabel)}
          </div>
          {IMEIComponent}
        </div>

        <div className={styles.InputContainer}>
          <div className={styles.InputContainer__label}>
            {intl.formatMessage(ConnectModemFormMessages.macLabel)}
          </div>
          {MACComponent}
        </div>

        <Button
          onClick={() => onSubmit()}
          text={intl.formatMessage(CommonButtonMessages.activate)}
          additionalClassNames={[styles.SubmitBtn]}
          loading={loading}
          disabled={loading}
        />
      </div>
    </ConnectModemFormWrapper>
  )
}
