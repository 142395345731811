import React, {
  FC, memo,
  ReactNode, useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { ReactComponent as SortedIconArrow } from '../../../../assets/icons/arrow-up-universal.svg'
import { ReactComponent as SortedIconAZ } from '../../../../assets/icons/sort-a-z.svg'
import { ReactComponent as SortedIconZA } from '../../../../assets/icons/sort-z-a.svg'
import { LC } from '../../../../tests/e2e/locators'
import { Loader as UIKitLoader } from '../../../ui-kit/data-display/loader/Loader'
import { ReactComponent as PrevPageIcon } from './assets/icons/caret-left.svg'
import { ReactComponent as NextPageIcon } from './assets/icons/caret-right.svg'
import {
  TableHeader,
  TableHeaderProps,
} from './components/TableHeader/TableHeader'
import { TableShowElements } from './components/TableShowElements/TableShowElements'
import { useTablePages } from './hooks/useTablePages'
import styles from './styles.module.scss'

type CellPositionType = 'left' | 'right' | 'center'

export type TableColType = {
  title: string | null | ReactNode
  position?: CellPositionType
  handleSorted?: () => void
  isSorted?: boolean // direction. false -> asc; true -> desc
  isAZZA?: boolean // true -> AZZA icon, false -> default arrow icon
  isActive?: boolean // coloring after click
  sortIcon?: ReactNode
}

export type RowGroupType = {
  groupHeader: string | ReactNode
  rows: ReactNode[][]
}

export interface TableProps {
  headerCols?: TableHeaderProps['cols']
  filters?: TableHeaderProps['filters']
  handleChangeFilters?: TableHeaderProps['handleChangeFilters']
  name: string
  cols?: TableColType[]
  rowGroups: RowGroupType[]
  currentPage: number
  totalCount: number
  onPageChange: (page: number, limit: number) => void
  loading: boolean
  // Length must be same as cols.length
  columnWidths: Array<number | string>
  footerInfo?: string
  noDataComponent?: ReactNode | null
  itemsPerPage: number
  testId?: string
  classes?: {
    container?: string
    groupHeader?: string
    footer?: string
    rowGroups?: string
  }

  // for "no devices" like example
  banner?: ReactNode | null
}

const FirstPage = 1

const TableMessages = defineMessages({
  notFound: {
    id: 'TableMessages.notFound',
    defaultMessage: 'Nothing found',
  },
})

const limiterPageCount = (totalCount: number, elementsShow: number) => {
  const maxCount = totalCount > 3000 ? 3000 : totalCount
  return Math.ceil(maxCount / elementsShow)
}

export const Table = memo(function Table(props: TableProps) {
  const {
    headerCols,
    filters,
    handleChangeFilters,
    name,
    cols, // TODO: REMOVE IN FUTURE
    rowGroups,
    currentPage,
    totalCount,
    onPageChange,
    loading,
    columnWidths,
    itemsPerPage,
    noDataComponent,
    testId,
    classes,
    banner
  } = props
  const intl = useIntl()
  const { pathname } = useLocation()
  const [elementsShow, setElementsShow] = useState(itemsPerPage)
  const isCallsPage = pathname.includes('/call')

  const rowGroupsRef = useRef<HTMLDivElement>(null)

  const pagesCount = useMemo(
    () => limiterPageCount(totalCount, elementsShow),
    [totalCount, elementsShow],
  )

  const pages = useTablePages(
    currentPage,
    pagesCount,
    onPageChange,
    elementsShow,
  )

  const prevPageAvailable = useMemo(
    () => currentPage !== FirstPage && pagesCount > 1,
    [currentPage, pagesCount],
  )
  const nextPageAvailable = useMemo(
    () => currentPage !== pagesCount - (1 - FirstPage) && pagesCount > 1,
    [currentPage, pagesCount],
  )

  const gridColWidthsStyles = {
    gridTemplateColumns: columnWidths
      ? columnWidths
          .map((w) => (typeof w === 'string' ? w : `minmax(0px, ${w}fr)`))
          .join(' ')
      : `repeat(${headerCols?.length || cols?.length}, minmax(max-content, 1fr))`,
  }

  useEffect(() => {
    if (rowGroupsRef.current) rowGroupsRef.current.scrollTop = 0
  }, [currentPage])

  useEffect(() => {
    onPageChange(currentPage, elementsShow)
  }, [elementsShow, currentPage])

  const SortedIconAZZAComponent = ({
    isSorted,
    className,
  }: {
    isSorted?: boolean
    className?: string
  }) =>
    !isSorted ? (
      <SortedIconAZ className={className} />
    ) : (
      <SortedIconZA className={className} />
    )

  const SortedIconArrowComponent = ({
    isSorted,
    className,
  }: {
    isSorted?: boolean
    className?: string
  }) => (
    <SortedIconArrow
      className={clsx(className, isSorted && styles.ArrowRotated)}
    />
  )

  const SortedIcon = (props: {
    isSorted?: boolean
    isAZZA?: boolean
    className?: string
  }) => {
    if (props?.isAZZA) {
      return <SortedIconAZZAComponent {...props} />
    }

    return <SortedIconArrowComponent {...props} />
  };

  return (
    <div className={styles.Table}>
      {!loading && banner
        ? (
          <div className={styles.Banner}>
            {banner}
          </div>
        )
        : (
          <div className={styles.Content}>
            <div
              className={clsx(styles.Container, classes?.container)}
              data-test-id={testId}
            >
              <div
                className={clsx(
                  styles.ContainerContent,
                  isCallsPage && styles.ContainerContentCalls,
                )}
              >
                {headerCols && handleChangeFilters && filters ? (
                  <TableHeader
                    cols={headerCols}
                    filters={filters}
                    handleChangeFilters={handleChangeFilters}
                    gridColWidthsStyles={gridColWidthsStyles}
                  />
                ) : (
                  <div className={styles.Header} style={gridColWidthsStyles}>
                    {cols?.map((c, index) => (
                      <div
                        key={`${name} | cols | ${index}`}
                        className={clsx(
                          styles.HeaderCell,
                          c.position === 'right' && styles.HeaderCell_right,
                          c.position === 'center' && styles.HeaderCell_center,
                          c.handleSorted && styles.HeaderCellSorted,
                        )}
                        onClick={c.handleSorted}
                      >
                        {c.title}
                        {c.handleSorted && !c?.sortIcon && (
                          <SortedIcon
                            isSorted={c.isSorted}
                            isAZZA={c.isAZZA}
                            className={clsx(c.isActive && styles.SortedIcon_active)}
                          />
                        )}
                        {c?.sortIcon || <></>}
                      </div>
                    ))}
                  </div>
                )}

                {loading ? (
                  <div className={styles.RowGroups}>
                    <div className={styles.Loader}>
                      <UIKitLoader />
                    </div>
                  </div>
                ) : (
                  <div
                    className={clsx(
                      styles.RowGroups,
                      classes?.rowGroups,
                      styles.RowGroupsWithoutPagination,
                    )}
                    ref={rowGroupsRef}
                  >
                    {rowGroups.reduce(
                      (rowsCount, { rows }) => rows.length + rowsCount,
                      0,
                    ) === 0 ? (
                      noDataComponent || (
                        <div className={styles.NothingFound}>
                          {intl.formatMessage(TableMessages.notFound)}
                        </div>
                      )) : (
                      rowGroups.map((rg, index) => (
                        <div
                          key={`${name} | rg | ${index}`}
                          className={styles.GroupContainer}
                        >
                          <div
                            className={clsx(styles.GroupHeader, classes?.groupHeader)}
                          >
                            {rg.groupHeader}
                          </div>

                          <div className={styles.Rows}>
                            {rg.rows.map((row, rowIndex) => (
                              <div
                                key={`${name} | row | ${rowIndex}`}
                                className={styles.Row}
                                style={gridColWidthsStyles}
                                data-test-id={LC.SMS.TABLE.ROW._(index)}
                              >
                                {row.map((cell, cellIndex) => {
                                  return (
                                    <div
                                      key={`${name} | cell | ${cellIndex}`}
                                      className={clsx(
                                        styles.Cell,
                                        headerCols?.[cellIndex]?.position === 'right' &&
                                        styles.Cell__right,
                                        headerCols?.[cellIndex]?.position ===
                                        'center' && styles.Cell__center,
                                      )}
                                    >
                                      {cell}
                                    </div>
                                  )
                                })}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
            {rowGroups.length > 0 && (
              <div className={clsx(styles.Footer, classes?.footer)}>
                <TableShowElements
                  elementsShow={elementsShow}
                  onPageChange={onPageChange}
                  setElementsShow={setElementsShow}
                />
                {pagesCount > 1 && (
                  <div className={styles.Pagination}>
                    <div
                      onClick={() =>
                        prevPageAvailable &&
                        onPageChange(currentPage - 1, itemsPerPage)
                      }
                      className={clsx(
                        styles.Pagination__arrow,
                        !prevPageAvailable && styles.Pagination__arrow_disabled,
                      )}
                    >
                      <PrevPageIcon />
                    </div>

                    {pages}

                    <div
                      onClick={() =>
                        nextPageAvailable &&
                        onPageChange(currentPage + 1, itemsPerPage)
                      }
                      className={clsx(
                        styles.Pagination__arrow,
                        !nextPageAvailable && styles.Pagination__arrow_disabled,
                      )}
                    >
                      <NextPageIcon />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )
      }
    </div>
  )
})
