import React, { useState } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

// TODO: Subscription Selector переместить в shared

export const SubscriptionSelector = ({
  selected,
  setSelected,
  options,
  row,
}: {
  selected: string | number | null
  setSelected: (selected: string | number | null) => void
  options: Array<{ id: string | number; label: string }>
  row?: boolean
}) => {
  const handleSelect = (id: string | number) => {
    setSelected(id)
  }

  return (
    <div className={clsx(styles.Subscription, row && styles.Subscription_row)}>
      {options.map((option) => (
        <div
          key={option.id}
          className={clsx(
            styles.Subscription__Card,
            selected === option.id && styles.Subscription__Card_selected,
          )}
          style={{ width: row ? `calc(100% / ${options.length})` : 'auto' }}
          onClick={() => handleSelect(option.id)}
        >
          <span className={styles.Subscription__Label}>{option.label}</span>
          <div className={styles.Subscription__Circle}>
            {selected === option.id && (
              <div className={styles.Subscription__Circle_filled} />
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
