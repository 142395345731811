import React, { useContext, useEffect, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import {
  Stepper,
  StepperStepType,
} from '../../../../../../shared/ui-kit-2/navigation/stepper/Stepper'
import {
  PURCHASE_STEPS,
  PurchaseProcessPageContext,
} from '../../context/PurchaseProcessPageContextProvider'
import { useUserProfile } from '../../hooks/useUserProfile'
import { PurchaseStepCustomer } from '../purchase-step-customer/PurchaseStepCustomer'
import { PurchaseStepPayment } from '../purchase-step-payment/PurchaseStepPayment'
import { PurchaseStepShipping } from '../purchase-step-shipping/PurchaseStepShipping'
import styles from './styles.module.scss'

export const PurchaseStepsMessages = defineMessages({
  title: {
    id: 'PurchaseStepsMessages.title',
    defaultMessage: 'Go through all the steps to buy a modem',
  },
  stepCustomer: {
    id: 'PurchaseStepsMessages.stepCustomer',
    defaultMessage: 'Customer',
  },
  stepShipping: {
    id: 'PurchaseStepsMessages.stepShipping',
    defaultMessage: 'Shipping',
  },
  stepPayment: {
    id: 'PurchaseStepsMessages.stepPayment',
    defaultMessage: 'Payment',
  },
  orderCreateError: {
    id: 'PurchaseStepsMessages.orderCreateError',
    defaultMessage: 'An error occurred while creating an order',
  },
  orderCreateSuccess: {
    id: 'PurchaseStepsMessages.orderCreateSuccess',
    defaultMessage: 'Order created successfully',
  },
})

export type PurchaseStepsFormType = {
  userId: number
  accountType: string
  firstName: string
  lastName: string
  companyName?: string
  companyPhone?: string
  userPhone?: string
  vatNumber?: string
  phoneCountryCode?: string
  phoneNationalNumber?: string
  billingEmail: string
  email: string

  // Delivery fields
  countryId: string
  streetAddress1: string
  streetAddress2?: string
  city: string
  region: string
  postalCode: string

  // Legal fields
  legalCountryId?: string
  legalStreetAddress1?: string
  legalStreetAddress2?: string
  legalCity?: string
  legalRegion?: string
  legalPostalCode?: string
}

export const PurchaseSteps = () => {
  const {
    order,
    setCurrentStep,
    currentStep,
    formData,
    setFormData,
    setDeliveryPriceWasFetched,
  } = useContext(PurchaseProcessPageContext)

  const intl = useIntl()

  const { userProfileData } = useUserProfile()

  useEffect(() => {
    if (userProfileData) {
      setFormData({
        ...(formData || {}),
        ...userProfileData,
      } as PurchaseStepsFormType)
    }
  }, [userProfileData])

  useEffect(() => {
    setDeliveryPriceWasFetched(false)
  }, [formData])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [currentStep])

  const backToCustomerStep = () => {
    setCurrentStep(PURCHASE_STEPS.CUSTOMER)
  }

  const StepperSteps: StepperStepType[] = useMemo(() => {
    return [
      {
        value: PURCHASE_STEPS.CUSTOMER,
        label: intl.formatMessage(PurchaseStepsMessages.stepCustomer),
        onClick: backToCustomerStep,
        disabled: !!order,
      },
      {
        value: PURCHASE_STEPS.SHIPPING,
        label: intl.formatMessage(PurchaseStepsMessages.stepShipping),
        disabled: !!order,
      },
      {
        value: PURCHASE_STEPS.PAYMENT,
        label: intl.formatMessage(PurchaseStepsMessages.stepPayment),
      },
    ]
  }, [order])

  const stepComponent = useMemo(() => {
    switch (currentStep) {
      case PURCHASE_STEPS.CUSTOMER:
        return <PurchaseStepCustomer />
      case PURCHASE_STEPS.SHIPPING:
        return <PurchaseStepShipping />
      case PURCHASE_STEPS.PAYMENT:
        return <PurchaseStepPayment />
      default:
        return <PurchaseStepCustomer />
    }
  }, [currentStep, formData])

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Header}>
        <div className={styles.Title}>
          {intl.formatMessage(PurchaseStepsMessages.title)}
        </div>

        <Stepper value={currentStep} steps={StepperSteps} />
      </div>
      {stepComponent}
    </Card>
  )
}
