import React, { FC, useEffect, useMemo, useState } from 'react'
import { useModal } from '../../../../../../app/hooks/useModal'
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/arrow-right.svg'
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { MailingTableRows } from '../../../../../../layouts/tables/sms-mailing-table/components/sms-mailing-table-rows/SmsMailingTableRows'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import { useMailings } from '../../../../../../sdk/hooks/use-mailings/useMailings'
import { WEB_SOCKETS_EVENTS_VALUES } from '../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES'
import { useWebSockets } from '../../../../../../shared/lib/hooks/useWebSockets'
import { tableFiltersToUrlFilters } from '../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS,
} from '../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader'
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
} from '../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols'
import { useTableFilters } from '../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters'
import { Table } from '../../../../../../shared/ui-kit-2/data-display/table/Table'
import ControlHeader from '../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader'
import styles from './styles.module.scss'
import {
  NOT_FOUND_TYPES,
  NotFoundDevicesBanner
} from "../../../../../../shared/global-components/Banners/banners/NotFoundDevicesBanner";
import {SmsListPageProps} from "../../SmsPage";

export const SmsMailingPage: FC<SmsListPageProps> = ({
  activeDevices,
  activeDevicesLoading,
  userHasDevices,
}) => {
  const { handleOpenModal } = useModal()

  const COLS = {
    campaignName: 'campaignName',
    modemName: 'modemName',
    recipients: 'recipients',
    sendTime: 'sendTime',
  }

  const headerCols = [
    {
      id: COLS.campaignName,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Campaign name',
      defaultText: 'Enter the name',
    },
    {
      id: COLS.modemName,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Modem',
      defaultText: 'Enter the name',
      postfix: <ArrowRightIcon />,
    },
    {
      id: COLS.recipients,
      label: 'Recipients',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.textMessage],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.mailingStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[]

  const { filters, handleChangeFilters, handlePageChange, debouncedFilters } =
    useTableFilters({
      queryIds: [
        ...headerCols.map(({ id }) => id),
        'page',
        'limit',
        'mailing_entity_type',
      ],
      defaultFilterValues: [
        { name: 'mailing_entity_type', value: ['1'] },
        {
          name: 'page',
          value: 1,
        },
        {
          name: 'limit',
          value: 50,
        },
      ],
    })

  const currentFilters = useMemo(
    () =>
      tableFiltersToUrlFilters([
        {
          name: 'mailing_entity_type_ids[]',
          value: debouncedFilters?.mailing_entity_type,
        },
        { name: 'page', value: debouncedFilters?.page - 1 },
        { name: 'limit', value: debouncedFilters?.limit },
        { name: 'name', value: debouncedFilters?.campaignName },
        { name: 'dongle_name', value: debouncedFilters?.modemName },
        { name: 'sms_type_ids[]', value: debouncedFilters?.type },
        { name: 'from', value: debouncedFilters?.time?.from },
        { name: 'to', value: debouncedFilters?.time?.to },
        { name: 'text', value: debouncedFilters?.textMessage },
        { name: 'sms_status_ids[]', value: debouncedFilters?.mailingStatus },
      ]),
    [debouncedFilters],
  )

  const {
    mailings,
    totalCount: mailingsTotalCount,
    handleFetch: handleFetchMailings,
    loading: mailingsLoading,
  } = useMailings({
    filters: currentFilters,
  })

  const { webSocketsEventData } = useWebSockets({
    events: [
      WEB_SOCKETS_EVENTS_VALUES.mailing_status_changed,
      WEB_SOCKETS_EVENTS_VALUES.mailing_created,
    ],
  })

  const handleOpenCreateMailingModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.SMS_MAILING_CREATE,
      props: {
        handleFetch: handleFetchMailings,
      },
    })
  }

  useEffect(() => {
    if (filters.page === 1 && !globalLoading) {
      handleFetchMailings({ hidden: true })
    }
  }, [webSocketsEventData])

  const rowGroups = MailingTableRows(mailings, handleFetchMailings)

  const globalLoading = useMemo(
    () => activeDevicesLoading || mailingsLoading,
    [activeDevicesLoading, mailingsLoading],
  )

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <ControlHeader
        title={'Bulk SMS'}
        counter={{
          min: mailings.length,
          max: mailingsTotalCount,
        }}
        actions={[
          {
            variant: 'greenFilled',
            size: 'md',
            text: 'Create',
            prefix: <PlusIcon />,
            onClick: handleOpenCreateMailingModal,
          },
        ]}
        loading={globalLoading}
      />

      <Table
        headerCols={headerCols}
        filters={filters}
        handleChangeFilters={handleChangeFilters}
        name={'SmsMailing'}
        rowGroups={rowGroups}
        currentPage={filters.page}
        totalCount={mailingsTotalCount}
        onPageChange={handlePageChange}
        itemsPerPage={filters.limit}
        loading={globalLoading}
        columnWidths={[1, 1, 1, 2, 1, 1, '100px']}

        banner={!userHasDevices && <NotFoundDevicesBanner type={NOT_FOUND_TYPES.SMSES} />}
      />
    </Card>
  )
}
