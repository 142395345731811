import React from 'react'
import { useSiteVersion } from '../../../app/hooks/useSiteVersion'
import { Card } from '../../../shared/ui-kit-2/data-display/card/Card'
import styles from '../privacy-policy/styles.module.scss'
import { EngTermsOfUse } from './components/EngTermsOfUse/EngTermsOfUse'
import { RuTermsOfUse } from './components/RuTermsOfUse/RuTermsOfUse'

export const TermsOfUsePage = () => {
  const { siteVersion } = useSiteVersion()

  const isRu = siteVersion === 'ru'

  return (
    <div className={styles.Page}>
      <Card additionalClassNames={[styles.Container]}>
        <div className={styles.Content}>
          <div className={styles.Text}>
            {isRu ? <RuTermsOfUse /> : <EngTermsOfUse />}
          </div>
        </div>
      </Card>
    </div>
  )
}
