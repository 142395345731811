import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { useDialog } from '../../../../../../app/hooks/useDialog'
import { useModal } from '../../../../../../app/hooks/useModal'
import { ReactComponent as CaretRightIcon } from '../../../../../../assets/icons/caret-right.svg'
import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/trash.svg'
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/v2/ic-close.svg'
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg'
import { CommonTableMessages } from '../../../../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { useAutoResponseRules } from '../../../../../../sdk/hooks/use-auto-response-rules/useAutoResponseRules'
import { WEB_SOCKETS_EVENTS_VALUES } from '../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES'
import { useWebSockets } from '../../../../../../shared/lib/hooks/useWebSockets'
import { formatDate } from '../../../../../../shared/lib/utils/date-utils/formatDate'
import { tableFiltersToUrlFilters } from '../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters'
import {
  WavSurfer,
  WavSurferThemes,
} from '../../../../../../shared/ui-kit-2/components/wav-surfer/WavSurfer'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Sticker } from '../../../../../../shared/ui-kit-2/data-display/sticker/Sticker'
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS,
} from '../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader'
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
} from '../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols'
import { useTableFilters } from '../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters'
import {
  Table,
  TableColType,
} from '../../../../../../shared/ui-kit-2/data-display/table/Table'
import { Button } from '../../../../../../shared/ui-kit-3/components/Button'
import ControlHeader from '../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader'
import { DialogVersion } from '../../../../../../store/reducers/dialog/types'
import styles from './styles.module.scss'
import {
  NOT_FOUND_TYPES,
  NotFoundDevicesBanner
} from "../../../../../../shared/global-components/Banners/banners/NotFoundDevicesBanner";
import {useDevices} from "../../../../../../sdk/hooks/use-devices/useDevices";

export const CallsAutoResponseRules = () => {
  const { handleOpenModal } = useModal()
  const { webSocketsEventData } = useWebSockets({
    events: [WEB_SOCKETS_EVENTS_VALUES.call_created],
  })

  const { devices, loading: devicesLoading } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    brandId: 2,
    callTypeId: 2,
  })

  const [activeWavSurfer, setActiveWavSurfer] = useState<any>(null)

  const COLS = {
    name: 'name',
    modemName: 'modemName',
  }

  const headerCols = [
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    {
      id: COLS.name,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Name',
      defaultText: 'Enter the name',
    },
    {
      id: COLS.modemName,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Modem name',
      defaultText: 'Enter the name',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.phone],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.audioFile],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[]

  const { filters, handleChangeFilters, handlePageChange, debouncedFilters } =
    useTableFilters({
      queryIds: [...headerCols.map(({ id }) => id), 'page', 'limit'],
      defaultFilterValues: [
        {
          name: 'page',
          value: 1,
        },
        {
          name: 'limit',
          value: 50,
        },
      ],
    })

  const currentFilters = useMemo(
    () =>
      tableFiltersToUrlFilters([
        { name: 'page', value: debouncedFilters?.page - 1 },
        { name: 'limit', value: debouncedFilters?.limit },
        { name: 'from', value: debouncedFilters?.time?.from },
        { name: 'to', value: debouncedFilters?.time?.to },
        { name: 'name', value: debouncedFilters?.name },
        { name: 'dongle_name', value: debouncedFilters?.modemName },
        {
          name: 'dongle_phone',
          value:
            debouncedFilters?.phone &&
            encodeURIComponent(debouncedFilters?.phone),
        },
        {
          name: 'is_empty_file_id',
          value:
            debouncedFilters?.audioFile &&
            !debouncedFilters?.audioFile.includes('All')
              ? debouncedFilters.audioFile[0]
              : undefined,
        },
      ]),
    [debouncedFilters],
  )

  const {
    rules,
    totalCount,
    loading: autoResponseLoading,
    handleFetchRules,
    handleUpdateRules,
    handleAddRule,
    handleUpdateRule,
    handleDeleteRule,
  } = useAutoResponseRules({
    filters: currentFilters,
  })

  const globalLoading = useMemo(() =>
    autoResponseLoading || devicesLoading,
    [autoResponseLoading, devicesLoading]
  );

  useEffect(() => {
    if (filters?.page === 1 && !globalLoading) {
      handleFetchRules()
    }
  }, [webSocketsEventData])

  const { handleOpenDialog, handleHideDialog } = useDialog()

  const onDeleteRule = async (callScenarioId: number) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete rule?',
        subtitle: 'This action cannot be undone',
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog(),
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: async () => {
            await handleDeleteRule({ callScenarioId })
            await handleFetchRules()
            handleHideDialog()
          },
        },
      },
    })
  }

  const rowGroups = useMemo(() => {
    if (Array.isArray(rules)) {
      return [
        {
          groupHeader: <></>,
          rows: rules.map((rule) => {
            const {
              call_scenario_id,
              updated_at,
              name,
              is_call_record,
              file,
              dongle,
            } = rule

            return [
              <div className={styles.Time}>
                {formatDate(updated_at, 0, 'time')}
              </div>,
              <div>{name}</div>,
              <div>{dongle?.name}</div>,
              <div>{dongle?.number}</div>,
              <div>
                {is_call_record && (
                  <WavSurfer
                    audioSource={file.cdn_url}
                    audioWavesWidth={61}
                    showDuration={false}
                    theme={WavSurferThemes.black}
                  />
                )}
              </div>,
              <div className={styles.Actions}>
                <button
                  className={styles.Action}
                  onClick={() => onDeleteRule(call_scenario_id)}
                >
                  <TrashIcon />
                </button>
                <div
                  className={styles.Action}
                  onClick={() =>
                    handleOpenModal({
                      type: MODAL_TYPES.CALLS_AUTO_RESPONSE_RULES,
                      props: {
                        rule,
                        handleUpdateRule,
                        handleUpdateRules,
                        handleFetchRules,
                      },
                    })
                  }
                >
                  <CaretRightIcon />
                </div>
              </div>,
            ]
          }),
        },
      ]
    }

    return []
  }, [rules])

  const userHasDevices = useMemo(() => devices?.length > 0, [devices]);

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.CallAutoResponseRules}>
        <ControlHeader
          title={'Autoreply rules'}
          counter={{
            min: rules.length,
            max: totalCount,
          }}
          actions={userHasDevices ? [
            {
              variant: 'greenFilled',
              size: 'md',
              text: 'Create',
              prefix: <PlusIcon />,
              onClick: () =>
                handleOpenModal({
                  type: MODAL_TYPES.CALLS_AUTO_RESPONSE_RULES,
                  props: {
                    handleAddRule,
                    handleFetchRules,
                  },
                }),
            },
          ] : []}
          loading={globalLoading}
          hideCounter={!userHasDevices}
        />
        {false && (
          <div className={styles.Wrapper}>
            <div className={styles.Audio}>
              <div className={styles.Audio__Wrapper}>
                <WavSurfer
                  audioWavesWidth={225}
                  theme={WavSurferThemes.white}
                />
              </div>
              <button
                className={styles.Audio__Close}
                onClick={() => setActiveWavSurfer(null)}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        )}
        <Table
          headerCols={headerCols}
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          name={'CallsAutoResponseRules'}
          rowGroups={rowGroups}
          currentPage={filters.page}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          loading={globalLoading}
          columnWidths={['125px', 3, 3, 3, 2, '100px']}
          itemsPerPage={filters.limit}

          banner={!userHasDevices && <NotFoundDevicesBanner type={NOT_FOUND_TYPES.CALLS} />}
        />
      </div>
    </Card>
  )
}
