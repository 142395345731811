import React from 'react'
import { useNavigate } from 'react-router'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useModal } from '../../../../../../app/hooks/useModal'
import { ReactComponent as BasketIcon } from '../../../../../../assets/icons/v2/ic-basket.svg'
import { ReactComponent as DeviceIcon } from '../../../../../../assets/icons/v2/ic-device.svg'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../config/routes/enums/route'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import { StatusNetwork } from '../../../../../../shared/ui-kit-2/data-display/status-network/StatusNetwork'
import { StatusSubscription } from '../../../../../../shared/ui-kit-2/data-display/status-subscription/StatusSubscription'
import { Button } from '../../../../../../shared/ui-kit-3/components/Button'
import { Sticker } from '../../../../../../shared/ui-kit-3/components/Sticker'
import { Card } from '../Card'
import { SeeAllButton } from '../SeeAllButton'
import styles from './styles.module.scss'

type ModemProps = {
  devices: DongleListResponse['dongles']
  countOnline: number
  countOffline: number
}

const EmptyModems = () => {
  const navigate = useNavigate()
  const { handleOpenModal } = useModal()

  return (
    <div className={styles.EmptyModems}>
      <div className={styles.EmptyModems__Title}>
        Buy a Teleсorn modem to receive and make calls
      </div>
      <div className={styles.EmptyModems__Buttons}>
        <Button
          variant={'greenOutlined'}
          text={'Activate modem'}
          size={'md'}
          prefix={<DeviceIcon />}
          onClick={() => navigate(RoutePath[Route.ActivateDevice])}
        />
        <Button
          variant={'greenFilled'}
          text={'Buy modem'}
          size={'md'}
          prefix={<BasketIcon />}
          onClick={() =>
            handleOpenModal({
              type: MODAL_TYPES.BUY_NEW_DEVICE,
            })
          }
        />
      </div>
    </div>
  )
}

const Slide = ({
  device,
}: {
  device: DongleListResponse['dongles'][number]
}) => (
  <div key={device.dongleId} className={styles.Slide}>
    <div className={styles.Slide__Name}>{device.name}</div>
    <div className={styles.Slide__Id}>ID: {device.dongleId}</div>
    <div className={styles.Slide__Statuses}>
      <StatusSubscription
        isTariffPackageActive={device.isTariffPackageActive}
      />
      <StatusNetwork
        isOnline={device.isOnline}
        dongleSignalQualityId={device.dongleSignalQualityId}
      />
    </div>
  </div>
)

const Modems = ({ devices, countOnline, countOffline }: ModemProps) => {
  const navigate = useNavigate()

  return (
    <Card style={{ gridColumn: 'span 2' }}>
      <div className={styles.Modems}>
        <div className={styles.Background}>
          <DeviceIcon />
        </div>
        <div className={styles.Top}>
          <div className={styles.Title}>Modems</div>
          <div className={styles.Stickers}>
            <Sticker variant={'green'} text={`${countOnline} online`} />
            <Sticker variant={'red'} text={`${countOffline} offline`} />
          </div>
        </div>
        {devices.length > 0 ? (
          <>
            <div className={styles.Slider}>
              <div className={styles.Slider__Background} />
              <Swiper
                spaceBetween={16}
                slidesPerView={'auto'}
                style={{ marginLeft: 0, marginRight: 24, paddingRight: 24 }}
              >
                {devices.map((device) => (
                  <SwiperSlide style={{ width: 171 }}>
                    <Slide device={device} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className={styles.Button}>
              <SeeAllButton
                onClick={() => navigate(RoutePath[Route.DeviceList])}
              />
            </div>
          </>
        ) : (
          <EmptyModems />
        )}
      </div>
    </Card>
  )
}

export default Modems
