import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSnackbar } from '../../../../../app/hooks/useSnackbar'
import { RoutePath } from '../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../config/routes/enums/route'
import { SNACKBAR_TYPES } from '../../../../../layouts/snackbar/SnackbarProvider'
import { orderCreateDatagate } from '../../../../../sdk/datagates/api/order'
import { TARIFF_PACKAGE_ID } from '../../../../../sdk/datagates/types/order/_common'
import { OrderCreateResponse } from '../../../../../sdk/datagates/types/order/_crud/create'
import { USER_TYPE_IDS } from '../../../../../sdk/datagates/types/user/_crud/get'
import { ORDER_TYPES } from '../../../../../sdk/hooks/use-order/useOrder'
import { getPhoneString } from '../../../../../shared/lib/utils/libphonenumber/create-phone-string'
import {
  PurchaseStepsFormType,
  PurchaseStepsMessages,
} from '../components/purchase-steps/PurchaseSteps'

type Props = {
  formData?: PurchaseStepsFormType
  monthPlanCount: number
  annualPlanCount: number
}

export const useCreateOrder = ({
  formData,
  monthPlanCount,
  annualPlanCount,
}: Props) => {
  const [createOrderData, setCreateOrderData] = useState<
    OrderCreateResponse | undefined
  >(undefined)
  const [createOrderLoading, setCreateOrderLoading] = useState<boolean>(false)
  const [createOrderError, setCreateOrderError] = useState<boolean>(false)
  const navigate = useNavigate()

  const createOrder = async (withRedirect: boolean = true) => {
    try {
      setCreateOrderLoading(true)

      if (!formData) throw new Error()

      const isOrganization =
        formData?.accountType === USER_TYPE_IDS.ORGANIZATION.toString()

      const phone = isOrganization ? formData.companyPhone : formData.userPhone

      const { data } = await orderCreateDatagate({
        order_type_id: ORDER_TYPES.DEVICES_WITH_SUBSCRIPTION,
        first_name: formData.firstName,
        last_name: formData.lastName,
        billing_email: formData.billingEmail,
        country_id: +formData.countryId,
        phone: phone as string,
        region: formData.region,
        city: formData.city,
        street_address1: formData.streetAddress1,
        street_address2: formData.streetAddress2,
        postal_code: formData.postalCode,
        order_dongles: [
          { tariff_package_id: TARIFF_PACKAGE_ID.MONTH, count: monthPlanCount },
          {
            tariff_package_id: TARIFF_PACKAGE_ID.ANNUAL,
            count: annualPlanCount,
          },
        ],
        is_update_profile: true,
        delivery_provider_id: 1,
        ...(formData.accountType === USER_TYPE_IDS.ORGANIZATION.toString()
          ? {
              legal_country_id: formData.legalCountryId
                ? +formData.legalCountryId
                : null,
              legal_street_address1: formData.legalStreetAddress1,
              legal_street_address2: formData.legalStreetAddress2,
              legal_postal_code: formData.legalPostalCode,
              legal_region: formData.legalRegion,
              legal_city: formData.legalCity,
            }
          : {}),
      })

      setCreateOrderData(data)

      withRedirect && navigate(RoutePath[Route.Orders])

      return data.orderId
    } catch (e) {
      setCreateOrderError(true)
    } finally {
      setCreateOrderLoading(false)
    }
  }

  return {
    createOrder,
    createOrderData,
    createOrderLoading,
    createOrderError,
  }
}
