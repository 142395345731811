import React, { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { PhoneMultipleSettings } from '../_components/phone-multiple-settings/PhoneMultipleSettings'
import { Source } from '../_hooks/use-add-source/useAddSource'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { blobCreateDatagate } from '../../../sdk/datagates/api/blob'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { formatPhoneNumber } from '../../../sdk/formatters/format-phone-number'
import { useDevices } from '../../../sdk/hooks/use-devices/useDevices'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { SelectSearchField } from '../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { blobToBase64 } from '../../../shared/lib/utils/blob-to-base-64/blobToBase64'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { SelectSearchOption } from '../../../shared/ui-kit-2/inputs/select-search/SelectSearch'
import { AudioUpload } from '../../../shared/ui-kit-3/components/AudioUpload'
import { DeviceSelector } from '../../../shared/ui-kit-3/components/DeviceSelector'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

export const CallsAutoResponseRulesModal = () => {
  const {
    props: {
      rule: propsRule,
      handleFetchRules,
      handleUpdateRules,
      handleAddRule,
      handleUpdateRule,
    },
    handleHideModal,
  } = useModal()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired } = useFormRules()
  const { resolveBackendError } = useBackendErrorCodes()

  const [rule, setRule] = useState(propsRule)
  const [sources, setSources] = useState<Source[]>(
    rule?.call_scenario_phones
      ? rule?.call_scenario_phones?.map(({ phone }: { phone: string }) => {
          const formattedPhone = formatPhoneNumber(phone)

          return {
            sourceName: formattedPhone,
            sourceType: 'phone',
            sourceValue: formattedPhone,
          }
        })
      : [],
  )
  const { devices, loading: devicesLoading } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    brandId: 2,
    callTypeId: 2,
  })
  const [allPhones, setAllPhones] = useState<boolean>(sources.length === 0)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [errors, setErrors] = useState<Record<string, string | boolean> | null>(
    null,
  )
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const dongleOptions: SelectSearchOption[] = useMemo(
    () =>
      devices.map((d) => {
        return {
          key: d.dongleId,
          label: d.name,
          inputLabel: d.name,
          value: d.dongleId.toString(),
        }
      }),
    [devices],
  )

  const handleSubmit = async (data: any) => {
    setSubmitLoading(true)

    try {
      if (!uploadedFile && !rule?.file?.cdn_url) {
        setErrors((prev) => ({ uploadedFile: prev?.uploadedFile || true }))
        setSubmitLoading(false)
        return
      }

      if (errors?.uploadedFile) {
        setSubmitLoading(false)
        return
      }

      const audioBase64 = uploadedFile
        ? await blobToBase64(
            new Blob([uploadedFile], { type: uploadedFile.type }),
          )
        : ''
      const contactIds = sources
        .filter(({ sourceType }) => sourceType === 'contacts')
        .map(({ sourceValue }) => +sourceValue)
      const contactGroupIds = sources
        .filter(({ sourceType }) => sourceType === 'contact-groups')
        .map(({ sourceValue }) => +sourceValue)
      const phones = sources
        .filter(({ sourceType }) => sourceType === 'phone')
        .map(({ sourceValue }) => `+${sourceValue}`)

      const tmpFile = await blobCreateDatagate({
        file_base64: audioBase64,
        file_name: uploadedFile?.name,
        scenario: 'SCENARIO_CONVERT_TO_DONGLE_AUDIO_AND_UPLOAD_TMP',
      })

      if (!tmpFile) {
        setErrors({
          uploadedFile: true,
        })

        return
      }

      if (rule) {
        await handleUpdateRule({
          callScenarioId: rule.call_scenario_id,
          contactIds,
          contactGroupIds,
          phones,
          ...(data.name !== rule.name ? { name: data.name } : {}),
          ...(audioBase64 && uploadedFile
            ? { tmpFileId: tmpFile?.data?.tmpFileId }
            : {}),
        })

        handleUpdateRules()
      } else {
        await handleAddRule({
          dongleId: +data.dongleId,
          name: data.name,
          tmpFileId: tmpFile?.data?.tmpFileId,
          audioFileName: uploadedFile?.name,
          contactIds,
          contactGroupIds,
          phones,
        })

        handleFetchRules()
      }

      handleHideModal()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      title={rule?.name || 'Create rule'}
      limitedWidth={false}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          name: rule?.name,
          dongleId:
            String(rule?.dongle_id) ??
            (dongleOptions.length === 1 ? dongleOptions[0].key : undefined),
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={styles.Form}>
            <InputField
              name={'name'}
              validate={ruleRequired()}
              markAsRequired={true}
              label={'Name'}
              placeholder={'Name of rule'}
            />
            <DeviceSelector
              devices={devices}
              name="dongleId"
              validate={!rule ? ruleRequired() : undefined}
              options={dongleOptions}
              label={'Modem'}
              placeholder={'Modem'}
              markAsRequired={true}
              dropdownElementsInView={12}
              disabled={rule}
              defaultInputValue={
                rule?.dongle?.name ??
                (dongleOptions.length === 1
                  ? dongleOptions[0].label
                  : undefined)
              }
            />
            <PhoneMultipleSettings
              title="Numbers"
              sources={sources}
              setSources={setSources}
              phoneSourceVariant={'all'}
              error={errors?.phones as boolean}
              allPhones={allPhones}
              setAllPhones={setAllPhones}
            />
            <AudioUpload
              cdnUrl={rule?.file?.cdn_url}
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              handleClear={() => {
                setRule((prev: any) => ({
                  ...prev,
                  file: {
                    cdn_url: null,
                  },
                }))
              }}
              errors={errors}
              setErrors={setErrors}
            />

            {typeof errors?.uploadedFile === 'string' && (
              <div className={styles.FileError}>{errors?.uploadedFile}</div>
            )}

            <div className={styles.Submit}>
              <Button
                type={'submit'}
                text={rule ? 'Update' : 'Create'}
                loading={submitLoading}
                disabled={submitLoading}
              />
            </div>
          </form>
        )}
      />
    </Drawer>
  )
}
