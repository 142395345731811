export enum callType {
  incoming,
  outgoing,
}

export interface SipType {
  sipUser: string | null
  from: {
    name: string | null
    phoneNumber: string | null
    imeiManufacture: string | null
  }
  to: {
    name: string | null
    phoneNumber: string | null
  }
  type: callType | null
  sip: {
    makeCall: () => void
    answerIncomingCall: () => void
    endCall: () => void
    ua: () => void
    hasOutgoingCall: () => void
    hasIncomingCall: () => void
    callHasAcceptedByOtherSide: () => void
  }
}
