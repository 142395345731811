import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { isObject } from '../../../../lib/utils/is-object/is-object'
import { TableHeaderProps } from '../components/TableHeader/TableHeader'

interface UseTableFiltersProps {
  queryIds: Array<string | number>
  defaultFilterValues?: Record<string, any>[]
}

export const useTableFilters = ({
  queryIds,
  defaultFilterValues,
}: UseTableFiltersProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [filters, setFilters] = useState<Record<string, any>>(() => {
    const initialFilters = queryIds.reduce(
      (acc, colId) => {
        const paramValue = searchParams.get(colId.toString())
        acc[colId] = paramValue ? JSON.parse(paramValue) : null
        return acc
      },
      {} as Record<string, any>,
    )

    if (defaultFilterValues) {
      defaultFilterValues.forEach((filter) => {
        if (!initialFilters[filter.name]) {
          initialFilters[filter.name] = filter.value
        }
      })
    }

    return initialFilters
  })

  const [debouncedFilters, setDebouncedFilters] = useState(filters)

  const handleChangeFilters: TableHeaderProps['handleChangeFilters'] = (
    colId,
    value,
  ) => {
    setFilters((prev) => {
      const prevValue = prev[colId]

      if (isObject(value)) {
        const newValue = isObject(prevValue) ? prevValue : {}
        return {
          ...prev,
          [colId]: {
            ...newValue,
            ...value,
          },
        }
      }

      return {
        ...prev,
        page: 1,
        [colId]: value,
      }
    })
  }

  const updateSearchParams = useCallback(
    _.debounce((updatedFilters) => {
      const newParams = new URLSearchParams(searchParams)

      Object.entries(updatedFilters).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          newParams.set(key, JSON.stringify(value))
        } else {
          newParams.delete(key)
        }
      })

      setSearchParams(newParams)
    }, 300),
    [searchParams, setSearchParams],
  )

  const handleSetGetFilterParams = () => {
    const urlFilters = queryIds.reduce(
      (acc, colId) => {
        const paramValue = searchParams.get(colId.toString())
        acc[colId] = paramValue ? JSON.parse(paramValue) : null
        return acc
      },
      {} as Record<string, any>,
    )

    setFilters(urlFilters)
  }

  const updateFilters = useCallback(
    _.debounce((newFilters) => {
      setDebouncedFilters(newFilters)
    }, 300),
    [],
  )

  const handlePageChange = (page: number, limit: number) => {
    if (filters.page !== page) {
      handleChangeFilters('page', page)
    }

    if (filters.limit !== limit) {
      handleChangeFilters('limit', limit)
    }
  }

  useEffect(() => {
    updateSearchParams(filters)
    updateFilters(filters)
  }, [filters])

  return {
    filters,
    debouncedFilters,
    handleSetGetFilterParams,
    handleChangeFilters,
    handlePageChange,
  }
}
