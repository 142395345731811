import React, { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Russian as RussianLocale } from 'flatpickr/dist/l10n/ru'
import Flatpickr from 'react-flatpickr'
import { useSiteVersion } from '../../../../app/hooks/useSiteVersion'
import { ReactComponent as CalendarIcon } from './assets/icons/calendar.svg'
import { ReactComponent as ClearIcon } from './assets/icons/clear.svg'
import { DatepickerOptionalFieldProps } from './DatePickerOptional.types'
import styles from './styles.module.scss'

export const DatepickerOptional: FC<DatepickerOptionalFieldProps> = ({
  value,
  onChange,
  title = 'Add date',
  label,
  placeholder,
  minDateToday,
  enableSeconds = true,
  error,
}) => {
  const flatpickrRef = useRef<any>(null)
  const { siteVersion } = useSiteVersion()
  const [isOpen, setIsOpen] = useState<boolean>(!!value)
  const [selectedDate, setSelectedDate] = useState<Date | null>(value || null)

  const now = new Date()

  const handleChangeIsOpen = () => {
    if (isOpen) {
      onChange(undefined)
      setSelectedDate(null)
    }
    setIsOpen((prev) => !prev)
  }

  const clearDatepicker = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear()
    }
    onChange(undefined)
    setSelectedDate(null)
  }

  const openCalendar = () => flatpickrRef.current.flatpickr.open()

  useEffect(() => {
    if (value) {
      setIsOpen(true)
      setSelectedDate(value)
    }
  }, [value])

  return (
    <div
      className={styles.DatePickerOptional}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.Header} onClick={handleChangeIsOpen}>
        <div className={styles.HeaderTitle}>
          <CalendarIcon />
          <div>{title}</div>
        </div>
        <div className={styles.AddOrRemove}>{isOpen ? '—' : '+'}</div>
      </div>
      {isOpen && (
        <div className={styles.Container}>
          {label && <div className={styles.Label}>{label}</div>}

          <div className={styles.InputContainer}>
            <Flatpickr
              ref={flatpickrRef}
              className={clsx(
                styles.Datepicker,
                error && styles.Datepicker__error,
              )}
              options={{
                enableTime: true,
                dateFormat: enableSeconds ? 'd.m.Y H:i:S' : 'd.m.Y H:i',
                time_24hr: true,
                disableMobile: true,
                enableSeconds,
                allowInput: false,
                minDate: minDateToday ? 'today' : '',
                defaultDate: value,
                minTime:
                  selectedDate &&
                  selectedDate.toDateString() === now.toDateString()
                    ? now
                    : '00:00',
                onChange: (dates) => {
                  const newDate = dates[0]
                  setSelectedDate(newDate)
                  onChange(newDate)
                },
                ...(siteVersion === 'ru' ? { locale: RussianLocale } : {}),
              }}
              placeholder={placeholder ?? 'Select date'}
            />

            <div className={styles.Icons}>
              <CalendarIcon
                onClick={openCalendar}
                className={styles.flatpickr_icons_calendar}
              />
              {!!value && (
                <ClearIcon
                  onClick={clearDatepicker}
                  className={styles.flatpickr_icons_clear}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
