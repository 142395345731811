import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { ReactComponent as WireTransferSuccessIcon } from '../../../assets/icons/wire-transfer-success.svg'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

const WireTransferSuccessModalMessages = defineMessages({
  title: {
    id: 'WireTransferSuccessModalMessages.title',
    defaultMessage: 'We will be expecting your payment',
  },
  subTitle: {
    id: 'WireTransferSuccessModalMessages.subTitle',
    defaultMessage:
      'Our manager will send you payment details to your email. After payment we will update the status of your order and send you the modem',
  },
  toOrders: {
    id: 'WireTransferSuccessModalMessages.toOrders',
    defaultMessage: 'My orders',
  },
  close: {
    id: 'WireTransferSuccessModalMessages.close',
    defaultMessage: 'Close',
  },
})

export const WireTransferSuccessModal = () => {
  const { handleHideModal, props } = useModal()

  const { onClose, isDonglePay } = props

  const intl = useIntl()

  const handleToOrders = async () => {
    onClose?.()
    handleHideModal()
  }

  return (
    <Drawer isOpen={true} close={handleToOrders}>
      <div className={styles.Content}>
        <WireTransferSuccessIcon />
        <div className={styles.Content_title}>
          {intl.formatMessage(WireTransferSuccessModalMessages.title)}
        </div>
        <div className={styles.Content__subTitle}>
          {intl.formatMessage(WireTransferSuccessModalMessages.subTitle)}
        </div>
      </div>
      <Button
        additionalClassNames={[styles.Button]}
        text={intl.formatMessage(
          isDonglePay
            ? WireTransferSuccessModalMessages.close
            : WireTransferSuccessModalMessages.toOrders,
        )}
        onClick={handleToOrders}
      />
    </Drawer>
  )
}
