import React, { useContext, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useModal } from '../../../../../../app/hooks/useModal'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../config/routes/enums/route'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { OrderModalMessages } from '../../../../../../layouts/modals/OrderModal/OrderModal'
import { TARIFF_PACKAGE_ID } from '../../../../../../sdk/datagates/types/order/_common'
import { formatPrice } from '../../../../../../sdk/formatters/format-price'
import { useTariffPackages } from '../../../../../../sdk/hooks/use-tariff-packages/useTariffPackages'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import { TextButton } from '../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import {
  PURCHASE_STEPS,
  PurchaseProcessPageContext,
} from '../../context/PurchaseProcessPageContextProvider'
import { PurchaseDetailFedex } from '../purchase-detail-fedex/PurchaseDetailFedex'
import { PurchaseDetail } from '../purchase-detail/PurchaseDetail'
import styles from './styles.module.scss'

const PurchaseDetailsMessages = defineMessages({
  title: {
    id: 'PurchaseDetailsMessages.title',
    defaultMessage: '{complete} all steps to proceed to payment',
  },
  complete: {
    id: 'PurchaseDetailsMessages.complete',
    defaultMessage: 'Complete',
  },
  subscription: {
    id: 'PurchaseDetailsMessages.subscription',
    defaultMessage: '{plan} subscription',
  },
  subscriptionMobile: {
    id: 'PurchaseDetailsMessages.subscriptionMobile',
    defaultMessage: '{plan}',
  },
  modem: {
    id: 'PurchaseDetailsMessages.modem',
    defaultMessage: 'modem',
  },
  delivery: {
    id: 'PurchaseDetailsMessages.delivery',
    defaultMessage: 'Delivery',
  },
  edit: {
    id: 'PurchaseDetailsMessages.edit',
    defaultMessage: 'Edit',
  },
  total: {
    id: 'PurchaseDetailsMessages.total',
    defaultMessage: 'Total:',
  },
  willBeCalculated: {
    id: 'PurchaseDetailsMessages.willBeCalculated',
    defaultMessage: 'Will be calculated',
  },
  checkout: {
    id: 'PurchaseDetailsMessages.checkout',
    defaultMessage: 'Checkout',
  },
})

export const PurchaseDetails = () => {
  const {
    order,
    monthPlanCount,
    annualPlanCount,
    setMonthPlanCount,
    setAnnualPlanCount,
    currentStep,
    deliveryPriceWasFetched,
    totalPrice,
    setTotalPrice,
    deliveryPrice,
    setDeliveryPrice,
  } = useContext(PurchaseProcessPageContext)

  const intl = useIntl()
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const { handleOpenModal } = useModal()
  const { getTariffPackageTranslation, getTariffPackagePrice } =
    useTariffPackages()
  const isMobileXL = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK_XL)

  const isPaymentStep = useMemo(
    () => currentStep === PURCHASE_STEPS.PAYMENT,
    [currentStep],
  )

  useEffect(() => {
    if (order) {
      const deliveryPriceFromOrder =
        order.deliveryPrice !== 0
          ? formatPrice(order.deliveryPrice)
          : intl.formatMessage(OrderModalMessages.free)

      const totalPriceFromOrder = formatPrice(order.totalPrice)

      setTotalPrice(totalPriceFromOrder)
      setDeliveryPrice(deliveryPriceFromOrder)
      setMonthPlanCount(
        order.orderDongles.filter(
          (od) => od.tariffPackageId === TARIFF_PACKAGE_ID.MONTH,
        ).length,
      )
      setAnnualPlanCount(
        order.orderDongles.filter(
          (od) => od.tariffPackageId === TARIFF_PACKAGE_ID.ANNUAL,
        ).length,
      )
    }
  }, [order, deliveryPriceWasFetched])

  useEffect(() => {
    const params = searchParams.getAll('plan_count')

    try {
      if (params.length > 0) {
        params.forEach((p) => {
          const [tariffPackageIdStr, planCountStr] = p.split('_')
          const tariffPackageId = Number(tariffPackageIdStr)
          const planCount = Number(planCountStr)
          if (tariffPackageId === TARIFF_PACKAGE_ID.MONTH) {
            setMonthPlanCount(planCount)
          } else if (tariffPackageId === TARIFF_PACKAGE_ID.ANNUAL) {
            setAnnualPlanCount(planCount)
          }
        })
      }
    } catch (e) {
      navigate(RoutePath[Route.NotFound])
    }
  }, [searchParams])

  const handleOpenBuyNewDeviceModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.BUY_NEW_DEVICE,
      props: {
        monthPlanCount,
        annualPlanCount,
      },
    })
  }

  const monthDetailValue = `${monthPlanCount} ${isMobileXL ? '' : intl.formatMessage(PurchaseDetailsMessages.modem)} x ${formatPrice(getTariffPackagePrice(TARIFF_PACKAGE_ID.MONTH, true))}`
  const annualDetailValue = `${annualPlanCount} ${isMobileXL ? '' : intl.formatMessage(PurchaseDetailsMessages.modem)} x ${formatPrice(getTariffPackagePrice(TARIFF_PACKAGE_ID.ANNUAL, false))}`

  const PurchaseDeliveryComponent = () => {
    const deliveryPriceWasFetchedWithStep =
      deliveryPriceWasFetched || currentStep === PURCHASE_STEPS.PAYMENT
    const Component = deliveryPriceWasFetchedWithStep
      ? PurchaseDetailFedex
      : PurchaseDetail

    return (
      <Component
        title={intl.formatMessage(PurchaseDetailsMessages.delivery)}
        value={
          deliveryPriceWasFetchedWithStep && deliveryPrice !== '---'
            ? deliveryPrice
            : intl.formatMessage(PurchaseDetailsMessages.willBeCalculated)
        }
      />
    )
  }

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.NestedContainer}>
        <div className={styles.Title}>
          {currentStep === PURCHASE_STEPS.PAYMENT
            ? intl.formatMessage(PurchaseDetailsMessages.checkout)
            : intl.formatMessage(PurchaseDetailsMessages.title, {
                complete: (
                  <span>
                    {intl.formatMessage(PurchaseDetailsMessages.complete)}
                  </span>
                ),
              })}
        </div>

        <div className={styles.DetailList}>
          {monthPlanCount > 0 && (
            <PurchaseDetail
              title={intl.formatMessage(
                isMobileXL
                  ? PurchaseDetailsMessages.subscriptionMobile
                  : PurchaseDetailsMessages.subscription,
                {
                  plan: getTariffPackageTranslation(TARIFF_PACKAGE_ID.MONTH),
                },
              )}
              value={monthDetailValue}
            />
          )}
          {annualPlanCount > 0 && (
            <PurchaseDetail
              title={intl.formatMessage(
                isMobileXL
                  ? PurchaseDetailsMessages.subscriptionMobile
                  : PurchaseDetailsMessages.subscription,
                {
                  plan: getTariffPackageTranslation(TARIFF_PACKAGE_ID.ANNUAL),
                },
              )}
              value={annualDetailValue}
            />
          )}
          <PurchaseDeliveryComponent />
        </div>
      </div>

      <div className={styles.TotalContainer}>
        {!isPaymentStep && (
          <TextButton
            text={intl.formatMessage(PurchaseDetailsMessages.edit)}
            icon={'edit'}
            onClick={handleOpenBuyNewDeviceModal}
            additionalClassNames={[styles.EditBtn]}
          />
        )}

        <div className={styles.Total}>
          <div>{intl.formatMessage(PurchaseDetailsMessages.total)}</div>
          <div>{totalPrice}</div>
        </div>
      </div>
    </Card>
  )
}
