import React, { useMemo, useState } from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { useSnackbar } from '../../../../../app/hooks/useSnackbar'
import { CommonFormMessages } from '../../../../../config/intl/common-messages/common-form-messages/CommonFormMessages'
import { dongleUpdateDatagate } from '../../../../../sdk/datagates/api/dongle'
import { DongleGetResponse } from '../../../../../sdk/datagates/types/dongle/_crud/get'
import { formatPrice } from '../../../../../sdk/formatters/format-price'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { Switcher } from '../../../../../shared/ui-kit-2/inputs/switcher/Switcher'
import { SNACKBAR_TYPES } from '../../../../snackbar/SnackbarProvider'
import { DeviceDetailsModalMessages } from '../../messages/DeviceDetailsModalMessages'
import styles from './styles.module.scss'
import {TextButton} from "../../../../../shared/ui-kit-2/inputs/text-button/TextButton";

interface SubscriptionsProps {
  getTariffPackagePrice: (
    tariffPackageId?: number | undefined,
    withDevice?: boolean,
    count?: number,
  ) => number | undefined
  getTariffPackageTranslation: (tariffPackageId?: number | undefined) => string
  device: DongleGetResponse
  tariffPackageEnable: boolean
  setIsPayDeviceOpen: (isOpen: boolean) => void
}
export const Subscription = (props: SubscriptionsProps) => {
  const {
    getTariffPackageTranslation,
    getTariffPackagePrice,
    device,
    tariffPackageEnable,
    setIsPayDeviceOpen
  } = props

  const subscriptionType = useMemo(
    () => getTariffPackageTranslation(device?.tariffPackageId),
    [],
  )
  const subscriptionPrice = useMemo(
    () => getTariffPackagePrice(device?.tariffPackageId),
    [device],
  )
  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [activeAutoSubscription, setActiveAutoSubscription] = useState<boolean>(
    device.isTariffPackageEnable,
  )
  const handleSwitchAutoRenewal = async (dongleId: number) => {
    setIsDisabled(true)
    setActiveAutoSubscription((prevState) => !prevState)
    try {
      const urlParams = [{ name: 'dongle_id', value: dongleId.toString() }]
      await dongleUpdateDatagate(
        {
          is_tariff_package_enabled: !activeAutoSubscription,
        },
        urlParams,
      )
      setIsDisabled(false)
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(CommonFormMessages.fetchError),
      })
      setTimeout(() => setIsDisabled(false), 3000)
      setActiveAutoSubscription(device.isTariffPackageEnable)
    }
  }

  return (
    <div className={clsx(styles.Subscription, !tariffPackageEnable && styles.Subscription_noSubscription)}>
      <div className={styles.MainCol}>
        <div className={styles.Title}>
          Subscription
        </div>
        {tariffPackageEnable && (
          <div className={styles.Row}>
            <Switcher
              onChange={() => handleSwitchAutoRenewal(device.dongleId)}
              checked={activeAutoSubscription}
              disabled={isDisabled}
            />
            <div className={styles.Label}>
              {intl.formatMessage(DeviceDetailsModalMessages.autoRenewal)}
            </div>
          </div>
        )}
      </div>
      {tariffPackageEnable ? (
        <div className={styles.Cols}>
          <div className={styles.Col}>
            <div className={styles.Col__Name}>
              Type
            </div>
            <div className={styles.Col__Value}>
              {subscriptionType}
            </div>
          </div>
          <div className={styles.Col}>
            <div className={styles.Col__Name}>
              Cost
            </div>
            <div className={styles.Col__Value}>
              {formatPrice(subscriptionPrice)}
            </div>
          </div>
          {device?.tariffPackageEnd && (
            <div className={styles.Col}>
              <div className={styles.Col__Name}>
                Next payment
              </div>
              <div className={styles.Col__Value}>
                {formatDate(device.tariffPackageEnd, 0, 'date')}
              </div>
            </div>
          )}
          <div className={styles.Col}>
            <TextButton
              icon={'manage'}
              text={'Manage'}
              additionalClassNames={[styles.EditButton]}
              onClick={() => setIsPayDeviceOpen(true)}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.NoSubscriptionText}>
            You don't have any subscriptions
          </div>
          <TextButton
            icon={'manage'}
            text={'Buy subscription'}
            additionalClassNames={[styles.EditButton]}
            onClick={() => setIsPayDeviceOpen(true)}
          />
        </>
      )}
    </div>
  )
}
