import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'

const DONGLE_OUTGOING_URL = 'http://192.168.8.1:8080';

export interface DongleCheckStatusRequest {}

export interface DongleCheckStatusBackendResponse {
  id: string
  wg_connected: boolean
}

export interface DongleCheckStatusResponse {
  hash: string
  wgConnected: boolean
}

export const dongleCheckStatusDatagate = wrapApiRequest<
  DongleCheckStatusRequest,
  DongleCheckStatusBackendResponse,
  DongleCheckStatusResponse
>(
  'info',
  (item) => {
    const { id, wg_connected } = item;

    return {
      hash: id,
      wgConnected: wg_connected,
    }
  },
  'GET',
  DONGLE_OUTGOING_URL,
)

export interface DongleAutoActivationRequest {
  hash: string | null
  order_dongle_id?: string
}

export interface DongleAutoActivationBackendResponse {
  data: {
    dongle_id: string
  }
}

export interface DongleAutoActivationResponse {
  dongleId: string
}

export const dongleAutoActivationDatagate = wrapApiRequest<
  DongleAutoActivationRequest,
  DongleAutoActivationBackendResponse,
  DongleAutoActivationResponse
>(
  'activation',
  (item) => {
    const { dongle_id } = item.data

    return {
      dongleId: dongle_id,
    }
  },
  'POST',
  DONGLE_OUTGOING_URL,
)
