import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { RoutePath } from '../../../../config/routes/constants/routePath';
import { Route } from '../../../../config/routes/enums/route';
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper';
import {
  dongleAutoActivationDatagate,
  dongleCheckStatusDatagate,
} from '../../../../sdk/datagates/api/device-auto-activation';
import Button from '../../../../shared/ui-kit-3/components/Button/Button';
import { Loader } from '../../../../shared/ui-kit/data-display/loader/Loader';
import { SubscriptionSelector } from '../manual-device-activation/components/step-connect-device/components/subscription-selector/SubscriptionSelector';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/v2/ic-arrow-right.svg';
import styles from './styles.module.scss';
import {useSearchParams} from "react-router-dom";

enum Steps {
  checkingDongleStatus = 'checkingDongleStatus',
  subscribeSelection = 'subscribeSelection',
  dongleActivation = 'dongleActivation',
}

export const ConnectToDonglePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [step, setStep] = useState<Steps>(Steps.checkingDongleStatus);
  const [loading, setLoading] = useState<boolean>(true);
  const [subscription, setSubscription] = useState<string | null>(null);
  const [subscriptionType, setSubscriptionType] = useState<string | number | null>(null);

  const isLocalhost = window.location.hostname.includes('localhost');

  const monthlyAvailableId = searchParams.get('monthlyAvailableId') ?? null;
  const annualAvailableId = searchParams.get('annualAvailableId') ?? null;
  const monthlyAvailableCount = searchParams.get('monthlyAvailableCount') ?? 0;
  const annualAvailableCount = searchParams.get('annualAvailableCount') ?? 0;
  const hash = searchParams.get('hash') ?? null;

  const options = [
    { id: 'monthly', label: 'Monthly Subscription' },
    { id: 'annual', label: 'Annual Subscription' },
  ];

  const handleError = () => {
    setLoading(false);
    // @ts-ignore
    window.location.href = `https://${window?.env?.HOSTNAME}${RoutePath[Route.DeviceConnectionStatus]}?from=auto`;
  };

  const handleCheckingDongleStatus = async () => {
    try {
      const { data } = await dongleCheckStatusDatagate();

      // @ts-ignore
      if (data?.wgConnected) {
        setStep(Steps.subscribeSelection);
      } else {
        handleError();
      }
    } catch {
      handleError();
    }
  };

  const handleSubscribeSelection = async () => {
    if (monthlyAvailableCount > 0 && annualAvailableCount > 0) {
      setLoading(false);
    } else {
      const source =
        monthlyAvailableCount > 0
          ? monthlyAvailableId
          : annualAvailableCount > 0
            ? annualAvailableId
            : undefined;

      if (source) {
        // @ts-ignore
        setSubscription(source);
      } else {
        handleError();
      }
    }
  };

  const handleSubscribeSelectionSubmit = async () => {
    setLoading(true);

    try {
      if (monthlyAvailableCount > 0 ?? annualAvailableCount > 0 ?? subscriptionType) {
        setSubscription(subscriptionType === 'monthly' ? monthlyAvailableId : annualAvailableId);
      }

      setStep(Steps.dongleActivation);
    } catch {
      handleError();
    }
  }

  const handleDongleActivation = async () => {
    try {
      const { data } = await dongleAutoActivationDatagate({
        hash,
        ...(subscription ? { order_dongle_id: subscription } : {}),
      });

      // @ts-ignore
      window.location.href = `https://${window?.env?.HOSTNAME}${RoutePath[Route.DeviceConnectionStatus]}?dongleId=${data?.dongleId}&from=auto`;
    } catch {
      handleError();
    }
  };

  const NavigateToBack = () => {
    if (!isLocalhost) {
      // @ts-ignore
      window.location.href = `https://${window?.env?.HOSTNAME}${RoutePath[Route.ActivateDevice]}?dongleId=${data?.dongleId}&from=auto`;
    } else {
      navigate(RoutePath[Route.AutoDeviceActivation]);
    }
  }

  useEffect(() => {
    switch (step) {
      case Steps.checkingDongleStatus:
        handleCheckingDongleStatus();
        break;

      case Steps.subscribeSelection:
        handleSubscribeSelection();
        break;

      case Steps.dongleActivation:
        handleDongleActivation();
        break;

      default:
        return;
    }
  }, [step]);

  return (
    <PageWrapper>
      <div className={styles.Card}>
        <div className={styles.Back} onClick={() => NavigateToBack()}>
          <div className={styles.Back__Icon}>
            <ArrowRightIcon />
          </div>
          <span>
            Go back
          </span>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.Subscriptions}>
            <div className={styles.Subscriptions__Title}>
              Select a subscription for this device, it will take effect upon activation
            </div>
            <div className={styles.Content}>
              <div className={styles.Available}>
                <div className={styles.Available__Item}>
                  <div className={styles.Available__Text}>
                    Monthly Available :
                  </div>
                  <div className={styles.Available__Count}>
                    {` ${monthlyAvailableCount ?? 0}`}
                  </div>
                </div>
                <div className={styles.Available__Item}>
                  <div className={styles.Available__Text}>
                    Annual Available :
                  </div>
                  <div className={styles.Available__Count}>
                    {` ${annualAvailableCount ?? 0}`}
                  </div>
                </div>
              </div>
              <SubscriptionSelector
                selected={subscriptionType}
                setSelected={setSubscriptionType}
                options={options}
                row
              />
              <Button
                onClick={handleSubscribeSelectionSubmit}
                className={styles.Button}
                variant="greenFilled"
                text="Okay"
                size="xl"
                disabled={!subscriptionType}
              />
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
