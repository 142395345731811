import {Switcher} from "../../../../../shared/ui-kit-2/inputs/switcher/Switcher";
import React, {FormEventHandler} from "react";
import { ReactComponent as SettingsIcon } from '../../../../../assets/icons/settings-v2.svg'
import styles from './styles.module.scss';
import {DeviceDetailsModalMessages} from "../../messages/DeviceDetailsModalMessages";
import { ReactComponent as WarningIcon } from '../../../../../assets/icons/danger-triangle.svg'
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/v2/ic-copy.svg'
import {useIntl} from "react-intl";
import clsx from 'clsx'
import {SNACKBAR_TYPES} from "../../../../snackbar/SnackbarProvider";
import {copyToClipboard} from "../../../../../shared/lib/utils/copy-to-clipboard/copyToClipboard";
import {useSnackbar} from "../../../../../app/hooks/useSnackbar";


interface IDetailsCard {
  title: string,
  onSettings?: () => void,
  switcher: {
    status: boolean
    onChange: FormEventHandler<HTMLInputElement>
    disabled: boolean
  }
  items: Array<{
    label: string,
    value: string,
  }>
  warning?: boolean
}

export const DetailsCard = ({ title, onSettings, switcher, items, warning }: IDetailsCard) => {
  const { status, onChange, disabled } = switcher

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()

  const handleCopy = (string: string) => {
    handleOpenSnackbar({
      type: SNACKBAR_TYPES.success,
      text: intl.formatMessage(DeviceDetailsModalMessages.handleCopy),
    })
    if (string) void copyToClipboard(string)
  }

  return (
    <div className={clsx(styles.DetailsCard)}>
      {warning && (
        <div className={styles.Mask} />
      )}
      <div className={styles.Top}>
        <div className={styles.Title}>
          {title}
        </div>
        {onSettings && (
          <div
            className={styles.Settings}
            onClick={() => onSettings()}
          >
            <SettingsIcon />
          </div>
        )}
        <div className={styles.Switcher}>
          <Switcher
            checked={status}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={styles.Divider} />
      <div className={styles.Bottom}>
        {items.map(({ label, value }) => (
          <div className={styles.Item}>
            <div className={styles.Item__Label}>
              {label}
            </div>
            <div className={styles.Item__Value}>
              <div className={styles.Item__Value__Text}>
                {value ?? '-'}
              </div>
              {value && (
                <button
                  className={styles.Action}
                  onClick={() => handleCopy(value)}
                >
                  <CopyIcon />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      {warning && (
        <div className={styles.Warning}>
          <WarningIcon />
          <div className={styles.Warning__Text}>{intl.formatMessage(DeviceDetailsModalMessages.warning)}</div>
        </div>
      )}
    </div>
  )
}