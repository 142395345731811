import { useEffect, useState } from 'react'
import SOUND_DATA from './sounds/sounds.json'

type SoundKey = keyof typeof SOUND_DATA

const SoundPlayer = () => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null)

  // Храним текущие активные источники (чтобы можно было их останавливать сразу).
  const [activeSources, setActiveSources] = useState<
    Map<SoundKey, AudioBufferSourceNode>
  >(new Map())

  // Предварительно загруженные AudioBuffer’ы, готовые к проигрыванию.
  const [sounds, setSounds] = useState<Map<SoundKey, AudioBuffer>>(new Map())

  // Доп. состояние для звуков, которые должны лупиться с паузой.
  // Т.е. если звук в `loopingSounds`, значит, при завершении мы хотим
  // перезапустить его снова (через паузу), пока его явно не остановили.
  const [loopingSounds, setLoopingSounds] = useState<Set<SoundKey>>(new Set())

  useEffect(() => {
    const context = new (window.AudioContext ||
      (window as any).webkitAudioContext)()
    setAudioContext(context)

    // Разблокировка в iOS/Android
    const unlockAudio = () => {
      if (context.state === 'suspended') {
        context.resume().then(() => console.log('🔊 AudioContext resumed'))
      }
      document.removeEventListener('click', unlockAudio)
    }
    document.addEventListener('click', unlockAudio)

    // Загружаем все звуки из JSON
    const loadSounds = async () => {
      const newSounds = new Map<SoundKey, AudioBuffer>()

      for (const [key, base64Data] of Object.entries(SOUND_DATA)) {
        try {
          const response = await fetch(base64Data) // base64 URL
          const arrayBuffer = await response.arrayBuffer()
          const decodedBuffer = await context.decodeAudioData(arrayBuffer)

          newSounds.set(key as SoundKey, decodedBuffer)
        } catch (error) {
          console.error(`❌ Ошибка загрузки звука "${key}":`, error)
        }
      }

      setSounds(newSounds)
    }

    loadSounds()

    return () => {
      context.close()
    }
  }, [])

  const playSound = (name: SoundKey, volume: number = 1.0) => {
    if (!audioContext) return
    if (!sounds.has(name)) {
      console.warn(`Звук "${name}" ещё не загружен или не найден в JSON.`)
      return
    }

    // Если это один из "должных лупиться" звуков, помечаем его как looping
    if (name === 'ringback' || name === 'ringing') {
      setLoopingSounds((prev) => new Set(prev).add(name))
    }

    const buffer = sounds.get(name)!
    const source = audioContext.createBufferSource()
    source.buffer = buffer

    // Настраиваем громкость
    const gainNode = audioContext.createGain()
    gainNode.gain.value = volume
    source.connect(gainNode)
    gainNode.connect(audioContext.destination)

    source.start()

    setActiveSources((prev) => {
      const newMap = new Map(prev)
      newMap.set(name, source)
      return newMap
    })

    // Когда звук закончится, удалим его из activeSources
    // и, при необходимости, перезапустим (луп).
    source.onended = () => {
      setActiveSources((prev) => {
        const newMap = new Map(prev)
        // Удаляем только если действительно тот же source (чтобы не перетереть
        // если параллельно был запущен новый)
        if (newMap.get(name) === source) {
          newMap.delete(name)
        }
        return newMap
      })

      // Если звук должен лупиться — ставим паузу и запускаем заново
      setLoopingSounds((prevLoopSet) => {
        // Перепроверяем в актуальном состоянии, что звук всё ещё в лупе
        if (prevLoopSet.has(name)) {
          setTimeout(() => {
            // Вновь смотрим, не убрали ли луп за эти 1000 мс
            setLoopingSounds((latestLoopSet) => {
              if (latestLoopSet.has(name)) {
                // Заново запускаем звук
                playSound(name, volume)
              }
              return latestLoopSet
            })
          }, 1500)
        }
        return prevLoopSet
      })
    }
  }

  const stopSound = (name: SoundKey) => {
    if (!audioContext) return

    // Убираем из луп-сета (если там был)
    setLoopingSounds((prev) => {
      const newSet = new Set(prev)
      newSet.delete(name)
      return newSet
    })

    // Останавливаем сам источник, если он есть
    setActiveSources((prev) => {
      const newMap = new Map(prev)
      const source = newMap.get(name)
      if (source) {
        source.stop()
        newMap.delete(name)
      }
      return newMap
    })
  }

  // Остановка всех звуков
  // (очищаем loopingSounds и останавливаем все источники)
  const stopAllSounds = () => {
    setLoopingSounds(new Set())
    setActiveSources((prev) => {
      // @ts-ignore
      for (const source of prev.values()) {
        source.stop()
      }
      return new Map()
    })
  }

  // Проверка, играется ли в данный момент звук
  const isPlaying = (name: SoundKey) => {
    return activeSources.has(name)
  }

  useEffect(() => {
    ;(window as any).playSound = playSound
    ;(window as any).stopSound = stopSound
    ;(window as any).isPlaying = isPlaying
    ;(window as any).stopAllSounds = stopAllSounds
  }, [sounds, audioContext, activeSources, loopingSounds])

  useEffect(() => {
    if (!audioContext) return

    // Периодическое пробуждение звука в Safari
    const keepAlive = setInterval(() => {
      if (audioContext.state === 'suspended') {
        audioContext.resume().then(() => console.log('🔊 AudioContext resumed'))
      }
    }, 5000)

    return () => clearInterval(keepAlive)
  }, [audioContext])

  return null
}

export default SoundPlayer
