import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../app/hooks/useModal'
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg'
import { ReactComponent as CancelIcon } from '../../../../../assets/icons/cancel.svg'
import { ReactComponent as CaretRightIcon } from '../../../../../assets/icons/caret-right.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg'
import { QueryParameter } from '../../../../../sdk/datagates/helpers/_common/wrap-api-request'
import { CallListResponse } from '../../../../../sdk/datagates/types/call/_crud/list'
import { MailingListResponse } from '../../../../../sdk/datagates/types/mailing/_crud/list'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import { CallType } from '../../../../../sdk/hooks/use-calls/useCalls'
import { MAILING_STATUSES } from '../../../../../sdk/hooks/use-mailing-statuses/constants/MailingStatuses'
import { useMailingStatuses } from '../../../../../sdk/hooks/use-mailing-statuses/useMailingStatuses'
import { SmsType } from '../../../../../sdk/hooks/use-smses/useSmses'
import { ObjectEntries } from '../../../../../shared/lib/types/objectEntries'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { parseStringToDate } from '../../../../../shared/lib/utils/parseStringToDate/parseStringToDate'
import { RowGroupType } from '../../../../../shared/ui-kit-2/data-display/table/Table'
import { LC } from '../../../../../tests/e2e/locators'
import { MODAL_TYPES } from '../../../../modals/ModalsProvider'
import styles from './styles.module.scss'

type CallGroupsType = {
  [key: string]: MailingListResponse['mailings']
}

const MailingTableRowsMessages = defineMessages({
  deletingItemName: {
    id: 'MailingTableRowsMessages.deletingItemName',
    defaultMessage: 'the mailing',
  },
  numberCount: {
    id: 'CallTableRows.numberCount',
    defaultMessage: '{count} Number',
  },
  numbersCount: {
    id: 'CallTableRows.numbersCount',
    defaultMessage: '{count} Numbers',
  },
})

export const MailingTableRows = (
  mailings: MailingListResponse['mailings'],
  handleFetch: (props: {
    params?: QueryParameter[] | undefined
    hidden?: boolean | undefined
  }) => Promise<void>,
): RowGroupType[] => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const { getMailingStatusStickerComponent } = useMailingStatuses()

  if (mailings.length === 0) return []

  const handleOpenMailingViewModal = (
    mailingId: number,
    forceAction: 'delete' | 'cancel' | null = null,
  ) => {
    handleOpenModal({
      type: MODAL_TYPES.SMS_MAILING_VIEW,
      props: {
        mailingId,
        handleFetch,
        forceAction,
      },
    })
  }

  return [
    {
      groupHeader: null,
      rows: mailings.map((mailing) => {
        const date = mailing.mailingHistory?.createdAt ?? mailing.scheduledAt

        const dateFormatted = date
          ? `${formatDate(date, 0, 'date')}, ${formatDate(date, 0, 'time')}`
          : '—'

        const getMailingAction = () => {
          switch (mailing.mailingStatusId) {
            case MAILING_STATUSES.IN_PROCESS:
              return null
            case MAILING_STATUSES.PLANNED:
              return (
                <CancelIcon
                  onClick={() =>
                    handleOpenMailingViewModal(mailing.mailingId, 'cancel')
                  }
                />
              )
            default:
              return (
                <TrashIcon
                  onClick={() =>
                    handleOpenMailingViewModal(mailing.mailingId, 'delete')
                  }
                />
              )
          }
        }

        return [
          <div className={styles.SimpleText}>{mailing.name}</div>,
          <div className={styles.Device}>
            <div className={styles.Device__container}>
              <div className={styles.Device__name}>{mailing.dongle.name}</div>
              <div className={styles.Device__phone}>
                {formatPhoneNumber(mailing.dongle.number)}
              </div>
            </div>
            <ArrowRightIcon />
          </div>,
          <div className={styles.SimpleTextWithRightBorder}>
            {mailing.countMailingPhones === 1
              ? intl.formatMessage(MailingTableRowsMessages.numberCount, {
                  count: mailing.countMailingPhones,
                })
              : intl.formatMessage(MailingTableRowsMessages.numbersCount, {
                  count: mailing.countMailingPhones,
                })}
          </div>,
          <div className={styles.MessageText}>{mailing.text}</div>,
          <div className={styles.SimpleText}>{dateFormatted}</div>,
          getMailingStatusStickerComponent(mailing.mailingStatusId),
          <div className={styles.Actions}>
            {getMailingAction()}
            <CaretRightIcon
              onClick={() => handleOpenMailingViewModal(mailing.mailingId)}
            />
          </div>,
        ]
      }),
    },
  ]
}
