import React, {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useClickOutside } from '../../../lib/hooks/useClickOutside'
import { ReactComponent as ClearIcon } from './assets/icons/clear.svg'
import { ReactComponent as ShowIcon } from './assets/icons/show.svg'
import { ReactComponent as SelectedIcon } from './assets/icons/validated.svg'
import styles from './styles.module.scss'

export type SelectSearchOption = {
  key: number | string
  label: string // Displays in option
  inputLabel: string // Displays in input after change submission
  value?: string
  disabled?: boolean
}

export interface SelectSearchProps extends React.HTMLProps<HTMLInputElement> {
  options: SelectSearchOption[]
  label?: string
  error?: string
  defaultInputValue?: string | undefined
  handleAfterSelect?: () => void
  optionCheckedIcon?: boolean
  helperText?: string
  width?: number
  markAsRequired?: boolean
  dropdownElementsInView?: number
  additionalClassNames?: string[]
  testId?: string
}

const SelectSearchMessages = defineMessages({
  notFound: {
    id: 'SelectSearchMessages.notFound',
    defaultMessage: 'Nothing found',
  },
})

export const SelectSearch: FC<SelectSearchProps> = (props) => {
  const {
    options,
    label,
    error,
    defaultInputValue,
    handleAfterSelect,
    optionCheckedIcon = true,
    helperText,
    width,
    placeholder,
    markAsRequired = false,
    dropdownElementsInView = 4,
    additionalClassNames = [],
    testId,
    ...otherProps
  } = props
  const intl = useIntl()
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [inputValue, setInputValue] = useState<string | undefined>(
    defaultInputValue,
  )
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false)
  const [filteredOptions, setFilteredOptions] =
    useState<SelectSearchOption[]>(options)
  const [fakeValue, setFakeValue] = useState<string | undefined>(
    defaultInputValue,
  )
  const areaEl = useRef<HTMLDivElement>(null)
  const fakeInputRef = useRef<HTMLInputElement>(null)
  const dropdownContentRef = useRef<HTMLDivElement>(null)
  const optionsRef = useRef<any>({})
  const [lastFocusedOption, setLastFocusedOption] =
    useState<SelectSearchOption | null>(null)
  const [prevLastFocusedOption, setPrevLastFocusedOption] =
    useState<SelectSearchOption | null>(null)
  const [nextLastFocusedOption, setNextLastFocusedOption] =
    useState<SelectSearchOption | null>(null)

  useEffect(() => {
    setInputValue(defaultInputValue)
    setFakeValue(defaultInputValue)
  }, [defaultInputValue])

  const openDropdown = () => {
    setIsDropdownOpened((prevState) => !prevState)
    const firstOptionKey = filteredOptions.find((x) => x)
    if (firstOptionKey) setLastFocusedOption(firstOptionKey)
  }
  const closeDropdown = () => {
    clearLastFocusedOption()
    setIsDropdownOpened(false)
  }

  useClickOutside(areaEl, closeDropdown)

  useEffect(() => {
    setInputValue(fakeValue)
    const realValue = options.find((o) => o.inputLabel === fakeValue)?.value
    otherProps.onChange &&
      (otherProps.onChange as (event: any) => void)(realValue)

    if (fakeInputRef.current) {
      fakeInputRef.current.focus({ preventScroll: true })
      fakeInputRef.current.blur()
    }

    setIsDropdownOpened(false)
    if (!isFirstRender) {
      fakeValue && handleAfterSelect?.()
    } else {
      setIsFirstRender(false)
    }
  }, [fakeValue])

  useEffect(() => {
    if (
      lastFocusedOption &&
      optionsRef.current.hasOwnProperty(lastFocusedOption.key)
    ) {
      optionsRef.current[lastFocusedOption.key].style.background = '#e3e3e3'

      const idx = filteredOptions.findIndex(
        (o) => o.key === lastFocusedOption.key,
      )
      if (dropdownContentRef.current)
        dropdownContentRef.current.scrollTop = 32 * idx

      const nextIdx = idx + 1
      if (nextIdx < filteredOptions.length) {
        setNextLastFocusedOption(filteredOptions[nextIdx])
      } else {
        setNextLastFocusedOption(null)
      }

      const prevIdx = idx - 1
      if (prevIdx >= 0) {
        setPrevLastFocusedOption(filteredOptions[prevIdx])
      } else {
        setPrevLastFocusedOption(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFocusedOption, filteredOptions])

  const forceFocusLastFocusedOption = () => {
    if (
      lastFocusedOption &&
      optionsRef.current.hasOwnProperty(lastFocusedOption.key)
    ) {
      optionsRef.current[lastFocusedOption.key].style.background = '#F3F4F6'
    }
  }

  const clearLastFocusedOption = () => {
    if (
      lastFocusedOption &&
      optionsRef.current.hasOwnProperty(lastFocusedOption.key)
    ) {
      optionsRef.current[lastFocusedOption.key].style.background = ''
    }
  }

  const onClear = () => {
    setFakeValue(undefined)
  }

  const onSelectChange = (option: SelectSearchOption) => {
    if (option.inputLabel === fakeValue) {
      closeDropdown()
      fakeInputRef.current?.blur()
      handleAfterSelect?.()
    } else {
      setFakeValue(option.inputLabel)
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isDropdownOpened) {
      setIsDropdownOpened(true)
    }

    setInputValue(e.target.value)

    const filtered =
      e.target.value.length === 0
        ? options
        : options
            .filter((o) =>
              o.label?.toLowerCase().includes(e.target.value.toLowerCase()),
            )
            .sort(
              (a, b) =>
                a.label?.toLowerCase().indexOf(e.target.value.toLowerCase()) -
                b.label?.toLowerCase().indexOf(e.target.value.toLowerCase()),
            )

    setFilteredOptions(filtered)

    clearLastFocusedOption()
    setLastFocusedOption(null)
    const firstOptionKey = filtered.find((x) => x)
    if (firstOptionKey) {
      setLastFocusedOption(firstOptionKey)
      forceFocusLastFocusedOption()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') closeDropdown()
    else if (event.key === 'ArrowDown') {
      if (nextLastFocusedOption) {
        clearLastFocusedOption()
        setLastFocusedOption(nextLastFocusedOption)
      }
    } else if (event.key === 'ArrowUp') {
      if (prevLastFocusedOption) {
        clearLastFocusedOption()
        setLastFocusedOption(prevLastFocusedOption)
      }
    } else if (event.key === 'Enter') {
      event.preventDefault()
      lastFocusedOption && onSelectChange(lastFocusedOption)
      clearLastFocusedOption()
      setLastFocusedOption(null)
    }
  }

  const isArrowShown = useMemo(
    () => !(inputValue || fakeValue) || !isDropdownOpened,
    [inputValue, fakeValue, isDropdownOpened],
  )

  const areaWidth = useMemo(() => (width ? width + 'px' : '100%'), [width])

  const dropdownHeight = useMemo(
    () => dropdownElementsInView * 32 + 12,
    [dropdownElementsInView],
  )

  return (
    <div
      className={clsx(
        styles.select,
        otherProps?.disabled && styles.select_disabled,
        ...additionalClassNames,
      )}
    >
      {label && (
        <label className={styles.select_label}>
          {label}
          {markAsRequired && <span>*</span>}
        </label>
      )}

      <div
        className={styles.select_area}
        onClick={
          !otherProps.disabled
            ? () => {
                setFilteredOptions(options)
                setInputValue('')
                openDropdown()
              }
            : () => {}
        }
        style={{ width: areaWidth }}
        ref={areaEl}
      >
        {/* REAL INPUT */}
        <input
          type={'text'}
          value={otherProps.value}
          readOnly={true}
          style={{ display: 'none' }}
          {...otherProps}
        />

        {/* FAKE INPUT */}
        <input
          {...otherProps}
          ref={fakeInputRef}
          value={inputValue}
          data-test-id={testId}
          onBlur={(e) => {
            otherProps.onBlur?.(e)
            setInputValue(fakeValue ?? '')
          }}
          onKeyDown={handleKeyDown}
          disabled={otherProps.disabled}
          onChange={onChange}
          placeholder={fakeValue || placeholder}
          autoComplete="off"
        />

        <div
          className={clsx(
            styles.select_area_background,
            error && styles.select_area_background_error,
            isDropdownOpened && styles.select_area_background_opened,
          )}
        />

        <div
          className={styles.AdditionalContent}
          onClick={() => fakeInputRef.current?.focus()}
        >
          <div className={styles.AdditionalContent__postfix}>
            {isArrowShown && (
              <ShowIcon
                className={clsx(
                  styles.AdditionalContent__postfix,
                  styles.select_area_arrow,
                  isDropdownOpened && styles.select_area_arrow_active,
                )}
              />
            )}
            {!isArrowShown && (
              <ClearIcon
                className={clsx(
                  styles.AdditionalContent__postfix,
                  styles.select_area_clear,
                )}
                onClick={(e) => {
                  e.stopPropagation()
                  onClear()
                }}
              />
            )}
          </div>
        </div>

        {isDropdownOpened && (
          <div
            ref={dropdownContentRef}
            className={styles.select_area_dropdown}
            style={{ maxHeight: dropdownHeight }}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <div
              className={styles.select_area_dropdown_content}
              onMouseEnter={() => {
                clearLastFocusedOption()
                setPrevLastFocusedOption(options[0])
                setNextLastFocusedOption(options[0])
                setLastFocusedOption(null)
              }}
            >
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <button
                    key={option.key}
                    data-test-id={`option-select-search-${option.key}`}
                    ref={(element) => {
                      if (element) optionsRef.current[option.key] = element
                      else delete optionsRef.current[option.key]
                    }}
                    type={'button'}
                    tabIndex={0}
                    onClick={() => !option.disabled && onSelectChange(option)}
                    className={clsx(
                      styles.select_area_dropdown_content_item,
                      option.disabled &&
                        styles.select_area_dropdown_content_item_disabled,
                    )}
                  >
                    <div
                      className={styles.select_area_dropdown_content_item_text}
                    >
                      {option.label}
                    </div>
                    {optionCheckedIcon && inputValue === option.value && (
                      <SelectedIcon />
                    )}
                  </button>
                ))
              ) : (
                <div className={styles.select_area_dropdown_content_notfound}>
                  {intl.formatMessage(SelectSearchMessages.notFound)}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {helperText && (
        <div className={styles.select_helperText}>{helperText}</div>
      )}
      {error && (
        <div className={styles.select_error}>
          <span>{error}</span>
        </div>
      )}
    </div>
  )
}
