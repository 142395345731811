import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useDevice } from '../../../sdk/hooks/use-device/useDevice'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { CloudTabs } from '../../../shared/ui-kit-2/navigation/cloud-tabs/CloudTabs'
import { TabType } from '../../../shared/ui-kit-2/navigation/tabs/Tabs'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { Details } from './components/details/Details'
import { Ussd } from './components/ussd/Ussd'
import { DeviceDetailsModalMessages } from './messages/DeviceDetailsModalMessages'
import styles from './styles.module.scss'

const TABS = {
  DETAILS: 'DETAILS',
  USSD: 'USSD',
}

export const DeviceDetailsModal = () => {
  const { handleHideModal, props } = useModal()
  const {
    deviceId,
    handleSwitchHotspot,
    handleSwitchVPN,
    handleFetchDevices,
    handleDeleteDevice,
    openSubscriptionsByDefault,
  }: {
    deviceId: number
    handleFetchDevices: () => Promise<void>
    handleSwitchVPN: () => Promise<void>
    handleDeleteDevice: (dongleId: number) => Promise<void>
    handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
    openSubscriptionsByDefault?: boolean
  } = props

  const intl = useIntl()

  const deviceIdFromProps = useMemo(() => deviceId, [])

  const {
    device,
    loading: deviceLoading,
    handleFetch: handleFetchDevice,
  } = useDevice({ deviceId: deviceIdFromProps })

  const [tab, setTab] = useState<string>(TABS.DETAILS)

  const tabs: TabType[] = [
    {
      id: TABS.DETAILS,
      label: intl.formatMessage(DeviceDetailsModalMessages.detailsTab),
    },
    {
      id: TABS.USSD,
      label: intl.formatMessage(DeviceDetailsModalMessages.ussdTab),
    },
  ]

  const content = useMemo(
    () =>
      device ? (
        <div className={styles.Container}>
          <CloudTabs
            defaultTabId={TABS.DETAILS}
            items={tabs}
            handleChange={setTab}
            classes={{
              container: styles.CloudTabs,
            }}
          />

          {tab === TABS.DETAILS && (
            <Details
              device={device}
              handleSwitchHotspot={handleSwitchHotspot}
              handleSwitchVPN={handleSwitchVPN}
              handleFetchDevices={handleFetchDevices}
              handleFetchDevice={handleFetchDevice}
              handleDeleteDevice={handleDeleteDevice}
              openSubscriptionsByDefault={openSubscriptionsByDefault}
            />
          )}

          {tab === TABS.USSD && <Ussd device={device} />}
        </div>
      ) : (
        <></>
      ),
    [device, tab],
  )

  const onHideModal = () => {
    const url = new URL(window.location.href)
    url.searchParams.delete('dongleId')
    window.history.replaceState(null, '', url.toString())

    handleHideModal()
  }

  return (
    <Drawer
      isOpen={true}
      close={onHideModal}
      title={intl.formatMessage(DeviceDetailsModalMessages.title)}
      limitedWidth={false}
      // additionalClassNames={[clsx((isOpenPayDevice || isPayOrChangeSubscription) && styles.ScrollOff)]}
      fullWidth
    >
      <LoadingContent loading={deviceLoading} content={content} />
    </Drawer>
  )
}
