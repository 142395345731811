import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonFormMessages } from '../../../config/intl/common-messages/common-form-messages/CommonFormMessages'
import {dongleChangePhoneNumber, dongleUpdateDatagate} from '../../../sdk/datagates/api/dongle'
import { PhoneInputFieldNumber } from '../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew'
import { getClearedPhoneNumber } from '../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

const DeviceChangePhoneNumberModalMessages = defineMessages({
  title: {
    id: 'DeviceChangePhoneNumberModalMessages.title',
    defaultMessage: 'Change phone number',
  },
  description: {
    id: 'DeviceChangePhoneNumberModalMessages.description',
    defaultMessage:
      "We were unable to automatically detect your device's phone number. Please enter the device phone number manually.",
  },
  phoneLabel: {
    id: 'DeviceChangePhoneNumberModalMessages.phoneLabel',
    defaultMessage: 'Enter the device phone number',
  },
  cancelText: {
    id: 'DeviceChangePhoneNumberModalMessages.cancelText',
    defaultMessage: 'Cancel',
  },
  submitText: {
    id: 'DeviceChangePhoneNumberModalMessages.submitText',
    defaultMessage: 'Save',
  },
  successSnackbar: {
    id: 'DeviceChangePhoneNumberModalMessages.successSnackbar',
    defaultMessage:
      'SIM card detection process is started, it will take some time',
  },
})

interface ChangePhoneNumberFormType {
  phone: string
}

export const DeviceChangePhoneNumberModal = () => {
  const { handleHideModal, props } = useModal()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const { handleOpenSnackbar } = useSnackbar()

  const onSubmit = async (values: ChangePhoneNumberFormType) => {
    try {
      setLoading(true)
      await dongleChangePhoneNumber(
        {
          number: `+${getClearedPhoneNumber(values.phone)}`,
        },
        [{ name: 'dongle_id', value: props.deviceId }],
      )
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(
          DeviceChangePhoneNumberModalMessages.successSnackbar,
        ),
      })
      props.handleFetchDevices()
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(CommonFormMessages.fetchError),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      title={intl.formatMessage(DeviceChangePhoneNumberModalMessages.title)}
    >
      <div className={styles.Container}>
        <div className={styles.Description}>
          {intl.formatMessage(DeviceChangePhoneNumberModalMessages.description)}
        </div>
        <Form
          initialValues={{
            devicePhone: null,
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form className={styles.Container} onSubmit={handleSubmit}>
              <PhoneInputFieldNumber
                name="phone"
                markAsRequired={true}
                label={intl.formatMessage(
                  DeviceChangePhoneNumberModalMessages.phoneLabel,
                )}
              />
              <div className={styles.Actions}>
                <Button
                  variant={'greenTextOutlined'}
                  text={intl.formatMessage(
                    DeviceChangePhoneNumberModalMessages.cancelText,
                  )}
                  disabled={loading}
                />
                <Button
                  type={'submit'}
                  text={intl.formatMessage(
                    DeviceChangePhoneNumberModalMessages.submitText,
                  )}
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </form>
          )}
        />
      </div>
    </Drawer>
  )
}
