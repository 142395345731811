import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { useRootPath } from '../../../../shared/lib/hooks/useRootPath'
import { Logo } from '../../../../shared/ui-kit-2/navigation/logo/Logo'
import styles from './styles.module.scss'

const AuthShowcaseMessages = defineMessages({
  title: {
    id: 'AuthShowcaseMessages.title',
    defaultMessage:
      'Take business communication to the next level with {serviceName}',
  },
  serviceName: {
    id: 'AuthShowcaseMessages.serviceName',
    defaultMessage: 'Teleleo',
  },
})

export const AuthShowcase = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const rootPath = useRootPath()

  const toHome = () => navigate(rootPath)

  // TODO VIDEO
  return (
    <div className={styles.Container}>
      <Logo className={styles.Logo} onClick={toHome} />

      <div className={styles.Title}>
        {intl.formatMessage(AuthShowcaseMessages.title, {
          serviceName: (
            <span>{intl.formatMessage(AuthShowcaseMessages.serviceName)}</span>
          ),
        })}
      </div>

      <div className={styles.VideoContainer}>
        <video
          autoPlay
          muted
          loop
          controls={false}
          className={styles.Video}
          playsInline
        >
          <div className={styles.VideoMask}></div>
          <source src="videos/auth.mp4" type="video/mp4" />
        </video>

        <div className={styles.VideoOverlay} />
      </div>
    </div>
  )
}
