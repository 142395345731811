import React from 'react'
import styles from '../../styles.module.scss'

export const EngPrivacyPolicy = () => {
  return (
    <>
      <div className={styles.Title}>Privacy Policy</div>
      <div className={styles.Content}>
        <div className={styles.Text}>
          <h3>Introduction</h3>
          <p>
            This privacy policy applies between you, the user of this Website,
            and <b>Teleleo Limited</b>, as the owner and operator of this
            website www.teleleo.com, which takes your privacy very seriously.
            This privacy policy applies to our use of any and all Data collected
            by us or provided by you in connection with your use of the Website.
          </p>
          <p>
            <b>Teleleo Limited</b> is a company registered in United Kingdom
            with Company Number 13578725, VAT GB388170859, with its registered
            and actual address at Office 1.01, 411-413 Oxford Street, London W1C
            2PE, United Kingdom.
          </p>
          <p>
            <b>Teleleo Limited</b> can be contacted by email at{' '}
            <b>info@teleleo.com</b>.
          </p>
          <p>
            Please note that this privacy policy should be read in conjunction
            with our Terms and Conditions, which can be found here:
            www.teleleo.com/terms-of-use, as well as on the website itself
            www.teleleo.com.
          </p>

          <h3>Definitions and Interpretation</h3>
          <p>1. The following definitions are used in this privacy policy:</p>
          <table className={styles.DefinitionsTable}>
            <tr>
              <td>Data</td>
              <td>
                Data means the information you provide to <b>Teleleo Limited</b>{' '}
                through the website. Where appropriate, this definition includes
                definitions provided in data protection laws.
              </td>
            </tr>
            <tr>
              <td>Cookies</td>
              <td>
                This is a small text file that is placed on your computer by
                this website when you visit certain areas of the website and/or
                use certain features of the website. Details of the cookies used
                by this website are set out in the following section. (Cookies)
              </td>
            </tr>
            <tr>
              <td>Data Protection Laws</td>
              <td>
                This is a reference to all applicable laws related to the
                processing of personal data, including but not limited to the
                General Data Protection Regulation (GDPR) and national laws,
                rules, and regulations governing the processing and protection
                of personal data.
              </td>
            </tr>
            <tr>
              <td>RGPD</td>
              <td>
                General Data Protection Regulation United Kingdom and the
                European Union. Refers to the GDPR (General Data Protection
                Regulation), which is a European Union law that regulates the
                protection of personal data in EU countries and The Data
                Protection Act 2018 in United Kingdom.
              </td>
            </tr>
            <tr>
              <td>
                <b>Teleleo Limited</b> Ways of labelling the company in this
                privacy policy
              </td>
              <td>
                <b>Teleleo Limited</b> is a company registered in United Kingdom
                with Company Number 13578725, VAT GB388170859, with its
                registered and actual address at Office 1.01, 411-413 Oxford
                Street, London W1C 2PE, United Kingdom. You can contact{' '}
                <b>Teleleo Limited</b> by email at <b>info@teleleo.com</b>.
              </td>
            </tr>
            <tr>
              <td>United Kingdom and European Union Cookie Law</td>
              <td>
                The Privacy and Electronic Communications (EU Directive)
                Regulations 2003, as amended by the Privacy and Electronic
                Communications (EU Directive) (Amendment) Regulations 2011 and
                the Privacy and Electronic Communications (EU Directive)
                (Amendment) Regulations 2018. Is a reference to legislation and
                developments relating to data protection and electronic
                communications in the European Union
              </td>
            </tr>
            <tr>
              <td>User or You</td>
              <td>
                Any third party who accesses the Website and is not (i) an
                employee of <b>Teleleo Limited</b> and acting within the scope
                of his or her employment or (ii) a hired consultant or service
                provider providing services to <b>Teleleo Limited</b> and
                accessing the Website in connection with the provision of such
                services.
              </td>
            </tr>
            <tr>
              <td>Website</td>
              <td>
                The website that you are currently using, www.teleleo.com, and
                any sub-domains of this site unless expressly excluded by their
                own terms and conditions.
              </td>
            </tr>
          </table>

          <p>2.</p>
          <ol type="a">
            <li>The singular includes the plural and vice versa;</li>
            <li>
              References to subparagraphs, paragraphs, annexes or supplements
              refer to subparagraphs, paragraphs, annexes or supplements of this
              Privacy Policy;
            </li>
            <li>
              Reference to an individual includes companies, firms, public
              bodies, trusts and associations;
            </li>
            <li>
              "'including' shall be construed as 'including, without
              limitation'''
            </li>
            <li>
              A reference to a legal provision includes any modification or
              amendment thereof.
            </li>
          </ol>

          <h3>Effect of this Privacy Policy</h3>
          <p>
            3. This privacy policy applies only to the actions of 
            <b>Teleleo Limited</b> and Users with respect to
            the www.teleleo.com website. It does not apply to any website
            accessible from said website, including but not limited to links we
            may provide to social media websites.
          </p>

          <h3>Data Collected</h3>
          <p>
            4. Personal data or personal information means any information about
            a natural person by which that person can be identified. This does
            not include data where identification has been removed (anonymised
            data). We may collect, use, store and transfer different types of
            your personal data, which we have grouped as follows:
          </p>
          <ol type="a">
            <li>
              Identification related data includes first name, last name,
              photograph, job title.
            </li>
            <li>Contact data include email address, telephone number.</li>
            <li>
              Technical data includes your IP address(IP), user ID, metadata,
              browser type and version, browser settings and time zone, browser
              plugin types and versions, operating system and platform, and
              other technologies on the devices you use to access this website.
            </li>
            <li>Profile Data includes your email address and password.</li>
            <li>
              Usage Data includes information about how you use our website,
              products and services.
            </li>
            <li>
              Marketing and Communications Data includes your preferences for
              receiving marketing information from us and from third parties and
              your communication preferences.
            </li>
          </ol>
          <p>
            We also collect, use and share aggregated data, such as statistical
            or demographic data, for any purpose. Aggregated data may be derived
            from your personal data, but it is not considered personal data
            under the law because it does not directly or indirectly reveal your
            identity. For example, we may aggregate your usage data to calculate
            the percentage of users accessing a particular website feature.
            However, if we combine or link the aggregated data with your
            personal data in a way that identifies you directly or indirectly,
            we process the combined data as personal data to be used in
            accordance with this Privacy Policy.
          </p>
          <p>
            We do not collect any special categories of your personal data (this
            includes information about your race or ethnicity, religious or
            philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health and
            genetic and biometric data).
          </p>
          <p>
            Under the Data Protection Act 2018, you have the right to find out
            what information our organisation store about you. These include the
            right to:
          </p>
          <ul>
            <li>be informed about how your data is being used</li>
            <li>access personal data</li>
            <li>have incorrect data updated</li>
            <li>have data erased</li>
            <li>stop or restrict the processing of your data</li>
            <li>
              data portability (allowing you to get and reuse your data for
              different services)
            </li>
            <li>
              object to how your data is processed in certain circumstances
            </li>
          </ul>
          <p>
            You also have rights when our organisation is using your personal
            data for:
          </p>
          <ul>
            <li>
              automated decision-making processes (without human involvement)
            </li>
            <li>
              profiling, for example to predict your behaviour or interests
            </li>
          </ul>
          <p>
            <b>Teleleo Limited</b> is responsible for using personal data has to
            follow strict rules called ‘data protection principles’. The
            information managed by <b>Teleleo Limited</b> is:
          </p>
          <ul>
            <li>used fairly, lawfully and transparently</li>
            <li>used for specified, explicit purposes</li>
            <li>
              used in a way that is adequate, relevant and limited to only what
              is necessary
            </li>
            <li>accurate and, where necessary, kept up to date</li>
            <li>kept for no longer than is necessary</li>
            <li>
              handled in a way that ensures appropriate security, including
              protection against unlawful or unauthorised processing, access,
              loss, destruction or damage
            </li>
          </ul>

          <h3>How We Collect Data</h3>
          <p>5. We collect data in the following ways:</p>
          <ol type="a">
            <li>You provide us with data;</li>
            <li>Data is obtained from other sources;</li>
            <li>Data is collected automatically.</li>
          </ol>

          <h3>Data You Provide to Us</h3>
          <p>
            6. <b>Teleleo Limited</b> will collect your data in various ways,
            such as:
          </p>
          <ol type="a">
            <li>
              when you contact us via the website, telephone, post, email or any
              other means;
            </li>
            <li>
              when you register with us and create an account to receive our
              products/services;
            </li>
            <li>
              when you complete surveys that we use for research purposes (you
              are under no obligation to respond);
            </li>
            <li>when you choose to receive our marketing communications;</li>
          </ol>
          <p>in each case, in accordance with this Privacy Policy.</p>

          <h3>Data is obtained from publicly available third party sources</h3>
          <p>
            7. We do not obtain data from other sources not listed in this
            policy. For legal entities (companies), it is possible to obtain
            information from publicly available information sources such as the
            public register of companies.
          </p>

          <h3>Data that is collected automatically</h3>
          <p>
            8. As you access the website, we will collect your data
            automatically, for example:
          </p>
          <ol type="a">
            <li>
              we automatically collect certain information about your visit to
              the website. This information helps us to improve the content and
              navigation on the website and includes your IP address, the date,
              time and frequency of access to the website and your activity and
              interaction with the content.
            </li>
            <li>
              we will automatically collect your data using cookies in
              accordance with your browser's cookie settings. For more
              information about cookies and how we use them on the website,
              please refer to the section below headed 'Cookies'.
            </li>
          </ol>

          <h3>Our Use of Data</h3>
          <p>
            9. Any or all of the above data may be requested by us from time to
            time to provide you with the best possible service and experience
            when using our website. In particular, data may be used by us for
            the following purposes:
          </p>
          <ol type="a">
            <li>
              to register you as a user we use Profile, Technical data types;
            </li>
            <li>
              to improve our website, products/services, marketing we use Usage,
              Technical, Marketing and Communications data types;
            </li>
            <li>
              to notify you of changes to our terms and conditions or privacy
              policy and to ask you to leave feedback or take a survey, we use
              Identity, Contact, Marketing and Communications data types;
            </li>
            <li>
              to provide you with relevant content and advertising from the
              website and to measure or understand the effectiveness of the
              advertising we provide to you, we use the data types usage,
              technical data, marketing and communications, profile,
              identification, contact;
            </li>
            <li>
              we use identification, contact and technical data to manage this
              website (including troubleshooting, data analysis, testing, system
              maintenance, support, reporting and data hosting).
            </li>
          </ol>
          <p>In each case, in accordance with this Privacy Policy.</p>
          <p>
            10. We may use your data for the above purposes if we believe it is
            necessary for our legitimate interests. If you do not agree, you
            have the right to object to this in certain circumstances (see 'Your
            Rights' below).
          </p>
          <p>
            11. In order to send you marketing offers by email, we will need
            your consent, either through opt-in or soft-opt-in.
          </p>
          <ol type="a">
            <li>
              If you are not happy with our marketing approach, you have the
              right to withdraw your consent at any time. Read about how to
              withdraw your consent in the 'Your Rights' section below.
            </li>
            <li>
              When you register with us and create an account to receive our
              services, the legal basis for such processing is the execution of
              a contract between you and us and/or taking steps to enter into
              such a contract at your request.
            </li>
            <li>
              If you are not happy with our marketing approach, you have the
              right to withdraw your consent at any time. Read about how to
              withdraw your consent in the 'Your Rights' section below.
            </li>
          </ol>
          <p>
            12. When you register with us and create an account to receive our
            services, the legal basis for such processing is the execution of a
            contract between you and us and/or taking steps to enter into such a
            contract at your request.
          </p>

          <h3>Ensuring Data Security</h3>
          <p>
            13. We use technical and organizational measures to protect your
            data, for example:
          </p>
          <ol type="a">
            <li>Access control using usernames and passwords.</li>
            <li>Storage on secure servers.</li>
          </ol>
          <p>
            14. Technical and organizational measures include measures to
            respond to any suspected data breach. If you suspect misuse, loss or
            unauthorized access to your Data(s), please notify us immediately by
            contacting us at this email address: <b>info@teleleo.com</b>.
          </p>

          <h3>Data Retention</h3>
          <p>
            15. Unless a longer retention period is required or permitted by
            law, we retain your data on our systems only for the period
            necessary to fulfil the purposes described in this privacy policy or
            until you request that the data be deleted.
          </p>
          <p>
            16. Even if we delete your data, it may be retained on backup or
            archival media for legal, tax or regulatory purposes.
          </p>

          <h3>Your rights</h3>
          <p>You have the following rights in relation to your data:</p>
          <ol type="a">
            <li>
              Right of Access: the right to request at any time (i) copies of
              the information we hold about you, or (ii) to amend, update or
              delete such information. If we give you access to the information
              we hold about you, we will not charge you for this unless your
              request is "manifestly unreasonable or excessive". If we are
              legally entitled to do so, we may refuse your request. If we
              refuse your request, we will tell you the reason why.
            </li>
            <li>
              Right to rectification: the right to have your data corrected if
              it is inaccurate or incomplete.
            </li>
            <li>
              Right to erasure: the right to request that we delete or destroy
              your data from our systems.
            </li>
            <li>
              The right to restrict the use of your data: the right to
              "prohibit" us from using your data or to restrict how we use it.
            </li>
            <li>
              The right to data portability: the right to request that we move,
              copy or transfer your data.
            </li>
            <li>
              Right to object: the right to object to our use of your data,
              including when we use it for our legitimate interests.
            </li>
          </ol>
          <p>
            To ask questions, exercise any of the above rights or withdraw your
            consent to the processing of your data (where consent is our lawful
            basis for processing your data), please contact us at This email
            address is <b>info@teleleo.com</b>. If you are not satisfied with
            the way we handle a complaint you have made about your data, you may
            contact the relevant data protection authority. It is important that
            the data we hold about you is accurate and up to date. Please let us
            know if your data has changed during the period for which we hold
            it.
          </p>

          <h3>Links to other sites</h3>
          <p>
            21. This website may from time to time contain links to other
            websites. We have no control over these sites and are not
            responsible for their content. This privacy policy does not apply to
            your use of such sites. We encourage you to read the privacy policy
            or privacy statement of other sites before using them.
          </p>

          <h3>Change in ownership and control of the business</h3>
          <p>
            22. <b>Teleleo Limited</b> may from time to time expand or reduce
            its operations, which may involve the sale and/or transfer of
            control of all or part of <b>Teleleo Limited</b>. Data provided by
            Users, if it relates to any transferred part of our business, will
            be transferred with that part, and the new owner or new controlling
            party, subject to the terms of this privacy policy, will be
            authorized to use that data for the purposes for which it was
            originally provided to us.
          </p>
          <p>
            23. We may also disclose data to a prospective purchaser of our
            business or part of it.
          </p>
          <p>
            24. In the above cases, we will take steps to ensure that your
            privacy is protected.
          </p>

          <h3>Cookies</h3>
          <p>
            25. This website may place and access certain cookies on your
            computer. <b>Teleleo Limited</b> uses cookies to improve your
            experience on the website and to improve the quality of our
            services. <b>Teleleo Limited</b> carefully selects these cookies and
            takes steps to ensure that your privacy is protected and respected
            at all times.
          </p>
          <p>
            26. All cookies used on this website are used in accordance with
            current UK and European Union cookie legislation.
          </p>
          <p>
            27. Before the website places cookies on your computer, a message
            bar will appear on your screen asking for your consent to set
            cookies. By giving your consent to the placing of cookies, you are
            enabling <b>Teleleo Limited</b> to provide you with a better
            service. If you wish to do so, you can refuse consent to the placing
            of cookies, but in this case some of the functions of the site may
            not function fully or as intended.
          </p>
          <p>The following cookies may be placed on this website:</p>
          <p>
            Statistical cookies - Statistical cookies help website owners
            understand how visitors interact with the site by collecting and
            presenting information in an anonymised form.
          </p>
          <p>
            29. You can enable or disable cookies in your Internet browser.
            Please refer to the help menu of your Internet browser for more
            information.
          </p>
          <p>
            30. You can disable cookies at any time, however in doing so you may
            lose any information that will allow you to access the site more
            quickly and efficiently. You can delete cookies at any time, however
            in doing so you may lose any information that will allow you to
            access the site more quickly and efficiently, including in
            particular your personalization settings.
          </p>
          <p>
            31. It is recommended that you ensure that your web browser is up to
            date and that you refer to the help and guidance provided by the
            developer of your web browser if you are unsure about your privacy
            settings.
          </p>
          <p>
            32. More information about cookies, including how to disable them,
          </p>

          <h3>General</h3>
          <p>
            33. You may not transfer any of your rights under this privacy
            policy to another person. We may transfer our rights under this
            Privacy Policy if we reasonably believe that your rights will not be
            affected.
          </p>
          <p>
            34. If any court or competent authority determines that any
            provision of this Privacy Policy (or part of any provision) is
            invalid, illegal or unenforceable, such provision or part of the
            provision, to the extent required, will be deemed deleted and the
            validity and enforceability of the remaining provisions of this
            Privacy Policy will not be affected.
          </p>
          <p>
            35. Unless otherwise agreed, no delay, act or omission by a party in
            exercising any right or remedy shall be deemed a waiver of that or
            any other right or remedy.
          </p>
          <p>
            36. This Agreement shall be governed by and construed in accordance
            with United Kingdom and European Union law. All disputes arising in
            connection with the Agreement shall be subject to the exclusive
            jurisdiction of the courts of United Kingdom.
          </p>

          <h3>Changes to this Privacy Policy</h3>
          <p>
            37. <b>Teleleo Limited</b> reserves the right to change this
            privacy policy from time to time at its discretion or as required by
            law. Any changes will be immediately posted on the site and you will
            be deemed to have accepted the terms of the privacy policy the first
            time you use the site after the changes have been made. You may
            contact <b>Teleleo Limited</b> by email: <b>info@teleleo.com</b>.
          </p>

          <h3>Attribution</h3>
          <p>
            38. This privacy policy was created using a document from Rocket
            Lawyer (www.rocketlawyer.com).
          </p>
        </div>
      </div>
    </>
  )
}
