import { SmsRoute } from '../enums/smsRoute'

export const SmsRootRoutePath = '/lk/sms'

export const SmsRoutePath: { [key in SmsRoute]: string } = {
  [SmsRoute.SmsList]: `${SmsRootRoutePath}/list`,
  [SmsRoute.SmsRedirection]: `${SmsRootRoutePath}/redirection`,
  [SmsRoute.SmsAutoReply]: `${SmsRootRoutePath}/auto-reply`,
  [SmsRoute.SmsMailing]: `${SmsRootRoutePath}/mailing`,
}
