import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { callListDatagate } from '../../datagates/api/call'
import { dongleCallDeleteDatagate } from '../../datagates/api/dongle'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { QueryParameter } from '../../datagates/helpers/_common/wrap-api-request'
import { CallListResponse } from '../../datagates/types/call/_crud/list'

interface UseCallsProps {
  filters?: QueryParameter[]
}

export const CallType = {
  OUTGOING: 1,
  INCOMING: 2,
  DELAYED: 3,
}

export const CallTypeTabs = {
  ALL: '',
  OUTGOING: CallType.OUTGOING.toString(),
  INCOMING: CallType.INCOMING.toString(),
  DELAYED: CallType.DELAYED.toString(),
}

const UseCallsMessages = defineMessages({
  positiveDeleted: {
    id: 'UseCallsMessages.positiveDeleted',
    defaultMessage: 'Call is successfully deleted',
  },
  negativeDeleted: {
    id: 'UseCallsMessages.negativeDeleted',
    defaultMessage: 'An error occurred while deleting',
  },
})

export const useCalls = (props: UseCallsProps) => {
  const { filters = [] } = props

  const stableFilters = useMemo(() => filters, [JSON.stringify(filters)])

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [calls, setCalls] = useState<CallListResponse['calls']>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [initialFetchCompleted, setInitialFetchCompleted] =
    useState<boolean>(false)

  const handleFetch = async ({
    params = [],
    hidden,
  }: { params?: QueryParameter[]; hidden?: boolean } = {}) => {
    try {
      setLoading(!hidden)

      const queryParams: QueryParameter[] = [
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
        ...stableFilters,
        ...params,
      ]

      const { data } = await callListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setCalls(data.calls)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
      setInitialFetchCompleted(true)
    }
  }

  const handleDeleteCall = async (dongleId: number, callId: number) => {
    try {
      const urlParams = [
        { name: 'dongle_id', value: dongleId.toString() },
        { name: 'call_id', value: callId.toString() },
      ]

      await dongleCallDeleteDatagate(null, urlParams)
      await handleFetch()

      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseCallsMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseCallsMessages.negativeDeleted),
      })
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [stableFilters])

  return {
    calls,
    totalCount,
    handleFetch,
    handleDeleteCall,
    loading,
    initialFetchCompleted,
    setInitialFetchCompleted,
  }
}
