import React, { FC, ReactNode, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { createPortal } from 'react-dom'
import { LC } from '../../../../tests/e2e/locators'
import { useClickOutside } from '../../../lib/hooks/useClickOutside'
import { Loader } from '../../../ui-kit/data-display/loader/Loader'
import { ReactComponent as CloseIcon } from './assets/icons/close.svg'
import styles from './styles.module.scss'

interface DrawerProps {
  title?: string
  children: ReactNode
  isOpen: boolean
  close: () => void
  limitedWidth?: boolean
  customWidth?: string
  additionalClassNames?: string[]
  testId?: string
  fullWidth?: boolean
  scrollOff?: boolean
  loading?: boolean
}

export const Drawer: FC<DrawerProps> = (props) => {
  const {
    title,
    children,
    isOpen,
    close,
    limitedWidth = true,
    customWidth,
    additionalClassNames = [],
    testId,
    fullWidth = true,
    scrollOff,
    loading,
  } = props

  const areaEl = useRef<HTMLDivElement>(null)

  useClickOutside(
    areaEl,
    () => {
      close()
    },
    ['.flatpickr-calendar', '.MuiSnackbar-root'],
  )

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : ''

    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  if (!isOpen) return null

  const drawer = (
    <div
      className={clsx(
        styles.Drawer,
        fullWidth && styles.Drawer_fullWidth,
        scrollOff && styles.Drawer_scrollOff,
      )}
    >
      <div
        className={clsx(
          styles.Content,
          limitedWidth && styles.Content_limitedWidth,
          ...additionalClassNames,
        )}
        ref={areaEl}
        onClick={(e) => e.stopPropagation()}
        data-test-id={testId}
        style={{
          ...(customWidth ? { width: customWidth, maxWidth: customWidth } : {}),
        }}
      >
        <button className={styles.Close} onClick={close}>
          <CloseIcon />
        </button>

        {title && (
          <div className={styles.Title} data-test-id={LC.MODAL.TITLE}>
            {title}
          </div>
        )}

        <div className={styles.Body}>
          {loading ? (
            <div className={styles.Loading}>
              <Loader />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  )

  return createPortal(drawer, document.body)
}
