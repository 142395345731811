import { CallsRoute } from '../enums/callsRoute'

export const CallsRootRoutePath = '/lk/calls'

export const CallsRoutePath: { [key in CallsRoute]: string } = {
  [CallsRoute.CallsList]: `${CallsRootRoutePath}/calls-list`,
  [CallsRoute.CallsRedirects]: `${CallsRootRoutePath}/calls-notifications`,
  [CallsRoute.CallsSettings]: `${CallsRootRoutePath}/settings`,
  [CallsRoute.CallsAutoResponse]: `${CallsRootRoutePath}/auto-response`,
  [CallsRoute.CallsAutoResponseRules]: `${CallsRootRoutePath}/auto-response-rules`,
  [CallsRoute.CallsAutoDialer]: `${CallsRootRoutePath}/auto-dialer`,
}
