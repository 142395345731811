// @ts-nocheck

import { useMemo, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import { CommonButtonMessages } from '../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { ContactListResponse } from '../../../../sdk/datagates/types/contact/_crud/list'
import { formatPhoneNumber } from '../../../../sdk/formatters/format-phone-number'
import { useContacts } from '../../../../sdk/hooks/use-contacts/useContacts'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import { Input } from '../../../../shared/ui-kit-2/inputs/input/Input'
import { RadioProps } from '../../../../shared/ui-kit-2/inputs/radio/components/Radio'
import { RadioGroup } from '../../../../shared/ui-kit-2/inputs/radio/RadioGroup'
import { LoadingContent } from '../../../loading-content/LoadingContent'
import styles from './styles.module.scss'

const UseAddContactMessages = defineMessages({
  title: {
    id: 'UseAddContactMessages.title',
    defaultMessage: 'Select contact',
  },
  contactsCardTitle: {
    id: 'UseAddContactMessages.contactsCardTitle',
    defaultMessage: 'Contacts',
  },
  searchPlaceholder: {
    id: 'UseAddContactMessages.searchPlaceholder',
    defaultMessage: 'Search by name or phone number',
  },
})

export const useAddContact = () => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState<boolean>()
  const { contacts, totalCount, loading } = useContacts({})
  const [selectedContactId, setSelectedContactId] = useState<
    string | undefined
  >()
  const [savedContact, setSavedContact] = useState<
    ContactListResponse['contacts'][0] | undefined
  >(undefined)
  const [searchValue, setSearchValue] = useState<string>('')
  const ref = useRef<HTMLDivElement>(null)

  const contactsRadioGroup = contacts.map<RadioProps>((c) => ({
    label: c.name,
    subLabel: formatPhoneNumber(c.phone),
    value: c.contactId.toString(),
    comparedValue: selectedContactId,
    onClick: () => setSelectedContactId(c.contactId.toString()),
    additionalClassNames: [styles.RadioAdditional],
  }))

  const contactsRadioGroupFiltered = useMemo(
    () =>
      contactsRadioGroup
        .filter((c) => {
          return (
            c.label?.toLowerCase().includes(searchValue.toLowerCase()) ||
            c.subLabel?.toLowerCase().includes(searchValue.toLowerCase())
          )
        })
        .sort((a, b) => {
          if (a.label && b.label) {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1

            if (a.subLabel && b.subLabel) {
              if (a.subLabel < b.subLabel) return -1
              if (a.subLabel > b.subLabel) return 1
            }
          }
          return 0
        }),
    [contactsRadioGroup, searchValue],
  )

  const onClose = () => {
    setSelectedContactId(undefined)
    setIsOpen(false)
  }

  useClickOutside(ref, onClose)
  const removeContact = () => {
    setSelectedContactId(undefined)
    setSavedContact(undefined)
    setSearchValue('')
  }

  const onSave = () => {
    const contact = contacts.find(
      (c) => c.contactId.toString() === selectedContactId,
    )

    if (selectedContactId && contact) {
      setSavedContact(contact)
      setIsOpen(false)
      setSearchValue('')
    }
  }

  const content = (
    <div className={styles.Content}>
      <div className={styles.ContactsCard}>
        <div className={styles.ContactsCard__header}>
          <div>
            {intl.formatMessage(UseAddContactMessages.contactsCardTitle)}
          </div>
          <Counter
            color={'grey'}
            count={contactsRadioGroupFiltered.length}
            size={'small'}
          />
        </div>

        <div className={styles.ContactsCardContent}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={intl.formatMessage(
              UseAddContactMessages.searchPlaceholder,
            )}
            inputSize={'small'}
            postfix={<SearchIcon />}
            validationNeeded={false}
          />

          <RadioGroup
            name={'contacts'}
            group={contactsRadioGroupFiltered}
            variant={'outlined'}
            additionalClassNames={[styles.RadioGroupAdditional]}
          />
        </div>
      </div>

      <Button
        text={'Okay'}
        onClick={onSave}
        disabled={!selectedContactId}
      />
    </div>
  )

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(UseAddContactMessages.title)}
          </div>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>

        <LoadingContent loading={loading} content={content} />
      </Card>
    </div>
  )

  return {
    setIsOpen,
    component: isOpen ? component : null,
    savedContact,
    removeContact,
  }
}
