import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapOrderCreateBackendToOrderCreate } from '../../mappers/order/_crud/create/map-order-create-backend-to-order-create'
import { mapOrderGetBackendToOrderGet } from '../../mappers/order/_crud/get/map-order-get-backend-to-order-get'
import { mapOrderListBackendToOrderList } from '../../mappers/order/_crud/list/map-order-list-backend-to-order-list'
import { mapOrderUpdateBackendToOrderUpdate } from '../../mappers/order/_crud/update/map-order-update-backend-to-order-update'
import { mapCalculateOrderPriceBackendToCalculateOrderPrice } from '../../mappers/order/calculate-order-price/map-calculate-order-price-backend-to-calculate-order-price'
import { mapOrderDeliveryValidate } from '../../mappers/order/map-order-delivery-validate'
import { mapOrderPayBackendToOrderPay } from '../../mappers/order/pay/map-order-pay-backend-to-order-pay'
import {
  OrderCreateBackendResponse,
  OrderCreateRequest,
  OrderCreateResponse,
} from '../../types/order/_crud/create'
import {
  OrderGetBackendResponse,
  OrderGetRequest,
  OrderGetResponse,
} from '../../types/order/_crud/get'
import {
  OrderListBackendResponse,
  OrderListRequest,
  OrderListResponse,
} from '../../types/order/_crud/list'
import {
  OrderUpdateBackendResponse,
  OrderUpdateRequest,
  OrderUpdateResponse,
} from '../../types/order/_crud/update'
import {
  CalculateOrderPriceBackendResponse,
  CalculateOrderPriceRequest,
  CalculateOrderPriceResponse,
} from '../../types/order/calculate-order-price'
import {
  OrderDeliveryValidateBackendResponse,
  OrderDeliveryValidateRequest,
  OrderDeliveryValidateResponse,
} from '../../types/order/order-delivery-validate'
import {
  OrderPayBackendResponse,
  OrderPayRequest,
  OrderPayResponse,
} from '../../types/order/pay'

export const calculateOrderPriceDatagate = wrapApiRequest<
  CalculateOrderPriceRequest,
  CalculateOrderPriceBackendResponse,
  CalculateOrderPriceResponse
>(
  'order/price-calculator',
  mapCalculateOrderPriceBackendToCalculateOrderPrice,
  'POST',
)

export const orderPayDatagate = wrapApiRequest<
  OrderPayRequest,
  OrderPayBackendResponse,
  OrderPayResponse
>('order/{order_id}/pay', mapOrderPayBackendToOrderPay, 'POST')

export const orderListDatagate = wrapApiRequest<
  OrderListRequest,
  OrderListBackendResponse,
  OrderListResponse
>('order', mapOrderListBackendToOrderList, 'GET')

export const orderCreateDatagate = wrapApiRequest<
  OrderCreateRequest,
  OrderCreateBackendResponse,
  OrderCreateResponse
>('order', mapOrderCreateBackendToOrderCreate, 'POST')

export const orderGetDatagate = wrapApiRequest<
  OrderGetRequest,
  OrderGetBackendResponse,
  OrderGetResponse
>('order/{order_id}', mapOrderGetBackendToOrderGet, 'GET')

export const orderUpdateDatagate = wrapApiRequest<
  OrderUpdateRequest,
  OrderUpdateBackendResponse,
  OrderUpdateResponse
>('order/{order_id}', mapOrderUpdateBackendToOrderUpdate, 'PUT')

export const orderDeleteDatagate = wrapApiRequest<{}, {}, {}>(
  'order/{order_id}',
  () => true,
  'DELETE',
)

export const orderDeliveryValidate = wrapApiRequest<
  OrderDeliveryValidateRequest,
  OrderDeliveryValidateBackendResponse,
  OrderDeliveryValidateResponse
>('delivery/validate', mapOrderDeliveryValidate, 'POST')
