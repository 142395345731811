import { Route } from './enums/route'
import { faq } from './route-descriptors/all/faq/faq'
import { home } from './route-descriptors/all/home/home'
import { notFound } from './route-descriptors/all/not-found/notFound'
import { privacyPolicy } from './route-descriptors/all/privacy-policy/privacyPolicy'
import { termsOfUse } from './route-descriptors/all/terms-of-use/termsOfUse'
import { auth } from './route-descriptors/guest/auth/auth'
import { activateDevice } from './route-descriptors/user/lk/subpages/activate-device/activateDevice'
import { addCardSuccess } from './route-descriptors/user/lk/subpages/add-card-success/addCardSuccess'
import { api } from './route-descriptors/user/lk/subpages/api/api'
import { autoDeviceActivation } from './route-descriptors/user/lk/subpages/auto-device-activation/autoDeviceActivation'
import { calls } from './route-descriptors/user/lk/subpages/calls/calls'
import { connectToDongle } from './route-descriptors/user/lk/subpages/connectToDongle/connectToDongle'
import { contacts } from './route-descriptors/user/lk/subpages/contacts/contacts'
import { dashboard } from './route-descriptors/user/lk/subpages/dashboard/dashboard'
import { deviceConnectionStatus } from './route-descriptors/user/lk/subpages/device-connection-status/deviceConnectionStatus'
import { deviceList } from './route-descriptors/user/lk/subpages/device-list/deviceList'
import { manualDeviceActivation } from './route-descriptors/user/lk/subpages/manual-device-activation/manualDeviceActivation'
import { orders } from './route-descriptors/user/lk/subpages/orders/orders'
import { paymentFail } from './route-descriptors/user/lk/subpages/payment-fail/paymentFail'
import { paymentSuccess } from './route-descriptors/user/lk/subpages/payment-success/paymentSuccess'
import { profile } from './route-descriptors/user/lk/subpages/profile/profile'
import { purchaseProcess } from './route-descriptors/user/lk/subpages/purchase-process/purchaseProcess'
import { sms } from './route-descriptors/user/lk/subpages/sms/sms'
import { vpn } from './route-descriptors/user/lk/subpages/vpn/vpn'
import { wifiList } from './route-descriptors/user/lk/subpages/wifi-list/wifiList'
import { RouteDescriptor } from './types/routeDescriptor'

export const routes: { [key in Route]: RouteDescriptor<key> } = {
  [Route.Home]: home,
  [Route.PrivacyPolicy]: privacyPolicy,
  [Route.TermsOfUse]: termsOfUse,
  [Route.NotFound]: notFound,
  [Route.FAQ]: faq,
  [Route.Auth]: auth,
  [Route.PurchaseProcess]: purchaseProcess,
  [Route.ActivateDevice]: activateDevice,
  [Route.ManualDeviceActivation]: manualDeviceActivation,
  [Route.AutoDeviceActivation]: autoDeviceActivation,
  [Route.ConnectToDongle]: connectToDongle,
  [Route.DeviceConnectionStatus]: deviceConnectionStatus,
  [Route.PaymentSuccess]: paymentSuccess,
  [Route.PaymentFail]: paymentFail,
  [Route.AddCardSuccess]: addCardSuccess,
  [Route.Orders]: orders,
  [Route.DeviceList]: deviceList,
  [Route.Sms]: sms,
  [Route.Calls]: calls,
  [Route.WifiList]: wifiList,
  [Route.VPN]: vpn,
  [Route.Profile]: profile,
  [Route.Api]: api,
  [Route.Contacts]: contacts,
  [Route.Dashboard]: dashboard,
}
