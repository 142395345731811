import React, { ReactNode, Suspense, useEffect, useState } from 'react'
import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { defineMessages, useIntl } from 'react-intl'
import { Modem3D } from '../../../../../assets/3d-models/modem/Modem3D'
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg'
import { ReactComponent as RotateIcon } from '../../../../../assets/icons/rotate.svg'
import { Card } from '../../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../../shared/ui-kit-2/inputs/button/Button'
import { Loader } from '../../../../../shared/ui-kit/data-display/loader/Loader'
import styles from './styles.module.scss'

const BuyNewDevice3DModelMessages = defineMessages({
  openAboutBtn: {
    id: 'BuyNewDevice3DModelMessages.openAboutBtn',
    defaultMessage: 'About modem',
  },
  aboutTitle: {
    id: 'BuyNewDevice3DModelMessages.aboutTitle',
    defaultMessage: 'Modem Teleleo',
  },
  aboutDescription: {
    id: 'BuyNewDevice3DModelMessages.aboutDescription',
    defaultMessage:
      'Compact, fault-tolerant, full-range WiFi LTE USB modem with Plug and Play technology. The modem features automatically adjustable frequency ranges, extended coverage, multiple antennas, and 1 mini-SIM slot. Transmission range up to 40 meters, LTE download speeds up to 150 Mbps, supports up to 8+1 users for shared network access. It is widely used for personal and business purposes, in the Internet of Things infrastructure (IoT), in transportation, commercial spaces, industrial production, agriculture, forestry, livestock, fisheries, security monitoring, and other scenarios that require portable, simple, and rapid network deployment for data transmission.',
  },
  rotate360: {
    id: 'BuyNewDevice3DModelMessages.rotate360',
    defaultMessage: '360°',
  },
  closeAboutBtn: {
    id: 'BuyNewDevice3DModelMessages.closeAboutBtn',
    defaultMessage: 'Close',
  },
})

export const BuyNewDevice3DModel = () => {
  const intl = useIntl()

  const [aboutOpen, setAboutOpen] = React.useState(false)
  const [model, setModel] = useState<ReactNode>(
    <Loader wrapperProps={{ className: styles.Loader }} color={'#32435B'} />,
  )

  useEffect(() => {
    setTimeout(() => {
      setModel(
        <Canvas
          camera={{ position: [0, 0, 2], zoom: 1.4 }}
          className={styles.Canvas}
        >
          <OrbitControls
            enableZoom={false}
            enableDamping={true}
            enablePan={false}
            enableRotate={true}
            target={[0, 0, 0]}
            minPolarAngle={Math.PI / 4}
            maxPolarAngle={Math.PI / 1.5}
          />
          <ambientLight intensity={2} castShadow />
          <directionalLight
            intensity={1}
            position={[-7, 3, 3]}
            castShadow={false}
          />
          <directionalLight
            intensity={1}
            position={[7, 3, 3]}
            castShadow={false}
          />
          <spotLight
            position={[-6, 1, 0]}
            angle={-45}
            penumbra={0.15}
            intensity={2}
            castShadow
          />
          <Suspense fallback={null}>
            <Modem3D />
          </Suspense>
        </Canvas>,
      )
    }, 600)
  }, [])

  return (
    <Card additionalClassNames={[styles.Card]}>
      <div className={styles.Content}>
        {model}

        <div className={styles.RotateTip}>
          <div className={styles.RotateTip__text}>
            {intl.formatMessage(BuyNewDevice3DModelMessages.rotate360)}
          </div>
          <RotateIcon className={styles.RotateTip__icon} />
        </div>

        <Button
          text={intl.formatMessage(BuyNewDevice3DModelMessages.openAboutBtn)}
          variant={'gray'}
          additionalClassNames={[styles.AboutBtn]}
          onClick={() => setAboutOpen(true)}
        />

        {aboutOpen && (
          <div className={styles.About}>
            <div className={styles.About__header}>
              <div className={styles.About__header__title}>
                {intl.formatMessage(BuyNewDevice3DModelMessages.aboutTitle)}
              </div>
              <CloseIcon
                className={styles.About__header__close}
                onClick={() => setAboutOpen(false)}
              />
            </div>

            <div className={styles.About__description}>
              {intl.formatMessage(BuyNewDevice3DModelMessages.aboutDescription)}
            </div>

            <Button
              text={intl.formatMessage(
                BuyNewDevice3DModelMessages.closeAboutBtn,
              )}
              onClick={() => setAboutOpen(false)}
            />
          </div>
        )}
      </div>
    </Card>
  )
}
