import { defineMessages } from 'react-intl'

export type ProfileFormType = {
  firstName: string
  lastName: string
  email: string
  companyName?: string
  userPhone?: string
  companyPhone?: string
  nationalNumber?: string
  vatNumber?: string
  billingEmail?: string
  countryId: string
  postalCode?: string
  streetAddress1: string
  streetAddress2?: string
  city?: string
  region?: string
  legal?: {
    streetAddress1?: string
    streetAddress2?: string
    postalCode?: string
    countryId?: string
    city?: string
    region?: string
  }
}

export const ProfileFormMessages = defineMessages({
  accountInfo: {
    id: 'ProfileFormMessages.accountInfo',
    defaultMessage: 'Account info',
  },
  accountTypeLabel: {
    id: 'ProfileFormMessages.accountTypeLabel',
    defaultMessage: 'Account type:',
  },
  accountTypeOrganization: {
    id: 'ProfileFormMessages.accountTypeOrganization',
    defaultMessage: 'Organization',
  },
  accountTypePerson: {
    id: 'ProfileFormMessages.accountTypePerson',
    defaultMessage: 'Individual',
  },
  firstNameLabel: {
    id: 'ProfileFormMessages.firstNameLabel',
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'ProfileFormMessages.lastNameLabel',
    defaultMessage: 'Last name',
  },
  emailLabel: {
    id: 'ProfileFormMessages.emailLabel',
    defaultMessage: 'Email',
  },
  companyNameLabel: {
    id: 'ProfileFormMessages.companyNameLabel',
    defaultMessage: 'Company name',
  },
  companyPhoneLabel: {
    id: 'ProfileFormMessages.companyPhoneLabel',
    defaultMessage: 'Company phone',
  },
  phoneNumberLabel: {
    id: 'ProfileFormMessages.phoneNumberLabel',
    defaultMessage: 'Phone Number',
  },
  vatNumberLabel: {
    id: 'ProfileFormMessages.vatNumberLabel',
    defaultMessage: 'VAT Number',
  },
  billingEmailLabel: {
    id: 'ProfileFormMessages.billingEmailLabel',
    defaultMessage: 'Billing email',
  },
  countryLabel: {
    id: 'ProfileFormMessages.countryLabel',
    defaultMessage: 'Country',
  },
  countryPlaceholder: {
    id: 'ProfileFormMessages.countryPlaceholder',
    defaultMessage: 'Select country',
  },
  postalCodeLabel: {
    id: 'ProfileFormMessages.postalCodeLabel',
    defaultMessage: 'Postal code',
  },
  addressLine1Label: {
    id: 'ProfileFormMessages.addressLine1Label',
    defaultMessage: 'Address line 1',
  },
  addressLine2Label: {
    id: 'ProfileFormMessages.addressLine2Label',
    defaultMessage: 'Address line 2',
  },
  cityLabel: {
    id: 'ProfileFormMessages.cityLabel',
    defaultMessage: 'City',
  },
  regionLabel: {
    id: 'ProfileFormMessages.regionLabel',
    defaultMessage: 'Region',
  },
  cancelLabel: {
    id: 'ProfileFormMessages.cancelLabel',
    defaultMessage: 'Cancel',
  },
  submitLabel: {
    id: 'ProfileFormMessages.submitLabel',
    defaultMessage: 'Save',
  },
  fetchUserError: {
    id: 'ProfileFormMessages.fetchUserError',
    defaultMessage: 'An error occurred while fetching user',
  },
  success: {
    id: 'ProfileFormMessages.success',
    defaultMessage: 'User profile is successfully updated',
  },
  error: {
    id: 'ProfileFormMessages.error',
    defaultMessage: 'An error occurred while updating user profile',
  },
  changePassword: {
    id: 'ProfileFormMessages.changePassword',
    defaultMessage: 'Change password',
  },
  edit: {
    id: 'ProfileFormMessages.edit',
    defaultMessage: 'Edit',
  },
  deliveryCheckBoxLabel: {
    id: 'ProfileFormMessages.deliveryCheckBoxLabel',
    defaultMessage: 'Shipping address is the same',
  },
  legalAddressTitle: {
    id: 'ProfileFormMessages.legalAddressTitle',
    defaultMessage: 'Billing address',
  },
  deliveryAddressTitle: {
    id: 'ProfileFormMessages.deliveryAddressTitle',
    defaultMessage: 'Shipping address',
  },
  deliverySameMobile: {
    id: 'ProfileFormMessages.deliverySameMobile',
    defaultMessage: 'Shipping address – {same}',
  },
  sameMobile: {
    id: 'ProfileFormMessages.sameMobile',
    defaultMessage: 'same',
  },
})
