import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { MailingGetResponse } from '../../../../sdk/datagates/types/mailing/_crud/get'
import { MAILING_STATUSES } from '../../../../sdk/hooks/use-mailing-statuses/constants/MailingStatuses'
import { useMailingStatuses } from '../../../../sdk/hooks/use-mailing-statuses/useMailingStatuses'
import { formatDate } from '../../../../shared/lib/utils/date-utils/formatDate'
import { formatToDateTimeWithoutTZ } from '../../../../shared/lib/utils/format-to-date-without-tz/formatToDateTimeWithoutTZ'
import {
  WavSurfer,
  WavSurferThemes,
} from '../../../../shared/ui-kit-2/components/wav-surfer/WavSurfer'
import { CallsMailingCancelledContent } from './components/calls-mailing/calls-mailing-cancelled-content/CallsMailingCancelledContent'
import { CallsMailingEndedContent } from './components/calls-mailing/calls-mailing-ended-content/CallsMailingEndedContent'
import { CallsMailingInProcess } from './components/calls-mailing/calls-mailing-in-process-content/CallsMailingInProcess'
import { CallsMailingPlanedContent } from './components/calls-mailing/calls-mailing-planned-content/CallsMailingPlanedContent'
import { SmsMailingCancelledContent } from './components/sms-mailing/sms-mailing-cancelled-content/SmsMailingCancelledContent'
import { SmsMailingEndedContent } from './components/sms-mailing/sms-mailing-ended-content/SmsMailingEndedContent'
import { SmsMailingInProcess } from './components/sms-mailing/sms-mailing-in-process-content/SmsMailingInProcess'
import { SmsMailingPlanedContent } from './components/sms-mailing/sms-mailing-planned-content/SmsMailingPlanedContent'
import styles from './styles.module.scss'

interface SmsMailingCreatedContentProps {
  mailing: MailingGetResponse
  handleHideModal: () => void
  onDeleteMailing: () => Promise<void>
  onCancelMailing: () => Promise<void>
  onEditMailing: Dispatch<SetStateAction<boolean>>
  forceAction: 'delete' | 'cancel' | null
  isCallsMailing?: boolean
}

const SmsMailingCreatedContentMessages = defineMessages({
  detailDevice: {
    id: 'SmsMailingCreatedContentMessages.detailDevice',
    defaultMessage: 'Device',
  },
  detailNumbers: {
    id: 'SmsMailingCreatedContentMessages.detailNumbers',
    defaultMessage: 'Recipients',
  },
  detailSentDate: {
    id: 'SmsMailingCreatedContentMessages.detailSentDate',
    defaultMessage: 'Sent Date',
  },
  textTitle: {
    id: 'SmsMailingCreatedContentMessages.textTitle',
    defaultMessage: 'Text',
  },
})

const CallsMailingCreatedContentMessages = defineMessages({
  detailDevice: {
    id: 'CallsMailingCreatedContentMessages.detailDevice',
    defaultMessage: 'Modem',
  },
  detailNumbers: {
    id: 'CallsMailingCreatedContentMessages.detailNumbers',
    defaultMessage: 'Numbers',
  },
  detailSentDate: {
    id: 'CallsMailingCreatedContentMessages.detailSentDate',
    defaultMessage: 'Sent Date',
  },
})

export const MailingCreatedContent: FC<SmsMailingCreatedContentProps> = (
  props,
) => {
  const {
    mailing,
    isCallsMailing, // TODO: надо брать из mailing
    handleHideModal,
    onDeleteMailing,
    onCancelMailing,
    onEditMailing,
    forceAction,
  } = props

  const intl = useIntl()
  const intlSource = isCallsMailing
    ? CallsMailingCreatedContentMessages
    : SmsMailingCreatedContentMessages

  const { getMailingStatusStickerComponent } = useMailingStatuses()

  const content = useMemo(() => {
    switch (mailing.mailingStatusId) {
      case MAILING_STATUSES.PLANNED:
        return isCallsMailing ? (
          <CallsMailingPlanedContent
            onCancelMailing={onCancelMailing}
            onEditMailing={onEditMailing}
            forceAction={forceAction}
          />
        ) : (
          <SmsMailingPlanedContent
            onCancelMailing={onCancelMailing}
            forceAction={forceAction}
          />
        )
      case MAILING_STATUSES.IN_PROCESS:
        return isCallsMailing ? (
          <CallsMailingInProcess
            mailing={mailing}
            handleHideModal={handleHideModal}
          />
        ) : (
          <SmsMailingInProcess
            mailing={mailing}
            handleHideModal={handleHideModal}
          />
        )
      case MAILING_STATUSES.ENDED:
        return isCallsMailing ? (
          <CallsMailingEndedContent
            mailing={mailing}
            onDeleteMailing={onDeleteMailing}
            forceAction={forceAction}
          />
        ) : (
          <SmsMailingEndedContent
            mailing={mailing}
            onDeleteMailing={onDeleteMailing}
            forceAction={forceAction}
          />
        )
      case MAILING_STATUSES.CANCELLED:
        return isCallsMailing ? (
          <CallsMailingCancelledContent
            onDeleteMailing={onDeleteMailing}
            forceAction={forceAction}
          />
        ) : (
          <SmsMailingCancelledContent
            onDeleteMailing={onDeleteMailing}
            forceAction={forceAction}
          />
        )
    }
  }, [])

  const mailingSentDate = useMemo(() => {
    const sentDate = mailing.mailingHistory?.createdAt || mailing.scheduledAt

    return formatToDateTimeWithoutTZ({
      date: sentDate,
      includeSeconds: false,
      toUTC: false,
      humanReadable: true,
    })
  }, [mailing.scheduledAt, mailing.mailingHistory?.createdAt])

  return (
    <div className={styles.Container}>
      <div className={styles.MailingInfoContainer}>
        <div className={styles.MailingInfoHeader}>
          <div className={styles.MailingInfoHeader__title}>{mailing.name}</div>
          {getMailingStatusStickerComponent(mailing.mailingStatusId)}
        </div>

        <div className={styles.MailingInfoDetails}>
          <div className={styles.MailingInfoDetail}>
            <div className={styles.MailingInfoDetail__title}>
              {intl.formatMessage(intlSource.detailDevice)}
            </div>
            <div className={styles.MailingInfoDetail__value}>
              {mailing.dongle.name}
            </div>
          </div>

          <div className={styles.MailingInfoDetails__divider} />

          <div className={styles.MailingInfoDetail}>
            <div className={styles.MailingInfoDetail__title}>
              {intl.formatMessage(intlSource.detailNumbers)}
            </div>
            <div className={styles.MailingInfoDetail__value}>
              {mailing.countMailingPhones}
            </div>
          </div>

          <div className={styles.MailingInfoDetails__divider} />

          <div className={styles.MailingInfoDetail}>
            <div className={styles.MailingInfoDetail__title}>
              {intl.formatMessage(intlSource.detailSentDate)}
            </div>
            <div className={styles.MailingInfoDetail__value}>
              {mailingSentDate}
            </div>
          </div>
        </div>

        {mailing?.file?.cdnUrl && (
          <div className={styles.MailingInfoAudio}>
            <WavSurfer
              audioSource={mailing.file.cdnUrl}
              theme={WavSurferThemes.black}
              showDuration
            />
          </div>
        )}

        {!isCallsMailing && (
          <div className={styles.MessageContainer}>
            <div className={styles.MessageContainer__title}>
              {intl.formatMessage(SmsMailingCreatedContentMessages.textTitle)}
            </div>
            <div className={styles.MessageContainer__text}>{mailing.text}</div>
          </div>
        )}
      </div>

      {content}
    </div>
  )
}
