// @ts-nocheck

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PushNotificationsType {
  sms: {
    smsList: number
    smsAutoReplies: number
    bulkSms: number
    totalCount: number
  }
  calls: {
    callsList: number
    callsNotifications: number
    autoReply: number
    autoReplyRules: number
    autoDialer: number
    totalCount: number
  }
  wifi: {
    wifiList: number
    totalCount: number
  }
  vpn: {
    vpnList: number
    totalCount: number
  }
  modems: {
    modemsList: number
    totalCount: number
  }
  contacts: {
    contactsList: number
    totalCount: number
  }
}

const pushNotificationsState: PushNotificationsType = {
  sms: {
    smsList: 0,
    smsAutoReplies: 0,
    bulkSms: 0,
    totalCount: 0,
  },
  calls: {
    callsList: 0,
    callsNotifications: 0,
    autoReply: 0,
    autoReplyRules: 0,
    autoDialer: 0,
    totalCount: 0,
  },
  wifi: {
    wifiList: 0,
    totalCount: 0,
  },
  vpn: {
    vpnList: 0,
    totalCount: 0,
  },
  modems: {
    modemsList: 0,
    totalCount: 0,
  },
  contacts: {
    contactsList: 0,
    totalCount: 0,
  },
}

const pushNotificationsSlice = createSlice({
  name: 'pushNotifications',
  initialState: pushNotificationsState,
  reducers: {
    setPushNotifications(
      state,
      { payload }: PayloadAction<PushNotificationsType.pushNotifications>,
    ) {
      state.sms = payload.sms || state.sms
      state.calls = payload.calls || state.calls
      state.wifi = payload.wifi || state.wifi
      state.vpn = payload.vpn || state.vpn
      state.modems = payload.modems || state.modems
      state.contacts = payload.contacts || state.contacts
    },
    clearPushNotifications(state) {
      return { ...pushNotificationsState }
    },
  },
})

export const { setPushNotifications, clearPushNotifications } =
  pushNotificationsSlice.actions
export default pushNotificationsSlice.reducer
