import React, { useMemo, useState } from 'react'
import { useDialog } from '../../../../../../app/hooks/useDialog'
import { useModal } from '../../../../../../app/hooks/useModal'
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/arrow-right.svg'
import { ReactComponent as CaretRightIcon } from '../../../../../../assets/icons/caret-right.svg'
import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/trash.svg'
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { RedirectionListResponse } from '../../../../../../sdk/datagates/types/redirection/_crud/list'
import { useDevices } from '../../../../../../sdk/hooks/use-devices/useDevices'
import { REDIRECTION_TYPES } from '../../../../../../sdk/hooks/use-redirection-types/constants/RedirectionTypes'
import { useRedirections } from '../../../../../../sdk/hooks/use-redirections/useRedirections'
import { formatDate } from '../../../../../../shared/lib/utils/date-utils/formatDate'
import { formatDateToTableHeader } from '../../../../../../shared/lib/utils/format-date-to-table-header/formatDateToTableHeader'
import { tableFiltersToUrlFilters } from '../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS,
} from '../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader'
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
} from '../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols'
import { useTableFilters } from '../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters'
import {
  RowGroupType,
  Table,
} from '../../../../../../shared/ui-kit-2/data-display/table/Table'
import ControlHeader from '../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader'
import { DialogVersion } from '../../../../../../store/reducers/dialog/types'
import styles from './styles.module.scss'
import {
  NOT_FOUND_TYPES,
  NotFoundDevicesBanner
} from "../../../../../../shared/global-components/Banners/banners/NotFoundDevicesBanner";

type RedirectionGroupsType = {
  [key: string]: RedirectionListResponse['redirections']
}

// TODO: Add intl
export const CallNotificationsPage = () => {
  const { handleOpenModal } = useModal()

  const { handleOpenDialog, handleHideDialog } = useDialog()

  const COLS = {
    notification: 'notification',
    modems: 'modems',
    numbers: 'numbers',
    email: 'email',
    telegram: 'telegram',
  }

  const headerCols = [
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    {
      id: COLS.notification,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Notification name',
      defaultText: 'Enter notification name',
    },
    {
      id: COLS.modems,
      label: 'Modems',
    },
    {
      id: COLS.numbers,
      label: 'Numbers',
      postfix: <ArrowRightIcon />,
    },
    {
      id: COLS.email,
      label: 'Email',
    },
    {
      id: COLS.telegram,
      label: 'Telegram',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[]

  const { filters, handleChangeFilters, handlePageChange, debouncedFilters } =
    useTableFilters({
      queryIds: [...headerCols.map(({ id }) => id), 'page', 'limit'],
      defaultFilterValues: [
        {
          name: 'redirection_type_ids',
          value: [REDIRECTION_TYPES.CALL],
        },
        {
          name: 'page',
          value: 1,
        },
        {
          name: 'limit',
          value: 50,
        },
      ],
    })

  const currentFilters = useMemo(
    () =>
      tableFiltersToUrlFilters([
        {
          name: 'redirection_type_ids[]',
          value: debouncedFilters?.redirection_type_ids,
        },
        { name: 'page', value: debouncedFilters?.page - 1 },
        { name: 'limit', value: debouncedFilters?.limit },
        { name: 'from', value: debouncedFilters?.time?.from },
        { name: 'to', value: debouncedFilters?.time?.to },
        { name: 'name', value: debouncedFilters?.notification },
      ]),
    [debouncedFilters],
  )

  const { devices, loading: devicesLoading } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    isActive: true,
  })

  const {
    redirections,
    totalCount,
    handleFetch,
    handleDeleteRedirection: handleDelete,
    loading: redirectionsLoading,
  } = useRedirections({
    // deviceIds: devices.map(({ dongleId }) => String(dongleId)),
    takeAll: true,
    filters: currentFilters,
  })

  const handleOpenRedirectionSettingsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirectionTypeId: REDIRECTION_TYPES.CALL,
        handleFetch,
        phoneSourceVariant: 'international-only',
      },
    })
  }

  const handleOpenEditRedirectionModal = (
    redirection: RedirectionListResponse['redirections'][0],
  ) => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirection: redirection,
        redirectionTypeId: redirection.redirectionTypeId,
        handleFetch,
        handleDeleteRedirection: handleDelete,
        phoneSourceVariant: 'international-only',
      },
    })
  }

  const redirectionGroups = useMemo(
    () =>
      redirections.reduce<RedirectionGroupsType>((acc, redirection) => {
        const date = formatDate(redirection.createdAt, 0, 'date')

        if (acc.hasOwnProperty(date))
          acc[date as keyof typeof acc].push(redirection)
        else acc[date as keyof typeof acc] = [redirection]

        return acc
      }, {}),
    [redirections],
  )

  const onDeleteNotification = (id: number) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete autodialer?',
        subtitle: 'This action cannot be undone',
        subtitleIsAlert: true,
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog(),
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: async () => {
            handleHideDialog()
            await handleDelete(id)
            await handleFetch()
          },
        },
      },
    })
  }

  const rowGroups = useMemo(() => {
    return Object.entries(redirectionGroups).map<RowGroupType>(
      ([date, redirections]) => {
        return {
          groupHeader: <div>{formatDateToTableHeader(date)}</div>,
          rows: redirections.map((redirection) => {
            return [
              <div className={styles.Time}>
                {formatDate(redirection.createdAt, 0, 'time')}
              </div>,
              <div>{redirection.name}</div>,
              <div className={styles.Counter}>
                <Counter
                  count={redirection.deviceIds.length}
                  variant="filled"
                />
              </div>,
              <div className={styles.Counter}>
                <Counter
                  count={redirection.fromContactIds.length}
                  variant="filled"
                />
                <div className={styles.Counter__Postfix}>
                  <ArrowRightIcon />
                </div>
              </div>,
              <div className={styles.Counter}>
                <Counter count={redirection.toEmails.length} variant="filled" />
              </div>,
              <div className={styles.Counter}>
                <Counter
                  count={redirection.toTelegramChatIds.length}
                  variant="filled"
                />
              </div>,
              <div className={styles.Actions}>
                <div
                  className={styles.Action}
                  tabIndex={0}
                  onClick={() =>
                    onDeleteNotification(redirection.redirectionId)
                  }
                >
                  <TrashIcon />
                </div>
                <div
                  className={styles.Action}
                  tabIndex={0}
                  onClick={() => handleOpenEditRedirectionModal(redirection)}
                >
                  <CaretRightIcon />
                </div>
              </div>,
            ]
          }),
        }
      },
    )
  }, [redirectionGroups]);

  const userHasDevices = useMemo(() => devices?.length > 0, [devices]);

  const globalLoading = useMemo(() =>
    redirectionsLoading || devicesLoading,
    [redirectionsLoading, devicesLoading]
  );


  return (
    <div className={styles.Container}>
      <Card additionalClassNames={[styles.CardAdditional]}>
        {}
        <ControlHeader
          title={'Calls notifications'}
          counter={{
            min: redirections.length,
            max: totalCount,
          }}
          actions={userHasDevices ? [
            {
              variant: 'greenFilled',
              size: 'md',
              text: 'Create',
              prefix: <PlusIcon />,
              onClick: handleOpenRedirectionSettingsModal,
            },
          ] : []}
          loading={globalLoading}
          hideCounter={!userHasDevices}
        />

        <Table
          headerCols={headerCols}
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          name={'CallNotification'}
          rowGroups={rowGroups}
          currentPage={filters.page}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          itemsPerPage={filters.limit}
          loading={globalLoading}
          columnWidths={['125px', 1.5, 1, 1, 1, 1, '100px']}
          banner={!userHasDevices && <NotFoundDevicesBanner type={NOT_FOUND_TYPES.CALLS} />}
        />
      </Card>
    </div>
  )
}
