export const FAQSectionAnchors = {
  ARTICLE1: '#article1',
  'ARTICLE1-1': '#article1-1',
  'ARTICLE1-2': '#article1-2',
  'ARTICLE1-3': '#article1-3',
  ARTICLE2: '#article2',
  'ARTICLE2-1': '#article2-1',
  'ARTICLE2-2': '#article2-2',
  'ARTICLE2-3': '#article2-3',
  'ARTICLE2-4': '#article2-4',
  'ARTICLE2-5': '#article2-5',
  'ARTICLE2-6': '#article2-6',
  ARTICLE3: '#article3',
  'ARTICLE3-1': '#article3-1',
  'ARTICLE3-2': '#article3-2',
  'ARTICLE3-3': '#article3-3',
  'ARTICLE3-4': '#article3-4',
  ARTICLE4: '#article4',
  'ARTICLE4-1': '#article4-1',
  'ARTICLE4-2': '#article4-2',
  'ARTICLE4-3': '#article4-3',
  ARTICLE5: '#article5',
  'ARTICLE5-1': '#article5-1',
  ARTICLE6: '#article6',
  'ARTICLE6-1': '#article6-1',
  ARTICLE7: '#article7',
  'ARTICLE7-1': '#article7-1',
  'ARTICLE7-2': '#article7-2',
  'ARTICLE7-3': '#article7-3',
  'ARTICLE7-4': '#article7-4',
  'ARTICLE7-5': '#article7-5',
  'ARTICLE7-6': '#article7-6',
  ARTICLE8: '#article8',
  'ARTICLE8-1': '#article8-1',
} as Record<string, string>
