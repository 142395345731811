import React, { useEffect } from 'react'
import EmptyEventLogImg from '../../../../../../assets/images/v2/no-modem-data.png'
import styles from './styles.module.scss'

const EmptyEventLog = () => (
  <div className={styles.EmptyEventLog}>
    <div className={styles.EmptyEventLog__Image}>
      <img src={EmptyEventLogImg} alt="Empty Event Log" />
    </div>
    <div className={styles.EmptyEventLog__TextContainer}>
      <div className={styles.EmptyEventLog__Text}>
        Looks like you don’t have any notifications yet!
      </div>
      <div className={styles.EmptyEventLog__SubText}>
        We’ll share important platform updates and information about your
        account with you here.
      </div>
    </div>
  </div>
)

const EventLog = () => {
  return (
    <div className={styles.EventLog}>
      <div className={styles.EventLog__Top}>
        <div className={styles.EventLog__Title}>Event Log</div>
      </div>
      <EmptyEventLog />
    </div>
  )
}

export default EventLog
