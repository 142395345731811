import { useEffect, useRef, useState } from 'react'
import { getWebsocketsUrl } from '../../../config/api/helpers/getWebsocketsUrl'
import { getAccessToken } from '../../../store/reducers/user'
import { WEB_SOCKETS_EVENTS_VALUES } from '../constants/WEB_SOCKETS_EVENTS_VALUES'

export enum WebSocketMessageType {
  PING_MESSAGE = 1,
  PONG_MESSAGE = 2,
  PUSH_NOTIFICATION_MESSAGE = 3,
  ERROR_MESSAGE = 4,
}

type WebSocketMessage = {
  messageType: WebSocketMessageType
  messageId: number
  event: string
  entityId: string
}

type EventHandler = (event: MessageEvent) => void

type UseWebSocketsProps = {
  events: Array<WEB_SOCKETS_EVENTS_VALUES | string>
  url?: string
}

const socketInstances: Map<string, WebSocket> = new Map()

const getSocket = (url: string): WebSocket => {
  try {
    if (!socketInstances.has(url)) {
      const socket = new WebSocket(url)
      socketInstances.set(url, socket)
    }
  } catch (e) {
    console.error('websockets error: ', e)
  }

  return socketInstances.get(url)!
}

const parseMessage = (event: MessageEvent): WebSocketMessage | null => {
  try {
    return JSON.parse(event.data)
  } catch {
    return null
  }
}

export const useWebSockets = ({ events, url }: UseWebSocketsProps) => {
  const [webSocketsEventData, setWebSocketsEventData] = useState<any>(null)
  const idCounterRef = useRef<number>(1)
  const eventHandlerRef = useRef<EventHandler | null>(null)
  const accessToken = getAccessToken()

  useEffect(() => {
    const webSocketsDefaultUrl = getWebsocketsUrl(
      // @ts-ignore
      window?.env?.REACT_APP_WS_HOST,
    )
    const sourceUrl = url ?? webSocketsDefaultUrl
    const socket = sourceUrl && getSocket(sourceUrl)

    if (socket) {
      socket.addEventListener('open', () => {
        if (accessToken) {
          try {
            socket.send(
              JSON.stringify({
                messageId: idCounterRef.current,
                messageType: WebSocketMessageType.PING_MESSAGE,
                accessToken,
              }),
            )
            idCounterRef.current += 1
          } catch (e) {
            console.log(e)
          }
        }
      })
    }

    const handleEvent: EventHandler = (event) => {
      const data = parseMessage(event)

      if (!data || !socket) return

      switch (data.messageType) {
        case WebSocketMessageType.PING_MESSAGE:
          socket.send(
            JSON.stringify({
              messageId: data.messageId,
              messageType: WebSocketMessageType.PONG_MESSAGE,
            }),
          )
          break

        case WebSocketMessageType.PUSH_NOTIFICATION_MESSAGE:
          if (events.includes(data?.event)) {
            setWebSocketsEventData(data)
          }
          break

        case WebSocketMessageType.ERROR_MESSAGE:
          break

        default:
          console.warn('Unknown messageType:', data.messageType)
          break
      }
    }

    eventHandlerRef.current = handleEvent

    if (socket) {
      socket.addEventListener('message', handleEvent)
    }

    return () => {
      const socket = sourceUrl && getSocket(sourceUrl)

      if (eventHandlerRef.current && socket) {
        socket.removeEventListener('message', eventHandlerRef.current)
        eventHandlerRef.current = null
      }
    }
  }, [events, url, accessToken])

  return {
    webSocketsEventData,
  }
}
