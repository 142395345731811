import { useState } from 'react'
import { useOrderDongle } from '../../../../../../../../sdk/hooks/use-order-dongle/useOrderDongle'
import { Button } from '../../../../../../../../shared/ui-kit-3/components/Button'
import { SubscriptionSelector } from '../subscription-selector/SubscriptionSelector'
import styles from './styles.module.scss'

export const SelectSubscription = ({
  mac,
  imei,
  onSubmit,
}: {
  mac?: string
  imei?: string
  onSubmit: ({ orderDongleId }: { orderDongleId: string }) => Promise<void>
}) => {
  const [selected, setSelected] = useState<string | number | null>(null)
  const { monthlyAvailable, annualAvailable } = useOrderDongle()

  const options = [
    { id: 'monthly', label: 'Monthly Subscription' },
    { id: 'annual', label: 'Annual Subscription' },
  ]

  const handleSubmit = async () => {
    const tariffSource =
      selected === 'monthly' ? monthlyAvailable : annualAvailable

    if (tariffSource.length > 0) {
      await onSubmit({ orderDongleId: tariffSource[0]?.orderDongleId })
    }
  }

  return (
    <div className={styles.SelectSubscription}>
      {(mac || imei) && (
        <div className={styles.Card}>
          {mac && (
            <div className={styles.Card__Row}>
              <div className={styles.Card__Name}>MAC Address:</div>
              <div className={styles.Card__Value}>{mac}</div>
            </div>
          )}
          {imei && (
            <div className={styles.Card__Row}>
              <div className={styles.Card__Name}>IMEI:</div>
              <div className={styles.Card__Value}>{imei}</div>
            </div>
          )}
        </div>
      )}
      <div className={styles.Available}>
        {monthlyAvailable && (
          <div className={styles.Available__Item}>
            Monthly Available
            <span className={styles.Available__Dots}>:</span>
            <span className={styles.Available__Count}>
              {monthlyAvailable?.length}
            </span>
          </div>
        )}
        {annualAvailable && (
          <div className={styles.Available__Item}>
            Annual Available
            <span className={styles.Available__Dots}>:</span>
            <span className={styles.Available__Count}>
              {annualAvailable?.length}
            </span>
          </div>
        )}
      </div>
      <SubscriptionSelector
        selected={selected}
        setSelected={setSelected}
        options={options}
      />
      <div className={styles.Button}>
        <Button
          variant={'greenFilled'}
          text={'Activate'}
          size={'xl'}
          disabled={!Boolean(selected)}
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  )
}
