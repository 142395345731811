import React from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { ReactComponent as BadSignalIcon } from '../../../../assets/icons/status-network-bad.svg'
import { ReactComponent as GoodSignalIcon } from '../../../../assets/icons/status-network-good.svg'
import { ReactComponent as MediumSignalIcon } from '../../../../assets/icons/status-network-medium.svg'
import { DONGLE_SIGNAL_ID } from '../../../../sdk/datagates/types/dongle/_common/constants'
import styles from './styles.module.scss'

const StatusNetworkMessages = defineMessages({
  online: {
    id: 'StatusNetworkMessages.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'StatusNetworkMessages.offline',
    defaultMessage: 'Offline',
  },
})

interface StatusNetworkProps {
  isOnline?: boolean
  dongleSignalQualityId?: number
}

export const StatusNetwork = (props: StatusNetworkProps) => {
  const { isOnline, dongleSignalQualityId } = props
  const intl = useIntl()

  const statusIcon = () => {
    switch (isOnline && dongleSignalQualityId) {
      case DONGLE_SIGNAL_ID.SIGNAL_GOOD:
        return <GoodSignalIcon />
      case DONGLE_SIGNAL_ID.SIGNAL_MEDIUM:
        return <MediumSignalIcon />
      case DONGLE_SIGNAL_ID.SIGNAL_BAD:
        return <BadSignalIcon />
      default:
        return
    }
  }
  return (
    <div className={clsx(styles.StatusNetwork, !isOnline && styles.Offline)}>
      {isOnline
        ? intl.formatMessage(StatusNetworkMessages.online)
        : intl.formatMessage(StatusNetworkMessages.offline)}
      {statusIcon()}
    </div>
  )
}
