import { Route } from '../enums/route'

export const RoutePath: { [key in Route]: string } = {
  [Route.Home]: '/',
  [Route.PrivacyPolicy]: '/privacy-policy',
  [Route.TermsOfUse]: '/terms-of-use',
  [Route.NotFound]: '/not-found',
  [Route.FAQ]: '/faq',
  [Route.Dashboard]: '/dashboard',
  [Route.VPN]: '/vpn',
  [Route.Auth]: '/auth/*',
  [Route.PurchaseProcess]: '/lk/purchase-process',
  [Route.ConnectToDongle]: '/connect-to-dongle',

  [Route.ActivateDevice]: '/lk/activate-device',
  [Route.ManualDeviceActivation]: '/lk/activate-device/manual',
  [Route.AutoDeviceActivation]: '/lk/activate-device/auto',
  [Route.DeviceConnectionStatus]: '/lk/activate-device/status',

  [Route.PaymentSuccess]: '/lk/payment-success',
  [Route.PaymentFail]: '/lk/payment-fail',
  [Route.AddCardSuccess]: '/lk/add-card-success',
  [Route.Orders]: '/lk/orders',
  [Route.DeviceList]: '/lk/device-list',
  [Route.Sms]: '/lk/sms/*',
  [Route.Calls]: '/lk/calls/*',
  [Route.WifiList]: '/lk/wifi-list',
  [Route.VPN]: '/lk/vpn',
  [Route.Profile]: '/lk/profile',
  [Route.Api]: '/lk/api',
  [Route.Contacts]: '/lk/contacts',
}
