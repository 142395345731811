import React, { FC, ReactNode, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as ErrorIcon } from './assets/icons/error.svg'
import { ReactComponent as ValidatedIcon } from './assets/icons/validated.svg'
import styles from './styles.module.scss'

const INPUT_SIZE_LIST = {
  default: 'default',
  small: 'small',
} as const

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string
  error?: string
  helperText?: string
  postfix?: string | ReactNode
  additionalAction?: {
    title: string
    activated: boolean
    callback: (...args: any[]) => any
  }
  markAsRequired?: boolean
  validationNeeded?: boolean
  inputSize?: (typeof INPUT_SIZE_LIST)[keyof typeof INPUT_SIZE_LIST]
  inputRef?: React.LegacyRef<HTMLInputElement>
  additionalClassNames?: string[]
  testId?: string
  customError?: string
  showErrorText?: boolean
  showSuccessValidationIcon?: boolean
}

export const Input: FC<InputProps> = (props) => {
  const {
    label,
    error,
    helperText,
    postfix,
    additionalAction,
    markAsRequired,
    validationNeeded = true,
    inputSize = INPUT_SIZE_LIST.default,
    inputRef,
    additionalClassNames = [],
    testId,
    showErrorText = true,
    showSuccessValidationIcon = true,
    maxLength,
    ...otherProps
  } = props

  const [hasValueOnBlur, setHasValueOnBlur] = useState<boolean>()

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    otherProps.onFocus?.(e)
    setHasValueOnBlur(false)
  }
  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    otherProps.onBlur?.(e)
    setHasValueOnBlur(!!e.target.value)
  }

  const disableKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'e' ||
      e.key === '+' ||
      e.key === '-' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown'
    ) {
      e.preventDefault()
    }
  }

  return (
    <div className={clsx(styles.Container, ...additionalClassNames)}>
      {label && (
        <label className={styles.Label}>
          {label}
          {markAsRequired && <span>*</span>}
        </label>
      )}

      <div
        className={clsx(
          styles.Area,
          inputSize === INPUT_SIZE_LIST.small && styles.Area_small,
        )}
      >
        <input
          {...otherProps}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          ref={inputRef}
          data-test-id={testId}
          onKeyDown={
            props.type === 'number' ? (e) => disableKeyDown(e) : () => {}
          }
          {...(maxLength ? { maxLength: maxLength } : {})}
        />
        <div
          className={clsx(
            styles.Area__background,
            error && styles.Area__background_error,
          )}
        />

        <div
          className={clsx(
            styles.AdditionalContent,
            inputSize === INPUT_SIZE_LIST.small &&
              styles.AdditionalContent_small,
          )}
        >
          {!error &&
            hasValueOnBlur &&
            validationNeeded &&
            showSuccessValidationIcon && (
              <div className={styles.AdditionalContent__validationElement}>
                <ValidatedIcon />
              </div>
            )}
          {error && validationNeeded && (
            <div className={styles.AdditionalContent__validationElement}>
              <ErrorIcon />
            </div>
          )}
          {postfix && (
            <div className={styles.AdditionalContent__postfix}>{postfix}</div>
          )}
          {additionalAction && (
            <div
              className={clsx(
                styles.AdditionalContent__action,
                additionalAction.activated &&
                  styles.AdditionalContent__action_activated,
              )}
              onClick={additionalAction.callback}
            >
              {additionalAction.title}
            </div>
          )}
        </div>
      </div>

      {helperText && <div className={styles.HelperText}>{helperText}</div>}
      {error && showErrorText && <div className={styles.Error}>{error}</div>}
    </div>
  )
}
