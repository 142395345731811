// @ts-nocheck

import React, { useEffect, useRef } from 'react'
import { Environment, useGLTF } from '@react-three/drei'
import * as THREE from 'three'

export function Modem3D(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3d-models/modem/new/modem_v002.glb')

  useEffect(() => {
    // Считаем габаритный контейнер всей группы
    const box = new THREE.Box3().setFromObject(group.current)
    // Получаем центр этого контейнера
    const center = new THREE.Vector3()
    box.getCenter(center)
    // Вычитаем координаты центра, чтобы «опустить» их в (0,0,0)
    group.current.position.x -= center.x
    group.current.position.y -= center.y
    group.current.position.z -= center.z
  }, [])

  return (
    <group ref={group} {...props} dispose={null}>
      <Environment
        files="/3d-models/modem/new/brown_photostudio_02_1k.hdr"
        background={false}
      />
      <mesh
        geometry={nodes.pCube1.geometry}
        material={materials['white plastic']}
      />
      <mesh
        geometry={nodes.pCube2.geometry}
        material={materials['white plastic']}
      />
      <mesh
        geometry={nodes.svgMesh1.geometry}
        material={materials['grey plastic']}
      />
      <mesh
        geometry={nodes.svgMesh2.geometry}
        material={materials['grey plastic']}
      />
      <mesh geometry={nodes.Mesh013.geometry} material={materials.metall} />
      <mesh
        geometry={nodes.Mesh013_1.geometry}
        material={materials['Material.002']}
      />
      <mesh
        geometry={nodes.Mesh013_2.geometry}
        material={materials['Material.003']}
      />
      <mesh
        geometry={nodes.Mesh017.geometry}
        material={materials['green plastic']}
      />
      <mesh
        geometry={nodes.Mesh017_1.geometry}
        material={materials['dark plastic.001']}
      />
    </group>
  )
}
