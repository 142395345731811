import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapSipUserGetBackendToSipUserGet } from '../../mappers/sip-user/_crud/get/map-sip-user-get-backend-to-sip-user-get'
import {
  SipUserGetBackendResponse,
  SipUserGetRequest,
  SipUserGetResponse,
} from '../../types/sip-user/_crud/get'

export const sipUserGetDatagate = wrapApiRequest<
  SipUserGetRequest,
  SipUserGetBackendResponse,
  SipUserGetResponse
>('sip-user', mapSipUserGetBackendToSipUserGet, 'GET')
