import { useWindowSize } from './useWindowSize'

export const ScreenDevices = {
  LAPTOP_L: 'laptop_l',
  LAPTOP: 'laptop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
  MOBILE_LK: 'mobile_lk',
  MOBILE_LK_XL: 'mobile_lk_xl',
}

const minScreenSizes: { [key: string]: number } = {
  [ScreenDevices.LAPTOP_L]: 1280,
  // TODO: ЗАМЕНИТЬ ВСЕ ВХОЖДЕНИЯ laptop на tablet для лэндинга
  [ScreenDevices.LAPTOP]: 1070,
  [ScreenDevices.TABLET]: 680,
  [ScreenDevices.MOBILE]: 335,
  [ScreenDevices.MOBILE_LK]: 1000,
  [ScreenDevices.MOBILE_LK_XL]: 1150,
}

export const useLessThanDeviceScreen = (device: string): boolean => {
  const { width } = useWindowSize()

  return width < minScreenSizes[device]
}

export const useDeviceScreenName = (): string => {
  const { width } = useWindowSize()

  const devicesMap = Object.entries(minScreenSizes)

  for (let i = 0; i < devicesMap.length; i++) {
    const [device, minSize] = devicesMap[i]
    if (width >= minSize) return device
  }

  return ScreenDevices.LAPTOP_L
}
