import React, { FC, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../app/hooks/useModal'
import { ReactComponent as CaretDownIcon } from '../../../../../../assets/icons/v2/ic-caret-down.svg'
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg'
import { CommonTabMessages } from '../../../../../../config/intl/common-messages/common-tab-messages/CommonTabMessages'
import {
  useFilters,
  UseFiltersTestIdsType,
} from '../../../../../../layouts/filters/hook/useFilters'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { SmsTableRows } from '../../../../../../layouts/tables/sms-table/components/sms-table-rows/SmsTableRows'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  SmsTypeTabs,
  useSmses,
} from '../../../../../../sdk/hooks/use-smses/useSmses'
import { WEB_SOCKETS_EVENTS_VALUES } from '../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES'
import { useTableFiltersWithPushNotifications } from '../../../../../../shared/lib/hooks/useTableFiltersWithPushNotifications'
import { useWebSockets } from '../../../../../../shared/lib/hooks/useWebSockets'
import { getUrlParams } from '../../../../../../shared/lib/utils/get-url-params/get-url-params'
import { tableFiltersToUrlFilters } from '../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS,
} from '../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader'
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
  IconContainer,
} from '../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols'
import { useTableFilters } from '../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters'
import { Table } from '../../../../../../shared/ui-kit-2/data-display/table/Table'
import ControlHeader from '../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader'
import { LC } from '../../../../../../tests/e2e/locators'
import { SMS_STATUSES } from '../../components/SmsStatus/constants/SmsStatuses'
import styles from './styles.module.scss'
import {
  NOT_FOUND_TYPES,
  NotFoundDevicesBanner
} from "../../../../../../shared/global-components/Banners/banners/NotFoundDevicesBanner";
import {SmsListPageProps} from "../../SmsPage";

const SmsListPageMessages = defineMessages({
  title: {
    id: 'SmsListPageMessages.title',
    defaultMessage: 'SMS',
  },
  newSms: {
    id: 'SmsListPageMessages.newSms',
    defaultMessage: 'SMS',
  },
})

export const SmsListPage: FC<SmsListPageProps> = ({
  activeDevices,
  activeDevicesLoading,
  userHasDevices
}) => {
  const intl = useIntl()

  const { webSocketsEventData } = useWebSockets({
    events: [
      WEB_SOCKETS_EVENTS_VALUES.sms_status_changed,
      WEB_SOCKETS_EVENTS_VALUES.sms_incoming_created,
      WEB_SOCKETS_EVENTS_VALUES.sms_outgoing_delayed_created,
    ],
  })

  const { handleOpenModal } = useModal()

  const COLS = {
    type: 'type',
    smsc: 'smsc',
  }

  const headerCols = [
    {
      id: COLS.type,
      filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
      label: 'Type',
      defaultText: 'Select',
      defaultTextIcon: (
        <IconContainer>
          <CaretDownIcon />
        </IconContainer>
      ),
      checkboxes: [
        { text: 'All', value: 'All' },
        { text: 'Incoming', value: '2' },
        { text: 'Outgoing', value: '1' },
        { text: 'Planned', value: '5' },
      ],
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.smsStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.sender],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.recipient],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.smsc],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.textMessage],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[]

  const { filters, handleChangeFilters, handlePageChange, debouncedFilters } =
    useTableFilters({
      queryIds: [...headerCols.map(({ id }) => id), 'page', 'limit'],
      defaultFilterValues: [
        {
          name: 'sms_status_ids[]',
          value: [1, 2],
        },
        {
          name: 'page',
          value: 1,
        },
        {
          name: 'limit',
          value: 50,
        },
      ],
    })

  useTableFiltersWithPushNotifications({
    filters,
    events: [
      WEB_SOCKETS_EVENTS_VALUES.sms_status_changed,
      WEB_SOCKETS_EVENTS_VALUES.sms_incoming_created,
      WEB_SOCKETS_EVENTS_VALUES.sms_outgoing_delayed_created,
    ],
    reduxTotalCountObjPath: 'sms.totalCount',
  })

  const smsStatuses = useMemo(() => {
    let smsStatuses = filters?.smsStatus ? [...filters?.smsStatus] : undefined

    if (smsStatuses) {
      if (smsStatuses.includes(SMS_STATUSES.CREATED)) {
        smsStatuses.push(SMS_STATUSES.SENT)
      } else {
        smsStatuses = smsStatuses.filter(
          (status) => status !== SMS_STATUSES.SENT,
        )
      }
    }

    return smsStatuses
  }, [filters?.smsStatus])

  const currentFilters = useMemo(
    () =>
      tableFiltersToUrlFilters([
        { name: 'page', value: debouncedFilters?.page - 1 },
        { name: 'limit', value: debouncedFilters?.limit },
        { name: 'sms_type_ids[]', value: debouncedFilters?.type },
        { name: 'from', value: debouncedFilters?.time?.from },
        { name: 'to', value: debouncedFilters?.time?.to },
        { name: 'sms_status_ids[]', value: smsStatuses },
        { name: 'caller', value: debouncedFilters?.sender },
        { name: 'receiver', value: debouncedFilters?.recipient },
        { name: 'sms_center', value: debouncedFilters?.smsc },
        { name: 'text', value: debouncedFilters?.textMessage },
      ]),
    [debouncedFilters, smsStatuses],
  )

  const {
    smses,
    totalCount: smsesTotalCount,
    handleFetch: handleFetchSmses,
    handleDeleteSms,
    loading: smsesLoading,
  } = useSmses({
    filters: currentFilters,
  })

  const openSendSmsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.SEND_SMS,
      props: { handleFetch: handleFetchSmses },
    })
  }

  useEffect(() => {
    if (filters.page === 1 && !smsesLoading) {
      handleFetchSmses({ hidden: true })
    }
  }, [webSocketsEventData])

  const rowGroups = SmsTableRows(smses, handleFetchSmses, handleDeleteSms)

  const globalLoading = activeDevicesLoading || smsesLoading

  return (
    <Card additionalClassNames={[styles.Card]}>
      <ControlHeader
        title={'SMS'}
        counter={{
          min: smses.length,
          max: smsesTotalCount,
        }}
        actions={userHasDevices ? [
          {
            variant: 'greenFilled',
            size: 'md',
            text: intl.formatMessage(SmsListPageMessages.newSms),
            prefix: <PlusIcon />,
            onClick: openSendSmsModal,
          },
        ] : []}
        loading={globalLoading}
      />

      <Table
        headerCols={headerCols}
        filters={filters}
        handleChangeFilters={handleChangeFilters}
        name={'SMS'}
        rowGroups={rowGroups}
        currentPage={filters.page}
        totalCount={smsesTotalCount}
        onPageChange={handlePageChange}
        itemsPerPage={filters.limit}
        loading={globalLoading}
        columnWidths={['100px', '125px', '120px', 2, 2, 1.5, 2, '100px']}
        testId={LC.SMS.TABLE._}

        banner={!userHasDevices && <NotFoundDevicesBanner type={NOT_FOUND_TYPES.SMSES} />}
      />
    </Card>
  )
}
