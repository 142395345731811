import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { NotificationsContext } from '../../../app/providers/notifications/notifications'
import { WEB_SOCKETS_EVENTS_VALUES } from '../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES'
import {
  pushNotificationGetDatagate,
  PushNotificationGetResponse,
  pushNotificationReadDatagate,
} from '../../datagates/api/push-notification'
import {useAppSelector} from "../../../store";

export const usePushNotifications = ({
  events,
  initialFetch = true,
}: {
  events: Array<WEB_SOCKETS_EVENTS_VALUES>
  initialFetch?: boolean
}) => {
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)
  const [pushNotifications, setPushNotifications] = useState<
   PushNotificationGetResponse['pushNotifications'] | null
  >(null)
  const [fetchNotificationsLoading, setFetchNotificationsLoading] =
   useState<boolean>(true)
  const [readNotificationsLoading, setReadNotificationsLoading] =
   useState<boolean>(false)
  
  const notificationsContext = useContext(NotificationsContext)
  
  const handleFetchNotifications = useCallback(async () => {
    if (isAuthenticated) {
      try {
        setFetchNotificationsLoading(true)
        
        const { data } = await pushNotificationGetDatagate(
         null,
         null,
         events.map((event) => ({
           name: 'events[]',
           value: event,
         })),
        )
        
        if (data) {
          setPushNotifications(
           data?.pushNotifications?.length > 0
            ? [...data.pushNotifications]
            : [],
          )
        }
      } catch {
      } finally {
        setFetchNotificationsLoading(false)
      }
    }
  }, [])
  
  const handleReadEvents = async ({
                                    events,
                                  }: {
    events: Array<WEB_SOCKETS_EVENTS_VALUES>
  }) => {
    if (isAuthenticated) {
      try {
        setReadNotificationsLoading(true)
        
        await pushNotificationReadDatagate({
          events: events.map((event) => event),
        })
        
        await notificationsContext?.handleFetchAllNotifications?.()
      } catch {
      } finally {
        setReadNotificationsLoading(false)
      }
    }
  }
  
  useEffect(() => {
    if (initialFetch) {
      handleFetchNotifications()
    }
  }, [])
  
  return {
    pushNotifications,
    fetchNotificationsLoading,
    readNotificationsLoading,
    handleFetchNotifications,
    handleReadEvents,
  }
}
