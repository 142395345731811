import React, { FC, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { useSnackbar } from '../../../../../../../../app/hooks/useSnackbar'
import { LoadingContent } from '../../../../../../../../layouts/loading-content/LoadingContent'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import { SNACKBAR_TYPES } from '../../../../../../../../layouts/snackbar/SnackbarProvider'
import {
  userGetDatagate,
  userUpdateDatagate,
} from '../../../../../../../../sdk/datagates/api/user'
import { useBackendErrorCodes } from '../../../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { USER_TYPE_IDS } from '../../../../../../../../sdk/datagates/types/user/_crud/get'
import { useCountrySelectOptions } from '../../../../../../../../sdk/hooks/use-countries/useCountrySelectOptions'
import { useDevices } from '../../../../../../../../sdk/hooks/use-devices/useDevices'
import { COUNTRY_PHONES } from '../../../../../../../../shared/lib/constants/COUNTRY_PHONES'
import { InputField } from '../../../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { PhoneInputFieldNumber } from '../../../../../../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew'
import { PhoneInputField } from '../../../../../../../../shared/lib/form/form-field-templates/v2/phone-input-field/PhoneInputField'
import { useFormRules } from '../../../../../../../../shared/lib/form/form-rules'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { getClearedPhoneNumber } from '../../../../../../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber'
import { getErrorMessage } from '../../../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { ItemWrapper } from '../../../../../../../../shared/ui-kit/data-display/ItemWrapper/ItemWrapper'
import { useAppDispatch } from '../../../../../../../../store'
import { setUser } from '../../../../../../../../store/reducers/user'
import styles from '../../../profilePageEn/components/profile-page-form/styles.module.scss'

export type ProfileFormType = {
  firstName: string
  lastName: string
  email: string
  companyName: string
  companyPhone?: string
  userPhone?: string
  nationalNumber?: string
  vatNumber?: string
  billingEmail?: string
  countryId: string
  postalCode?: string
  streetAddress1: string
  streetAddress2?: string
  city?: string
  region?: string
}

export const ProfileFormMessages = defineMessages({
  accountInfo: {
    id: 'ProfileFormMessages.accountInfo',
    defaultMessage: 'Account info',
  },
  devicesCount: {
    id: 'ProfileFormMessages.devicesCount',
    defaultMessage: 'Devices: {count}',
  },
  onlineCount: {
    id: 'ProfileFormMessages.onlineCount',
    defaultMessage: 'Online: {count}',
  },
  accountTypeLabel: {
    id: 'ProfileFormMessages.accountTypeLabel',
    defaultMessage: 'Account type:',
  },
  accountTypeOrganization: {
    id: 'ProfileFormMessages.accountTypeOrganization',
    defaultMessage: 'Organization',
  },
  accountTypePerson: {
    id: 'ProfileFormMessages.accountTypePerson',
    defaultMessage: 'Individual',
  },
  firstNameLabel: {
    id: 'ProfileFormMessages.firstNameLabel',
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'ProfileFormMessages.lastNameLabel',
    defaultMessage: 'Last name',
  },
  emailLabel: {
    id: 'ProfileFormMessages.emailLabel',
    defaultMessage: 'Email',
  },
  phoneNumberLabel: {
    id: 'ProfileFormMessages.phoneNumberLabel',
    defaultMessage: 'Phone Number',
  },
  cancelLabel: {
    id: 'ProfileFormMessages.cancelLabel',
    defaultMessage: 'Cancel',
  },
  submitLabel: {
    id: 'ProfileFormMessages.submitLabel',
    defaultMessage: 'Save',
  },
  fetchUserError: {
    id: 'ProfileFormMessages.fetchUserError',
    defaultMessage: 'An error occurred while fetching user',
  },
  success: {
    id: 'ProfileFormMessages.success',
    defaultMessage: 'User profile is successfully updated',
  },
  error: {
    id: 'ProfileFormMessages.error',
    defaultMessage: 'An error occurred while updating user profile',
  },
  changePassword: {
    id: 'ProfileFormMessages.changePassword',
    defaultMessage: 'Change password',
  },
  edit: {
    id: 'ProfileFormMessages.edit',
    defaultMessage: 'Edit',
  },
})

export const ProfileForm: FC = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired, ruleNotIncludeNumbers } = useFormRules()
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const {
    devices: devices,
    totalCount: devicesCount,
    loading: fetchActiveDevicesLoading,
  } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    isActive: true,
  })

  const { loading: fetchCountriesLoading } = useCountrySelectOptions()

  const [initialValues, setInitialValues] = useState<Partial<ProfileFormType>>(
    {},
  )
  const [userInitialPhone, setUserInitialPhone] = useState<string | null>(null)
  const [fetchUserLoading, setFetchUserLoading] = useState<boolean>(false)
  const [userType, setUserType] = useState(
    USER_TYPE_IDS.NATURAL_PERSON.toString(),
  )
  const { handleOpenModal } = useModal()

  const isOrganization = useMemo(
    () => userType === USER_TYPE_IDS.ORGANIZATION.toString(),
    [userType],
  )

  const onlineDevicesCount = useMemo(
    () => devices.filter((d) => d.isOnline).length,
    [fetchActiveDevicesLoading],
  )
  const mobilePhoneNumber = (values: any) => {
    return COUNTRY_PHONES[values.userPhoneCountryCode]?.code
  }
  const fetchDefaultPageData = async () => {
    try {
      setFetchUserLoading(true)
      const { data } = await userGetDatagate()

      const { userPhone, companyPhone } = data

      setUserInitialPhone(userPhone ?? null)
      setInitialValues({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        companyName: data.companyName,
        companyPhone: companyPhone,
        userPhone: userPhone,
        vatNumber: data.vatNumber,
        billingEmail: data.billingEmail,
        countryId: data.countryId?.toString(),
        postalCode: data.postalCode,
        streetAddress1: data.streetAddress1,
        streetAddress2: data.streetAddress2,
        city: data.city,
        region: data.region,
      })

      setUserType(
        data.userTypeId?.toString() ?? USER_TYPE_IDS.ORGANIZATION.toString(),
      )

      dispatch(setUser(data))
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ProfileFormMessages.fetchUserError),
        ),
      })
    } finally {
      setFetchUserLoading(false)
    }
  }

  const onSetNewProfile = async (values: ProfileFormType) => {
    try {
      await userUpdateDatagate({
        user_type_id: +userType,
        name: values.firstName,
        last_name: values.lastName,
        company_name: isOrganization ? values.companyName : '',
        company_phone: isOrganization
          ? getClearedPhoneNumber(values.companyPhone)
          : '',
        phone: getClearedPhoneNumber(values.userPhone),
        billing_email: values.billingEmail ?? '',
        vat_number: isOrganization ? values.vatNumber : '',
        country_id: +values.countryId,
        postal_code: values.postalCode ?? '',
        street_address_1: values.streetAddress1 ?? '',
        street_address_2: values.streetAddress2 ?? '',
        city: values.city ?? '',
        region: values.region ?? '',
      })

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(ProfileFormMessages.success),
      })

      void fetchDefaultPageData()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ProfileFormMessages.error),
        ),
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      await fetchDefaultPageData()
    })()
  }, [])

  const handleChangePassword = () => {
    handleOpenModal({ type: MODAL_TYPES.CHANGE_PASSWORD })
  }

  const handleEdit = () => {
    handleOpenModal({
      type: MODAL_TYPES.EDIT_PROFILE,
      props: {
        initialValues,
        onClose: fetchDefaultPageData,
        userInitialPhone,
        fetchDefaultPageData,
      },
    })
  }

  const DesktopGrid = ({ form, values }: { form: any; values: any }) => (
    <div className={styles.ProfileFormContainer}>
      <div className={styles.ProfileFormGrid}>
        <InputField
          name="firstName"
          validate={ruleNotIncludeNumbers(true)}
          label={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
          markAsRequired={true}
        />

        <InputField
          name="lastName"
          validate={ruleNotIncludeNumbers(true)}
          label={intl.formatMessage(ProfileFormMessages.lastNameLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.lastNameLabel)}
          markAsRequired={true}
        />

        <div className={styles.fullSizeItem}>
          <PhoneInputFieldNumber
            name="userPhone"
            values={values}
            label={intl.formatMessage(ProfileFormMessages.phoneNumberLabel)}
            initialValue={userInitialPhone as string}
            markAsRequired={true}
            noHelperText={true}
          />
        </div>
      </div>

      <InputField
        name="email"
        validate={ruleRequired()}
        label={intl.formatMessage(ProfileFormMessages.emailLabel)}
        placeholder={intl.formatMessage(ProfileFormMessages.emailLabel)}
        markAsRequired={true}
        disabled
      />
    </div>
  )

  const MobileGrid = ({ form, values }: { form: any; values: any }) => {
    const Item = ({
      fieldName,
      fieldValue,
    }: {
      fieldName: string
      fieldValue?: string
    }) => (
      <div className={styles.MobileGrid__Item}>
        <div className={styles.MobileGrid__FieldName}>{fieldName}</div>
        <div className={styles.MobileGrid__FieldValue}>
          {fieldValue || ' - '}
        </div>
      </div>
    )

    const mobileCountryCode = mobilePhoneNumber(values)
    const phoneNumber = mobileCountryCode + values?.userPhoneNationalNumber

    return (
      <div className={styles.MobileGrid}>
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
          fieldValue={values.firstName}
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.lastNameLabel)}
          fieldValue={values.lastName}
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.phoneNumberLabel)}
          fieldValue={phoneNumber}
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.emailLabel)}
          fieldValue={values.email}
        />
      </div>
    )
  }

  const content = (
    <Form
      onSubmit={onSetNewProfile}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, form, values }) => {
        let nickname = initialValues.email

        if (initialValues.companyName) nickname = initialValues.companyName
        else if (initialValues.lastName && initialValues.firstName)
          nickname = `${initialValues.firstName} ${initialValues.lastName}`

        return (
          <form onSubmit={handleSubmit} className={styles.ProfileForm}>
            <ItemWrapper additionalClassNames={[styles.NicknameHeader]}>
              <div className={styles.Header}>
                <div className={styles.NicknameSection}>
                  <div className={styles.Nickname}>{nickname}</div>
                  <div className={styles.DevicesAmount}>
                    <div className={styles.DevicesAmount__inactive}>
                      {intl.formatMessage(ProfileFormMessages.devicesCount, {
                        count: devicesCount,
                      })}
                    </div>
                    <div className={styles.DevicesAmount__active}>
                      {intl.formatMessage(ProfileFormMessages.onlineCount, {
                        count: onlineDevicesCount,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </ItemWrapper>

            {isMobile && <div className={styles.Divider} />}

            {!isMobile && (
              <ItemWrapper>
                <div className={styles.Controls}>
                  <div className={styles.ChangePassword}>
                    <Button
                      text={intl.formatMessage(
                        ProfileFormMessages.changePassword,
                      )}
                      variant="blackTextGreenBorderOutlined"
                      onClick={handleChangePassword}
                      size={'small'}
                    />
                  </div>
                </div>
              </ItemWrapper>
            )}

            {isMobile ? (
              <MobileGrid form={form} values={values} />
            ) : (
              <DesktopGrid form={form} values={values} />
            )}

            {isMobile && <div className={styles.Divider} />}

            <div className={styles.Actions}>
              {isMobile ? (
                <>
                  <div className={styles.ChangePassword}>
                    <Button
                      text={intl.formatMessage(
                        ProfileFormMessages.changePassword,
                      )}
                      onClick={handleChangePassword}
                      variant="greenTextOutlined"
                      size={'small'}
                    />
                  </div>
                  <div className={styles.Edit}>
                    <Button
                      text={intl.formatMessage(ProfileFormMessages.edit)}
                      onClick={handleEdit}
                      size={'small'}
                    />
                  </div>
                </>
              ) : (
                <Button
                  type={'submit'}
                  text={intl.formatMessage(ProfileFormMessages.submitLabel)}
                  disabled={submitting}
                  additionalClassNames={[styles.Actions_submit]}
                />
              )}
            </div>
          </form>
        )
      }}
    />
  )

  return (
    <LoadingContent
      loading={
        fetchCountriesLoading ||
        fetchUserLoading ||
        fetchActiveDevicesLoading ||
        fetchActiveDevicesLoading
      }
      content={content}
    />
  )
}
