import React from "react";
import noModemDataImage from '../../../../../assets/images/v2/no-modem-data.png'
import noModemDataImageGrey from '../../../../../assets/images/v2/no-modem-data-grey.png'
import styles from './styles.module.scss';
import Button from "../../../../ui-kit-3/components/Button/Button";
import { MODAL_TYPES } from "../../../../../layouts/modals/ModalsProvider";
import { ReactComponent as BasketIcon } from '../../../../../assets/icons/header-dropdown-buy-new-device.svg';
import { useModal } from "../../../../../app/hooks/useModal";

export enum NOT_FOUND_TYPES {
  CALLS = "CALLS",
  SMSES = "SMSES",
  WIFI = "WIFI",
  VPN = "VPN"
}

export enum VARIANTS {
  GREY = "GREY",
}

const TITLE_BY_NOT_FOUND_TYPE: Record<NOT_FOUND_TYPES, string> = {
  [NOT_FOUND_TYPES.CALLS]: "Buy a Teleсorn modem to receive and make calls",
  [NOT_FOUND_TYPES.SMSES]: "Buy a Teleсorn modem to send and receive SMS",
  [NOT_FOUND_TYPES.WIFI]: "Buy Telecorn modem to use it as a Wi-Fi modem",
  [NOT_FOUND_TYPES.VPN]: "Buy Telecorn modem to use it as a VPN server",
};

const SUBTITLE_BY_NOT_FOUND_TYPE: Record<NOT_FOUND_TYPES, string> = {
  [NOT_FOUND_TYPES.CALLS]: "",
  [NOT_FOUND_TYPES.SMSES]: "",
  [NOT_FOUND_TYPES.WIFI]: "Experience rapid 4G LTE internet access, ensuring smooth and reliable connectivity for all your business needs.",
  [NOT_FOUND_TYPES.VPN]: "",
};

interface NotFoundDevicesBannerProps {
  type?: NOT_FOUND_TYPES;
  variant?: VARIANTS;
}

export const NotFoundDevicesBanner: React.FC<NotFoundDevicesBannerProps> = ({ type, variant }) => {
  const { handleOpenModal } = useModal();

  return (
    <div className={styles.NotFoundDevicesBanner}>
      <img
        src={variant === VARIANTS.GREY ? noModemDataImageGrey : noModemDataImage}
        alt="Modem"
        className={styles.ModemImage}
      />

      {type && TITLE_BY_NOT_FOUND_TYPE[type] && (
        <div className={styles.Title}>
          {TITLE_BY_NOT_FOUND_TYPE[type]}
        </div>
      )}

      {type && SUBTITLE_BY_NOT_FOUND_TYPE[type] && (
        <div className={styles.Subtitle}>
          {SUBTITLE_BY_NOT_FOUND_TYPE[type]}
        </div>
      )}

      <Button
        variant="greenFilled"
        text="Buy modem"
        size="xl"
        prefix={<BasketIcon />}
        onClick={() =>
          handleOpenModal({
            type: MODAL_TYPES.BUY_NEW_DEVICE,
          })
        }
        className={styles.Button}
      />
    </div>
  );
};
