import React, { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useAddContact } from '../_hooks/use-add-contact/useAddContact'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg'
import { dongleSmsCreateDatagate } from '../../../sdk/datagates/api/dongle'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { DongleSmsCreateRequest } from '../../../sdk/datagates/types/dongle/sms/_crud/create'
import { formatPhoneNumber } from '../../../sdk/formatters/format-phone-number'
import { useDevices } from '../../../sdk/hooks/use-devices/useDevices'
import { formatDate } from '../../../shared/lib/form/form-field-adapters/v2/datepicker-field/_helpers/formatDate'
import { SelectSearchField } from '../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import { TextareaField } from '../../../shared/lib/form/form-field-adapters/v2/textarea-field/TextareaField'
import { DatepickerOptionalField } from '../../../shared/lib/form/form-field-templates/v2/datepicker-optional-field/DatepickerOptionalField'
import { PhoneInputFieldNumber } from '../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew'
import { PhoneInputField } from '../../../shared/lib/form/form-field-templates/v2/phone-input-field/PhoneInputField'
import { clearPhoneMutator } from '../../../shared/lib/form/form-helpers/mutators'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { getClearedPhoneNumber } from '../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { getPhoneString } from '../../../shared/lib/utils/libphonenumber/create-phone-string'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { Input } from '../../../shared/ui-kit-2/inputs/input/Input'
import { SelectSearchOption } from '../../../shared/ui-kit-2/inputs/select-search/SelectSearch'
import { TextButton } from '../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { DeviceSelector } from '../../../shared/ui-kit-3/components/DeviceSelector'
import { LC } from '../../../tests/e2e/locators'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

type SendSmsFormType = {
  dongleId: number
  phone: string
  message: string
  scheduled_at?: string
}

export const SendSmsModalMessages = defineMessages({
  title: {
    id: 'SendSmsModalMessages.title',
    defaultMessage: 'Send SMS',
  },
  replyTitle: {
    id: 'SendSmsModalMessages.replyTitle',
    defaultMessage: 'Reply to SMS',
  },
  sendSms: {
    id: 'SendSmsModalMessages.sendSms',
    defaultMessage: 'Send SMS',
  },
  createSms: {
    id: 'SendSmsModalMessages.createSms',
    defaultMessage: 'Create SMS',
  },
  deviceLabel: {
    id: 'SendSmsModalMessages.deviceLabel',
    defaultMessage: 'Device',
  },
  devicePlaceholder: {
    id: 'SendSmsModalMessages.devicePlaceholder',
    defaultMessage: 'Select device',
  },
  phoneLabel: {
    id: 'SendSmsModalMessages.phoneLabel',
    defaultMessage: 'Phone',
  },
  contacts: {
    id: 'SendSmsModalMessages.contacts',
    defaultMessage: 'Contacts',
  },
  contactLabel: {
    id: 'SendSmsModalMessages.contactLabel',
    defaultMessage: 'Contact',
  },
  scheduledLabel: {
    id: 'SendSmsModalMessages.scheduledLabel',
    defaultMessage: 'Delayed SMS (UTC)',
  },
  messageLabel: {
    id: 'SendSmsModalMessages.messageLabel',
    defaultMessage: 'SMS message',
  },
  messagePlaceholder: {
    id: 'SendSmsModalMessages.messagePlaceholder',
    defaultMessage: 'Enter SMS message you want to be sent',
  },
  positiveSent: {
    id: 'SendSmsModalMessages.positiveAdded',
    defaultMessage: 'SMS is successfully sent',
  },
  negativeSent: {
    id: 'SendSmsModalMessages.negativeAdded',
    defaultMessage: 'An error occurred while sending SMS',
  },
  positiveCreated: {
    id: 'SendSmsModalMessages.positiveCreated',
    defaultMessage: 'SMS is successfully created',
  },
  negativeCreated: {
    id: 'SendSmsModalMessages.negativeCreated',
    defaultMessage: 'An error occurred while creating SMS',
  },
})

export const SendSmsModal = () => {
  const { handleHideModal, props } = useModal()

  const { deviceId, deviceName, handleFetch, handleFetchDevices } = props

  const { handleOpenSnackbar } = useSnackbar()
  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired } = useFormRules()
  const [isDelayedSms, setIsDelayedSms] = useState<boolean>(false)

  const { devices, loading } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
  })
  const dongleOptions: SelectSearchOption[] = useMemo(
    () =>
      devices.map((d) => {
        return {
          key: d.dongleId,
          label: d.name,
          inputLabel: d.name,
          value: d.dongleId.toString(),
        }
      }),
    [devices],
  )

  const {
    component: contactSelectComponent,
    setIsOpen: setIsContactSelectOpen,
    savedContact,
    removeContact,
  } = useAddContact()

  const handleAddSms = async (values: SendSmsFormType) => {
    try {
      const urlParams = [
        { name: 'dongle_id', value: values.dongleId.toString() },
      ]

      let reqBody: DongleSmsCreateRequest = {
        message: values.message,
      }

      if (values.scheduled_at)
        reqBody.scheduled_at = formatDate(values.scheduled_at, true)

      if (savedContact) reqBody.contact_id = savedContact.contactId
      else reqBody.number = `+${getClearedPhoneNumber(values.phone)}`

      await dongleSmsCreateDatagate(reqBody, urlParams)

      await handleFetch?.()
      await handleFetchDevices?.()

      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(
          values.scheduled_at
            ? SendSmsModalMessages.positiveCreated
            : SendSmsModalMessages.positiveSent,
        ),
      })
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(
            values.scheduled_at
              ? SendSmsModalMessages.negativeCreated
              : SendSmsModalMessages.negativeSent,
          ),
        ),
      })
    }
  }

  const openContactsButton = (
    <TextButton
      text={intl.formatMessage(SendSmsModalMessages.contacts)}
      icon={'contact'}
      onClick={() => setIsContactSelectOpen(true)}
    />
  )

  const content = (
    <Form
      onSubmit={handleAddSms}
      initialValues={{
        dongleId:
          deviceId ?? (devices.length === 1 ? devices[0].dongleId : undefined),
      }}
      mutators={{
        clearPhoneMutator,
      }}
      render={({ handleSubmit, submitting, values, form }) => {
        const isDelayed = !!values.scheduled_at
        const submitBtnText = intl.formatMessage(
          isDelayed
            ? SendSmsModalMessages.createSms
            : SendSmsModalMessages.sendSms,
        )

        return (
          <form onSubmit={handleSubmit} className={styles.Container}>
            <DeviceSelector
              devices={devices}
              name="dongleId"
              validate={ruleRequired()}
              options={dongleOptions}
              label={intl.formatMessage(SendSmsModalMessages.deviceLabel)}
              placeholder={intl.formatMessage(
                SendSmsModalMessages.devicePlaceholder,
              )}
              markAsRequired={true}
              defaultInputValue={
                deviceName ??
                (dongleOptions.length === 1
                  ? dongleOptions[0].label
                  : undefined)
              }
              testId={LC.SMS.MODAL.MULTISELECT_DEVICE}
            />

            {savedContact ? (
              <Input
                value={`${savedContact.name}: ${formatPhoneNumber(savedContact.phone)}`}
                label={intl.formatMessage(SendSmsModalMessages.contactLabel)}
                readOnly={true}
                postfix={
                  <TrashIcon
                    onClick={removeContact}
                    className={styles.DeleteContact}
                  />
                }
                markAsRequired={true}
                testId={LC.SMS.MODAL.INPUT_PHONE}
              />
            ) : (
              <PhoneInputFieldNumber
                name="phone"
                label={intl.formatMessage(SendSmsModalMessages.phoneLabel)}
                markAsRequired={true}
                canBeShort={true}
                postfix={openContactsButton}
              />
            )}

            <DatepickerOptionalField
              name={'scheduled_at'}
              validate={ruleRequired()}
              isOpen={isDelayedSms}
              setIsOpen={setIsDelayedSms}
              title={intl.formatMessage(SendSmsModalMessages.scheduledLabel)}
              minDateToday={true}
              onRemove={() => form.change('scheduled_at', undefined)}
              testId={LC.SMS.MODAL.DATEPICKER}
            />

            <TextareaField
              name={'message'}
              validate={ruleRequired()}
              label={intl.formatMessage(SendSmsModalMessages.messageLabel)}
              placeholder={intl.formatMessage(
                SendSmsModalMessages.messagePlaceholder,
              )}
              testId={LC.SMS.MODAL.TEXTAREA}
            />

            <Button
              type={'submit'}
              text={submitBtnText}
              additionalClassNames={[styles.SubmitBtn]}
              disabled={submitting}
              loading={submitting}
              testId={LC.SMS.MODAL.SEND_BTN}
            />
          </form>
        )
      }}
    />
  )

  return (
    <Drawer
      title={intl.formatMessage(SendSmsModalMessages.title)}
      isOpen={true}
      close={handleHideModal}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      <LoadingContent loading={loading} content={content} />

      {contactSelectComponent}
    </Drawer>
  )
}
