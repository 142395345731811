import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import howToConnectModemImage from '../../../assets/images/v2/how-to-connect-modem.png'
import styles from './styles.module.scss'

const HowToConnectModemMessages = defineMessages({
  step1Title: {
    id: 'HowToConnectModemMessages.step1Title',
    defaultMessage: 'Install a SIM card',
  },
  step1Description: {
    id: 'HowToConnectModemMessages.step1Description',
    defaultMessage:
      "The SIM card slot is under the modem cover. Disable the SIM card's PIN code beforehand ",
  },
  step2Title: {
    id: 'HowToConnectModemMessages.step2Title',
    defaultMessage: 'Enter your IMEI code and MAC address',
  },
  step2Description: {
    id: 'HowToConnectModemMessages.step2Description',
    defaultMessage:
      'They can be found on a sticker under the back cover. Enter them in the fields at the right of this page',
  },
  step3Title: {
    id: 'HowToConnectModemMessages.step3Title',
    defaultMessage: 'Press the “Activate” button',
  },
  step4Title: {
    id: 'HowToConnectModemMessages.step4Title',
    defaultMessage: 'Plug the device into a computer or power supply',
  },
  step4Description: {
    id: 'HowToConnectModemMessages.step4Description',
    defaultMessage:
      'If you have done so before this step, remove and reinsert it',
  },
})

const ManualDeviceActivationStepper = () => {
  const intl = useIntl()

  return (
    <div className={styles.Content}>
      <img
        src={howToConnectModemImage}
        alt="Modem"
        className={styles.ModemImage}
      />

      <div className={styles.Stepper}>
        <div className={styles.StepperTextContent}>
          <div className={styles.VerticalLine} />

          <div className={styles.StepperTextContent__title}>
            {intl.formatMessage(HowToConnectModemMessages.step1Title)}

            <div className={styles.Dot} />
          </div>
          <div className={styles.StepperTextContent__description}>
            {intl.formatMessage(HowToConnectModemMessages.step1Description)}
          </div>
        </div>
        <div className={styles.StepperTextContent}>
          <div className={styles.VerticalLine} />

          <div className={styles.StepperTextContent__title}>
            {intl.formatMessage(HowToConnectModemMessages.step2Title)}

            <div className={styles.Dot} />
          </div>
          <div className={styles.StepperTextContent__description}>
            {intl.formatMessage(HowToConnectModemMessages.step2Description)}
          </div>
        </div>
        <div className={styles.StepperTextContent}>
          <div className={styles.VerticalLine} />

          <div className={styles.StepperTextContent__title}>
            {intl.formatMessage(HowToConnectModemMessages.step3Title)}

            <div className={styles.Dot} />
          </div>
        </div>
        <div className={styles.StepperTextContent}>
          <div className={styles.StepperTextContent__title}>
            {intl.formatMessage(HowToConnectModemMessages.step4Title)}

            <div className={styles.Dot} />
          </div>

          <div className={styles.StepperTextContent__description}>
            {intl.formatMessage(HowToConnectModemMessages.step4Description)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManualDeviceActivationStepper
