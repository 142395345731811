import { useEffect, useMemo, useState } from 'react'
import { isNumber } from 'lodash'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonFormMessages } from '../../../config/intl/common-messages/common-form-messages/CommonFormMessages'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { convertToCamelCase } from '../../../shared/lib/utils/convert-to-camel-case/convertToCamelCase'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  dongleDeleteDatagate,
  dongleListDatagate,
  dongleUpdateDatagate,
} from '../../datagates/api/dongle'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { QueryParameter } from '../../datagates/helpers/_common/wrap-api-request'
import { DongleListResponse } from '../../datagates/types/dongle/_crud/list'

type UseDevicesProps = {
  page?: number
  limit?: number
  brandId?: number
  callTypeId?: number
  takeAll?: boolean
  isActive?: boolean
  sortBy?: string
  orderBy?: string
  filters?: QueryParameter[]
}

const UseDevicesMessages = defineMessages({
  positiveDeleted: {
    id: 'UseDevicesMessages.positiveDeleted',
    defaultMessage: 'Modem is successfully deleted',
  },
  negativeDeleted: {
    id: 'UseDevicesMessages.negativeDeleted',
    defaultMessage: 'An error occurred while deleting modem',
  },
})

export const useDevices = (props: UseDevicesProps) => {
  const {
    page,
    limit,
    sortBy,
    orderBy,
    brandId,
    callTypeId,
    takeAll = false,
    isActive = true,
    filters = [],
  } = props

  const stableFilters = useMemo(() => filters, [JSON.stringify(filters)])

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [devices, setDevices] = useState<DongleListResponse['dongles']>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [isRepeatedRequest, setIsRepeatedRequest] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [initialFetchCompleted, setInitialFetchCompleted] =
    useState<boolean>(false)

  const handleUpdateDevice = async (
    dongleId: number,
    updatedFields: Record<string, any>,
  ) => {
    try {
      const urlParams = [{ name: 'dongle_id', value: dongleId.toString() }]

      const { data } = await dongleUpdateDatagate(updatedFields, urlParams)

      await handleFetchDevices({ noLoading: true })

      setDevices((prev) =>
        prev.map((device) => {
          if (device.dongleId !== dongleId) return device

          return { ...device, ...data }
        }),
      )
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    }
  }

  const handleDeleteDevice = async (dongleId: number) => {
    try {
      const urlParams = [{ name: 'dongle_id', value: dongleId.toString() }]

      await dongleDeleteDatagate({ is_deleted: true }, urlParams)
      await handleFetchDevices()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseDevicesMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseDevicesMessages.negativeDeleted),
      })
    }
  }

  const handleSwitchHotspot = async (dongleId: number, enabled: boolean) => {
    handleUpdateDevice(dongleId, {
      is_hotspot_enable: enabled,
    })
  }

  const handleFetchDevices = async (props?: {
    params?: QueryParameter[]
    imeiManufacture?: number
    noLoading?: boolean
  }) => {
    const imeiManufacture = props?.imeiManufacture
    const { params = [] } = props || {}

    try {
      if (!props?.noLoading) {
        setLoading(true)
      }

      if (!isRepeatedRequest && !initialLoading) setIsRepeatedRequest(true)

      const queryParams = [
        { name: 'order_by', value: sortBy || 'created_at' },
        { name: 'order_type', value: orderBy || 'desc' },
        { name: 'is_active', value: isActive ? '1' : '0' },
        ...stableFilters,
        ...(!takeAll ? params : []),
      ]

      if (!takeAll && !stableFilters && isNumber(page) && isNumber(limit)) {
        queryParams.push(
          ...[
            { name: 'page', value: (page - 1).toString() },
            { name: 'limit', value: limit.toString() },
          ],
        )
      }

      if (brandId) {
        queryParams.push({
          name: 'bootstrap_dongle_brand_ids[]',
          value: `${brandId}`,
        })
      }

      if (callTypeId) {
        queryParams.push({
          name: 'dongle_call_type_ids[]',
          value: `${callTypeId}`,
        })
      }

      if (imeiManufacture) {
        queryParams.push(
          {
            name: 'bootstrap_dongle_imei_manufactures[]',
            value: `W${imeiManufacture}`,
          },
          { name: 'short', value: 'true' },
        )
      }

      const { data } = await dongleListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setDevices(data.dongles)
    } catch (e) {
      setErrorText(resolveBackendError(getErrorMessage(e)))
    } finally {
      if (!props?.noLoading) {
        setLoading(false)
      }
      initialLoading && setInitialLoading(false)
    }
  }

  useEffect(() => {
    void handleFetchDevices()
  }, [page, limit, sortBy, orderBy, stableFilters])

  return {
    devices,
    totalCount,
    handleFetchDevices,
    handleUpdateDevice,
    handleDeleteDevice,
    handleSwitchHotspot,
    loading,
    errorText,
    initialLoading,
    isRepeatedRequest,
    setDevices,
    initialFetchCompleted,
    setInitialFetchCompleted,
  }
}
