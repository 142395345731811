import { combineReducers } from 'redux'
import cartSlice from './cart'
import dialogSlice from './dialog'
import modalSlice from './modal'
import pushNotificationsSlice from './push-notifications'
import sipSlice from './sip'
import snackbarSlice from './snackbar'
import userSlice from './user'
import tariffPackagesSlice from './tariff-packages'

export const rootReducer = combineReducers({
  user: userSlice,
  modal: modalSlice,
  snackbar: snackbarSlice,
  cart: cartSlice,
  dialog: dialogSlice,
  sip: sipSlice,
  pushNotifications: pushNotificationsSlice,
  tariffPackages: tariffPackagesSlice
})
