import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useWavesurfer } from '@wavesurfer/react'
import clsx from 'clsx'
import { ReactComponent as PauseIcon } from '../../../../assets/icons/v2/ic-pause.svg'
import { ReactComponent as PlayIcon } from '../../../../assets/icons/v2/ic-play.svg'
import { isValidWavByBase64 } from '../../../lib/utils/is-valid-wav-by-base-64/isValidWavByBase64'
import { ConditionalWavesurfer } from './components/conditional-wav-surfer/ConditionalWavSurfer'
import styles from './styles.module.scss'

export enum WavSurferThemes {
  black,
  white,
}

type WavSurferProps = {
  // url to audio
  audioSource?: string
  // base64
  base64Data?: string
  // file
  blobData?: Blob

  audioWavesWidth?: string | number
  showDuration?: boolean
  handleListen?: (arg1: any) => void
  theme?: WavSurferThemes
  onPlay?: () => void
  isActive?: boolean
}

const formatTime = (seconds: number) =>
  [seconds / 60, seconds % 60]
    .map((v) => `0${Math.floor(v)}`.slice(-2))
    .join(':')

export const WavSurfer: React.FC<WavSurferProps> = (props) => {
  const {
    audioSource,
    base64Data,
    blobData,
    audioWavesWidth = '100%',
    showDuration = true,
    handleListen,
    isActive,
    onPlay,
    theme = WavSurferThemes.white,
  } = props
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [audioUrl, setAudioUrl] = useState<string>('')
  const [parsingError, setParsingError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    height: 25,
    waveColor: theme === WavSurferThemes.white ? '#7B8AA0' : '#BFCCD7',
    progressColor: theme === WavSurferThemes.white ? '#F6F6FB' : '#7B8AA0',
    cursorColor: 'inherit',
    url: audioUrl,
    barWidth: 2,
    barGap: 1,
    barRadius: 2,
  })

  const base64ToBlob = useCallback(
    (base64: string, contentType: string): string => {
      const byteCharacters = atob(base64)
      const byteArrays: Uint8Array[] = []

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return URL.createObjectURL(blob)
    },
    [],
  )

  const handlePlayPause = useCallback(() => {
    if (wavesurfer) {
      wavesurfer.playPause()

      if (handleListen) {
        handleListen(audioSource || base64Data)
      }

      if (onPlay) onPlay()
    }
  }, [wavesurfer])

  const isValidWavByUrl = async (url: string): Promise<boolean> => {
    try {
      setLoading(true)
      const response = await fetch(url)
      if (!response.ok) throw new Error('Failed to fetch WAV file')
      const arrayBuffer = await response.arrayBuffer()
      const dataView = new DataView(arrayBuffer)

      // Проверяем сигнатуры RIFF, WAVE, и fmt
      const riff =
        String.fromCharCode(
          dataView.getUint8(0),
          dataView.getUint8(1),
          dataView.getUint8(2),
          dataView.getUint8(3),
        ) === 'RIFF'
      const wave =
        String.fromCharCode(
          dataView.getUint8(8),
          dataView.getUint8(9),
          dataView.getUint8(10),
          dataView.getUint8(11),
        ) === 'WAVE'
      const fmt =
        String.fromCharCode(
          dataView.getUint8(12),
          dataView.getUint8(13),
          dataView.getUint8(14),
          dataView.getUint8(15),
        ) === 'fmt '

      return riff && wave && fmt
    } catch (error) {
      console.error('Error validating WAV file:', error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const handleSetAudio = async () => {
    if (audioSource) {
      if (await isValidWavByUrl(audioSource)) {
        setAudioUrl(audioSource)
      } else {
        setParsingError(true)
      }
    }

    if (base64Data) {
      if (isValidWavByBase64(base64Data)) {
        const url = base64ToBlob(base64Data, 'audio/wav')
        setAudioUrl(url)
      } else {
        setParsingError(true)
      }
    }

    if (blobData) {
      if (true) {
        setAudioUrl(URL.createObjectURL(blobData))
      } else {
        setParsingError(true)
      }
    }
  }

  useEffect(() => {
    handleSetAudio()
  }, [base64Data, base64ToBlob])

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(audioUrl)

      if (wavesurfer && wavesurfer?.isPlaying()) {
        wavesurfer?.playPause()
      }
    }
  }, [])

  useEffect(() => {
    if (isActive && wavesurfer) {
      wavesurfer.play()
    } else {
      wavesurfer?.pause()
    }
  }, [isActive])

  return parsingError || loading ? (
    <></>
  ) : (
    <>
      <div
        className={clsx(
          styles.WavSurfer,
          theme === WavSurferThemes.black && styles.WavSurfer_black,
          showDuration && styles.WavSurfer_withDuration,
        )}
      >
        <div className={styles.ActionButton} onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </div>
        <div
          ref={containerRef}
          className={styles.Audio}
          style={{ width: audioWavesWidth }}
        />
        {showDuration && (
          <div className={styles.Duration}>{formatTime(currentTime)}</div>
        )}
      </div>
    </>
  )
}
