import { useMemo } from 'react'
import clsx from 'clsx'
import { useSiteVersion } from '../../../../../app/hooks/useSiteVersion'
import { FAQSectionAnchors } from '../../constants/anchors'
import Article1_1 from './articles/en/article1-1.md'
import Article1_2 from './articles/en/article1-2.md'
import Article1_3 from './articles/en/article1-3.md'
import Article1 from './articles/en/article1.md'
import Article2_1 from './articles/en/article2-1.md'
import Article2_2 from './articles/en/article2-2.md'
import Article2_3 from './articles/en/article2-3.md'
import Article2_4 from './articles/en/article2-4.md'
import Article2_5 from './articles/en/article2-5.md'
import Article2_6 from './articles/en/article2-6.md'
import Article2 from './articles/en/article2.md'
import Article3_1 from './articles/en/article3-1.md'
import Article3_2 from './articles/en/article3-2.md'
import Article3_3 from './articles/en/article3-3.md'
import Article3_4 from './articles/en/article3-4.md'
import Article3 from './articles/en/article3.md'
import Article4_1 from './articles/en/article4-1.md'
import Article4_2 from './articles/en/article4-2.md'
import Article4_3 from './articles/en/article4-3.md'
import Article4 from './articles/en/article4.md'
import Article5_1 from './articles/en/article5-1.md'
import Article5 from './articles/en/article5.md'
import Article6_1 from './articles/en/article6-1.md'
import Article6 from './articles/en/article6.md'
import Article7_1 from './articles/en/article7-1.md'
import Article7_2 from './articles/en/article7-2.md'
import Article7_3 from './articles/en/article7-3.md'
import Article7_4 from './articles/en/article7-4.md'
import Article7_5 from './articles/en/article7-5.md'
import Article7_6 from './articles/en/article7-6.md'
import Article7 from './articles/en/article7.md'
import Article8_1 from './articles/en/article8-1.md'
import Article8 from './articles/en/article8.md'
import Article1_1_RU from './articles/ru/article1-1.md'
import Article1_2_RU from './articles/ru/article1-2.md'
import Article1_3_RU from './articles/ru/article1-3.md'
import Article1_RU from './articles/ru/article1.md'
import Article2_1_RU from './articles/ru/article2-1.md'
import Article2_2_RU from './articles/ru/article2-2.md'
import Article2_3_RU from './articles/ru/article2-3.md'
import Article2_4_RU from './articles/ru/article2-4.md'
import Article2_5_RU from './articles/ru/article2-5.md'
import Article2_6_RU from './articles/ru/article2-6.md'
import Article2_RU from './articles/ru/article2.md'
import Article3_1_RU from './articles/ru/article3-1.md'
import Article3_2_RU from './articles/ru/article3-2.md'
import Article3_3_RU from './articles/ru/article3-3.md'
import Article3_4_RU from './articles/ru/article3-4.md'
import Article3_RU from './articles/ru/article3.md'
import Article4_1_RU from './articles/ru/article4-1.md'
import Article4_2_RU from './articles/ru/article4-2.md'
import Article4_3_RU from './articles/ru/article4-3.md'
import Article4_RU from './articles/ru/article4.md'
import Article5_1_RU from './articles/ru/article5-1.md'
import Article5_RU from './articles/ru/article5.md'
import Article6_1_RU from './articles/ru/article6-1.md'
import Article6_RU from './articles/ru/article6.md'
import Article7_1_RU from './articles/ru/article7-1.md'
import Article7_2_RU from './articles/ru/article7-2.md'
import Article7_3_RU from './articles/ru/article7-3.md'
import Article7_4_RU from './articles/ru/article7-4.md'
import Article7_5_RU from './articles/ru/article7-5.md'
import Article7_6_RU from './articles/ru/article7-6.md'
import Article7_RU from './articles/ru/article7.md'
import Article8_1_RU from './articles/ru/article8-1.md'
import Article8_RU from './articles/ru/article8.md'
import { Article } from './components/Article/Article'
import styles from './styles.module.scss'

export type ArticleProps = {
  lang: string
}

const ARTICLES_EN = [
  Article1,
  Article1_1,
  Article1_2,
  Article1_3,
  Article2,
  Article2_1,
  Article2_2,
  Article2_3,
  Article2_4,
  Article2_5,
  Article2_6,
  Article3,
  Article3_1,
  Article3_2,
  Article3_3,
  Article3_4,
  Article4,
  Article4_1,
  Article4_2,
  Article4_3,
  Article5,
  Article5_1,
  Article6,
  Article6_1,
  Article7,
  Article7_1,
  Article7_2,
  Article7_3,
  Article7_4,
  Article7_5,
  Article7_6,
  Article8,
  Article8_1,
] as Array<any>

const ARTICLES_RU = [
  Article1_RU,
  Article1_1_RU,
  Article1_2_RU,
  Article1_3_RU,
  Article2_RU,
  Article2_1_RU,
  Article2_2_RU,
  Article2_3_RU,
  Article2_4_RU,
  Article2_5_RU,
  Article2_6_RU,
  Article3_RU,
  Article3_1_RU,
  Article3_2_RU,
  Article3_3_RU,
  Article3_4_RU,
  Article4_RU,
  Article4_1_RU,
  Article4_2_RU,
  Article4_3_RU,
  Article5_RU,
  Article5_1_RU,
  Article6_RU,
  Article6_1_RU,
  Article7_RU,
  Article7_1_RU,
  Article7_2_RU,
  Article7_3_RU,
  Article7_4_RU,
  Article7_5_RU,
  Article7_6_RU,
  Article8_RU,
  Article8_1_RU,
] as Array<any>

export const FAQArticles = () => {
  const { siteVersion } = useSiteVersion()

  const articles = useMemo(
    () => (siteVersion === 'ru' ? ARTICLES_RU : ARTICLES_EN),
    [siteVersion],
  )

  return (
    <div
      className={clsx(
        styles.FAQArticles,
        siteVersion === 'ru' && styles.FAQArticles_ru,
      )}
    >
      {articles.map((mdFile, i) => (
        <div
          id={Object.entries(FAQSectionAnchors)[i][1]}
          key={`article/${i}`}
          className={styles.Article}
        >
          <Article>{mdFile}</Article>
        </div>
      ))}
    </div>
  )
}
