import { Method } from 'axios'
import axios, { HttpStatusCode } from 'axios'
import axiosApiInstance from '../../../../../config/api'
import { getErrorMessage } from '../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { resolveResponseData } from '../resolve-response-data'

export type UrlParameter = {
  name: string
  value: string
}

export type QueryParameter = {
  name: string
  value: string | string[]
}

// Добавляем параметр `customBaseUrl`
export function wrapApiRequest<TReq, TBackendRes, TRes>(
  requestUrl: string,
  mapper: (item: TBackendRes) => TRes,
  method: Method = 'POST',
  customBaseUrl?: string,
) {
  return async (
    reqBody: TReq | null = null,
    urlParams: UrlParameter[] | null = [],
    queryParams: QueryParameter[] | null = [],
  ) => {
    try {
      // Подставляем `customBaseUrl` если он передан, иначе используем стандартный `axiosApiInstance`
      const instance = customBaseUrl
        ? axios.create({
          baseURL: customBaseUrl,
          validateStatus: (status) =>
            (status >= 200 && status < 300) || status === 400 || status === 403,
        })
        : axiosApiInstance

      // Обрабатываем URL параметры
      let requestUrlWithParams = requestUrl.slice()
      if (urlParams) {
        for (const param of urlParams)
          requestUrlWithParams = requestUrlWithParams.replace(
            `{${param.name}}`,
            param.value,
          )
      }

      // Обрабатываем query параметры
      if (queryParams) {
        requestUrlWithParams += queryParams.reduce(
          (acc: string, cur, curIndex) => {
            if (Array.isArray(cur.value)) {
              const nodes = cur.value
                .map((node) => `${cur.name}[]=${node}`)
                .join('&')

              return curIndex === 0 ? `${acc}?${nodes}` : `${acc}&${nodes}`
            }

            return curIndex === 0
              ? `${acc}?${cur.name}=${cur.value}`
              : `${acc}&${cur.name}=${cur.value}`
          },
          '',
        )
      }

      // Выполняем запрос
      const response = await instance({
        method: method,
        url: requestUrlWithParams,
        data: reqBody,
      })

      const { data: backendData } = resolveResponseData<TBackendRes>(response)
      const data = mapper(backendData)

      return { data }
    } catch (e) {
      throw new Error(getErrorMessage(e))
    }
  }
}
