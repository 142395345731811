import clsx from 'clsx'
import { StickerProps } from './Sticker.types'
import styles from './styles.module.scss'

const Sticker = ({ text, variant, textVariant }: StickerProps) => {
  return (
    <div
      className={clsx(
        styles.Sticker,

        variant === 'red' && styles.Sticker_red,
        variant === 'green' && styles.Sticker_green,
        variant === 'grey' && styles.Sticker_grey,
        variant === 'darkgrey' && styles.Sticker_darkgrey,
        variant === 'blue' && styles.Sticker_blue,

        textVariant === 'darkgreen' && styles.Sticker_textVariantDarkGreen
      )}
    >
      {text}
    </div>
  )
}

export default Sticker
