export const compareAddresses = (legal: any, primary: any) => {
  return (
    legal.countryId === primary.countryId &&
    legal.postalCode === primary.postalCode &&
    legal.streetAddress1 === primary.streetAddress1 &&
    legal.streetAddress2 === primary.streetAddress2 &&
    legal.city === primary.city &&
    legal.region === primary.region
  )
}
