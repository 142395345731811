import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ManualDeviceActivationStepper from '../../../../../../../../shared/global-components/ManualDeviceActivationStepper'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import styles from './styles.module.scss'

const HowToConnectModemMessages = defineMessages({
  title: {
    id: 'HowToConnectModemMessages.title',
    defaultMessage: 'How to connect {productName} modem?',
  },
})

export const HowToConnectModem = () => {
  const intl = useIntl()

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Header}>
        {intl.formatMessage(HowToConnectModemMessages.title, {
          productName: <div className={styles.ProductName}>Teleleo</div>,
        })}
      </div>
      <ManualDeviceActivationStepper />
    </Card>
  )
}
