import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSiteVersion } from '../../../app/hooks/useSiteVersion'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { BuyNewDevice3DModel } from './components/BuyNewDevice3DModel/BuyNewDevice3DModel'
import { BuyNewDeviceForm } from './components/BuyNewDeviceForm/BuyNewDeviceForm'
import { BuyNewDeviceFormRu } from './components/BuyNewDeviceFormRu/BuyNewDeviceFormRu'
import styles from './styles.module.scss'

const BuyNewDeviceModalMessages = defineMessages({
  title: {
    id: 'BuyNewDeviceModalMessages.title',
    defaultMessage: 'To buy a modem, select options',
  },
})

export const BuyNewDeviceModal = () => {
  const { handleHideModal, props } = useModal()
  const { monthPlanCount = 0, annualPlanCount = 0 } = props
  const { siteVersion } = useSiteVersion()
  const intl = useIntl()
  const isLessThenLaptopL = useLessThanDeviceScreen(ScreenDevices.LAPTOP_L)

  return (
    <Drawer
      title={intl.formatMessage(BuyNewDeviceModalMessages.title)}
      isOpen={true}
      close={handleHideModal}
      limitedWidth={true}
      customWidth={isLessThenLaptopL ? '100vw' : '85vw'}
      additionalClassNames={[styles.BuyNewDeviceModal]}
      fullWidth={false}
    >
      <div className={styles.Content}>
        {siteVersion === 'ru' ? (
          <BuyNewDeviceFormRu />
        ) : (
          <>
            <BuyNewDevice3DModel />
            <BuyNewDeviceForm
              defaultMonthPlanCount={monthPlanCount}
              defaultAnnualPlanCount={annualPlanCount}
            />
          </>
        )}
      </div>
    </Drawer>
  )
}
