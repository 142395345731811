import { ButtonHTMLAttributes, ReactNode } from 'react'
import styles from './styles.module.scss'

export enum ICON_BUTTON_THEMES {
  grey = 'grey',
}

type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  theme?: ICON_BUTTON_THEMES
  icon: ReactNode
}

const IconButton = ({ theme, icon, ...props }: IconButtonProps) => {
  return (
    <button className={styles.IconButton} {...props}>
      {icon}
    </button>
  )
}

export default IconButton
