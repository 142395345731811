import React, { FC, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../app/hooks/useModal'
import { useSiteVersion } from '../../../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../../../app/hooks/useSnackbar'
import { globalHistory } from '../../../../../app/providers/with-router/withRouter'
import { RoutePath } from '../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../config/routes/enums/route'
import { ContactUsFormMessages } from '../../../../../pages/home/components/sections/home-09/components/contact-us-form/ContactUsForm'
import {
  ProfileFormMessages,
  ProfileFormType,
} from '../../../../../pages/lk/subpages/profile/components/profilePageEn/components/profile-page-form/ProfileForm'
import { callbackRequestFormDatagateBuyDevice } from '../../../../../sdk/datagates/api/callback'
import { useBackendErrorCodes } from '../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { TARIFF_PACKAGE_ID } from '../../../../../sdk/datagates/types/order/_common'
import { formatPrice } from '../../../../../sdk/formatters/format-price'
import { useTariffPackages } from '../../../../../sdk/hooks/use-tariff-packages/useTariffPackages'
import { InputField } from '../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { PhoneInputFieldNumber } from '../../../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew'
import { useFormRules } from '../../../../../shared/lib/form/form-rules'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { getClearedPhoneNumber } from '../../../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber'
import { getErrorMessage } from '../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../shared/ui-kit-2/inputs/button/Button'
import { Loader } from '../../../../../shared/ui-kit/data-display/loader/Loader'
import { useAppSelector } from '../../../../../store'
import { SNACKBAR_TYPES } from '../../../../snackbar/SnackbarProvider'
import {
  ProductCardDetailType,
  ProductCounterCard,
} from '../ProductCounterCard/ProductCounterCard'
import styles from './styles.module.scss'

interface BuyNewDeviceFormProps {
  defaultMonthPlanCount: number
  defaultAnnualPlanCount: number
}

interface ContactUsFormType {
  firstName: string
  email: string
  userPhone: string
}

const BuyNewDeviceFormMessages = defineMessages({
  monthlyTitle: {
    id: 'BuyNewDeviceFormMessages.monthlyTitle',
    defaultMessage: 'Monthly subscription',
  },
  annualTitle: {
    id: 'BuyNewDeviceFormMessages.annualTitle',
    defaultMessage: 'Annual subscription',
  },
  discount: {
    id: 'BuyNewDeviceFormMessages.discount',
    defaultMessage: 'Save {discount}%',
  },
  monthlyPrice: {
    id: 'BuyNewDeviceFormMessages.monthlyPrice',
    defaultMessage: 'Monthly price',
  },
  usbModem: {
    id: 'BuyNewDeviceFormMessages.usbModem',
    defaultMessage: 'USB Modem',
  },
  delivery: {
    id: 'BuyNewDeviceFormMessages.delivery',
    defaultMessage: 'Delivery',
  },
  priceForTwelveMonths: {
    id: 'BuyNewDeviceFormMessages.priceForTwelveMonths',
    defaultMessage: 'For 12 months',
  },
  free: {
    id: 'BuyNewDeviceFormMessages.free',
    defaultMessage: 'Free',
  },
  calculated: {
    id: 'BuyNewDeviceFormMessages.calculated',
    defaultMessage: 'Calculated',
  },
  willBeCalculated: {
    id: 'BuyNewDeviceFormMessages.willBeCalculated',
    defaultMessage: 'Will be calculated',
  },
  calculationError: {
    id: 'BuyNewDeviceFormMessages.calculationError',
    defaultMessage: 'Calculation error',
  },
  totalAmount: {
    id: 'BuyNewDeviceFormMessages.totalAmount',
    defaultMessage: 'Amount:',
  },
  buy: {
    id: 'BuyNewDeviceFormMessages.buy',
    defaultMessage: 'Buy',
  },
  willBeAvailableLater: {
    id: 'BuyNewDeviceFormMessages.willBeAvailableLater',
    defaultMessage: 'Device purchase will be available later',
  },
  save: {
    id: 'BuyNewDeviceFormMessages.save',
    defaultMessage: 'Save',
  },
  callToUs: {
    id: 'BuyNewDeviceFormMessages.callToUs',
    defaultMessage:
      'When ordering more than 80 modems, our manager will contact you',
  },
  create: {
    id: 'BuyNewDeviceFormMessages.create',
    defaultMessage: 'Create',
  },
})

export const BuyNewDeviceForm: FC<BuyNewDeviceFormProps> = (props) => {
  const { defaultMonthPlanCount, defaultAnnualPlanCount } = props

  const intl = useIntl()
  const { handleHideModal } = useModal()
  const { getTariffPackagePrice, getDevicePrice, getTotalPrices } =
    useTariffPackages()
  const [monthPlanCount, setMonthPlanCount] = useState<number>(
    defaultMonthPlanCount,
  )
  const [annualPlanCount, setAnnualPlanCount] = useState<number>(
    defaultAnnualPlanCount,
  )
  const [monthPlanTotalPrice, setMonthPlanTotalPrice] = useState<string>(
    formatPrice(0),
  )
  const [annualPlanTotalPrice, setAnnualPlanTotalPrice] = useState<string>(
    formatPrice(0),
  )
  const [totalPrice, setTotalPrice] = useState<string>(formatPrice(0))
  const [priceCalculationLoading, setPriceCalculationLoading] =
    useState<boolean>(false)

  const [initialValues, setInitialValues] = useState<Partial<ProfileFormType>>(
    {},
  )
  const { ruleNotIncludeNumbers } = useFormRules()
  const { email, phone, firstName } = useAppSelector((state) => state.user)

  const href = window.location.href
  const USER_CAN_CREATE_ORDER =
    href.includes(':3000') || href.includes('-stage')
  const MANAGER_COUNT_LIMIT = USER_CAN_CREATE_ORDER ? 80 : 0

  const monthPlanPrice = getTariffPackagePrice(TARIFF_PACKAGE_ID.MONTH) || 0
  const monthPlanDevicePrice = getDevicePrice(TARIFF_PACKAGE_ID.MONTH) || 0
  const annualPlanPrice = getTariffPackagePrice(TARIFF_PACKAGE_ID.ANNUAL) || 0

  const isLessThenMobileLK = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const { siteDomainId } = useSiteVersion()

  useEffect(() => {
    setInitialValues({
      firstName: firstName,
      email: email,
      userPhone: phone,
    })
  }, [])

  const getCalculatedPrice = (
    monthPlanCount: number,
    annualPlanCount: number,
  ) => {
    if (monthPlanCount <= 0 && annualPlanCount <= 0) {
      setTotalPrice(formatPrice(null))
      setMonthPlanTotalPrice(formatPrice(null))
      setAnnualPlanTotalPrice(formatPrice(null))
      setPriceCalculationLoading(false)
      return
    }

    getTotalPrices([
      { tariffPackageId: TARIFF_PACKAGE_ID.MONTH, count: monthPlanCount },
      { tariffPackageId: TARIFF_PACKAGE_ID.ANNUAL, count: annualPlanCount },
    ])
      .then((data) => {
        setTotalPrice(data.price)
        const monthPrice = data.tariffPackagePrices.hasOwnProperty(
          TARIFF_PACKAGE_ID.MONTH,
        )
          ? data.tariffPackagePrices[TARIFF_PACKAGE_ID.MONTH]
          : formatPrice(null)
        const annualPrice = data.tariffPackagePrices.hasOwnProperty(
          TARIFF_PACKAGE_ID.ANNUAL,
        )
          ? data.tariffPackagePrices[TARIFF_PACKAGE_ID.ANNUAL]
          : formatPrice(null)
        setMonthPlanTotalPrice(monthPrice)
        setAnnualPlanTotalPrice(annualPrice)
      })
      .finally(() => {
        setPriceCalculationLoading(false)
      })
  }

  const debounceGetCalculatedPrice = useMemo(
    () => debounce<typeof getCalculatedPrice>(getCalculatedPrice, 1000),
    [],
  )

  useEffect(() => {
    setPriceCalculationLoading(true)
    void debounceGetCalculatedPrice(monthPlanCount, annualPlanCount)
  }, [monthPlanCount, annualPlanCount])

  const isBuyNowDisabled = useMemo(
    () => monthPlanCount + annualPlanCount === 0 || priceCalculationLoading,
    [totalPrice, priceCalculationLoading],
  )

  const handleBuyNow = () => {
    if (!isBuyNowDisabled) {
      globalHistory.push({
        pathname: RoutePath[Route.PurchaseProcess],
        search: `?plan_count=${TARIFF_PACKAGE_ID.MONTH}_${monthPlanCount}&plan_count=${TARIFF_PACKAGE_ID.ANNUAL}_${annualPlanCount}`,
      })
      handleHideModal()
    }
  }

  const monthlyDetails: ProductCardDetailType[] = [
    {
      title: intl.formatMessage(BuyNewDeviceFormMessages.monthlyPrice),
      value: formatPrice(monthPlanPrice),
    },
    {
      title: intl.formatMessage(BuyNewDeviceFormMessages.usbModem),
      value: formatPrice(monthPlanDevicePrice),
    },
    {
      title: intl.formatMessage(BuyNewDeviceFormMessages.delivery),
      valueSmaller: intl.formatMessage(
        BuyNewDeviceFormMessages.willBeCalculated,
      ),
    },
  ]

  const annualDetails: ProductCardDetailType[] = [
    {
      title: intl.formatMessage(BuyNewDeviceFormMessages.priceForTwelveMonths),
      value: formatPrice(annualPlanPrice),
      //TODO DISCOUNT RESEARCH
      valueGreenCrossedOut: formatPrice(600),
    },
    {
      title: intl.formatMessage(BuyNewDeviceFormMessages.usbModem),
      valueGreen: intl.formatMessage(BuyNewDeviceFormMessages.free),
    },
    {
      title: intl.formatMessage(BuyNewDeviceFormMessages.delivery),
      valueGreen: intl.formatMessage(BuyNewDeviceFormMessages.free),
    },
  ]

  const submitButtonTextDesktop =
    defaultMonthPlanCount || defaultAnnualPlanCount
      ? intl.formatMessage(BuyNewDeviceFormMessages.save)
      : intl.formatMessage(BuyNewDeviceFormMessages.buy)

  const submitButtonTextMobile = intl.formatMessage(
    BuyNewDeviceFormMessages.create,
  )

  const TotalAmount = () =>
    isLessThenMobileLK ? <TotalAmountMobile /> : <TotalAmountDesktop />

  const TotalAmountDesktop = () => (
    <div className={styles.TotalAmount}>
      <div className={styles.TotalAmount__title}>
        {intl.formatMessage(BuyNewDeviceFormMessages.totalAmount)}
      </div>
      <div className={styles.TotalAmount__value}>
        {priceCalculationLoading || totalPrice === 'undefined' ? (
          <Loader isSmall />
        ) : (
          totalPrice
        )}
      </div>

      <Button
        text={submitButtonTextDesktop}
        onClick={handleBuyNow}
        additionalClassNames={[styles.TotalAmount__btn]}
        disabled={isBuyNowDisabled}
        loading={priceCalculationLoading}
      />
    </div>
  )

  const TotalAmountMobile = () => (
    <Button
      text={submitButtonTextMobile}
      onClick={handleBuyNow}
      additionalClassNames={[styles.TotalAmount__btn]}
      disabled={isBuyNowDisabled}
      loading={priceCalculationLoading}
    />
  )

  // TODO: Remove this copypaste and merge to one component
  const onSubmit = async (values: ContactUsFormType) => {
    try {
      await callbackRequestFormDatagateBuyDevice({
        name: values.firstName,
        phone: getClearedPhoneNumber(values.userPhone),
        email: email ? email : '',
        site_domain_id: siteDomainId,
      })

      handleHideModal()

      setTimeout(() => {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.success,
          text: intl.formatMessage(ContactUsFormMessages.success),
        })
      }, 300)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ContactUsFormMessages.error),
        ),
      })
    }
  }

  const CallToUs = () => (
    <div className={styles.CallToUs}>
      <div className={styles.FormContainer}>
        <div className={styles.FormTitle}>
          {intl.formatMessage(BuyNewDeviceFormMessages.callToUs)}
        </div>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={styles.Form}>
              <div className={styles.ItemName}>
                <InputField
                  name="firstName"
                  validate={ruleNotIncludeNumbers(true)}
                  label={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
                  placeholder={intl.formatMessage(
                    ProfileFormMessages.firstNameLabel,
                  )}
                  markAsRequired={true}
                />
              </div>
              <div className={styles.ItemPhone}>
                <PhoneInputFieldNumber
                  name="userPhone"
                  label={intl.formatMessage(
                    ProfileFormMessages.phoneNumberLabel,
                  )}
                  initialValue={phone}
                  markAsRequired={true}
                  noHelperText={true}
                />
              </div>
              <div className={styles.ItemButton}>
                <Button
                  text={intl.formatMessage(ContactUsFormMessages.submit)}
                  onClick={handleSubmit}
                  loading={submitting}
                  variant={'green'}
                  additionalClassNames={[styles.Button]}
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  )

  return (
    <div className={styles.Content}>
      <ProductCounterCard
        title={intl.formatMessage(BuyNewDeviceFormMessages.monthlyTitle)}
        details={monthlyDetails}
        count={monthPlanCount}
        setCount={setMonthPlanCount}
        amount={monthPlanTotalPrice}
      />
      <ProductCounterCard
        title={intl.formatMessage(BuyNewDeviceFormMessages.annualTitle)}
        subtitle={intl.formatMessage(BuyNewDeviceFormMessages.discount, {
          discount: 20,
        })}
        details={annualDetails}
        count={annualPlanCount}
        setCount={setAnnualPlanCount}
        amount={annualPlanTotalPrice}
      />

      {monthPlanCount + annualPlanCount > MANAGER_COUNT_LIMIT ? (
        <CallToUs />
      ) : (
        <TotalAmount />
      )}
    </div>
  )
}
