import React from 'react'
import clsx from 'clsx'
import { ReactComponent as PlusInCircleIcon } from '../../../../assets/icons/v2/ic-plus-in-circle.svg'
import { formatFileSize } from '../../../lib/utils/format-file-size/formatFileSize'
import {
  WavSurfer,
  WavSurferThemes,
} from '../../../ui-kit-2/components/wav-surfer/WavSurfer'
import { IconButton } from '../../../ui-kit-2/inputs/icon-button/IconButton'
import { AudioUploadProps } from './AudioUpload.types'
import styles from './styles.module.scss'

const AudioUpload = ({
  uploadedFile,
  setUploadedFile,
  errors,
  setErrors,
  handleClear,
  cdnUrl,
}: AudioUploadProps) => {
  const validateUploadedFile = async (uploadedFile: File) => {
    if (uploadedFile.size >= 2 * 1024 * 1024) {
      setErrors({ uploadedFile: 'File must be have a size 2 MB' })
      return false
    }

    // const audioContext = new AudioContext();
    const fileReader = new FileReader()

    return new Promise((resolve) => {
      fileReader.onload = async () => {
        const arrayBuffer = fileReader.result

        if (arrayBuffer instanceof ArrayBuffer) {
          try {
            setErrors({ uploadedFile: false })
            resolve(true)
          } catch (error) {
            setErrors({ uploadedFile: 'Invalid audio file' })
            resolve(false)
          }
        } else {
          setErrors({ uploadedFile: 'Failed to read file' })
          resolve(false)
        }
      }

      fileReader.readAsArrayBuffer(uploadedFile)
    })
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0]
    if (file && (await validateUploadedFile(file))) {
      setUploadedFile(file)
    }
  }

  return uploadedFile || cdnUrl ? (
    <div
      className={clsx(
        styles.UploadedFile,
        errors?.uploadedFile && styles.UploadedFile_error,
      )}
    >
      <div className={styles.UploadedFile__Container}>
        <div>
          <WavSurfer
            blobData={
              !cdnUrl && uploadedFile
                ? new Blob([uploadedFile], { type: uploadedFile.type })
                : undefined
            }
            audioSource={cdnUrl}
            audioWavesWidth={200}
            theme={WavSurferThemes.black}
          />
        </div>
        <div className={styles.UploadedFile__Delete}>
          <IconButton
            icon={'trash'}
            onClick={() => {
              setUploadedFile(null)
              handleClear()
            }}
          />
        </div>
      </div>
      {uploadedFile && (
        <div className={styles.UploadedFile__Info}>
          <div className={styles.UploadedFile__Name}>{uploadedFile.name}</div>
          <div className={styles.UploadedFile__Size}>
            {formatFileSize(uploadedFile.size)}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div
      className={clsx(
        styles.AudioUpload,
        errors?.uploadedFile && styles.AudioUpload_error,
      )}
    >
      <label className={styles.UploadBox}>
        <div className={styles.Row}>
          <div className={styles.Icon}>
            <PlusInCircleIcon />
          </div>
          <div>
            Drag & Drop or{' '}
            <span className={styles.Link}>Choose audio file</span> to upload
          </div>
          <input
            type="file"
            accept=".aac,.m4a,.m4r,.mp3,.ogg,.wav"
            onChange={handleFileChange}
            className={styles.FileInput}
          />
        </div>
        <div className={styles.Instructions}>
          Max. 2Mb, Acceptable formats: AAC, M4A, M4R, MP3, OGG, WAV
        </div>
      </label>
    </div>
  )
}

export default AudioUpload
