import {
  OrderDeliveryValidateBackendResponse,
  OrderDeliveryValidateResponse,
} from '../../../types/order/order-delivery-validate'

export const mapOrderDeliveryValidate = (
  item: OrderDeliveryValidateBackendResponse,
): OrderDeliveryValidateResponse => {
  return {
    errors: item.data?.errors,
  }
}
