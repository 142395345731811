import React, { useMemo, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../app/hooks/useModal'
import { useSnackbar } from '../../../../app/hooks/useSnackbar'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import { PaymentMethodCard } from '../../../../pages/lk/subpages/purchase-process/components/purchase-step-payment/components/PaymentMethodCard/PaymentMethodCard'
import { BankCardPaymentMethod } from '../../../../pages/lk/subpages/purchase-process/components/purchase-step-payment/components/paymentsMethods/BankCardPaymentMethod/BankCardPaymentMethod'
import { LegalEntitiesPaymentMethod } from '../../../../pages/lk/subpages/purchase-process/components/purchase-step-payment/components/paymentsMethods/LegalEntitiesPaymentMethod/LegalEntitiesPaymentMethod'
import { BalancePaymentMethod } from '../../../../pages/lk/subpages/purchase-process/components/purchase-step-payment/components/paymentsMethods/TelecornBalancePaymentMethod/BalancePaymentMethod'
import { PaymentMethods } from '../../../../pages/lk/subpages/purchase-process/components/purchase-step-payment/PurchaseStepPayment'
import { donglePayDatagate } from '../../../../sdk/datagates/api/dongle'
import {
  PAYMENT_TYPE_IDS,
  TARIFF_PACKAGE_TYPE_IDS,
} from '../../../../sdk/datagates/types/dongle/pay/_common'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import { TextButton } from '../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { useAppSelector } from '../../../../store'
import { LoadingContent } from '../../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../../snackbar/SnackbarProvider'
import { MODAL_TYPES } from '../../ModalsProvider'
import { UsePayDeviceMessages } from '../use-pay-device/usePayDevice'
import styles from './styles.module.scss'

const UsePayOrChangeSubscriptionMessage = defineMessages({
  back: {
    id: 'UsePayOrChangeSubscriptionMessage.back',
    defaultMessage: 'Back',
  },
  bankCard: {
    id: 'UsePayOrChangeSubscriptionMessage.bankCard',
    defaultMessage: 'Bank Card',
  },
  balanceCard: {
    id: 'UsePayOrChangeSubscriptionMessage.balanceCard',
    defaultMessage: 'Teleleo balance',
  },
  legalEntityCard: {
    id: 'UsePayOrChangeSubscriptionMessage.legalEntityCard',
    defaultMessage: 'Payment by details',
  },
  dongleNotFound: {
    id: 'UsePayOrChangeSubscriptionMessage.dongleNotFound',
    defaultMessage: 'Dongle is not found',
  },
})
interface UsePayOrChangeSubscriptionProps {
  isPayOrChangeSubscription: boolean
  setIsPayOrChangeSubscription: React.Dispatch<React.SetStateAction<boolean>>
  tariffPackageId: number
  setIsPayDeviceOpen: React.Dispatch<React.SetStateAction<boolean>>
  dongleId: number
}
export const UsePayOrChangeSubscription = (
  props: UsePayOrChangeSubscriptionProps,
) => {
  const {
    setIsPayOrChangeSubscription,
    isPayOrChangeSubscription,
    tariffPackageId,
    setIsPayDeviceOpen,
    dongleId,
  } = props
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const { handleOpenModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()
  const { balance } = useAppSelector((state) => state.user)
  const [paymentMethod, setPaymentMethod] = useState<number>(
    PaymentMethods.CARD,
  )
  const [loading, setLoading] = useState<boolean>(false)

  const dongle = {
    totalPrice:
      tariffPackageId === TARIFF_PACKAGE_TYPE_IDS.MONTH_EUROPE ? 50 : 1000,
  }

  const balanceNotEnoughAmount = useMemo(() => {
    if (!dongle || !dongle.totalPrice || dongle.totalPrice <= balance)
      return null

    return Math.ceil(dongle.totalPrice - balance)
  }, [dongle, balance])

  const isPayWithBalanceDisabled = useMemo(() => {
    return !!(dongle && dongle.totalPrice && dongle.totalPrice > balance)
  }, [balance, dongle])

  const onClose = () => {
    setIsPayOrChangeSubscription(false)
  }

  const handlePayClick = async () => {
    try {
      const urlParams = [{ name: 'dongle_id', value: dongleId.toString() }]

      switch (paymentMethod) {
        case PaymentMethods.CARD:
          handleOpenModal({
            type: MODAL_TYPES.PAYMENT_BANK_CARD_CHOOSE,
            props: {
              dongleId: dongleId,
              isDonglePay: true,
              tariffPackageId: tariffPackageId,
            },
          })
          return
        case PaymentMethods.BALANCE:
          await donglePayDatagate(
            {
              invoice_type_id: PAYMENT_TYPE_IDS.DONGLE_BALANCE,
              tariff_package_id: tariffPackageId,
              payment_method_id: null,
              payment_provider_id: null,
            },
            urlParams,
          )
          return
        case PaymentMethods.WIRE_TRANSFER:
          await donglePayDatagate(
            {
              invoice_type_id: PAYMENT_TYPE_IDS.DONGLE_WIRE,
              tariff_package_id: tariffPackageId,
              payment_method_id: null,
              payment_provider_id: null,
            },
            urlParams,
          )
          handleOpenModal({
            type: MODAL_TYPES.WIRE_TRANSFER_SUCCESS,
            props: {
              isDonglePay: true,
            },
          })
      }
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(
          UsePayOrChangeSubscriptionMessage.dongleNotFound,
        ),
      })
    } finally {
      setLoading(false)
    }
  }

  const handleOpenUsePayDevice = () => {
    setIsPayOrChangeSubscription(false)
    setIsPayDeviceOpen(true)
  }

  useClickOutside(ref, onClose)

  const content = (
    <div className={styles.PaymentsWrapper}>
      <PaymentMethodCard
        title={intl.formatMessage(UsePayOrChangeSubscriptionMessage.bankCard)}
        paymentMethodType={PaymentMethods.CARD}
        children={<BankCardPaymentMethod />}
        currentMethodType={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
      <PaymentMethodCard
        title={intl.formatMessage(
          UsePayOrChangeSubscriptionMessage.balanceCard,
        )}
        children={
          <BalancePaymentMethod
            balanceNotEnoughAmount={balanceNotEnoughAmount}
          />
        }
        paymentMethodType={PaymentMethods.BALANCE}
        currentMethodType={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        disabled={isPayWithBalanceDisabled}
      />
      <PaymentMethodCard
        title={intl.formatMessage(
          UsePayOrChangeSubscriptionMessage.legalEntityCard,
        )}
        children={<LegalEntitiesPaymentMethod />}
        paymentMethodType={PaymentMethods.WIRE_TRANSFER}
        currentMethodType={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
    </div>
  )

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(UsePayDeviceMessages.title)}
          </div>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>
        <TextButton
          type={'button'}
          text={intl.formatMessage(UsePayOrChangeSubscriptionMessage.back)}
          icon={'caret-left'}
          onClick={handleOpenUsePayDevice}
        />
        <LoadingContent loading={false} content={content} />
        <Button
          text={intl.formatMessage(UsePayDeviceMessages.pay)}
          onClick={() => handlePayClick()}
        />
      </Card>
    </div>
  )

  return {
    component: isPayOrChangeSubscription ? component : null,
  }
}
