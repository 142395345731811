import { FC, ReactNode, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useClickOutside } from '../../../lib/hooks/useClickOutside'
import styles from './styles.module.scss'

interface ICenteredDrawer {
  children: ReactNode
  isOpen: boolean
  close: () => void
  dataTestId?: string
}

export const CenteredDrawer: FC<ICenteredDrawer> = (props) => {
  const { children, isOpen, close, dataTestId } = props

  const areaEl = useRef<HTMLDivElement>(null)

  useClickOutside(areaEl, close)

  if (!isOpen) return null

  const drawer = (
    <div className={styles.Drawer}>
      <div
        className={styles.Content}
        data-test-id={dataTestId}
        ref={areaEl}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )

  return createPortal(drawer, document.body)
}
