import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { isNumber } from 'lodash'
import { defineMessages, useIntl } from 'react-intl'
import { TextButton } from '../../shared/ui-kit-2/inputs/text-button/TextButton'
import { LC } from '../../tests/e2e/locators'
import styles from './styles.module.scss'

const useVerificationCodeInputMessages = defineMessages({
  didntGetCode: {
    id: 'useVerificationCodeInputMessages.didntGetCode',
    defaultMessage: "Didn't get the code?",
  },
  clickToResend: {
    id: 'useVerificationCodeInputMessages.clickToResend',
    defaultMessage: 'Click to resend',
  },
  timer: {
    id: 'useVerificationCodeInputMessages.timer',
    defaultMessage: 'The code can be resent in {time} sec',
  },
})

// TODO: props to obj
export const useVerificationCodeInput = (
  onEnterComplete: (code: string) => any,
  isLoading: boolean,
  hasErrors: boolean,
  getCode?: () => any,
  numFields: number = 6,
  defaultValue?: string | null,
  regex?: RegExp,
) => {
  const intl = useIntl()
  const [fullCode, setFullCode] = useState<string>(defaultValue || '')
  const [timer, setTimer] = useState<number | undefined>(undefined)

  const refs = useRef(
    Array.from({ length: numFields }, () =>
      React.createRef<HTMLInputElement>(),
    ),
  )
  const [values, setValues] = useState<Array<string | undefined>>(() =>
    Array(numFields)
      .fill('')
      .map((field, index) => defaultValue?.[index] || field),
  )

  useEffect(() => {
    setTimer(60)
  }, [])

  useEffect(() => {
    if (timer) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    } else if (timer === 0) {
      setTimer(undefined)
    }
  }, [timer])

  useEffect(() => {
    setFullCode(values.join(''))
  }, [values])

  useEffect(() => {
    if (fullCode.length === numFields) {
      onEnterComplete(fullCode)
    }
  }, [fullCode])

  const handleChange = (index: number, value: string) => {
    if (value.match(regex ? regex : /^[0-9]+$/)) {
      const newValues = [...values]

      if (
        newValues[index] !== '' &&
        index !== newValues.length - 1 &&
        newValues[index + 1] === ''
      ) {
        newValues[index + 1] = value.slice(-1)
      } else if (newValues[index] === '') {
        newValues[index] = value
      }

      setValues(newValues)

      const nextEmptyIndex = newValues.findIndex(
        (v, i) => !isNumber(v) && i > index,
      )
      if (nextEmptyIndex !== -1) {
        refs.current[nextEmptyIndex].current?.focus()
      } else if (index < numFields - 1) {
        refs.current[index + 1].current?.focus()
      }
    }
  }

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const newValues = [...values]
      newValues[index] = ''
      setValues(newValues)
      if (index > 0) refs.current[index - 1].current?.focus()
    }
  }
  const handlePaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    e.preventDefault()
    const pastedValue = e.clipboardData.getData('text').replace(/\D/g, '')
    if (!pastedValue) return

    const newValues = [...values]
    let insertIndex = index

    if (newValues[insertIndex] && insertIndex < numFields - 1) {
      insertIndex++
    }

    for (let char of pastedValue) {
      if (insertIndex >= numFields || newValues[insertIndex]) break
      newValues[insertIndex] = char
      insertIndex++
    }

    setValues(newValues)

    const lastInsertedIndex = Math.min(insertIndex - 1, numFields - 1)
    refs.current[lastInsertedIndex]?.current?.focus()
  }

  const handleClickToResend = () => {
    setTimer(60)
    getCode?.()
  }

  const component = (
    <div className={styles.Container}>
      <div className={styles.InputsRow}>
        {values.map((val, index) => (
          <input
            key={index}
            type="tel"
            className={clsx(
              styles.Input,
              hasErrors && styles.Input_error,
              isLoading && styles.Input_disabled,
            )}
            placeholder={'0'}
            ref={refs.current[index]}
            value={val}
            disabled={isLoading}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={(e) => handlePaste(e, index)}
            data-test-id={LC.FORGOT_PASSWORD_PAGE.VERIFICATION_CODE(index)}
          />
        ))}
      </div>

      {getCode && (
        <div className={styles.Bottom}>
          <div className={styles.DidntGetCode}>
            {timer
              ? intl.formatMessage(useVerificationCodeInputMessages.timer, {
                  time: timer,
                })
              : intl.formatMessage(
                  useVerificationCodeInputMessages.didntGetCode,
                )}
          </div>

          <TextButton
            variant={'bold'}
            text={intl.formatMessage(
              useVerificationCodeInputMessages.clickToResend,
            )}
            onClick={handleClickToResend}
            disabled={!!timer}
            additionalClassNames={[styles.ClickToResend]}
            testId={LC.FORGOT_PASSWORD_PAGE.RESEND_BTN}
          />
        </div>
      )}
    </div>
  )

  return {
    fullCode,
    component,
  }
}
