import React, { createContext, ReactNode, useContext } from 'react'

interface NotificationsContextType {
  handleFetchAllNotifications: () => void
}

export const NotificationsContext = createContext<NotificationsContextType>({
  handleFetchAllNotifications: () => {},
})

export const NotificationsProvider: React.FC<{
  handleFetchAllNotifications: () => void
  children: ReactNode
}> = ({ handleFetchAllNotifications, children }) => {
  return (
    <NotificationsContext.Provider value={{ handleFetchAllNotifications }}>
      {children}
    </NotificationsContext.Provider>
  )
}
