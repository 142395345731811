import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearModal, setModal, updateModal } from '../../store/reducers/modal'

export const useModal = () => {
  // @ts-ignore
  const { type, props } = useSelector((store) => store.modal)
  const dispatch = useDispatch()

  const handleHideModal = () => {
    dispatch(clearModal({}))
  }

  const handleOpenModal = ({ type, props }: any) => {
    dispatch(
      setModal({
        type,
        props: {
          handleClose: handleHideModal,
          ...props,
        },
      }),
    )
  }

  const handleChangeModal = (newProps: any) => {
    dispatch(
      updateModal({
        props: {
          handleClose: handleHideModal,
          ...props,
          ...newProps,
        },
      }),
    )
  }

  return {
    handleOpenModal,
    handleHideModal,
    handleChangeModal,
    type,
    props,
  }
}
