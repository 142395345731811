import React from 'react'
import clsx from 'clsx'
import { SendSmsModalMessages } from 'layouts/modals/SendSmsModal/SendSmsModal'
import { defineMessages, useIntl } from 'react-intl'
import { useDialog } from '../../../../../app/hooks/useDialog'
import { useModal } from '../../../../../app/hooks/useModal'
import { useSnackbar } from '../../../../../app/hooks/useSnackbar'
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg'
import { ReactComponent as CaretRightIcon } from '../../../../../assets/icons/caret-right.svg'
import { ReactComponent as ReceivedIcon } from '../../../../../assets/icons/received-mini.svg'
import { ReactComponent as SentIcon } from '../../../../../assets/icons/sent-mini.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg'
import { ReactComponent as ReplyIcon } from '../../../../../assets/icons/v2/ic-reply.svg'
import { ReactComponent as RetryIcon } from '../../../../../assets/icons/v2/ic-retry.svg'
import { SMS_STATUSES } from '../../../../../pages/lk/subpages/sms/components/SmsStatus/constants/SmsStatuses'
import { SmsStatus } from '../../../../../pages/lk/subpages/sms/components/SmsStatus/SmsStatus'
import { dongleSmsCreateDatagate } from '../../../../../sdk/datagates/api/dongle'
import { useBackendErrorCodes } from '../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { QueryParameter } from '../../../../../sdk/datagates/helpers/_common/wrap-api-request'
import { DongleSmsCreateRequest } from '../../../../../sdk/datagates/types/dongle/sms/_crud/create'
import { SmsListResponse } from '../../../../../sdk/datagates/types/sms/_crud/list'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import { CallType } from '../../../../../sdk/hooks/use-calls/useCalls'
import {
  canSmsBeReplied,
  canSmsBeRetrying,
  isDeviceSender,
  isSmsAutoReplied,
  isSmsManuallyReplied,
  isSmsRedirected,
  SmsType,
} from '../../../../../sdk/hooks/use-smses/useSmses'
import { formatDate as formatDateV2 } from '../../../../../shared/lib/form/form-field-adapters/v2/datepicker-field/_helpers/formatDate'
import { ObjectEntries } from '../../../../../shared/lib/types/objectEntries'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { getClearedPhoneNumber } from '../../../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber'
import { getErrorMessage } from '../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { getSmsCenter } from '../../../../../shared/lib/utils/get-sms-center/getSmsCenter'
import { parseStringToDate } from '../../../../../shared/lib/utils/parseStringToDate/parseStringToDate'
import { RowGroupType } from '../../../../../shared/ui-kit-2/data-display/table/Table'
import { BUTTON_VARIANT_LIST } from '../../../../../shared/ui-kit-2/inputs/button/Button'
import IconButton from '../../../../../shared/ui-kit-3/components/IconButton/IconButton'
import { DialogVersion } from '../../../../../store/reducers/dialog/types'
import { LC } from '../../../../../tests/e2e/locators'
import { SmsTypeBadge } from '../../../../custom-badges/sms-type-badge/CallTypeBadge'
import { MODAL_TYPES } from '../../../../modals/ModalsProvider'
import { SNACKBAR_TYPES } from '../../../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

type SmsGroupsType = {
  [key: string]: SmsListResponse['smses']
}

const SmsTableRowsMessages = defineMessages({
  groupHeaderSent: {
    id: 'SmsTableRowsMessages.groupHeaderSent',
    defaultMessage: 'Sent: {count}',
  },
  groupHeaderReceived: {
    id: 'SmsTableRowsMessages.groupHeaderReceived',
    defaultMessage: 'Received: {count}',
  },
  deletingItemName: {
    id: 'SmsTableRowsMessages.deletingItemName',
    defaultMessage: 'the sms',
  },
  reply: {
    id: 'SmsTableRowsMessages.reply',
    defaultMessage: 'Reply',
  },
})

export const SmsTableRows = (
  smses: SmsListResponse['smses'],
  handleFetch: (props: {
    params?: QueryParameter[] | undefined
    hidden?: boolean | undefined
  }) => Promise<void>,
  handleDeleteSms: (dongleId: number, smsId: number) => Promise<void>,
  settings?: Record<string, any>,
): RowGroupType[] => {
  const intl = useIntl()
  const { handleOpenDialog, handleHideDialog } = useDialog()
  const { handleOpenModal, handleHideModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()

  const smsGroups = smses.reduce<SmsGroupsType>((acc, sms) => {
    const date = formatDate(sms.createdAt, 0, 'date')
    if (acc.hasOwnProperty(date)) acc[date as keyof typeof acc].push(sms)
    else acc[date as keyof typeof acc] = [sms]

    return acc
  }, {})

  const handleOpenSmsInfoModal = (
    sms: SmsListResponse['smses'][0],
    forceAction: 'reply' | 'delete' | null = null,
  ) => {
    handleOpenModal({
      type: MODAL_TYPES.SMS_VIEW,
      props: {
        sms,
        forceAction,
        onDeleteSms: () => handleDeleteSms(sms.dongleId, sms.smsId),
        handleFetch,
      },
    })
  }

  const handleAddSms = async (values: {
    dongleId: number
    phone: string
    message: string
    scheduled_at?: string
  }) => {
    try {
      const urlParams = [
        { name: 'dongle_id', value: values.dongleId.toString() },
      ]

      let reqBody: DongleSmsCreateRequest = {
        message: values.message,
      }

      if (values.scheduled_at)
        reqBody.scheduled_at = formatDateV2(values.scheduled_at, true)
      // if (savedContact) reqBody.contact_id = savedContact.contactId
      else reqBody.number = `+${getClearedPhoneNumber(values.phone)}`

      await dongleSmsCreateDatagate(reqBody, urlParams)

      // @ts-ignore
      await handleFetch?.()

      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(
          values.scheduled_at
            ? SendSmsModalMessages.positiveCreated
            : SendSmsModalMessages.positiveSent,
        ),
      })
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(
            values.scheduled_at
              ? SendSmsModalMessages.negativeCreated
              : SendSmsModalMessages.negativeSent,
          ),
        ),
      })
    }
  }

  const handleRetrySms = (sms: SmsListResponse['smses'][0]) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Send this SMS again?',
        subtitle: 'This action cannot be undone',
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog(),
        },
        submit: {
          variant: BUTTON_VARIANT_LIST.greenTextOutlined,
          icon: <RetryIcon />,
          text: 'Send again',
          onClick: () => {
            handleAddSms({
              dongleId: sms.dongleId,
              phone: sms.numberReceiver,
              message: sms.messageDecoded,
            })
            handleHideDialog()
          },
        },
      },
    })
  }

  const sortSms = (smsArray: SmsListResponse['smses']) => {
    const sortedArray = [...smsArray]

    const SORTED_COLS = {
      status: 1,
      time: 2,
    }

    if (settings?.sortBy === SORTED_COLS.status) {
      return sortedArray.sort((a, b) => {
        const dateA = a.createdAt.getTime()
        const dateB = b.createdAt.getTime()

        if (
          a.smsStatusId === SMS_STATUSES.DELIVERED &&
          b.smsStatusId === SMS_STATUSES.DELIVERED
        ) {
          return dateB - dateA
        }

        if (a.smsStatusId === SMS_STATUSES.DELIVERED) {
          return settings?.orderBy === 'asc' ? 1 : -1
        }

        if (b.smsStatusId === SMS_STATUSES.DELIVERED) {
          return settings?.orderBy === 'asc' ? -1 : 1
        }

        return dateB - dateA
      })
    }

    if (settings?.sortBy === SORTED_COLS.time) {
      return sortedArray.sort((a, b) => {
        const dateA = a.createdAt.getTime()
        const dateB = b.createdAt.getTime()
        return settings?.orderBy === 'desc' ? dateB - dateA : dateA - dateB
      })
    }

    return sortedArray
  }

  const sortEntriesByDate = (entries: ObjectEntries<SmsGroupsType>[]) => {
    const sortedArray = [...entries]

    sortedArray.sort((a, b) => {
      const dateA = parseStringToDate(a[0]).getTime()
      const dateB = parseStringToDate(b[0]).getTime()
      return dateB - dateA
    })

    return sortedArray
  }

  return sortEntriesByDate(Object.entries(smsGroups)).map<RowGroupType>(
    ([date, smses], index) => {
      return {
        groupHeader: (
          <div className={styles.Header}>
            <div
              className={styles.Header__date}
              data-test-id={LC.SMS.TABLE.ROW.DATE(index)}
            >
              {date}
            </div>
            <div className={styles.Header__sentAndReceived}>
              <div className={styles.Header__sentAndReceived__item}>
                <SentIcon />
                <div>
                  {intl.formatMessage(SmsTableRowsMessages.groupHeaderSent, {
                    count: smses.filter((s) =>
                      [
                        SmsType.OUTGOING,
                        SmsType.REPLY,
                        SmsType.AUTO_REPLY,
                        SmsType.MAILING,
                      ].includes(s.smsTypeId),
                    ).length,
                  })}
                </div>
              </div>

              <div className={styles.Header__sentAndReceived__item}>
                <ReceivedIcon />
                <div>
                  {intl.formatMessage(
                    SmsTableRowsMessages.groupHeaderReceived,
                    {
                      count: smses.filter(
                        (s) =>
                          s.smsTypeId === CallType.INCOMING ||
                          s.smsTypeId === SmsType.SILENT,
                      ).length,
                    },
                  )}
                </div>
              </div>
            </div>
          </div>
        ),
        rows: sortSms(smses).map((sms) => {
          const sentFromDevice = isDeviceSender(sms.smsTypeId)

          const contactSenderName = sentFromDevice
            ? sms.dongleName
            : sms.contact
              ? sms.contact.name
              : ''
          const contactReceiverName = sentFromDevice
            ? sms.contact
              ? sms.contact.name
              : ''
            : sms.dongleName

          return [
            <SmsTypeBadge type={sms.smsTypeId} />,
            <div className={styles.Time}>
              {formatDate(sms.createdAt, 0, 'time')}
            </div>,
            <div>
              {SmsStatus({
                smsStatusId: sms.smsStatusId,
                testId: LC.SMS.TABLE.ROW.STATUS(index),
              })}
            </div>,
            <div className={styles.Contact}>
              <div className={styles.Contact__container}>
                <div
                  className={clsx(
                    styles.Contact__name,
                    sentFromDevice && styles.Contact__name_device,
                  )}
                  data-test-id={LC.SMS.TABLE.ROW.DEVICE_SENDER(index)}
                >
                  {contactSenderName}
                </div>
                <div
                  className={styles.Contact__phone}
                  data-test-id={LC.SMS.TABLE.ROW.SENDER(index)}
                >
                  {formatPhoneNumber(sms.numberCaller)}
                </div>
              </div>
              <ArrowRightIcon />
            </div>,
            <div className={styles.Contact}>
              <div className={styles.Contact__container}>
                <div
                  className={clsx(
                    styles.Contact__name,
                    !sentFromDevice && styles.Contact__name_device,
                  )}
                  data-test-id={LC.SMS.TABLE.ROW.DEVICE_RECEIVER(index)}
                >
                  {contactReceiverName}
                </div>
                <div
                  className={styles.Contact__phone}
                  data-test-id={LC.SMS.TABLE.ROW.RECEIVER(index)}
                >
                  {formatPhoneNumber(sms.numberReceiver)}
                </div>
              </div>
            </div>,
            <div className={styles.RightBordered}>
              {getSmsCenter(sms?.smsCenter)}
            </div>,
            <div
              className={styles.MessageText}
              data-test-id={LC.SMS.TABLE.ROW.TEXT(index)}
            >
              {sms.messageDecoded}
            </div>,
            <div className={styles.Actions}>
              {canSmsBeReplied(sms) && (
                <IconButton
                  onClick={() => handleOpenSmsInfoModal(sms, 'reply')}
                  icon={<ReplyIcon />}
                />
              )}

              {canSmsBeRetrying(sms) && (
                <IconButton
                  onClick={() => handleRetrySms(sms)}
                  icon={<RetryIcon />}
                />
              )}

              <IconButton
                onClick={() => handleOpenSmsInfoModal(sms, 'delete')}
                icon={<TrashIcon />}
              />

              <IconButton
                onClick={() => handleOpenSmsInfoModal(sms)}
                icon={<CaretRightIcon />}
              />
            </div>,
          ]
        }),
      }
    },
  )
}
